import React, { Component } from 'react';
import Search from './search';
import NewList from './newList';
import { connect } from "react-redux";
import EmptyComponent from '../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../store/actions";
import { GET_PAYROLL_BASED_ON_FILTER, GENERATE_PAYROLL, NEXT_INVOICE_PAYROLL } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI, getAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import { toFixed } from '../../../Utils/stringManupulation'

class NewPayroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [],
            apiData: [],
            isEmpty: false,
            filterData: null,
            payrollNumber:"",
            chqNumber:"",
            selected: new Set(),
            columns: [
                {
                    label: <label className="container">
                        <input
                            type="checkbox"
                            value={"all"}
                            className="form-control"
                            onClick={(event) => this.handleCheckboxAll(event, "all")}
                            // checked={() => this.checkAllChech()}
                        />
                        <span className="checkmark"></span>
                    </label>,
                    field: 'sro',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Issued To',
                    field: 'issued',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'SSN Number',
                    field: 'ssn',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'No of Shifts',
                    field: 'shifts',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Total Amount',
                    field: 'amount',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Total Hours',
                    field: 'hours',
                    sort: 'asc',
                    width: 150
                }
            ]
        }
    }

    checkAllChech() {
        //console.log(selectedQuestion);
        let { allData, selected } = this.state
        //console.log(levels);
        if (allData.length > 0) {
            var ret = false;
            for (var i = 0; i < allData.length; i++) {
                let ret = selected.has(allData[i].contractor);
                //console.log(questions[i].quest_id.toString()) ;
                if (ret === false) {
                    return false;
                }
            }
            return true;
        }
        else {
            return false;
        }
    }

    handleCheckboxAll = (event) => {
        let { apiData, selected } = this.state
        if (event.target.checked) {
            apiData.map((q, i) => {
                selected.add(q.contractor);
            })
        }
        else {
            apiData.map((q, i) => {
                selected.delete(q.contractor);
            })
        }
        this.setState({...this.state, selected})
    }

    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Bulk Orders", this.state.breadcrumbItems);
        this.getNextPayroll()
        let d = {
            "contractor": [
                13,
                33,
                37,
                10,
                20,
                2,
                35,
                29,
                19,
                16,
                14,
                23,
                11,
                5,
                21,
                22,
                27,
                15,
                3,
                7,
                8,
                32,
                40,
                39,
                28,
                36,
                12,
                30,
                9,
                4,
                17,
                18,
                26,
                6,
                25,
                24,
                1,
                38,
                31,
                34
            ],
            "client": [
                3,
                1,
                2,
                30,
                31,
                36,
                35,
                39,
                32,
                29,
                34,
                38,
                33
            ],
            "startDate": "2024-01-04",
            "endDate": "2024-04-18",
            chequeDate:"2024-04-18",
        }
        // this.handleFilter(d)
    }

    getNextPayroll = async () =>{
        let  res =  await getAdminAPI(NEXT_INVOICE_PAYROLL)
        if(res?.payrollNumber?.[0]?.max_payroll_number){
            this.setState({...this.state, payrollNumber:res.payrollNumber[0].max_payroll_number +1, chqNumber:res.payrollNumber[0].max_payroll_number +1})
        }
        else{
            this.setState({...this.state, payrollNumber:1, chqNumber:1})
        }
    }

    // Filter
    handleFilter = async (filterData) => {
        let _filterDate = { ...filterData }
        this.setState({ filterData: filterData })
        delete _filterDate.chequeDate;
        let result = await postAdminAPI(GET_PAYROLL_BASED_ON_FILTER, _filterDate)
        if (result.status) {
            if (result.data.length > 0) {
                // this.fetchAllData(result.data);
                this.setState({ allData: result.data })
            }
            else {
                this.setState({ isEmpty: true })
            }
        }

    }

    // AllNewData
    fetchAllData = (allData, selected) => {
        let _allData = []
        allData && allData.length > 0 && allData.map(a => {
            _allData.push({
                sro: <label className="container">
                    <input
                        type="checkbox"
                        value={"all"}
                        className="form-control"
                        onClick={(event) => this.handleCheckbox(event, a.contractor)}
                        checked={ selected ? selected.has(a.contractor) ? true : false : false}
                    />
                    <span className="checkmark"></span>
                </label>,
                issued: a.contractorName,
                ssn: a.ssn,
                shifts: a.totalShifts,
                amount: toFixed(a.total),
                hours: a.totalHours,
            })
        })
        return _allData;
        // this.setState({ allData: _allData })
    }

    handleCheckbox = (event, id) => {
        let { selected } = this.state
        if (event.target.checked) {
            selected.add(id);
        }
        else {
            selected.delete(id);
        }
        this.setState({...this.state, selected})
    }

    handleGeneratePayroll = async (e) => {
        e.preventDefault();
        notiflixLoaderStart();
        const { filterData, chqNumber, payrollNumber, selected } = this.state;
        let contractor = [...this.state.selected]
        if (filterData && contractor.length) {
            let data = {
                "contractors": contractor,
                "client": filterData.client,
                "startDate": filterData.startDate,
                "endDate": filterData.endDate,
                "chequeDate": filterData.chequeDate,
            }
            if(selected.size == 1 && chqNumber != payrollNumber){
                data.chqNumber = chqNumber
            }
            console.log(data)
            let result = await postAdminAPI(GENERATE_PAYROLL, data)
            console.log(result)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    this.props.history.push("/view_all_payroll/");
                }
            }
        }
        else{
            notificationStatus({status:false, message:"Please select atleast one contractor to proceed."})
        }
    }

    setChqNumber = (chqNumber) =>{
        this.setState({...this.state, chqNumber: chqNumber})
    }

    render() {

        const { allData, isEmpty, payrollNumber, selected, columns, filterData, chqNumber } = this.state;
        return (
            <>
                <Search
                    handleFilter={this.handleFilter}
                    allClients={this.props.basicDetails.allClients}
                    allContractor={this.props.basicDetails.allContractor}
                    chqNumber={chqNumber}
                    selected={selected}
                    setChqNumber={this.setChqNumber}
                />
                {
                    allData && allData.length > 0
                        ?
                        <NewList
                            // allData={this.fetchAllData(apiData, selected)}
                            columns={columns}
                            allData={this.fetchAllData(allData, selected)}
                            handleGeneratePayroll={this.handleGeneratePayroll}
                        />
                        : null

                }
                {
                    isEmpty
                        ?
                        <EmptyComponent />
                        : null

                }

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(NewPayroll);;