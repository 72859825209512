import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { days } from "../../../Utils/Variables/days";
import { changeTzToNormal,dateUSStandard } from "../../../Utils/dateFunctions"

const AvailabilityPopup = (props) => {

    // console.log(props)
    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmit(state, props.selectedDate, props.selectedDayNum)
        
        // console.log(props)
        // console.log(state)
    }

    useEffect(() => {
        if(props.selected && Object.keys(props.selected).length > 0){
            setState(props.selected)
        }
    }, [props])

    const [state, setState] = useState({
        day: 0,
        eve: 0,
        hours: "0",
        night: 0,
        not_available: 0,
        note: ""
    })

    const handleCheckbox = (e, type) => {
        // console.log(e.target.checked, type)
        let _state = { ...state }
        if (e.target.checked) {
            if(type === "not_available"){
                _state.day = 0;
                _state.eve = 0;
                _state.night = 0;
            }
            else if(type === "day" || type === "eve" || type === "night"){
                _state.not_available = 0;
            }
            _state[type] = 1
        }
        else {
            _state[type] = 0
        }
        setState(_state)
    }

    const handleChange = (e, type) => {
        // console.log(e.target, type)
        let _state = { ...state }
        _state[type] = e.target.value
        setState(_state)
    }

    return (
        <Modal size="lg" isOpen={props.isOpen}>
            <ModalHeader>
                Availability Details
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <label className="col-md-2">Contractor</label>
                        <div className="col-md-4">
                            <div className="form-group input-group">
                                <input
                                    type="text"
                                    value={props.selectedContName}
                                    disabled
                                    className="form-control" />
                            </div>
                        </div>

                        {
                            props.selectedDate
                                ?
                                <>
                                    <label className="col-md-1" id="date_label">Date</label>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={dateUSStandard(props.selectedDate)}
                                                disabled

                                            />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <label className="col-md-1" id="date_label">Day</label>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"

                                                disabled
                                                value={days[props.selectedDayNum]}
                                            />
                                        </div>
                                    </div>
                                </>
                        }

                    </div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group checkbox-div">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    id="day"
                                    checked={state.day == 1 ? true : false}
                                    onChange={(e) => handleCheckbox(e, "day")}
                                />&nbsp;
                                <label
                                    className="checkbox-label"
                                    htmlFor="day">Day</label>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group checkbox-div">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    id="eve"
                                    checked={state.eve == 1 ? true : false}
                                    onChange={(e) => handleCheckbox(e, "eve")}
                                />&nbsp;
                                <label
                                    className="checkbox-label"
                                    htmlFor="eve">Eve</label>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group checkbox-div">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    id="night"
                                    checked={state.night == 1 ? true : false}
                                    onChange={(e) => handleCheckbox(e, "night")}
                                />&nbsp;
                                <label
                                    className="checkbox-label"
                                    htmlFor="night">Night</label>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group checkbox-div">
                                <input
                                    type="checkbox"
                                    className="checkbox-input"
                                    id="not_available"
                                    checked={state.not_available == 1 ? true : false}
                                    onChange={(e) => handleCheckbox(e, "not_available")}
                                />&nbsp;
                                <label
                                    className="checkbox-label"
                                    htmlFor="not_available">Not Available</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-md-2" for="hours">Max Hrs</label>
                        <div className="col-md-4">
                            <div className="form-group">
                                <select 
                                    className="form-control" i
                                    value={state.hours}
                                    onChange={(e) => handleChange(e, "hours")}    
                                >
                                    <option value="0">Any</option>
                                    <option value="4">4</option>
                                    <option value="8">8</option>
                                    <option value="12">12</option>
                                </select>
                            </div>
                        </div>

                        {/* <div className="col-md-6">
                            <button type="button" className="btn btn-link float-right" id="any_btn">Any</button>
                        </div> */}

                    </div>

                    <div className="row">
                        <label className="col-md-1" for="note">Note</label>
                        <div className="col-md-11">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state.note}
                                    onChange={(e) => handleChange(e, "note")}    
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <label className="col-sm-3 control-label">Last Modified By &amp; On</label>

                        <div className="col-md-4">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Modified By"
                                    disabled
                                    value={props.selected.name ? props.selected.name : ''}
                                />
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Modified On"
                                    disabled
                                    value={props.selected.modified_at ? changeTzToNormal(props.selected.modified_at).date +" "+changeTzToNormal(props.selected.modified_at).time : ''}
                                />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AvailabilityPopup;