import React, { useState, useEffect } from "react";

import { Popper, Typography, Paper, Fade } from "@material-ui/core";

const MuiPopper = ({ isOpen, anchorEl }) => {
  // console.log(isOpen, anchorEl);
  const [placement, setPlacement] = React.useState();
  return (
    <div>
      <Popper open={isOpen} anchorEl={anchorEl} transition>
        <Paper>
          <Typography>The content of the Popper.</Typography>
        </Paper>
      </Popper>
    </div>
  );
};
export default MuiPopper;
