import React, { useState } from 'react';
import { changeTzToNormal, singleDate } from '../../Utils/dateFunctions'

const Comments = (props) => {

    const [comment, setComment] = useState('');
    const handleSubmit = (e) => {
        e.preventDefault();
        props.addComments({
            comment: comment
        })
        setComment('')
    }

    // console.log(props.comments)
    return (
        <>
            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="col-md-12 mt-2">
                        <h5>Comments</h5>
                    </div>

                    <div className="col-md-12">
                        <textarea
                            className="form-control"
                            rows={5}
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            required
                        />
                    </div>
                    <div className="col-md-12 mt-3">
                        <button type="submit" className="btn btn-primary" >Submit</button>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-sm-12">
                    <table className="table table-bordered" cellspacing="10" >
                        <thead>
                            <tr>
                                <th>Comment</th>
                                <th>Last Modified On</th>
                                <th>By</th>
                            </tr>
                        </thead>
                        <tbody id="comment_body">
                            {
                                props.comments && props.comments.length > 0 && props.comments.map((c, i) => (
                                    <tr>
                                        <td width="65%">{c.comment}</td>
                                        {/* <td>{c.mod_on ? c.mod_on : c.modified_at}</td> */}
                                        <td>{c.mod_on ? singleDate(changeTzToNormal(c.mod_on))  :singleDate(changeTzToNormal(c.modified_at))}</td>
                                        <td>{c.mod_by ? c.mod_by : c.modified_by_name}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </>


    )
}
export default Comments;