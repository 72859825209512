export const objectLength = (object) => {
    if (object && Object.keys(object).length > 0) {
        return true;
    }
    else {
        return false;
    }
}

export const checkFilter = (filterData) => {
    let filterArr = Object.keys(filterData);
    let _new = {};
    filterArr.forEach(f => {
        if (filterData[f] && filterData[f].length > 0) {
            _new = {
                ..._new,
                [f]: filterData[f]
            }
        }
    })
    return _new
}