import React, { useState, useEffect } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { generateCellNumber } from '../../../../shared/numberFormat';


const AddUser = (props) => {
    const [type, setType] = useState();
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');


    const onSubmitHandler = (e) => {
        e.preventDefault();
        let data = {
            "type": type,
            "name": name,
            "mobile": Number(mobile.replace(/-/g, '')),
            "email": email,
            "active": "1",
            "deleted": "0"
        }

        props.addUserData(data);

    }

    // console.log(tableRow, contractorTypes, t)

    return (
        <Modal size="md" isOpen={props.modal}>
            <ModalHeader>
                <div className={`create-order-modal-header`}>
                    <span>User</span>
                    <CloseIcon
                        onClick={() => props.toggle()}
                        className={`create-modal-close-icon`}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <form onSubmit={onSubmitHandler}>
                    <div className={`create-order-modal-body`}>
                        <div className={`row`}>

                            <div className={`col-md-3 mb-2`}>User Type</div>
                            <div className={`col-md-9 mb-2`}>
                                <select
                                    value={type}
                                    className={`form-control`}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    {
                                        props.userType.map((u, i) => (
                                            <option value={u.id}>{u.label}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className={`col-md-3 mb-2`}>Name</div>
                            <div className={`col-md-9 mb-2`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    placeholder="Enter Name"
                                />
                            </div>

                            <div className={`col-md-3 mb-2`}>Mobile</div>
                            <div className={`col-md-9 mb-2`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={mobile}
                                    onChange={(e) => setMobile(generateCellNumber(e.target.value))}
                                    required
                                    placeholder="Enter Mobile Number"
                                />
                            </div>

                            <div className={`col-md-3 mb-2`}>Email</div>
                            <div className={`col-md-9 mb-2`}>
                                <input
                                    type="email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Enter Email Id"
                                />
                            </div>

                            <div className={`col-md-12 text-center`} >
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddUser;
