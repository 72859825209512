import React, { Component } from "react";
import { connect } from "react-redux";
import ModalWrapper from "./ModalWrapper/ModalWrapper";
import { postAdminAPI, postMessageAdminAPI, getAdminAPI } from '../../APICall/index';
import { dateStandard, dateArithmetic, dateDifference } from '../../Utils/dateFunctions';
import { GET_ALL_SHIFT, CREATE_SHIFT, EDIT_SHIFT, GET_COMMENT, ADD_COMMENT, GET_HISTORY, ADD_NOTIFICATION, GET_CLIENT_CONTACT, ORDER_RESPONSE, APPROVE_SHIFT, ORDER_STATE } from '../../APICall/urls';
import { notiflixLoaderStart, notiflixStatus, notiflixLoaderEnd } from '../../component/Notiflix/notiflix';
import ConfirmModal from "../../component/confirm/confirmForOrder";
import { ConvertForSelect } from "../../Utils/arrayFunction"

class ModalComponents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedOrder: null,

            selectedComments: [],

            selectedHistory: [],

            loading: false,
            error: null,
            message: null,
            allContacts: [],
            shifts: [],

            selectedResponse: [],
            selectedOrder: null,
            modalConfirm: false,
            allState: []

        }
    }

    ;
    componentDidMount() {
        this.getContact(this.props.selectedOrder.id)
        this.getHistory(this.props.selectedOrder.id)
        this.fetchBasicAPIs(this.props.selectedClient)
        this.getAllInitialData()
    }


    getAllInitialData = async () => {
        let _label = await getAdminAPI(ORDER_STATE)
        console.log(_label);
        if (_label?.length) {
            this.setState({ ...this.state, allState: _label });
        }
    }

    afterActionCalls = (content, callback, orderId) => {
        notiflixStatus(content.status, content.message);
        if (content.status) {
            if (content.status) {
                this.props.updateAPI();
            }
            if (callback) {
                callback(orderId)
            }
            // this.handleStaffingSheetModal();
        }
        // this.fetchMerchandiseProduct(this.state.productId);
    }

    fetchBasicAPIs = async (selectedClient) => {
        console.log(this.props.selectedClient);
        if (!this.props.shifts) {
            let shifts = await postAdminAPI(GET_ALL_SHIFT, { "client": selectedClient });
            // console.log(shifts)
            this.setState({ shifts: shifts.data })
        }
        else {
            this.setState({ shifts: this.props.shifts })
        }
        // console.log(contractors, shifts, clients, contractorType);
    }


    getContact = async (client) => {
        let data = {
            "client_id": client
        };
        let result = await postAdminAPI(GET_CLIENT_CONTACT, data)
        // console.log(result);
        if (result.status) {
            // console.log(result.data)
            this.setState({ allContacts: result.data })
        }
    }

    handleStaffingSheetModal = async (selectedShift, dayCount, selectedOrder) => {
        // console.log(selectedShift, dateArithmetic(this.state.startDate, dayCount), selectedOrder);
        //   console.log(this.state)
        let comments;
        // console.log(selectedOrder);
        if (selectedOrder && Object.keys(selectedOrder).length > 0) {
            this.getComments(selectedOrder.id);
            this.getHistory(selectedOrder.id);

        }
        else {
            selectedOrder = null
        }
        // console.log(selectedOrder);
        this.setState({
            modal: !this.state.modal,
            selectedShift: selectedShift,
            selectedDate: dateArithmetic(this.state.startDate, dayCount),
        })

        this.props.handleStaffingSheetModal();
    }

    //add update shift
    handleSubmitShift = async (shiftInfo, shiftId, actionAllowed, actionMessage, isReplacement, callback) => {
        // console.log(shiftInfo, shiftId, actionAllowed, actionMessage);

        if (actionAllowed) {
            notiflixLoaderStart();
            let content;
            if (shiftId) {
                shiftInfo = {
                    ...shiftInfo,
                    id: parseInt(shiftId)
                }
                // console.log(shiftInfo)
                content = await postAdminAPI(EDIT_SHIFT, shiftInfo);
                // console.log(content);
                // this.afterActionCalls(content);
            }
            else {

                content = await postAdminAPI(CREATE_SHIFT, shiftInfo);
                // console.log(content);
                // this.afterActionCalls(content);
            }

            if (content.status) {
                // console.log(content.data)
                if (content.data === true) {
                    this.afterActionCalls(content, callback, shiftId ? shiftId : content.data[0]);
                    // console.log(content)
                }
                else if (content.data === false) {
                    notiflixStatus(false, "Something went wrong.");
                    // console.log(content)
                }
                else if (typeof content.data === "string") {
                    notiflixStatus(false, content.data);
                    // console.log(content)
                }
                else {
                    this.afterActionCalls(content, callback, shiftId ? shiftId : content.data[0]);
                    // console.log(content)
                }
            }
            else {
                notiflixStatus(false, content.message);
            }
        }
        else {
            notiflixStatus(false, actionMessage);
        }

    }
    // Response
    // handleResponse = async (shiftId, contId) => {
    //     // console.log(shiftId, contId);
    //     this.setState({ modal: false });
    //     notiflixLoaderStart();
    //     let shiftInfo = {
    //         "id": shiftId,
    //         "assignedCtr": contId,
    //         "state": 1
    //     }
    //     let content = await postAdminAPI(EDIT_SHIFT, shiftInfo);
    //     // console.log(content);
    //     // console.log(content);
    //     this.afterActionCalls(content);

    // }
    // Response
    handleResponse = async (shiftId, contId, ctrType, payableCtrType) => {
        console.log(shiftId, contId, ctrType);
        notiflixLoaderStart();
        let shiftInfo = {
            "id": shiftId,
            "assignedCtr": contId,
        }
        let content = await postAdminAPI(APPROVE_SHIFT, shiftInfo);
        // console.log(content);
        if (content.status === false) {
            notiflixStatus(false, content.message);
        }
        else {
            if (content.data?.showPrompt) {
                this.setState({
                    approveInfo: {
                        "id": shiftId,
                        "assignedCtr": contId,
                        ctrType,
                        payableCtrType,
                        "state": 1
                    }
                })
                this.setState({ modalConfirm: { ...content.data } });
            }
            else {
                let content = await postAdminAPI(EDIT_SHIFT, {
                    "id": shiftId,
                    "assignedCtr": contId,
                    ctrType,
                    payableCtrType,
                    "state": 1
                });
                this.setState({ modal: false });
                this.afterActionCalls(content);
                this.props.updateNotificationOnApprove(shiftId)
            }
            notiflixLoaderEnd()
        }
        this.handleStaffingSheetModal()
        
        // console.log(content);
    }

    handleApprove = async () => {
        notiflixLoaderStart();
        let shiftInfo = { ...this.state.approveInfo }
        let content = await postAdminAPI(EDIT_SHIFT, shiftInfo);
        this.setState({ modal: false, modalConfirm: false });
        this.props.updateNotificationOnApprove(shiftInfo.id)
        this.afterActionCalls(content);
        notiflixLoaderEnd()
    }
    //Notification
    handleAddNotification = async (posData) => {
        this.setState({ modal: false });
        let notification = await postAdminAPI(ADD_NOTIFICATION, posData)
        // console.log(notification)
        this.afterActionCalls(notification);
    }
    //Comment
    getComments = async (orderId) => {
        let comments = await postAdminAPI(GET_COMMENT, { order: orderId })
        // console.log(comments.data)
        this.setState({
            selectedComments: comments ? comments.data : []
        })
    }

    handleAddComment = async (commentData) => {
        notiflixLoaderStart();
        let data = {
            ...commentData,
            order: this.props.selectedOrder.id
        }
        // console.log(data)
        let comments = await postAdminAPI(ADD_COMMENT, data)
        // console.log(comments)
        await this.getComments(this.props.selectedOrder.id);
        this.afterActionCalls(comments);
    }

    //History
    getHistory = async (orderId) => {
        let history = await postAdminAPI(GET_HISTORY, { order: orderId })
        // console.log(history.data)
        this.setState({
            selectedHistory: history ? history.data : []
        })
    }

    updateResponse = async (startDate, endDate, client, ctrType) => {
        // console.log(startDate, endDate, client, ctrType);

        let postData = {
            "start_date": startDate.date + " " + startDate.time + ":00",
            "end_date": endDate.date + " " + endDate.time + ":00",
            "client": client,
            "ctr_type": ctrType,
        }
        // console.log(postData)

        let response = await postAdminAPI(ORDER_RESPONSE, postData);

        if (response.status) {
            let res = [];

            response.data && response.data.map((r, i) => {
                res.push({
                    activeHours: null,
                    contractorName: r.contractorName,
                    applicationCreatedAt: r.created_at,
                    ctr: r.ctr,
                    mob: r.mob1,
                    mob_code: r.mob1_code,
                    rating: [],
                    res: r.res
                })
            })

            let _selectedOrder = {
                ...this.props.selectedOrder,
                responses: res,
            }
            this.setState({ selectedOrder: _selectedOrder });
            // console.log(res);
        }


    }

    render() {

        const { selectedHistory, allContacts, selectedComments, shifts, selectedResponse, selectedOrder, modalConfirm } = this.state;
        return (
            <>
                <ModalWrapper
                    handleClose={this.handleStaffingSheetModal}
                    isOpen={this.props.modal}
                    clients={this.props.basicDetails.allClients}
                    contractors={this.props.basicDetails.allContractor}
                    shifts={shifts}
                    contractorType={this.props.basicDetails.allContractorTypes}
                    selectedClient={this.props.selectedClient}
                    selectedDate={this.props.selectedDate}
                    selectedShift={this.props.selectedShift}
                    handleSubmitShift={this.handleSubmitShift}
                    selectedOrder={selectedOrder ? selectedOrder : this.props.selectedOrder}

                    handleAddNotification={this.handleAddNotification}

                    handleResponse={this.handleResponse}

                    handleAddComment={this.handleAddComment}
                    selectedComments={selectedComments}

                    selectedHistory={selectedHistory}
                    selectedResponse={selectedResponse}

                    allContacts={allContacts}
                    selectedContractor={this.props.selectedContractor}
                    updateResponse={this.updateResponse}
                    allState={this.state.allState}
                />
                {
                    modalConfirm
                        ?
                        <ConfirmModal
                            modal={modalConfirm}
                            title="Approve Shift"
                            content="The contractor has applied to another same shift with bonus. Do you want to continue?"
                            toggle={() => this.setState({ modalConfirm: null })}
                            click={() => this.handleApprove()}
                        />
                        : null
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
        basicDetails: state.BasicDetails,
        auth: state.Login.user,
    };
};


export default connect(mapStateToProps, null)(ModalComponents);