export const columns = [
    {
        label: 'Client',
        field: 'clnt_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Contractor',
        field: 'ctr_name',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Type',
        field: 'code',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Shift',
        field: 'name',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Start Time',
        field: 'start_date',
        sort: 'asc',
        width: 150
    },
    {
        label: 'End Time',
        field: 'end_date',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Status',
        field: 'short_name',
        sort: 'asc',
        width: 100
    }
]