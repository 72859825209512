import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from "../../../component/datePicker/datePicker";
import { postAdminAPI, postAdminFormadataAPI, getAdminAPI } from '../../../APICall/index';
import {
    GET_STATES, GET_POLICY
} from '../../../APICall/urls/contractor';
import Star from '../../../component/Required/star';

const size = [0, 1, 2, 3, 4, 5, 6];

const alertDays = [
    {
        label: "30 days",
        value: 30
    },

    {
        label: "20 days",
        value: 20
    },
    {
        label: "10 days",
        value: 10
    },
]

const AddRateCard = (props) => {

    const [credentials, setCredentials] = useState({
        "credType": '',
        licenseType: '',
        "issueDate": '',
        "expDate": '',
        "active": '1',
        "file": '',
        "name": '',
        number: "",
        alert: "30",
        state: '',
        note: ''
    })

    const [extraData, setExtraData] = useState([{}])
    const [policy, setPolicy] = useState([])
    const [states, setStates] = useState([])
    const [fileType, setFileType] = useState(null)

    useEffect(() => {
        console.log(props.contractorCredentials);
        console.log(props)
        if (props.selectedCredential) {
            setCredentials({
                "credType": props.selectedCredentialId,
                "licenseType": props.selectedCredential.license_type,
                "issueDate": props.selectedCredential.issue_date,
                "expDate": props.selectedCredential.exp_date,
                "active": "1",
                "name": props.selectedCredential.name,
                "number": props.selectedCredential.number,
                "id": props.selectedCredential.id,
                "alert": props.selectedCredential.alert,
                "state": props.selectedCredential.state,
                "note": props.selectedCredential.note,

            })
            getPolicy(props.selectedCredentialId)

            if (props.selectedCredential.other) {
                setExtraData(JSON.parse(props.selectedCredential.other))
            }
        }
        getStates(props.selectedCredentialId)
    }, [])

    const handleFile = (e, type) => {
        let _credentials = { ...credentials }
        _credentials[type] = e.target.files[0];
        setCredentials(_credentials)
    }

    const getStates = async (credId) => {
        let result = await getAdminAPI(GET_STATES)
        console.log(result)
        if (result?.length) {
            setStates(result)
        }
    }

    const getPolicy = async (credId) => {
        let result = await postAdminAPI(GET_POLICY, { credId })
        console.log(result)
        if (result.status) {
            setPolicy(result.data)
        }
    }

    const handleChange = (e, type) => {
        let _credentials = { ...credentials }
        _credentials[type] = e.target.value;
        if (type === "credType") {
            getPolicy(e.target.value)
        }
        setCredentials(_credentials)
    }

    const handleChangeDate = (type, e) => {
        console.log(e, type)
        let _credentials = { ...credentials }
        _credentials[type] = e;
        setCredentials(_credentials)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(credentials)

        let formData = new FormData();
        formData.append('ctr', props.contractor)
        formData.append('credType', credentials.credType)
        formData.append('licenseType', credentials.licenseType)
        formData.append('issueDate', credentials.issueDate)
        formData.append('expDate', credentials.expDate)
        formData.append('active', credentials.active.toString())
        formData.append('file', credentials.file)
        formData.append('name', credentials.name)
        formData.append('number', credentials.number)
        formData.append('alert', credentials.alert)
        formData.append('state', credentials.state)
        formData.append('note', credentials.note)
        formData.append('other', JSON.stringify(extraData))
        if (credentials.id) {
            formData.append('id', credentials.id)
        }
        props.addContractorCredentials(formData)
    }

    console.log(fileType)

    const handleAdd = () => {
        setExtraData([...extraData, {}])
    }

    const handleDelete = (i) => {
        let _extraData = [...extraData]
        _extraData.splice(i, 1)
        setExtraData(_extraData)
    }

    const handleUpdate = (e, i, type) => {
        let _extraData = [...extraData]
        _extraData[i][type] = e.target.value
        setExtraData(_extraData)
    }

    console.log(extraData);

    return (
        <Modal size="lg" isOpen={props.isOpen}>
            <ModalHeader>
                Add/Update credentials
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Credentials<Star /></label>
                            <select className="form-control"
                                value={credentials.credType}
                                required
                                onChange={(e) => handleChange(e, "credType")}
                            >
                                <option value={""}>---SELECT---</option>
                                {
                                    props.contractorCredentials?.map((a) => (
                                        <option key={a.id} value={a.id}>{a.code}</option>
                                    ))
                                }
                            </select>

                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Type<Star /></label>
                            <select className="form-control"
                                value={credentials.licenseType}
                                required
                                onChange={(e) => handleChange(e, "licenseType")}
                            >
                                <option value={""}>---SELECT---</option>
                                {
                                    policy?.map((a) => (
                                        <option key={a.id} value={a.id}>{a.short_name}</option>
                                    ))
                                }
                            </select>

                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Title<Star /></label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="desc_id"
                                placeholder="Name"
                                required
                                value={credentials.name}
                                onChange={(e) => handleChange(e, "name")}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">State</label>
                            <select className="form-control"
                                value={credentials.state}
                                onChange={(e) => handleChange(e, "state")}
                            >
                                <option value={0}>---SELECT---</option>
                                {
                                    states?.map((a) => (
                                        <option key={a.id} value={a.id}>{a.name}</option>
                                    ))
                                }
                            </select>

                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Expiry Date<Star /></label>
                            <DatePicker
                                date={credentials.expDate}
                                name="expDate"
                                required={true}
                                handleDateChange={handleChangeDate}
                                

                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Date of service<Star /></label>
                            <DatePicker
                                date={credentials.issueDate ? credentials.issueDate : null}
                                name="issueDate"
                                required={true}
                                handleDateChange={handleChangeDate}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Certification Number<Star /></label>
                            <input
                                type="text"
                                className="form-control"
                                name="number"
                                id="desc_id"
                                placeholder="Certification Number"
                                required
                                value={credentials.number}
                                onChange={(e) => handleChange(e, "number")}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Alert setup(Before expiry)<Star /></label>
                            <select className="form-control"
                                value={credentials.alert}
                                required
                                onChange={(e) => handleChange(e, "alert")}
                            >
                                <option value={""}>---SELECT---</option>
                                {
                                    alertDays.map((a) => (
                                        <option key={a.id} value={a.value}>{a.label}</option>
                                    ))
                                }
                            </select>

                        </div>

                    </div>
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label className="label-class" htmlFor="desc_id">Note if any</label>
                            <textarea
                                className="form-control"
                                name="desc_name"
                                id="desc_id"
                                placeholder="Please type the note here"
                                value={credentials.note}
                                onChange={(e) => handleChange(e, "note")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Other Information</label>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id"></label>
                        </div>
                        {
                            extraData.map((a, i) => (
                                <>
                                    <div className="form-group col-md-5 mt-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="desc_name"
                                            id="extraTitle"
                                            placeholder="Title"
                                            value={a.key}
                                            onChange={(e) => handleUpdate(e, i, "key")}
                                        />
                                    </div>
                                    <div className="form-group col-md-6 mt-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="desc_name"
                                            id="desc_id"
                                            placeholder="Value"
                                            value={a.value}
                                            onChange={(e) => handleUpdate(e, i, "value")}
                                        />
                                    </div>
                                    <div className="form-group col-md-1 mt-2">
                                        <button type="button" onClick={() => handleDelete(i)} className="removeButtonCss  deleteBtn">
                                            <i className="fa fa-times"></i>
                                        </button>
                                    </div>
                                </>
                            ))
                        }
                        <div className="form-group col-md-11">
                        </div>
                        <div className="form-group col-md-1">
                            <button type="button" onClick={() => handleAdd()} className="removeButtonCss addBtn">

                                <i className="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Select File Type<Star /></label>
                            <select className="form-control"
                                value={fileType}
                                required
                                onChange={(e) => setFileType(e.target.value)}
                            >
                                <option value={""}>---SELECT---</option>
                                <option value={2}>PDF</option>
                                <option value={3}>Image</option>
                            </select>

                        </div>
                        <div className="form-group col-md-6">
                            <label className="label-class" htmlFor="desc_id">Select Type<Star /></label>
                            <input
                                type="file"
                                className="form-control"
                                required
                                disabled={fileType <= 0}
                                onChange={(e) => handleFile(e, "file")}
                                accept={fileType === 3 ? "image/*" : (fileType === 2 ? "application/pdf" : '')}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddRateCard;