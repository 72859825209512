import React, { useState, useEffect } from 'react'
import TableComponent from '../../../../component/TableComponent';
import { contactColumn } from "./columns/contacts"
import { notiflixStatus } from '../../../../component/Notiflix/notiflix'
import { changeTzToNormal } from '../../../../Utils/dateFunctions';
import ConfirmModal from "../../../../component/confirm/confirmForOrder";
import { generateCellNumber } from '../../../../shared/numberFormat';

const sampleObject = {
    "cont_name": "",
    "description": "",
    "main": "",
    "cell": "",
    "pager": "123",
    "fax": "",
    "email": "",
    "note": "",
    "active": 1,
}

const AllContacts = ({ allContacts, setAllContacts, props }) => {

    const [contacts, setContacts] = useState([]);
    const [selected, setSelected] = useState(null);
    const [deleteContact, setDeleteContact] = useState(null)

    // useEffect(() => {
    //     // console.log(props, props.allContacts)
    //     if (allContacts && allContacts.length >= 0) {
    //         updateTable(allContacts, selected)
    //     }
    // }, [props])


    const updateTable = (allContacts, selected) => {
        let _allContacts = [];
        allContacts.map((p, i) => {
            if (p.active == 1) {
                _allContacts.push({
                    name: <div>
                        <input
                            type="text"
                            className="form-control"
                            style={{ width: 220 }}
                            value={p.cont_name}
                            onChange={(e) => handleChange(e, i, allContacts, "cont_name")}
                        />
                    </div>,
                    description: <div>
                        <textarea
                            type="text"
                            className="form-control"
                            style={{ width: 220 }}
                            value={p.description}
                            onChange={(e) => handleChange(e, i, allContacts, "description")}

                        />
                    </div>,
                    main: <div> <input
                        type="text"
                        className="form-control"
                        value={p.main}
                        style={{ width: 135 }}
                        onChange={(e) => handleChange(e, i, allContacts, "main")}

                    /></div>,
                    cell: <div><input
                        type="text"
                        className="form-control"
                        value={p.cell}
                        style={{ width: 135 }}
                        onChange={(e) => handleChange(e, i, allContacts, "cell")}

                    /></div>,
                    email: <div><input
                        type="email"
                        className="form-control"
                        value={p.email}
                        style={{ width: 200 }}
                        onChange={(e) => handleChange(e, i, allContacts, "email")}

                    /></div>,
                    fax: <div><input
                        type="text"
                        className="form-control"
                        value={p.fax}
                        style={{ width: 135 }}
                        onChange={(e) => handleChange(e, i, allContacts, "fax")}
                    /></div>,
                    note: <textarea
                        type="text"
                        className="form-control"
                        value={p.note}
                        onChange={(e) => handleChange(e, i, allContacts, "note")}

                    />,
                    select: <input
                        type="checkbox"
                        className="form-control"
                        checked={selected == i ? true : false}
                        onChange={(e) => handleSelectCheck(e, i, allContacts)}
                    />,

                })
            }
        })
        return _allContacts;
        // console.log(_allContacts)
        // setContacts(_allContacts)
    }

    const handleChange = (e, i, allContacts, state, type) => {
        // console.log(allContacts)
        let _allContacts = [...allContacts];
        // console.log(_allContacts, _allContacts[i], i, state)
        if (type === "checkbox") {
            // console.log(e.target.checked)
            if (e.target.checked) {
                _allContacts[i][state] = 1;
            }
            else {
                _allContacts[i][state] = 0;
            }
            // console.log(_allShifts[i][state])
        }
        if (state === "cell" || state === "fax" || state === "main") {
            _allContacts[i][state] = generateCellNumber(e.target.value);
        }
        else {
            _allContacts[i][state] = e.target.value;
        }
        // updateTable(_allContacts, selected)
        setAllContacts(_allContacts)
    }

    const handleSelectCheck = (e, id, allContacts) => {
        if (e.target.checked) {
            setSelected(id)
            // updateTable(allContacts, id)
        }
        else {
            setSelected(null)
            // updateTable(allContacts, null)
        }
        // console.log(id, allContacts)

    }

    const handleDeleteButton = () => {
        let _allContacts = [...allContacts];
        // console.log(_allContacts, selected)
        if (_allContacts[selected].cont_id) {
            props.deleteContacts(_allContacts[selected].cont_id)
            setSelected(null)
        }
        else {
            _allContacts.splice(selected, 1);
            // console.log(_allContacts)
            setSelected(null)
            setAllContacts(_allContacts)
            // updateTable(_allContacts, null)
        }
        setDeleteContact(null)
    }

    const onDeleteBtnClick = () => {
        if (typeof selected === 'undefined' || typeof selected === null || selected === null) {
            notiflixStatus(false, "Please select a contacts to delete.")
        }
        else {
            setDeleteContact(true)
        }
    }

    

    const copyRow = () => {
        if (selected) {
            let _contacts = { ...allContacts[selected] };
            let _allContacts = [...allContacts];
            if (_contacts.cont_id) {
                delete _contacts.cont_id
            }
            _allContacts.push(_contacts)
            // updateTable(_allContacts, null)
            setSelected(null)
        }
        else {
            notiflixStatus(false, "Please select a contacts to copy.")
        }
    }

    const addRow = () => {
        let _allContacts = [...allContacts];
        _allContacts.push({ ...sampleObject })
        // console.log(_allContacts)
        // updateTable(_allContacts, selected)
        setAllContacts(_allContacts)
    }

    return (
        <>
            <TableComponent
                rows={updateTable(allContacts, selected)}
                columns={contactColumn}
                addRow={addRow}
                deleteRow={onDeleteBtnClick}
                handleDeleteButton={handleDeleteButton}
                // saveTable={saveData}
                copyRow={copyRow}
                width="100vw"
                hideSave={true}
            />
            {
                deleteContact
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this contacts?",
                                proceed: true
                            }

                        }
                        title="Delete contact"
                        toggle={() => setDeleteContact(null)}
                        click={handleDeleteButton}
                    />
                    : null
            }
        </>
    )
};

export default AllContacts;