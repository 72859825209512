import React, { useState } from 'react';

const InputComponent = (props) => {

    const [singleRow, setSingleRow] = useState([''])


    const addRow = () => {
        let newRow = {
            [props.first]:'',
            [props.second]:''
        }
        props.handleContactAdd(newRow, props.state)
    }

    const deleteRow = (i) => {
        let _singleRow = [
            ...singleRow.slice(0, i),
            ...singleRow.slice(i + 1),
        ]
        setSingleRow(_singleRow);
    }

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-md-4 mb-2">
                    <h5>{props.firstTitle}</h5>
                </div>
                <div className="col-md-7 input-group mb-2">
                    <h5>{props.secondTitle}</h5>
                </div>
                <div className="col-md-1 mb-2" >
                    <div className="input-group-append" style={{ float: 'right' }}>
                        <button
                            onClick={addRow}
                            type="button"
                            className="btn btn-success btn-add"
                        >+</button>
                    </div>
                </div>
                {
                    props.allData && props.allData.map((r, i) => (
                        <>

                            <div className="col-md-4 mb-2">
                                <input
                                    type={props.first === "email" ? "email" :"text"}
                                    className="form-control"
                                    value={r[props.first]}
                                    onChange={(e) => props.handleChange(e, i, props.state, props.first)}
                                    required
                                    
                                />
                            </div>
                            <div className="col-md-8 input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={r[props.second]}
                                    onChange={(e) => props.handleChange(e, i, props.state, props.second)}
                                    required
                                />
                                <div className="input-group-append">
                                    <button
                                        type="button"
                                        className="btn btn-danger"

                                        onClick={() => props.handleDelete(i, props.state)}
                                    >-</button>
                                </div>
                            </div>
                        </>
                    ))
                }

            </div>
            {/* <div className="row">
                <div className="col-md-11"></div>
                <div className="col-md-1" >
                    <div className="input-group-append" style={{float:'right'}}>
                        <button 
                            onClick={addRow}
                            type="button" 
                            className="btn btn-success btn-add"
                        >+</button>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default InputComponent;
