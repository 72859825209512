import React, { useRef, useState } from 'react';
import { changeTzToNormal, singleDate } from '../../Utils/dateFunctions'

const Documents = (props) => {

    const fileRef = useRef();
    const handleFile = () => {
        if (file !== 0) {
            fileRef.current.click();
        }
    }

    const [file, setFile] = useState(0);

    const handleCheckbox = (e, type) => {
        if (e.target.checked) {
            setFile(type)
        }
        else {
            setFile(0)
        }
    }

    const handleFileUpload = (e) => {
        props.handleUpload(e)
    }

    return (
        <div className="row">
            <div className="col-sm-11">
                <table className="table table-bordered" >
                    <thead>
                        <tr>
                            <th>Comment</th>
                            <th>Last Modified On</th>
                            <th>By</th>
                            <th>Select</th>
                        </tr>
                    </thead>
                    <tbody id="comment_body">
                        {
                            props.files && props.files.length > 0 && props.files.map((f, i) =>{
                                if(f.is_active == 1)
                                    return(<tr key={i}>
                                        <td width="65%">
                                            <a
                                                href={f.filename}
                                                target="_blank"
                                            >
                                                {f.name}
                                            </a>
                                        </td>
                                        <td>{f.modOn ? singleDate(changeTzToNormal(f.modOn))  :singleDate(changeTzToNormal(f.mod_on))}</td>
                                        <td>{f.modBy ? f.modBy  :f.mod_by}</td>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-control"
                                                checked={props.selected == f.id ? true : false}
                                                onChange={(e) => props.handleSelected(e, f.id.toString())}
                                            />
                                        </td>
                                    </tr>)
                            })
                        }

                    </tbody>
                </table>
            </div>
            <div className="col-md-1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', float: 'right' }}>
                <button 
                    class="btn btn-danger add_btn" 
                    disabled={!props.selected}
                    onClick={() => props.handleDelete()}
                >Delete</button><br />
                <button class="btn btn-success add_btn"
                    onClick={handleFile}
                    disabled={!file}
                >New</button><br />

                <form>
                    <input
                        type="file"
                        style={{ display: 'none' }}
                        multiple
                        ref={fileRef}
                        onChange={(e) => handleFileUpload(e)}
                        accept={file === "image" ? "image/*" : (file ?  "application/pdf" : '' ) }
                    />

                    <div className="form-row">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="pdf_id"
                                onChange={(e) => handleCheckbox(e, "pdf")}
                                checked={file === "pdf" ? true : false}
                            />
                            <label className="custom-control-label" for="pdf_id" >PDF</label>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="img_id"
                                onChange={(e) => handleCheckbox(e, "image")}
                                checked={file === "image" ? true : false}
                            />
                            <label className="custom-control-label" for="img_id" >Image</label>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    )
}
export default Documents;