import * as actionTypes from './actionType';

export const openBulkModal = () =>{
    return {
        type:actionTypes.BULK_MODAL_OPEN
    };
};

export const closeBulkModal = () =>{
    return {
        type:actionTypes.BULK_MODAL_CLOSE,
    }
}

export  default{
    openBulkModal, closeBulkModal
};