import React, { useState } from 'react'
import {
    Card,
    CardBody,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';

const columns = [

]

const AllNewList = (props) => {

    return (
        <Card>
            <CardBody>
                <form style={{ display: "flex", gap: 20, justifyContent: "end" }} onSubmit={props.handleGeneratePayroll}>
                    <button
                        className="btn btn-primary"
                        type="submit"
                    >Generate Payroll</button>
                </form>
                <MDBDataTable
                    striped
                    hover
                    bordered
                    data={{ columns: props.columns, rows: props.allData }}
                />
            </CardBody>
        </Card>
    )
}

export default AllNewList;