import * as date from "../dateFunctions"
const dateDrop = [
  {
    "id": 1,
    "start": [date.dateStandard(), date.dateStandard()],
    "fullName": "Today"
  },
  {
    "id": 2,
    "start": date.getCurrentWeekDates(),
    "fullName": "This Week"
  },
  {
    "id": 3,
    "start": date.getLastWeekDates(),
    "fullName": "Last week"
  },
  {
    "id": 4,
    "start": date.getCurrentMonthDates(),
    "fullName": "This Month"
  },
  {
    "id": 5,
    "start": date.getLastMonthDates(),
    "fullName": "Last Month"
  },
  {
    "id": 6,
    "start": date.getCurrentYearDates(),
    "fullName": "This Year"
  },
  {
    "id": 7,
    "start": date.getLastYearDates(),
    "fullName": "Last Year"
  },
  {
    "id": 8,
    "start": null,
    "fullName": "Custom Range"
  }
]


export { dateDrop }
