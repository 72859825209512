export const columns = [
    {
        label: 'Contractor',
        field: 'contractor_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Payroll Date',
        field: 'cheque_date',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Regular Hours',
        field: 'regular_hours',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Holiday Hours',
        field: 'holiday_hours',
        sort: 'asc',
        width: 150
    }
]