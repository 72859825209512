import React, { useState } from 'react';
import ImageModal from "../../../component/modal/imageModal";
import { camelCaseToTitleCase, snakeCaseToTitleCase } from "../../../Utils/stringManupulation"

const SingleChange = (props) => {

    const [modal, setModal] = useState(false);
    const [file, setFile] = useState(false);

    const onClick = (name, file) => {
        var ext = file.split('.').reverse()[0]
        if (name === 'filename' && ext === "pdf") {
            window.open(`${process.env.REACT_APP_IMAGE_URL}/contractor-cred/` + file, "_blank")
        }
        else if (name === "filename") {
            setModal(true);
            setFile(file);
        }
    }

    return (
        <>
            <h4 onClick={() => onClick(props.name, props.new)} className="font-medium text-inverse">{snakeCaseToTitleCase(props.name)}</h4>
            <div onClick={() => onClick(props.name, props.new)} className="row mb-2">
                <div className="col-md-6">
                    <h6 className="font-medium text-inverse">{props.new}</h6>
                </div>
                <div className="col-md-6">
                    <h6 className="font-medium text-inverse ml-5">{props.old}</h6>
                </div>
            </div>
            {
                modal
                    ?
                    <ImageModal
                        isOpen={modal}
                        handleClose={() => setModal(false)}
                        image={`${process.env.REACT_APP_IMAGE_URL}/contractor-cred/` + file}
                    />
                    : null
            }
        </>
    )
}

export default SingleChange