import React, { useState, useEffect } from 'react';
import { GoogleMap, StandaloneSearchBox, Marker, Polyline } from "@react-google-maps/api";
import { LoadScript } from "@react-google-maps/api";
import { MAP_API_KEY } from "../../../../Utils/Variables/map";
import { postAdminAPI, postMessageAdminAPI } from '../../../../APICall/index';
import { GET_LOCATIONS } from '../../../../APICall/urls';


const lib = ["places"];
const google = window.google;

const Maps = (props) => {
    const [currentLocation, setCurrentLocation] = useState({});
    const [markers, setMarkers] = useState([])
    const [bonus, setBonus] = useState(null);

    const [open, setOpen] = useState(true);



    const onMapLoad = map => {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                setCurrentLocation(pos)
                // this.setState({ currentLocation: pos });
            }
        );
        new window.google.maps.event.addListener(map, "bounds_changed", () => {
            // console.log(map.getBounds());
            setBonus(map.getBounds())
            //   this.setState({ bounds: map.getBounds() });
        });
    };

    useEffect(() => {
        const getAPIData = () => {

            let data = {
                "orderId": props.selectedOrder.id,
            }
            // console.log(data);
    
            postAdminAPI(GET_LOCATIONS, data)
                .then((result) => {
                    // console.log(result)
                    if (result.data && result.data.length > 0) {
                        // console.log(result)
                        let _marker = [];
                        result.data.map(r => {
                            _marker.push({
                                lat: parseFloat(r.lat),
                                lng: parseFloat(r.lng)
                            })
                        })
                        setMarkers(_marker);
                        setCurrentLocation(_marker[0])
                    }
                    else {
                        // console.log(result)
                        navigator?.geolocation.getCurrentPosition(
                            ({ coords: { latitude: lat, longitude: lng } }) => {
                                const pos = { lat, lng };
                                setCurrentLocation(pos)
                            }
                        );
                    }
                });
    
    
    
        }
        getAPIData();
        const interval = setInterval(() => getAPIData(), 10000)
        return () => {
            clearInterval(interval);
        }
        // updateAPI()
    }, [])

    

    
    // console.log(markers, currentLocation)
    return (
        <div>


            <LoadScript googleMapsApiKey={MAP_API_KEY} libraries={lib}>
                <GoogleMap
                    center={currentLocation}
                    zoom={5}
                    onLoad={map => onMapLoad(map)}
                    mapContainerStyle={{ height: '100vh', width: "100%" }}
                >
                    {markers.map((mark, index) => (

                        index === 0 ? <Marker key={index} position={mark} /> : null
                    ))}

                    <Polyline geodesic={false}
                        options={{
                            path: markers,
                            strokeColor: '#00ffff',
                            strokeOpacity: 1,
                            strokeWeight: 6,
                            icons: [{
                                offset: '0',
                                repeat: '10px'
                            }],
                        }}
                    />
                </GoogleMap>
            </LoadScript>


            <div className="row mt-4">
                <div className="col-md-6">Signed In:</div>
                <div className="col-md-6">Signed Out:</div>
            </div>

        </div>
    )
}

export default Maps;