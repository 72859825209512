import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardHeader} from 'reactstrap';

import DatePicker from '../../../../component/datePicker/datePicker';
import Required from '../../../../component/Required/star';

import { postAdminAPI } from '../../../../APICall/index';
import { GET_ALL_CONTRACTOR, GET_CONTRACTOR_TYPE } from '../../../../APICall/urls';


const OrderFilter = (props) =>{

    const [allContractor, setAllContractor] = useState([]);
    const [allContractorTypes, setAllContractorTypes] = useState([]);
   
    const [filter, setFilter] = useState({
        ctrType:'',
        ctr:'',
        startDate: '',
        endDate:''
    });
    

    useEffect(() => {
        getApiData();
    },[])

    const getApiData =async () =>{
        let contractors  = await postAdminAPI(GET_ALL_CONTRACTOR);
        let contractorType  = await postAdminAPI(GET_CONTRACTOR_TYPE);

        setAllContractor(contractors.data);
        setAllContractorTypes(contractorType.data);
       
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        let _filter = {};
        let list = Object.keys(filter);
        list.map((l) =>{
            if(filter[l]){
                _filter = {
                    ...filter,
                    filter:filter[l]
                }
            }
        })

        console.log(_filter)
        props.handleFilter(_filter);
    }

    const updateFilter = (e, name) =>{
        let _filter = {...filter};
        _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    return (
        <>
           <Card>
                <CardHeader>
                    Generate Report
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4}>
                                <label>Contractor Type<Required /></label>
                                <select 
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">All Contractor Type</option>
                                    {
                                        allContractorTypes && allContractorTypes.map((a,i) =>(
                                            <option key={i} value={a.id}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={4}>
                                <label>Contractor</label>
                                <select 
                                    className="form-control"
                                    
                                    value={filter.ctr}
                                    onChange={(e) => updateFilter(e, "ctr")}
                                >
                                    <option value="" className="allOptions">All Contractors</option>
                                    {
                                        allContractor && allContractor.map((a,i) =>(
                                            <option key={i} value={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            
                            <Col md={4}>
                                <label>Start Date</label>
                                <DatePicker
                                    date={filter.startDate}
                                    name={"startDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    value={filter.checkDate}
                                    onChange={(e) => updateFilter(e, "checkDate")}
                                /> */}
                            </Col>
                            <Col md={4}>
                                <label>End Date</label>
                                <DatePicker
                                    date={filter.endDate}
                                    name={"endDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    value={filter.checkDate}
                                    onChange={(e) => updateFilter(e, "checkDate")}
                                /> */}
                            </Col>
                            
                        </Row>
                        
                        <Row>
                            <Col md={12} className="text-center">
                                <hr />
                                <button type="submit" className="btn btn-primary">
                                   Generate Report
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default OrderFilter;