import React, { useState, useEffect, useCallback } from 'react'
import TableComponent from '../../../component/TableComponent';
import { holidayColumns } from "../../../pages/Client/SetUp/SetupTags/columns/holiday"
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import DatePicker from "../../../component/datePicker/datePicker"
import { Card, CardHeader, Row, Col } from "reactstrap";
import * as APIs from '../../../APICall/index';
import * as URLs from '../../../APICall/urls/settings';
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../component/Notiflix/notiflix'
import { dateSlashFormant, dateTimeFormatFull } from "../../../Utils/dateFunctions";
import CopyClient from '../../../component/copyClient'
import { getTempId } from '../../../Utils/shifts';

const sampleObject = {
    "name": "",
    "is_recurring": 1,
    "client_bill_factor": 1,
    "client_pay_factor": 1,
    "is_active": 1,
    "sDate": "",
    "sTime": "",
    "eDate": "",
    "eTime": ""
}

const Holiday = (props) => {
    const [allHolidays, setAllHolidays] = useState([]);
    const [selected, setSelected] = useState(new Set());
    const [deleteHoliday, setDeleteHoliday] = useState(null)
    const [copyClient, setCopyClient] = useState(false)


    const getHoliday = useCallback(async () => {
        let res = await APIs.postAdminAPI(URLs.GET_HOLIDAYS, {})
        if (res.status) {
            let _allHoliday = modifyData(res.data)
            setAllHolidays(_allHoliday);
        }
    }, [])
    useEffect(() => {
        getHoliday()
    }, [getHoliday])

    const modifyData = (allHolidays) => {
        let _allHoliday = [];
        allHolidays.forEach(h => {
            let sDate = dateTimeFormatFull(h.start_date)
            let eDate = dateTimeFormatFull(h.end_date)
            // console.log(sDate, h.start_date)
            _allHoliday.push({
                ...h,
                sDate: sDate.date,
                sTime: sDate.time,
                eDate: eDate.date,
                eTime: eDate.time
            })
        })
        return _allHoliday
    }

    const updateTable = (allHolidays, selected) => {
        let _allHoliday = [];
        allHolidays.forEach((p, i) => {
            if (parseInt(p.is_active) === 1) {
                _allHoliday.push({
                    hName: <input
                        style={{ width: 'auto' }}
                        type="text"
                        className="form-control"
                        value={p.name}
                        onChange={(e) => handleChange(e, i, allHolidays, "name")}
                    />,
                    sDate: <DatePicker
                        type="date"
                        className="holiday-date"
                        date={p.sDate}
                        name="sDate"
                        handleDateChange={(name, date) => handleChange(date, i, allHolidays, name)}

                    />,
                    sTime: <input
                        type="time"
                        className="form-control"
                        value={p.sTime}
                        style={{ width: 150 }}
                        onChange={(e) => handleChange(e, i, allHolidays, "sTime")}

                    />,
                    eDate:
                        <DatePicker
                            type="date"
                            className="holiday-date"
                            date={p.eDate}
                            name="eDate"
                            handleDateChange={(name, date) => handleChange(date, i, allHolidays, name)}
                        />
                    ,
                    eTime: <input
                        type="time"
                        style={{ width: 150 }}
                        className="form-control"
                        value={p.eTime}
                        onChange={(e) => handleChange(e, i, allHolidays, "eTime")}

                    />,
                    recurring: <input
                        type="checkbox"
                        className="form-control"
                        value={p.is_recurring}
                        style={{ width: 70 }}
                        checked={parseInt(p.is_recurring) === 1}
                        onChange={(e) => handleChange(e, i, allHolidays, "is_recurring", "checkbox")}

                    />,
                    bill: <input
                        type="number"
                        step={0.01}
                        style={{ width: 70 }}
                        className="form-control"
                        value={p.client_bill_factor}
                        onChange={(e) => handleChange(e, i, allHolidays, "client_bill_factor")}

                    />,
                    pay: <input
                        type="number"
                        step={0.01}
                        style={{ width: 70 }}
                        className="form-control"
                        value={p.client_pay_factor}
                        onChange={(e) => handleChange(e, i, allHolidays, "client_pay_factor")}

                    />,
                    select: <input
                        type="checkbox"
                        style={{ width: 70 }}
                        className="form-control"
                        checked={selected.has(p.id)}
                        onChange={(e) => handleSelectCheck(e, p.id)}
                    />,

                })
            }
        })
        return _allHoliday;
    }

    const handleChange = (e, i, allHoliday, state, type) => {
        let _allHoliday = [...allHoliday];
        if (type === "checkbox") {
            if (e.target.checked) {
                _allHoliday[i][state] = 1;
            }
            else {
                _allHoliday[i][state] = 0;
            }
        }
        else if (state === "sDate" || state === "eDate") {
            _allHoliday[i][state] = e;
        }
        else {
            _allHoliday[i][state] = e.target.value;
        }
        setAllHolidays(_allHoliday)
    }

    const handleSelectCheck = (e, id) => {
        let _selected = new Set([...selected])
        if (e.target.checked) {
            _selected.add(id)
        }
        else {
            _selected.delete(id)
        }
        setSelected(_selected)
    }

    const handleDeleteButton = async (id) => {
        let _selected = [...selected]
        if (_selected.length === 1) {
            let index = allHolidays.findIndex((a) => parseInt(a.id) === parseInt(_selected[0]))
            if (allHolidays[index]?.id?.toString()?.includes("temp")) {
                let _allHolidays = [...allHolidays];
                _allHolidays.splice(index, 1);
                setAllHolidays(_allHolidays)
            }
            else {
                notiflixLoaderStart()
                let data = {
                    "id": allHolidays[index].id,
                };
                let result = await APIs.postAdminAPI(URLs.DELETE_HOLIDAYS, data)
                notificationStatus(result)
                if (result.status) {
                    getHoliday()
                }
            }
            setSelected(new Set())
            setDeleteHoliday(null)
        }
    }

    const onDeleteBtnClick = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a holiday to delete.")
        }
        else {
            setDeleteHoliday(true)
        }
    }

    const saveData = async () => {
        notiflixLoaderStart()
        let _allHoliday = []
        if (allHolidays && allHolidays.length > 0) {
            allHolidays.forEach(h => {
                let holiday = {
                    "name": h.name,
                    "startDate": dateSlashFormant(h.sDate) + " " + h.sTime,
                    "endDate": dateSlashFormant(h.eDate) + " " + h.eTime,
                    "clientBillFactor": h.client_bill_factor,
                    "clientPayFactor": h.client_pay_factor,
                    "isActive": h.is_active,
                    "isRecurring": h.is_recurring
                }
                if (h.id && !h.id.toString().includes("temp")) {
                    holiday = {
                        ...holiday,
                        id: h.id
                    }
                }
                _allHoliday.push(holiday)
            })
        }
        let result = await APIs.postAdminAPI(URLs.ADD_EDIT_HOLIDAYS, _allHoliday)
        notificationStatus(result)
        if (result.status) {
            getHoliday()
        }
    }

    const copyRow = () => {
        if (selected.size === 1) {
            let _selected = [...selected]
            let _holiday = allHolidays.filter((a) => a.id === _selected[0]);
            let _single = { ..._holiday[0] }
            _single.id = getTempId();
            let _allHoliday = [...allHolidays];
            _allHoliday.push(_single)
            setSelected(new Set())
            setAllHolidays(_allHoliday)
        }
        else {
            notiflixStatus(false, "Please select one holiday to copy.")
        }
    }

    const addRow = () => {
        let _allHolidays = [...allHolidays];
        _allHolidays.push({ ...sampleObject })
        setAllHolidays(_allHolidays)
    }

    const handleSubmit = async (clientIds) => {
        notiflixLoaderStart()
        if ([...selected].length && clientIds.length) {
            let data = {
                "shiftIds": [...selected],
                "clientIds": [...clientIds],
            }
            let res = await APIs.postAdminAPI(URLs.COPY_HOLIDAYS, data)
            if (res.status) {
                setCopyClient(false)
                setSelected(new Set())
                notificationStatus({ status: true, message: "Successfully copied the holidays" })
            }
            else
                notificationStatus({ status: false, message: "Failure" })
        }
        else {
            console.log("asdasdas");
            notificationStatus({ status: false, message: "Please select atleast one holiday and one client to preoceed." })
        }
    }

    return (
        <>
            <Card>

                <CardHeader style={{ padding: '0.25rem 1.25rem' }}>
                    <Row>
                        <Col md={6}>
                            System Holiday
                        </Col>
                        {/* <Col md={6} className="text-right">
                            <button
                                className="btn btn-circle1 btn-success mb-2" id="submit_shift"
                                title="Save"
                                disabled={![...selected].length}
                                onClick={() => setCopyClient(true)}
                            >
                                <i className="fas fa-copy"></i>
                            </button>
                        </Col> */}
                    </Row>
                </CardHeader>
                <TableComponent
                    rows={updateTable(allHolidays, selected)}
                    columns={holidayColumns}
                    addRow={addRow}
                    deleteRow={onDeleteBtnClick}
                    handleDeleteButton={handleDeleteButton}
                    saveTable={saveData}
                    copyRow={copyRow}
                    className="shift-table"
                    selected={selected}
                    copyToClient={() => setCopyClient(true)}
                />
                {
                    deleteHoliday
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to delete this holiday?",
                                    proceed: true
                                }

                            }
                            title="Delete holiday"
                            toggle={() => setDeleteHoliday(null)}
                            click={handleDeleteButton}
                        />
                        : null
                }
            </Card>
            {
                copyClient && <CopyClient
                    handleClose={() => setCopyClient(false)}
                    title="Copy holiday to"
                    handleSubmit={handleSubmit}
                />
            }
        </>

    )
};

export default Holiday;