//STAFFING SHEET
export const GET_SHIFT = '/get';
export const GET_SHIFT_MINI = '/order/get-mini';
export const CREATE_SHIFT = '/order/create';
export const SHIFT_REPLACEMENT = '/client/update_notification';

export const EDIT_SHIFT = '/order/update';
export const APPROVE_SHIFT = '/order/approve';
export const VIEW_SINGLE_ORDER = '/order/get-one';
export const ORDER_CUSTOM_PAY_BILL = '/order/order-custom-pay-bill';
export const ADD_NOTIFICATION = '/notification/send';

export const ADD_COMMENT = '/order/add-comment';
export const GET_COMMENT = '/order/get-comment';

export const GET_HISTORY = '/order/get-history';
export const GET_LOCATIONS = '/contractor/get-location-history';
export const ORDER_RESPONSE = "/order/get-order-response";
export const OTHER_CHARGES = "/order/other-charges";
export const DELETE_OTHER_CHARGES = "/order/delete-other-charges";
export const VALIDATE_CONTRACTOR = '/order/validate';
export const ORDER_DRAG = '/order/drag-create';
export const UPDATE_PRICE = '/order/drag-create';



//NOTIFICATION
export const GET_NOTIFICATION = '/order/get-wide';

//CREATE BULK ORDER
export const CREATE_BULK_ORDER = '/order/create-bulk';
export const GET_BULK_ORDER = '/order/get-bulk';
export const ASSIGN_BULK_ORDER = '/order/assign-bulk';

//---CONTARCTOR - CLIENT - SHIFT
export const GET_ALL_CONTRACTOR = '/contractor/get-name';
export const GET_ALL_CLIENT = '/client/get-name';
export const GET_ALL_SHIFT = '/shift/get';
export const GET_CONTRACTOR_TYPE = '/contractor/get-type';

//CLIENT
export const GET_ONE_CLIENT = "/client/get-one";
export const ADD_UPDATE_ONE_CLIENT = "/client/add-update-client";
export const GET_CLIENT_STATES = "/client/get-states";


export const GET_CLIENT_LOCATION = "/client/get-lat-long";
export const UPDATE_CLIENT_LOCATION = "/client/add-update-lat-long";

export const GET_CLIENT_USERS = "/client/get-users";
export const ADD_CLIENT_USER = "/client/add-user";
export const TOGGLE_CLIENT_USER = "/client/make-user-active-inactive";
export const RESET_CLIENT_PASSWORD = "/client/reset-user-password";
export const DELETE_CLIENT_USER = "/client/delete-undelete-user";

export const GET_AREA = "/system/contractor-area";
export const ADD_AREA = "/system/add-update-contractor-area";
export const DELETE_AREA = "/client/delete-undelete-user";

export const GET_WORK_CODE = "/shift/get-work-code";
export const ADD_UPDATE_SHIFT = "/shift/add-many-shift";
export const DELETE_SHIFT = "/shift/delete-undelete-shift";

export const ADD_UPDATE_HOLIDAY = "/payroll/add-update-holiday"
export const DELETE_HOLIDAY = "/payroll/delete-holiday";
export const GET_HOLIDAY = "/payroll/get-holiday"

export const ADD_CLIENT_COMMENT = "/client/add-comment";
export const GET_CLIENT_COMMENT = "/client/get-comment";

export const SAVE_CLIENT_CONTACTS_DIRECTION = "/client/save-contact-and-directions";
export const GET_CLIENT_CONTACTS_DIRECTION = "/client/get-contact-and-directions";
export const GET_CLIENT_CONTACT = "/client/get-client-contacts";
export const DELETE_CLIENT_CONTACT = "/client/toggle-client-contacts-status";
export const UPDATE_CLIENT_CONTACT = "/client/add-update-client-contacts";


export const UPLOAD_CLIENT_FILE = "/client/upload-file";
export const GET_CLIENT_FILE = "/client/get-file-list";
export const GET_CLIENT_FILE_SINGLE = "/client/get-file";
export const DELETE_CLIENT_FILE = "/client/delete-file";
export const MAKE_COPY_FILE_CLIENT = "/client/make-copy";

export const GET_PAY_RATES = "/client/get-pay-rates";
export const ADD_UPDATE_PAY_RATES = "/client/add-update-pay-rate";
export const DELETE_PAY_RATES = "/client/toggle-pay-rates-status"
export const GET_BILL_RATES = "/client/get-bill-rates";
export const ADD_UPDATE_BILL_RATES = "/client/add-update-bill-rate";
export const DELETE_BILL_RATES = "/client/toggle-bill-rates-status";

export const MAKE_DUPLICATE_CLIENT = "/client/duplicate-client";
export const UPDATE_BULK_ORDER_BONUS = "/order/update-bulk-order-bonus";

export const TOGGLE_CLIENT_ORIENTATION = "/client/toggle-client-cont-orientation";
export const GET_CLIENT_ORIENTATION = "/client/get-cont-orientation";

export const GET_CLIENT_CRED = "/client/get-client-cred";
export const UPDATE_CLIENT_CRED = "/client/add-update-client-cred";
export const ORDER_LABEL = "/order/order-label";
export const ORDER_STATE = "/order/order-state";
export const FORMAT_DATA = "/order/truncate";



//Contractor 
export const TOAST_TIME = 5000;