import React, { useState, useEffect } from "react"
import { notificationStatus } from "../../../component/Notiflix/notiflix"

const BonusModal = (props) => {

    const [state, setState] = useState({
        "bulk_order_id": "",
        "bonus_by_client_type": "",
        "bonus_by_client": "",
        "bonus_to_ctr": "",
        "bonus_to_ctr_type": "",
        "bonus_ratio": ""
    })

    useEffect(() => {
        if (props.data?.data) {
            let ratio = ((props.data.data?.bonus_ratio) * 100).toFixed(2)
            setState({
                bulk_order_id: props.data.id,
                "bonus_by_client_type": props.data.data.bonus_by_client_type,
                "bonus_by_client": props.data.data.bonus_by_client,
                "bonus_to_ctr": props.data.data.bonus_to_ctr,
                "bonus_to_ctr_type": props.data.data.bonus_to_ctr_type,
                "bonus_ratio": ratio,
            })

        }

    }, [props.data])

    const handleState = (e, name) => {
        let _state = { ...state }
        _state[name] = e.target.value ? parseFloat(e.target.value) : 0;
        // console.log(_state);
        setState(_state)
    }

    const handleSubmit = () => {
        // console.log(state);
        if (state.bonus_to_ctr > state.bonus_by_client) {
            notificationStatus({ status: false, message: "Bonus to contractor can't be greater than bonus to client." })
        }
        else {
            let ratio = parseFloat(state.bonus_ratio);
            // console.log(ratio);
            if (ratio > 0) {
                ratio = +((ratio / 100).toFixed(2))
            }
            let _state = { ...state }
            _state.bonus_ratio = ratio;
            // console.log(_state);
            props.updateBulkOrder(_state, props.toggle)
        }

    }


    return (
        <div className="row">
            <div className={`col-6`}>
                <div className={`row`}>
                    <div className={`col-6`}>Client Bonus Type</div>
                    <div className={`col-6`}>
                        <select
                            className="form-control"
                            value={state.bonus_by_client_type ? state.bonus_by_client_type : 0}
                            required
                            onChange={(e) => handleState(e, "bonus_by_client_type")}
                        >
                            <option value={0} selected disabled>Type</option>
                            <option value={1}>Overall</option>
                            <option value={2}>Hourly</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={`col-6`}>
                <div className={`row`}>
                    <div className={`col-6`}>Client Bonus Amount</div>
                    <div className={`col-6`}>
                        <input
                            value={state.bonus_by_client}
                            required
                            className="form-control"
                            placeholder="Amount"
                            type="number"
                            step={0.01}
                            onChange={(e) => handleState(e, "bonus_by_client")}
                        />
                    </div>
                </div>
            </div>
            <div className={`col-6`}>
                <div className={`row`}>
                    <div className={`col-6`}>Contractor Bonus Type</div>
                    <div className={`col-6`}>
                        <select
                            className="form-control"
                            value={state.bonus_to_ctr_type ? state.bonus_to_ctr_type : 0}
                            required
                            onChange={(e) => handleState(e, "bonus_to_ctr_type")}
                        >
                            <option value={0} selected disabled>Type</option>
                            <option value={1}>Overall</option>
                            <option value={2}>Hourly</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className={`col-6`}>
                <div className={`row`}>
                    <div className={`col-6`}>Contractor Bonus Amount</div>
                    <div className={`col-6`}>
                        <input
                            className="form-control"
                            placeholder="Amount"
                            value={state.bonus_to_ctr}
                            required
                            type="number"
                            step={0.01}
                            onChange={(e) => handleState(e, "bonus_to_ctr")}
                        />
                    </div>
                </div>
            </div>
            <div className={`col-6`}>
                <div className={`row`}>
                    <div className={`col-6`}>Bonus Ratio(%)</div>
                    <div className={`col-6`}>
                        <input
                            className="form-control"
                            placeholder="Bonus Ratio"
                            value={state.bonus_ratio}
                            required
                            type="number"
                            step={0.01}
                            onChange={(e) => handleState(e, "bonus_ratio")}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <button onClick={handleSubmit} className="btn btn-primary">Submit</button>
            </div>
        </div>
    )
}

export default BonusModal