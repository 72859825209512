import React, { useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import {  updateRealTime,updateRealTimeCheck } from '../../store/actions';

import audio from "../../assets/audio/notification1.mp3";

const mySound = new Audio(audio);

const Notifications = (props) => {

    const { title, body, time }  = props;
    // console.log(props)
    useEffect(() => {
        props.updateRealTime(time);
        console.log(props);
        localStorage.setItem("RNPlusNotification", time)
        updateStamp();
    },[time])

    const updateStamp = () =>{
        
        setTimeout(() =>{
            props.updateRealTimeCheck(time);
            // props.updateBody();
        },10000)
    }

    let jsx = <div className="row">
        <div className="col-md-12"><strong>{title}</strong></div>
        <div className="col-md-12">{body}</div>
    </div>
    const notify = () => toast(jsx,{
        onOpen: () =>  mySound.play(),
        onClose: () => mySound.pause()
  });
    return (
        <div>
            {notify()}
            <ToastContainer />
        </div>
    );
}

export default connect(null, {  updateRealTime, updateRealTimeCheck })(Notifications);

//Informative Notifications
