import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN, UPDATE_LOGIN } from './actionTypes';
import { apiLoginError, loginUserSuccessful } from './actions';
import { notiflixLoaderStart, notiflixLoaderEnd } from '../../../component/Notiflix/notiflix'

// AUTH related methods
import { setLoggeedInUser, postLogin, getLoggedInUser, postDetails } from '../../../helpers/authUtils';
import { act } from '@testing-library/react';

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { username, password, history } }) {
    try {
        notiflixLoaderStart()
        const response = yield call(postLogin, '/auth/login-admin', { handle: username, password: password });
        if (response) {
            console.log(response)
            const allContractors = yield call(postDetails, '/contractor/get-name', response.token);
            const allClients = yield call(postDetails, '/client/get-name', response.token);

            console.log(allContractors);

            setLoggeedInUser(response, allContractors, allClients, allContractors?.[0]?.id, allClients?.[0]?.id);
            yield put(loginUserSuccessful(response));
            // console.log(ans);
            history.push('/staffing_sheet');
        }
        else {
            // console.log(response)

            yield put(apiLoginError(response.message));
        }

        notiflixLoaderEnd()
    } catch (error) {
        yield put(apiLoginError(error));
    }
}


function* updareReduxData() {
    let response = getLoggedInUser();
    yield put(loginUserSuccessful(response));
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* updateLogin() {
    yield takeEvery(UPDATE_LOGIN, updareReduxData)
}


function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(updateLogin)
    ]);
}

export default loginSaga;