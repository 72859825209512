import React, { useState, useEffect } from 'react';

import InputComponent from '../../../../component/InputComponent/inputComponent';
import AllContacts from "./AllContacts";
import { generateCellNumber } from '../../../../shared/numberFormat';


const Contacts = (props) => {

    const [contactDirection, setContactDirection] = useState({})
    const [allContacts, setAllContacts] = useState([]);

    useEffect(() => {
        // console.log(props)
        if (props.allContactDirection && Object.keys(props.allContactDirection).length > 0) {
            setContactDirection(props.allContactDirection);
        }
        if (props.allContacts && props.allContacts.length >= 0) {
            setAllContacts(props.allContacts);
            // updateTable(props.allContacts, selected)
        }
    }, [props])


    //Contact and direction
    const handleChange = (e, state) => {
        // console.log(contactDirection)
        let _contactDirection = { ...contactDirection };
        _contactDirection[state] = e.target.value;
        setContactDirection(_contactDirection)
    }

    const handleChangeContactDirection = (e, i, state, name) => {
        let _contactDirection = { ...contactDirection }
        let _mainData = _contactDirection[state]
        // console.log(e, i, state, name)
        if (name === "phone" || name === "fax") {
            _contactDirection[state][i][name] = generateCellNumber(e.target.value);
        }
        else
            _contactDirection[state][i][name] = e.target.value;
        setContactDirection(_contactDirection)
    }

    const handleContactAdd = (newData, state) => {
        let _contactDirection = { ...contactDirection }
        newData = {
            ...newData,
            client: parseInt(props.client)
        }
        _contactDirection[state] = [
            ..._contactDirection[state],
            newData
        ]
        setContactDirection(_contactDirection)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(contactDirection)

        let _contactDirection = {}

        let emails = contactDirection.emails && contactDirection.emails.length > 0 ? deleteExtraData(contactDirection.emails) : [];
        let faxes = contactDirection.faxes && contactDirection.faxes.length > 0 ? deleteExtraData(contactDirection.faxes) : [];
        let phones = contactDirection.phones && contactDirection.phones.length > 0 ? deleteExtraData(contactDirection.phones) : [];

        _contactDirection = {
            emails: emails,
            faxes: faxes,
            phones: phones,
            directions: contactDirection.directions
        }

        // console.log(_contactDirection)
        props.updateContactDirection(contactDirection)
        saveContactData()

    }

    const saveContactData = () => {
        let _allContacts = [];
        allContacts && allContacts.map(a => {
            let temp = {
                "client_id": props.client,
                "description": a.description,
                "cont_name": a.cont_name,
                "main": a.main,
                "cell": a.cell,
                "pager": a.pager,
                "fax": a.fax,
                "email": a.email,
                "note": a.note
            }
            if (a.cont_id) {
                temp = {
                    ...temp,
                    cont_id: a.cont_id
                }
            }
            _allContacts.push(temp)
        })
        props.updateContacts(_allContacts)
    }

    const deleteExtraData = (data) => {
        let finalData = data.map(e => {
            delete e.client;
            delete e.id;
            return e;
        })
        // console.log(finalData)
        return finalData;
    }

    // console.log(contactDirection)

    //--------------contacts

    const handleDelete = (index, state) => {
        let _contactDirection = { ...contactDirection }
        // console.log(_contactDirection[state], index, state)

        _contactDirection[state].splice(index, 1)
        // console.log(_contactDirection[state])
        setContactDirection(_contactDirection)

    }
    console.log(contactDirection);
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <InputComponent
                            firstTitle="Phone"
                            secondTitle="Description"
                            allData={contactDirection.phones}
                            first={"phone"}
                            second={"des"}
                            state={"phones"}
                            handleChange={handleChangeContactDirection}
                            handleContactAdd={handleContactAdd}
                            handleDelete={handleDelete}
                        />
                    </div>
                    <div className="col-md-12">
                        <InputComponent
                            firstTitle="Fax"
                            secondTitle="Description"
                            allData={contactDirection.faxes}
                            first={"fax"}
                            second={"des"}
                            state={"faxes"}
                            handleChange={handleChangeContactDirection}
                            handleContactAdd={handleContactAdd}
                            handleDelete={handleDelete}
                        />
                    </div>
                    <div className="col-md-12">
                        <InputComponent
                            firstTitle="Email"
                            secondTitle="Description"
                            allData={contactDirection.emails}
                            first={"email"}
                            second={"des"}
                            state={"emails"}
                            handleChange={handleChangeContactDirection}
                            handleContactAdd={handleContactAdd}
                            handleDelete={handleDelete}
                        />
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <label>Direction</label>
                                <input
                                    required
                                    type="text"
                                    className="form-control"
                                    value={contactDirection.directions}
                                    onChange={(e) => handleChange(e, "directions")}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <AllContacts
                    allContacts={allContacts}
                    setAllContacts={setAllContacts}
                    deleteContacts={props.deleteContacts}
                    updateContacts={props.updateContacts}
                    client={props.client}
                />
                <div className='row'>
                    <div className="col-md-12 mt-2 text-center">
                        <button type="submit" className="btn btn-outline-success" >Save</button>
                    </div>
                </div>
            </form>
        </>

    )
}
export default Contacts;