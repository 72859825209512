import React from 'react'
import AvailabilityCard from '../AvailabilityCard'
import { changeTime, dateArithmetic } from '../../../Utils/dateFunctions';

const AvailabilityRow = (props) => {

    const getStaffingCard = (date, dailyAvailability) => {
        let _rows = [];
        for (let i = 0; i < 7; i++) {
            let currentDate = i > 0 ? dateArithmetic(date, i) : date;
            let _data = dailyAvailability.filter(a => a.date === currentDate)
            _rows.push(
                <AvailabilityCard
                    currentDate={currentDate}
                    showDate={props.showDate}
                    handleModal={props.handleModal}
                    allData={_data.length > 0 ? _data[0] : {}}
                />
            )
        }
        return _rows;
    }

    const getAlwaysStaffingCard = (alwaysAvailability, alwaysAvailable) =>{
        let _rows = [];
        for (let i = 0; i < 7; i++) {
            // console.log(alwaysAvailability)
            let _always = alwaysAvailability && alwaysAvailability.filter(a => parseInt(a.day_num) === i)
            _rows.push(
                <AvailabilityCard
                    currentDate={""}
                    showDate={props.showDate}
                    handleModal={props.handleModal}
                    allData={_always.length > 0 ? _always[0] : {}}
                    alwaysAvailable={alwaysAvailable}
                    dayNum={i}
                />
            )
        }
        return _rows;
    }

    return (
        <>
            <>
                {
                    props.numberOfRow === 4
                        ?
                        <>
                            <tr>
                                {getStaffingCard(dateArithmetic(props.startDate, 0), props.dailyAvailability)}
                            </tr>
                            <tr>
                                {getStaffingCard(dateArithmetic(props.startDate, 7), props.dailyAvailability)}
                            </tr>
                            <tr>
                                {getStaffingCard(dateArithmetic(props.startDate, 14), props.dailyAvailability)}
                            </tr>
                            <tr>
                                {getStaffingCard(dateArithmetic(props.startDate, 21), props.dailyAvailability)}
                            </tr>
                        </>
                        :
                        <tr>
                            {
                                props.alwaysAvailable
                                ?
                                <>
                                    {getAlwaysStaffingCard(props.alwaysAvailability, props.alwaysAvailable)}
                                
                                </>
                                :
                                <>
                                    {getStaffingCard(dateArithmetic(props.startDate, 0), props.dailyAvailability)}
                                </>
                            }
                            
                        </tr>

                }

            </>
        </>
    )
}

export default AvailabilityRow;