import React from 'react'
import DateCard from '../dateCard'
import { changeTime, dateArithmetic, timeFormatFull, dateUSStandard } from '../../../Utils/dateFunctions';
import SingleStaffingCard from "../../../component/StaffingSheet/SingleStaffignCard/index"

const ScheduleRow = (props) => {

    const getDate = (date) => {
        let _rows = [];
        for (let i = 0; i < 7; i++) {
            let currentDate = i > 0 ? dateArithmetic(date, i) : date;
            _rows.push(
                <>
                    <DateCard
                        currentDate={dateUSStandard(currentDate)}
                    />
                </>
            )
        }
        return _rows;
    }

    const getMaximumCount = (allOrders, date) =>{
        let count = 0;
        // console.log(count);
        for (let i = 0; i < 7; i++) {
            let currentDate = i > 0 ? dateArithmetic(date, i) : date;
            // console.log(allOrders[currentDate],allOrders, currentDate)
            
            if((allOrders[currentDate] && (allOrders[currentDate].length > count))){
                count = allOrders[currentDate].length;
            }
        }
        // console.log(count);
        return count;
    }

    const generateRow = (date, orderData, startCount) => {
        let _rows = [];
        let maxCount = getMaximumCount(orderData, date)
        for (let d = 0; d <= maxCount; d++) {
            let singleRow = [];
            
            for (let i = 0; i < 7; i++) {
                let currentDate = i > 0 ? dateArithmetic(date, i) : date;
                // console.log(orderData[currentDate],d, currentDate)
                let data = orderData[currentDate] ? (orderData[currentDate][d] 
                        ? 
                        {...orderData[currentDate][d],
                            isContractor:true,
                            start_time:timeFormatFull(orderData[currentDate][d].start_date),
                            end_time:timeFormatFull(orderData[currentDate][d].end_date),
                        }
                        
                        :{} ) : {};
                // console.log(data);
                if(d === maxCount){
                    singleRow.push(
                        <>
                            <SingleStaffingCard
                                data={{}}
                                shift={{}}
                                count={i+startCount}
                                key={i}
                                date={currentDate}
                                handleModal={props.handleModal}
                                isContractor={true}
                                afterActionCalls={props.afterActionCalls}
                            />
                        </>
                    )
                }
                else{
                    singleRow.push(
                        <>
                            <SingleStaffingCard
                                data={data}
                                shift={{}}
                                count={i+startCount}
                                key={i}
                                date={currentDate}
                                handleModal={props.handleModal}
                                isContractor={true}
                                afterActionCalls={props.afterActionCalls}
                            />
                        </>
                    )
                }
            }
            _rows.push(<tr>
                {singleRow}
            </tr>)
        }

        return _rows;
    }


    return (
        <>
            <>
                {
                    <>
                        <tr>
                            {getDate(dateArithmetic(props.startDate, 0))}
                        </tr>
                        {generateRow(dateArithmetic(props.startDate, 0), props.allOrders, 0)}
                        <tr>
                            {getDate(dateArithmetic(props.startDate, 7))}
                        </tr>
                        {generateRow(dateArithmetic(props.startDate, 7), props.allOrders, 7)}
                        <tr>
                            {getDate(dateArithmetic(props.startDate, 14))}
                        </tr>
                        {generateRow(dateArithmetic(props.startDate, 14), props.allOrders, 14)}
                        <tr>
                            {getDate(dateArithmetic(props.startDate, 21))}
                        </tr>
                        {generateRow(dateArithmetic(props.startDate, 21), props.allOrders,21)}
                    </>

                }

            </>
        </>
    )
}

export default ScheduleRow;