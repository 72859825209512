import React, { Component } from 'react';
import {
    Col,
    Row,
    Card,
    CardHeader,
} from "reactstrap";
import C3Chart from 'react-c3js';
import 'c3/c3.css';

class AreaChart extends Component {
    constructor(props) {
        super(props);
        this.state ={
            columns: [
                ['x', '2010-01-01', '2011-01-01', '2012-01-01', '2013-01-01', '2014-01-01', '2015-01-01', '2016-01-01'],
                ["Shifts",130,120,150,140,160,150,130],
            ],
            x:'x',
            types: {Revenue:"area-spline",},
            colors: {Revenue:"#f0f1f4",},
            axis: {
                x: {
                    type: 'timeseries',
                    tick: {
                        format: '%Y-%m-%d'
                    }
                }
            }
        }
        
    }

    getParticularData = (data, name) =>{
        let _data = [];
        if(data && data.length > 0){
            data.forEach(d =>{
                _data.push(d[name])
            })
        }

        return _data

    }


    componentDidMount() {
        if(this.props.data){
            this.generateData();
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.data !== this.props.data){
            this.generateData();
        }
    }

    generateData = () =>{
        let data = [...this.props.data];
        let date = this.getParticularData(data, "date");
        let value = this.getParticularData(data, "count");

        let columns = [
            ['x', ...date],
            ["Shifts", ...value],
        ]

        console.log(columns);

        this.setState({columns: columns})
    }
    

    render() {
        const { columns, types, colors, axis } = this.state;
        return (
            <React.Fragment>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={6}>
                                {this.props.title}
                            </Col>
                        </Row>
                    </CardHeader>
                    <C3Chart data={{columns:columns, types:types, colors:colors, x:'x'}} axis={axis} />
                </Card>
            </React.Fragment>
           
        );
    }
}
export default AreaChart;