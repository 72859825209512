import React, { useState, useEffect, useRef } from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Overlay from "../overlay";
import ModalLayout from "../ModalLayout";

import AllColumns from "./allColumns";
import Loader from "../smallLoader"
import Pagination from 'rc-pagination';
import AllSort from "./allSort"
import AllFilter from "./allFilterSection"
import { formatDateCurrent, isDateFormatWithTZ, changeTzToNormalDefault, isDBDateFormat, convertDateFormatUSA } from "./../../Utils/dateFunctions"
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css"
import "./rc.css"
import { isInteger, isFinite } from 'lodash';
import * as dates from "./../../Utils/dateFunctions"


const Table = ({ search, onSearch, handleChangeColumn, fetchSelectedPage, loadingPagination, totalSize, pageNumber, columns, updateColumns, sort, setSort, changeColumn, exportExcel, modalFilter, setModalFilter, updateExtraFilter, extraFilter, isLastRowFooter, ...props }) => {
    const perPage = 10;
    const [allLabels, setAllLabels] = useState([])
    const [size, setSize] = useState(perPage)
    const tableRef = useRef(null);

    useEffect(() => {
        getColumn()
    }, [columns, ...columns.map((c) => c.isVisible)])

    const getColumn = () => {
        if (columns && columns.length > 0) {
            let _allLabels = [];
            columns.forEach((p) => {
                if (p.isVisible)
                    _allLabels.push(p.field)
            })
            setAllLabels(_allLabels)
        }
    }

    const getCount = () => {
        let a = parseInt(totalSize) % perPage === 0 ? (parseInt(totalSize) / perPage) : (parseInt(totalSize) / perPage + 1)
        return parseInt(a)
    }

    const PaginationChange = (page, pageSize) => {
        fetchSelectedPage(page)
        setSize(pageSize)
    }

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(parseInt(totalSize) / value);
        if (pageNumber > newPerPage) {
            fetchSelectedPage(newPerPage);
        }
    }

    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button><i className="fa fa-angle-double-left"></i></button>;
        }
        if (type === 'next') {
            return <button><i className="fa fa-angle-double-right"></i></button>;
        }
        return originalElement;
    }

    const handleExport = () => {
        const wb = XLSX.utils.table_to_book(tableRef.current, { sheet: "Sheet1" });
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        saveAs(blob, `${props.reportName}_${formatDateCurrent()}.xlsx`);
    };

    const validateData = (value, name) => {        
        if(isFinite(value)){
            let filter = props.decimalArr?.filter((n) => n === name)
            if(filter?.length)
                return value.toFixed(2);
            else
                return value;
        }   
        else if(isDBDateFormat(value)){
            return convertDateFormatUSA(value)
        }
        else if(isDateFormatWithTZ(value ? value.toString() : "")){
            console.log(value);
            let date = dates.dateTimeBasicFormantSplit(value)
            console.log(date);
            return date.date+" "+ date.time
        }
        return value
    }    
    return (
        <div className="mdb-datatable dt-bootstrap4">

            <div className="row mb-2">

                <div className="col-md-8"></div>
                <div className="col-md-2 d-flex align-items-center">
                    {
                        onSearch && <div className="" style={{ flexDirection: "column", width: "inherit" }}>
                            <label htmlFor={"htmlFor"} className={"input-label"}>
                                Search
                            </label>
                            <input
                                style={{ width: "100%" }}
                                className={"form-control"}
                                type="text"
                                value={search}
                                onChange={(e) => onSearch(e)}
                            />

                        </div>
                    }
                </div>
                <div className="col-md-2 d-flex justify-content-end">
                    {
                        handleChangeColumn
                        &&
                        <button
                            className="btn btn-primary btn-sm"
                            title="View Columns"
                            onClick={() => handleChangeColumn(true)}
                        >Column</button>
                    }
                    &nbsp;
                    {
                        setSort
                        &&
                        <button
                            className="btn btn-primary btn-sm"
                            title="View Sort"
                            onClick={() => setSort(true)}
                        >Sort</button>
                    }
                    &nbsp;
                    {
                        setModalFilter
                        &&
                        <button
                            className="btn btn-primary btn-sm"
                            title="View Filter"
                            onClick={() => setModalFilter(true)}
                        >Filter</button>
                    }
                    &nbsp;
                    {
                        exportExcel &&
                        <button
                            className="btn btn-primary btn-sm"
                            title="Downloa Excel"
                            onClick={() => handleExport()}
                        >Export</button>
                    }
                    &nbsp;
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="" style={{ overflowX: 'auto' }}>
                        <table className={isLastRowFooter ? "custom-table custom-table-footer" : "custom-table"} ref={tableRef}>
                            <thead className="mdb-dataTable-head">
                                <tr>
                                    {
                                        columns?.map((h, i) => (
                                            h.isVisible &&
                                            <th key={i}>
                                                <div
                                                    className="custom-table-head"
                                                >
                                                    <span>{h.label}</span> &nbsp;
                                                    {
                                                        (h.isSortActive)
                                                            ?
                                                            <i style={{ marginTop: 1 }} className={h.sortValue === 'desc' ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.rows?.map((r, j) => (
                                        <tr key={j}>
                                            {
                                                allLabels && allLabels.length > 0 && allLabels.map((a, k) => {
                                                    // let _data = r[a];
                                                    return (
                                                        <td key={k} style={isFinite(r[a]) ? {textAlign: 'right'} : null}>
                                                            {
                                                                validateData(r[a], a)
                                                            }
                                                            {/* {isDateFormatWithTZ(r[a] ? r[a].toString() : "") ? convertToFullTime(_data) : _data} */}
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
                {
                    fetchSelectedPage && <div className="col-md-12 table-pagination mt-2">
                        {
                            loadingPagination && <Loader />
                        }
                        <Pagination
                            className="pagination-data"
                            showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}
                            onChange={PaginationChange}
                            total={totalSize}
                            current={pageNumber}
                            pageSize={size}
                            showSizeChanger={false}
                            itemRender={PrevNextArrow}
                            onShowSizeChange={PerPageChange}
                        />

                    </div>
                }

                {
                    changeColumn
                        ?
                        <Overlay
                            overlay={true}
                            title={"All Columns"}
                            isLarge={true}
                            handleOverLay={() => handleChangeColumn(false)}
                            style={{ width: '17vw' }}
                        >
                            <AllColumns
                                columns={columns}
                                updateColumns={updateColumns}
                            />
                        </Overlay>
                        : null
                }
                {
                    sort
                        ?
                        <Overlay
                            overlay={true}
                            title={"Sort Options"}
                            isLarge={true}
                            handleOverLay={() => setSort(false)}
                            style={{ width: '17vw' }}
                        >
                            <AllSort
                                allColumns={columns}
                                updateColumns={updateColumns}
                            />
                        </Overlay>
                        : null
                }
                {
                    modalFilter
                        ?
                        <ModalLayout
                            modal={true}
                            toggle={() => setModalFilter(null)}
                            title={'Filter options'}
                            size="lg"
                        >
                            <AllFilter
                                columns={columns}
                                updateFilters={updateExtraFilter}
                                filters={extraFilter}
                            />
                        </ModalLayout>
                        : null
                }
            </div>
        </div>
    )
}


export default Table