import React, { useState, useEffect } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { notiflixLoaderStart, notiflixStatus } from '../../../component/Notiflix/notiflix'


const ModalDuplicate = (props) => {
    const [client, setClient] = useState(0);
    const [name, setName] = useState('');
    const [state, setState] = useState({
        shiftAllowed: true,
        holidayAllowed: true,
        payRateAllowed: true,
        billRateAllowed: true
    })

    const handleCheckbox = (e) => {
        // console.log(e.target.checked, e.target.name);
        let _state = { ...state }
        _state[e.target.name] = e.target.checked;
        console.log(e.target.checked, e.target.name, _state);
        setState(_state)
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        if (client > 0) {
            let data = {
                "clnt_id": client,
                "new_clnt_name": name,
                shiftAllowed: state.shiftAllowed,
                holidayAllowed: state.holidayAllowed,
                payRateAllowed: state.payRateAllowed,
                billRateAllowed: state.billRateAllowed,
            }
            // console.log(data);
            props.handleDuplicate(data);
        }
        else {
            notiflixStatus(false, "Please select a client to continue.")
        }
    }

    // console.log(tableRow, contractorTypes, t)

    return (
        <Modal size="md" isOpen={props.modal}>
            <ModalHeader>
                <div className={`create-order-modal-header`}>
                    <span>Create Clients</span>
                    <CloseIcon
                        onClick={() => props.toggle()}
                        className={`create-modal-close-icon`}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <form onSubmit={onSubmitHandler}>
                    <div className={`create-order-modal-body`}>
                        <div className={`row`}>

                            <div className={`col-md-3 mb-2`}>Clients</div>
                            <div className={`col-md-9 mb-2`}>
                                <select
                                    value={client}
                                    required
                                    className={`form-control`}
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <option value={0}>Select Client</option>
                                    {
                                        props.allClients.map((u, i) => (
                                            <option value={u.id}>{u.name}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className={`col-md-3 mb-2`}>Name</div>
                            <div className={`col-md-9 mb-2`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    placeholder="Enter Name"
                                />
                            </div>

                            <div className={`col-md-3 mb-2`}>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    name="holidayAllowed"
                                    checked={state.holidayAllowed}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                            </div>
                            <div className={`col-md-9 mb-2`}>
                                Copy Holiday
                            </div>
                            <div className={`col-md-3 mb-2`}>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    name="shiftAllowed"
                                    checked={state.shiftAllowed}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                            </div>
                            <div className={`col-md-9 mb-2`}>
                                Copy Shift
                            </div>
                            <div className={`col-md-3 mb-2`}>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    name="billRateAllowed"
                                    checked={state.billRateAllowed}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                            </div>
                            <div className={`col-md-9 mb-2`}>
                                Copy Bill Rate
                            </div>
                            <div className={`col-md-3 mb-2`}>
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    name="payRateAllowed"
                                    checked={state.payRateAllowed}
                                    onChange={(e) => handleCheckbox(e)}
                                />
                            </div>
                            <div className={`col-md-9 mb-2`}>
                                Copy Pay rate
                            </div>


                            <div className={`col-md-12 text-center`} >
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default ModalDuplicate;
