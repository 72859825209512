import React, { Component } from 'react';
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../../store/actions";
import SettingTable from '../../../component/SettingTable';
import ConfirmModal from "../../../component/confirm/confirmForOrder";

import { columns } from "./columns";



let first = {
    code: "",
    des: "",
}

class SelectedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            rows: [],
            allData: [],
            selected: null
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("general Shifts", this.state.breadcrumbItems);
        this.updateState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedData != this.props.selectedData) {
            this.updateState();
        }
    }

    updateState = () => {
        this.setState({ allData: this.props.selectedData })
        this.updateTable(this.props.selectedData)
    }

    deleteData = () => {
        let _allData = [...this.state.allData];
        if (this.state.selected.item?.id) {
            this.props.deleteData(this.state.selected.item, this.state.selected.index)
        }
        else {
            _allData.splice(this.state.selected.index, 1);
            this.setState({ allData: _allData })
            this.updateTable(_allData)
        }

        this.setState({ selected: null })

    }

    saveTable = () => {
        this.props.saveData(this.state.allData);
    }

    //ACTIONS
    updateTable = (allData) => {
        let _allData = [];
        // console.log(allData)
        allData?.map((p, i) => {
            _allData.push({
                code: <input
                    type="text"
                    className="form-control"
                    disabled={p.id ? true : false}
                    value={p.code}
                    onChange={(e) => this.handleChange(e, i, allData, "code")}
                />,
                description: 
                <input
                    type="text"
                    className="form-control"
                    value={p.des}
                    onChange={(e) => this.handleChange(e, i, allData, "des")}

                />,
                delete: <button
                    className="btn btn-circle1 btn-outline-danger mb-2 text-center"
                    title="Delete"
                    onClick={() => this.setState({ selected: { item: p, index: i } })}
                >
                    <i className="fas fa-trash"></i>
                </button>,

            })
        })
        // console.log(_allData)
        this.setState({ rows: _allData })
    }

    handleChange = (e, i, allData, state, type) => {
        // console.log(allData)
        let _allData = [...allData];
        // console.log(_allData, _allData[i], i, state)
        if (type === "checkbox") {
            // console.log(e.target.checked)
            if (e.target.checked) {
                _allData[i][state] = 1;
            }
            else {
                _allData[i][state] = 0;
            }
            // console.log(_allShifts[i][state])
        }
        else {
            _allData[i][state] = e.target.value;
        }
        this.setState({ rows: _allData })
        this.updateTable(_allData)

    }

    addRow = () => {
        let _userData = [...this.state.allData];
        _userData.push({ ...first })
        // console.log(_userData)
        this.setState({ allData: _userData })
        this.updateTable(_userData)
    }

    render() {
        const { rows } = this.state;
        return (
            <>
                <SettingTable
                    rows={rows}
                    columns={columns}
                    addRow={this.addRow}
                    saveTable={this.saveTable}
                    title={this.props.selected.name}
                />
                {
                    this.state.selected
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to delete this shift?",
                                    proceed: true
                                }

                            }
                            title="Delete shift"
                            toggle={() => this.setState({ selected: null })}
                            click={this.deleteData}
                        />
                        : null
                }
            </>

        )
    }
}
export default connect(null, { setBreadcrumbItems })(SelectedList);

