import React from 'react';
import { timeFormatFull } from "../../../../Utils/dateFunctions";

const Information = (props) => {
    console.log(props)

    const checkFn = (name) =>{
        return name ? name : ""
    }

    return (
        <table className="breakdown-table" style={{ width: '100%' }}>
            {
                props.type === "client"
                    ?
                    <tbody>
                        <tr>
                            <td><strong>Name: </strong> {props.data.name}</td>
                        </tr>
                        <tr>
                            <td><strong>Address: </strong> {props.data.add_1+","+props.data.add_2+","+props.data.city}</td>
                        </tr>
                        <tr>
                            <td><strong>Number: </strong>{props.data.login_no} </td>
                        </tr>
                    </tbody>
                    :
                    <tbody>
                        <tr>
                            <td><strong>Name: </strong> {props.data.f_name+" "+props.data.l_name ? props.data.l_name : ""}</td>
                        </tr>
                        <tr>
                            <td><strong>Type: </strong> {props.data.add_1+","+props.data.add_2+","+props.data.city}</td>
                        </tr>
                        <tr>
                            <td><strong>Number: </strong>{props.data.mob1} </td>
                        </tr>
                    </tbody>
            }

        </table>
    )
}

export default Information;