import React from 'react';
//user Defined Functions
import { dateArithmetic, changeDateByTimezone } from '../../Utils/dateFunctions'
//Component
import ScheduleRow from './ScheduleRow';
import { days } from "../../Utils/Variables/days";

const ContractorSchedule = (props) => {

    // console.log(props.days)

    const getHeader = () => {
        let _head = [];
        let current = props.startDate;
        // console.log(current, today);

        for (let i = 0; i < 7; i++) {
            let style = { backgroundColor: '#ffffff', textAlign: 'center'}
            
            var d = changeDateByTimezone(current)
            var dayName = days[d.getDay()];
            _head.push(<th key={i} style={style} >{dayName}</th>)
            current = dateArithmetic(current, 1);
        }
        // console.log(_head)
        return _head;
    }

    return (
        <div className="calendertable">
            <table className="table table-bordered" style={{marginBottom:0}} >
                <thead>
                    <tr>
                        {getHeader()}
                    </tr>
                </thead>
                <tbody>
                    <ScheduleRow
                        startDate={props.startDate}
                        showDate={props.showDate}
                        numberOfRow={props.numberOfRow}
                        handleModal={props.handleModal}
                        allOrders={props.allOrders}
                        afterActionCalls={props.afterActionCalls}
                    />
                </tbody>

            </table>
        </div>

    )
}

export default ContractorSchedule;