// import React from 'react';
// import Map from './Location1';

// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { 
//       map: {}
//     }
//   } 

//   handleMapLoad = (map) => {
//     this.setState({
//       map: map
//     })
//   }

//   render() {
//     return (
//       <div style={{minHeight:2000}}>
//          <input id="pac-input" class="controls form-control col-md-6 mt-2" type="text" placeholder="Search Box"></input>
//         <Map id="myMap" options={{center: { lat: 51.501904, lng: -0.115871 }, zoom: 13}}    onMapLoad = {this.handleMapLoad}/>  
//       </div>

//     );
//   }
// }

// export default App;

import React from 'react';

import { LoadScript } from "@react-google-maps/api";
import Map from "../../../../component/Client/Location"
import { MAP_API_KEY } from "../../../../Utils/Variables/map"
const lib = ["places", "geometry"];
const Location = (props) => {
    // console.log(props.location)
    return (
        <div style={{padding:'2rem'}}>
            <LoadScript googleMapsApiKey={MAP_API_KEY} libraries={lib}>
                <Map
                    updateLocation={props.updateLocation}
                    location={props.location}
                
                />
            </LoadScript>
        </div>

    );
}

export default Location;