import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from "react-redux";

import DatePicker from '../../../../component/datePicker/datePicker';
import allStatus from "../../../../Utils/reportjson/status.json"
import PaidStatus from "../../../../Utils/reportjson/paidStatus.json"
import Confirmation from "../../../../Utils/reportjson/confirmation.json"
import OrderStatus from "../../../../Utils/reportjson/orderStatus.json"
import Timesheet from "../../../../Utils/reportjson/timeSheetStatus.json"
import { dateDrop } from "../../../../Utils/reportjson/dateDropdown"

import ReactSelect from '../../../../component/ReactSelect/checkboxSelect';
import { dateArithmetic, dateStandard } from '../../../../Utils/dateFunctions';

import { ConvertForSelect } from "../../../../Utils/arrayFunction"
import * as helper from '../report/helper';
import { notificationStatus } from '../../../../component/Notiflix/notiflix';
import './style.scss'

export let filledBy = [
    {
        "id": 1,
        "value": ["order_filed_by", "=", 'Abraham'],
        "fullName": "Abraham"
    },
]

export let allRegion = [
    {
        "id": 1,
        "value": ["client_region", "=", "region1"],
        "fullName": "region1"
    },
    {
        "id": 2,
        "value": ["client_region", "=", "region2"],
        "fullName": "region2"
    },
]

export const generateFilter = (filter) => {
    let _filter = [];
    if (filter.ctr?.length) {
        let _f = helper.getContractorFilter(filter.ctr)
        _filter = [..._filter, ..._f]
    }
    if (filter.client?.length) {
        let _f = helper.getArrayFilter(filter.client, "client_name")
        _filter = [..._filter, ..._f]
    }
    if (filter.state) {
        let _f = helper.getListFilter(filter.state, allStatus)
        _filter.push(_f)
    }
    if (filter.startDate) {
        _filter = [..._filter, ["order_start_date", ">=", filter.startDate]]
    }
    if (filter.endDate) {
        _filter = [..._filter, ["order_end_date", "<=", filter.endDate]]
    }
    if (filter.confirmedWith) {
        let _f = helper.getListFilter(filter.confirmedWith, Confirmation)
        _filter.push(_f)
    }
    if (filter.orderStatus) {
        let _f = helper.getListFilter(filter.orderStatus, OrderStatus)
        _filter.push(_f)
    }
    if (filter.paidInvoiced) {
        let _f = helper.getListFilter(filter.paidInvoiced, PaidStatus)
        if (Array.isArray(_f[0])) {
            _filter = [..._filter, ..._f]
        }
        else
            _filter.push(_f)
    }
    
    //---------
    if (filter.client_region) {
        let _f = helper.getListFilter(filter.client_region, allRegion)
        _filter.push(_f)
    }
    if (filter.shift_work_code) {
        _filter = [..._filter, ["shift_work_code", "=", filter.shift_work_code]]
    }
    if (filter.filledBy) {
        let _f = helper.getListFilter(filter.filledBy, filledBy)
        _filter.push(_f)
    }
    if (filter.shift_type) {
        _filter = [..._filter, ["shift_type", "=", filter.shift_type]]
    }
    if (filter.ctrt_code) {
        _filter = [..._filter, ["ctrt_code", "=", filter.ctrt_code]]
    }
    if (filter.timeSheet) {
        let _f = helper.getListFilter(filter.timeSheet, Timesheet)
        _filter.push(_f)
    }


    return _filter
}

const OrderFilter = (props) => {
    console.log(props.filterFromApply);
    const [filter, setFilter] = useState({
        ctr: '',
        client: '',
        state: '',
        startDate: '',
        endDate: '',
        confirmedWith: "",
        orderStatus: "",
        paidInvoiced: "",
        dateDropdown: "",
        client_region:'',
        filledBy:'',
        shift_work_code:'',
        shift_type:'',
        ctrt_code:'',
        timeSheet:''
    });

    useEffect(() => {
        if (props.filterFromApply) {
            setFilter(props.filterFromApply)
        }
    }, [props.filterFromApply])
    const handleSubmit = (e) => {
        e.preventDefault();
        let _filter = generateFilter(filter)
        if (_filter.length) {
            props.handleFilter(_filter);
        }
        else {
            notificationStatus({ status: false, message: "Please select at least one filter to proceed. " })
        }
        // console.log(_filter);
    }

    const updateFilter = (e, name) => {
        let _filter = { ...filter };
        if (name === "ctr" || name == "client")
            _filter[name] = e;
        else
            _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    useEffect(() => {
        if (filter.dateDropdown) {
            let getDate = dateDrop.filter((d) => d.id == filter.dateDropdown)
            if (getDate.length && getDate[0].start) {
                setFilter({ ...filter, startDate: getDate[0].start[0], endDate: getDate[0].start[1] })
            }
            else {
                setFilter({ ...filter, startDate: null, endDate: null })
            }
        }
    }, [filter.dateDropdown])

    return (

        <Card>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <div className='workCodeCard'>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Contractor</label>
                            </Col>
                            <Col md={6}>
                                <ReactSelect
                                    options={ConvertForSelect(props.basicDetails?.allContractor, "name", "id")}
                                    onChange={(e) => updateFilter(e, "ctr")}
                                    value={filter.ctr}
                                    allowSelectAll={true}
                                    isMulti={true}
                                    noMin={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Client</label>
                            </Col>
                            <Col md={6}>
                                <ReactSelect
                                    options={ConvertForSelect(props.basicDetails?.allClients, "name", "id")}
                                    onChange={(e) => updateFilter(e, "client")}
                                    value={filter.client}
                                    allowSelectAll={true}
                                    isMulti={true}
                                    noMin={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Shift Date</label>
                            </Col>
                            <Col md={2}>
                                <select
                                    className="form-control"
                                    value={filter.dateDropdown}
                                    onChange={(e) => updateFilter(e, "dateDropdown")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dateDrop?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.startDate}
                                    name={"startDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.endDate}
                                    name={"endDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Status</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.state}
                                    onChange={(e) => updateFilter(e, "state")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        allStatus?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Confirmed With</label>
                            </Col>
                            <Col md={6}>

                                <select
                                    className="form-control"
                                    value={filter.confirmedWith}
                                    onChange={(e) => updateFilter(e, "confirmedWith")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        Confirmation?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Order Status</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.orderStatus}
                                    onChange={(e) => updateFilter(e, "orderStatus")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        OrderStatus?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Paid/Invoiced</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.paidInvoiced}
                                    onChange={(e) => updateFilter(e, "paidInvoiced")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        PaidStatus?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Client Work code</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.shift_work_code}
                                    onChange={(e) => updateFilter(e, "shift_work_code")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allWorkCode?.map((a, i) => (
                                            <option key={a.code} value={a.code}>{a.des}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Shift type</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.shift_type}
                                    onChange={(e) => updateFilter(e, "shift_type")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allShiftTypes?.map((a, i) => (
                                            <option key={a.code} value={a.code}>{a.des}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Timesheet verified</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.timeSheet}
                                    onChange={(e) => updateFilter(e, "timeSheet")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        Timesheet?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Contractor type</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrt_code}
                                    onChange={(e) => updateFilter(e, "ctrt_code")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allContractorTypes?.map((a, i) => (
                                            <option key={a.code} value={a.code}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Client region</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.client_region}
                                    onChange={(e) => updateFilter(e, "client_region")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        allRegion?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Filled by</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.filledBy}
                                    onChange={(e) => updateFilter(e, "filledBy")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        filledBy?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <hr className='hrTag' />
                    <Row>
                        <Col md={8} className="text-left">
                            <button type="button" onClick={() => props.setModalFilter(filter)} className="btn btn-primary">
                                Save Filter
                            </button>
                            &nbsp; &nbsp;
                            <button type="button" onClick={() => props.getModalFilterGet(true)} className="btn btn-primary">
                                Custom Reports
                            </button>
                        </Col>
                        <Col md={4} className="text-right">
                            <button type="submit" className="btn btn-primary">
                                Generate Report
                            </button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>

    )
}

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps)(OrderFilter);;