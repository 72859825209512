export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeAttribute = (string) => {
    if (string) {
        let _word = string.replace(/_/g, " ");
        _word = capitalizeFirstLetter(_word);
        return _word;
    }
    else {
        return "";
    }
}

export const camelCaseToTitleCase = (s) => {
    // console.log(s)
    if (s) {
        return s.split("").reduce(
            (acc, letter, i) => (
                i === 0
                    ? [...acc, letter.toUpperCase()]
                    : letter === letter.toUpperCase()
                        ? [...acc, " ", letter]
                        : [...acc, letter]
            ), []
        ).join("")
    }
    else return ""
}

export const snakeCaseToTitleCase = (str) => {
    return str ? str.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase()) : ""
}


export const stringAbbreviation = (str) => {
    let _new = '';
    if (str) {
        let arr = str.split(" ");
        _new = arr[arr.length - 1];
        // console.log(arr)
        arr.pop();
        // console.log(arr)
        let first = [];
        if (arr && arr.length > 0) {
            first = arr.map(a => (
                a.charAt(0)
            ))
        }
        _new = _new + " " + first.join(" ");
    }
    return _new;

}

export const stringAbbreviationForClient = (str) => {
    let _new = '';
    if (str) {
        let arr = str.split(" ");
        _new = arr[0];
        arr.splice(0, 1);
        let first = [];
        if (arr && arr.length > 0) {
            first = arr.map(a => (
                a.charAt(0)
            ))
        }
        _new = _new + " " + first.join(" ");
    }
    return _new;

}

export const toFixed = (number, fix = 2) => {
    return number ? number.toFixed(fix) : ''
}