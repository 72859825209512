import React, { Component, useEffect, useState } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { connect } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

import VerticalLayout from "./component/Layout/VerticalLayout";
import NonAuthLayout from "./component/NonAuthLayout/NonAuthLayout";
import { updateLogin, updateRealTime, updateBothTime } from './store/actions';
import { getAllBasicDetails } from './store/actions';

import ViewSinglePayroll from './pages/Payroll/ViewSingle/SinglePayroll';
import DirectInvoiceView from './pages/Invoice/Direct/view/view';
import firebase from "./Utils/firebase";
import NotificationsCard from "./component/Notiflix/notifToast";

import './App.css'

// Import scss
import "./theme.scss";

//Fake backend
// import fakeBackend from './helpers/fakeBackend';

// fakeBackend();

// const storageRef = firebase.storage().ref();
const App = (props) => {

    const [state, setState] = useState({
        error: null,
        notificationTitle: null,
        notificationBody: null,
        notificationTime: null,
    })

    const getLayout = () => {
        let layoutCls = VerticalLayout;
        return layoutCls;
    };

    useEffect(() => {
        props.updateLogin();
       
        let notification = localStorage.getItem("RNPlusNotification")
        // console.log(notification);
        if (notification) {
            props.updateBothTime(parseInt(notification))
        }
        updateDataUsingFireBase()
    }, [])

    useEffect(() => {
        //run only when token is present
        let token = localStorage.getItem("RNPlusToken");
        if (token) {
            props.getAllBasicDetails();
        }
    }, [localStorage.getItem("RNPlusToken")])

    const updateDataUsingFireBase = () => {
        // console.log("notification 123")

        setState({ ...state, error: "asddsadsa" })
        var starCountRef = firebase.database().ref("admin");
        // console.log(starCountRef)
        starCountRef.on('value', (snapshot) => {
            let notification = localStorage.getItem("RNPlusNotification")
            const data = snapshot.val();
            // console.log(notification, data);
            if (data && data.body) {
                // this.props.updateRealTime(data.time);
                if (notification == data.time) {
                    // this.setState({ notificationBody: null, notificationTitle: null, notificationTime: null })
                }
                else {
                    setState({ ...state, notificationBody: data.body, notificationTitle: data.title, notificationTime: data.time })

                    // localStorage.setItem("RNPlusNotification", data.body)
                }

            }
        });
        // var database = fbase.database();
        // console.log(storageRef)
    }



    const Layout = getLayout();
    const { error, notificationBody, notificationTitle, notificationTime } = state;


    return (
        <React.Fragment>
            {/* {
                    error
                    ?
                    <Notif />
                    : null
                } */}
            <Router>
                <Switch>
                    <Route path="/view_payroll/:payroll_id" component={ViewSinglePayroll} />
                    <Route path="/invoice/direct/:group_id" component={DirectInvoiceView} />
                    {publicRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            component={route.component}
                            layout={NonAuthLayout}
                            key={idx}
                            isAuthProtected={false}
                        />
                    ))}

                    {authProtectedRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            component={route.component}
                            layout={Layout}
                            key={idx}
                            isAuthProtected={true}
                        />
                    ))}

                </Switch>
            </Router>

            {
                notificationBody && props.basicDetails.realtimeTimeStampCheck != notificationTime && props.auth
                    ?
                    <NotificationsCard
                        title={notificationTitle}
                        body={notificationBody}
                        time={notificationTime}
                        // updateBody={updateBody}
                    />
                    : null
            }
        </React.Fragment>
    );
}


const mapStateToProps = state => {
    return {
        layout: state.Layout,
        auth: state.Login.user,
        basicDetails: state.BasicDetails
    };
};

export default connect(mapStateToProps, { updateLogin, getAllBasicDetails, updateRealTime, updateBothTime })(App);
