export const columnsInvoiced = [
    {
        label: 'Id',
        field: 'id',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Generated At',
        field: 'generated_on',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 150
    },
]