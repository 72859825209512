import React, { useState, useEffect } from 'react';
import { dateUSStandard } from "../../../Utils/dateFunctions"
import './style.css';
 
const styleRed={border: '1px solid red'}
const styleGreen={border: '1px solid green'}

const AvailabilityCard = (props) => {
    
    // console.log(props)
    const [total, setTotal] =  useState(0);

    const handleButton = (currentDate) =>{
        if(props.showDate){
            props.handleModal(props.allData, true, null, currentDate)
        }
        else{
            props.handleModal(props.allData, true, props.dayNum, null)
            
        }
        
    }

    useEffect(() => {
        if(props.allData && Object.keys(props.allData).length > 0){
            if(parseInt(props.allData.not_available) > 0){
                setTotal(-1);
            }
            else{
                let total = (parseInt(props.allData.day)) + parseInt(props.allData.eve) + parseInt(props.allData.night)
                console.log(total)
                setTotal(total)
            }
            
        }
    }, [])
 
    return (
        <>
            <td style={{padding:0}}>
                <div className="availability-card" style={props.showDate ? {height:170} : {height:150}}>
                    { props.showDate ?
                        <div className="availability-date">
                            {dateUSStandard(props.currentDate)}
                        </div>
                    : null}
                    <div className="availability-data" style={total == -1 ? styleRed : (total === 3 ? styleGreen : {})}
                        onClick={() =>handleButton(props.currentDate)}
                    >
                        <div className="row">
                            <div className="col-md-4 availability-input">
                                <input 
                                    type="checkbox" 
                                    checked={ props.allData ? parseInt(props.allData.day) === 1 ? true : false : false} 
                                    onChange={() =>{}}   
                                />&nbsp;Day
                            </div>
                            <div className="col-md-3 availability-input">
                                <input 
                                    type="checkbox" 
                                    checked={  props.allData ? parseInt(props.allData.eve) === 1 ? true : false : false} 
                                    onChange={() =>{}}  
                                />&nbsp;Eve
                            </div>
                            <div className="col-md-3 availability-input">
                                <input 
                                    type="checkbox" 
                                    checked={  props.allData ? parseInt(props.allData.night) === 1 ? true : false : false} 
                                    onChange={() =>{}}  
                                />&nbsp;Night
                            </div>
                            <div className="col-md-12 availability-input">
                                <input 
                                    type="checkbox" 
                                    checked={ props.allData ? parseInt(props.allData.not_available) === 1 ? true : false : false} 
                                    onChange={() =>{}}  
                                />&nbsp;Not Available
                            </div>
                            <div className="col-md-12 text-right">
                                Max Hrs: {props.allData && props.allData.hours ?  props.allData.hours : "Any"}
                            </div>
                        </div>
                        <div className="availability-input">
                            <input 
                                type="text" 
                                className="form-control"
                                value={props.allData && props.allData.note ? props.allData.note : ''}
                                disabled />
                        </div>
                        </div>

                    </div>
            </td>
        </>

    )
}

export default AvailabilityCard;