import React from 'react';
import './styles.css'
const commonStyle = { position: "fixed", right: 0, top: 0, maxHeight: '92vh' }
const styleLarge = { ...commonStyle, width: '50vw', paddingTop: 0, 'boxShadow': '#00000014 -8px -2px 13px 0px', marginTop: 71 }
const styleNormal = { ...commonStyle, width: '22vw', paddingTop: 0, 'boxShadow': '#00000014 -8px -2px 13px 0px', marginTop: 71, }


const Overlay = (props) => {

    return (
        <div id="mySidenav" style={props.overlay ? (props.isLarge ? { ...styleLarge, ...props.style } : styleNormal) : styleNormal} className="sidenav" >
            <div className="sidenav-section" style={props.isLarge ? { width: '100%' } : props.style}>
                <div className="row sideNav-row" >
                    <div className="col-md-9 col-xs-9 pl-4">
                        <h6 className="modal-title">{props.title}</h6>
                    </div>
                    <div className="col-md-3 col-xs-3">
                        <a href="javascript:void(0)" className="closebtn" onClick={props.handleOverLay}>&times;</a>
                    </div>
                </div>
                <div className="overlay-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Overlay;