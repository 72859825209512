import React from "react";
import { Button } from 'reactstrap';
import "./Responses.scss";

const tableData = [
    {
        contractor: "Nishant",
        number: "7764068337",
        action: "Submit",
    },
    {
        contractor: "Amrit",
        number: "8092485674",
        action: "Submit",
    },
    {
        contractor: "Amutesh",
        number: "8967854324",
        action: "Submit",
    },
];

const Response = (props) => {
    console.log(props.selectedOrder)

    const getRating = (rating) => {

    }

    const checkRating = (order, response) => {
        let res = [];
        if (order && order.responses) {
            res = props.selectedOrder.responses;
        }
        else if (response) {
            res = response;
        }
        // console.log(res);
        return res;
    }

    console.log(props.selectedOrder);

    return (
        <div className={`row`}>
            <div className={`col-3`}>List of Respondents</div>
            <div className={`col-12`}>
                <table>
                    <tr>
                        <th>Contractor</th>
                        <th>Number</th>
                        {/* <th>Rating</th> */}
                        <th>Action</th>
                    </tr>

                    {checkRating(props.selectedOrder, null).map((c, i) => {
                        return (
                            <tr key={i}>
                                <td>{c.contractorName}{"(" + (c.activeHours ? c.activeHours : 0) + " active hours) "}</td>
                                <td>{c.mob}</td>
                                {/* <td>{getRating(c.rating)}</td> */}
                                {
                                    c.res === 1
                                        ?
                                        <td>
                                            <Button
                                                onClick={() => props.handleResponse(props.selectedOrder.id, c.ctr, props.selectedOrder.ctr_type, props.selectedOrder.payable_ctr_type)}
                                                disabled={c.activeHours && c.activeHours > 17 ? true : false}
                                            >Accept</Button>
                                        </td>
                                        : <td>
                                            <Button
                                                className="btn-danger"
                                                disabled={true}
                                            >Rejected</Button>
                                        </td>
                                }

                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
};
export default Response;
