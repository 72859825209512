import React from 'react';
import './style.css';
const AvailabilityCard = (props) => {
    return (
        <>
            <td style={{ padding: 0 }}>
                <div className="availability-card">
                    <div className="availability-date">
                        {props.currentDate}
                    </div>
                </div>
            </td>
        </>

    )
}

export default AvailabilityCard;