import React, { useState } from 'react'
import {
    Card,
    CardBody,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import "./style.css";


const AllNewList = (props) => {

    return (
        <Card>
            <CardBody>
                <form style={{ display: "flex", gap: 20, justifyContent:"end" }} onSubmit={props.handleGeneratePayroll}>
                    <select
                        className="form-control"
                        // required
                        style={{ width: 230 }}
                    >
                        <option value={1}>Sort by date and cont. type</option>
                        <option value={2} >Sort by cont. type and date</option>
                    </select>
                    <button
                        className="btn btn-primary"
                        type="submit"
                    // onClick={() => this.handleGeneratePayroll(a.client)}
                    >Generate Invoice</button>
                </form>
                <MDBDataTable
                    striped
                    hover
                    bordered
                    data={{ columns: props.columns, rows: props.allData }}
                    className="invoiceClass"
                />

            </CardBody>
        </Card>
    )
}

export default AllNewList;