import moment from 'moment';

export const currentDate = () => {
    return moment()
}


export const getTzDateTime = (date) => {
    if (date)
        return moment.utc(date).format('HH:mm') + ":00"
    else
        return ""
}

export const dateStandard = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
}

export const dateBasicFormant = (date) => {
    if (date && date != "Invalid date") {
        var _date = moment(date, "YYYY-MM-DD");
        return _date._d;
    }
    return ""

}

export const dateTimeBasicFormant = (date) => {
    var _date = moment(date, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY hh:mmA");
    return _date;
}

export const dateTimeBasicFormantSplit = (date) => {
    if (date) {
        var _date = moment(date, "YYYY-MM-DD HH:mm:ss").format("MM-DD-YYYY hh:mmA");
        // console.log(_date);
        _date = _date.split(" ");
        return {
            date: _date[0],
            time: _date[1]
        }
    }
    else {
        return {
            date: "",
            time: ""
        }
    }

}


export const dateUSStandard = (date) => {
    return date ? moment(date).format("MM-DD-YYYY") : "";
}

export const dateSlashFormant = (date) => {
    return moment(date).format("YYYY/MM/DD");
}

export const dateArithmetic = (date, qty) => {
    return moment(date, "YYYY-MM-DD").add(qty, 'days').format("YYYY-MM-DD");
}

export const dateDifference = (start, end) => {
    start = moment(start, "YYYY-MM-DD");
    end = moment(end, "YYYY-MM-DD");
    return end.diff(start, 'days');
}

export const dateMonthFormat = (date) => {
    return moment(date).format("DD MMMM, YYYY");
}

export const changeTzToNormal = (date) => {
    if (date) {
        // console.log(date);
        // let sDate =  moment(date);
        let sDate = moment(date).utcOffset(0, true).format('MM-DD-YYYY HH:mm')
        // console.log(sDate)
        // console.log(sDate)
        return {
            date: sDate.split(" ")[0],
            time: sDate.split(" ")[1]
        }
    }
    else {
        return {
            date: "",
            time: ""
        }
    }

}

export const changeTzToNormalDefault = (date) => {
    if (date) {
        let sDate = moment(date);
        sDate = sDate.format('YYYY-MM-DD HH:mm')
        // console.log(sDate)
        return {
            date: sDate.split(" ")[0],
            time: sDate.split(" ")[1]
        }
    }
    else {
        return {
            date: "",
            time: ""
        }
    }

}

export const singleDate = (date) => {
    return date.date + " " + date.time
}

export const getEndTime = (noBreak, type, endDate) => {
    let time = 0;
    // if (type == 2) {
    //     time = 30;
    // }
    if (!noBreak) {
        time = time + 30;
    }
    time = time * 1000 * 60;
    return endDate.getTime() - time;
}


export const TimeDiff = (startTime, endTime, noBreak, type) => {

    // console.log(getEndTime(noBreak, type, endTime));

    var todayDate = moment(new Date()).format("MM-DD-YYYY");
    var startDate = new Date(`${todayDate} ${startTime}`);
    var endDate = new Date(`${todayDate} ${endTime}`);
    let endGetTime = getEndTime(noBreak, type, endDate)
    var timeDiff = endGetTime - startDate.getTime();
    // console.log(timeDiff);
    if (timeDiff <= 0) {
        var nextDay = moment(new Date()).add(1, 'days').format("MM-DD-YYYY");
        endDate = new Date(`${nextDay} ${endTime}`);
        endGetTime = getEndTime(noBreak, type, endDate)
    }

    // console.log(endDate);
    timeDiff = endGetTime - startDate.getTime();

    var hh = Math.floor(timeDiff / 1000 / 60 / 60);
    hh = ('0' + hh).slice(-2)

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ('0' + mm).slice(-2)

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ('0' + ss).slice(-2)

    return {
        h: hh,
        m: mm
    }
}


//Time
export const changeTime = (time) => {
    let _time = time.split(':');
    return _time[0] + ":" + _time[1];
}

export const timeFormat = (time) => {
    // console.log(time)
    let _time = moment(time, "HH:mm:ss").format("hh:mmA").slice(0, -1);
    // console.log(_time)
    return _time
}

export const timeFormatWithoutSeconds = (time) => {
    let _time = moment(time, "HH:mm:ss").format("HH:mm");
    return _time
}


export const timeFormatWithData = (date) => {
    let _time = changeTzToNormal(date);
    return timeFormat(_time.time + ":00")
}

export const timeFormatFull = (time) => {
    time = time.split("T");
    time = time[1].split(".")

    let _time = moment(time[0], "HH:mm:ss").format("hh:mmA");
    return _time
}

export const dateTimeFormatFull = (time) => {
    let date = time.split("T");
    time = date[1].split(".")

    return {
        date: date[0],
        time: moment(time[0], "HH:mm:ss").format("HH:mm"),
    }
}

//week and month
export const weekDate = () => {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var start = dateStandard(new Date(curr.setDate(first)));
    var end = dateStandard(new Date(curr.setDate(last)));
    return {
        start: start,
        end: end,
    }
}

export const monthDate = () => {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    return {
        start: dateStandard(firstDay),
        end: dateStandard(lastDay),
    }
}

//compare teo time 
export const compareTwoTime = (start, end) => {
    if (start && end) {
        var beginningTime = moment(start, 'HH:mm');
        var endTime = moment(end, 'HH:mm');
        return beginningTime.isBefore(endTime) || beginningTime.isSame(endTime);
    }
    return false;

}

export const updateShiftHours = (startDate, endDate) => {
    if (endDate.length === 0 || startDate.length === 0) return

    const duration = moment.duration(moment(endDate, 'YYYY-MM-DD hh:mm').diff(moment(startDate, 'YYYY-MM-DD hh:mm')));
    // console.log(duration);
    const totalHours = `${parseInt(duration.asHours())}.${Math.abs(parseInt(duration.asMinutes()) % 60)}`

    return totalHours

}


export const zzz = (startDate, endDate) => {
    if (endDate.length == 0 || startDate.length == 0) return
    const duration = moment.duration(moment(endDate, 'YYYY-MM-DD hh:mm').diff(moment(startDate, 'YYYY-MM-DD hh:mm')));
    const totalHours = `${parseInt(duration.asHours())}.${Math.abs(parseInt(duration.asMinutes()))}`

    return totalHours
}


export const changeDateByTimezone = (current) => {
    if (Intl.DateTimeFormat().resolvedOptions().timeZone === "America/New_York") {
        return new Date(dateArithmetic(current, 1));
    }
    else
        return new Date(current)
}

export const updateDateIfNightShift = (date, time, shiftType) => {
    // console.log(date, time, shiftType);
    let t = time.split(":")
    if (shiftType.shift_type_code === "NGT" && parseInt(t[0]) < 12) {
        // console.log(dateArithmetic(date, 1));
        return dateArithmetic(date, 1) + "T" + time
    }
    return date + "T" + time
}

export const customToBasicTime = (time) => {
    return moment(time, "hh:mmA").format("HH:mm:ss");
}
export const checkTime = (time) => {
    if (time) {
        let _time = time.split(":");
        return _time[0] + ":" + _time[1];
    }
    return time;
}

export const formatDateCurrent = () => {
    return moment().format('MM-DD-YYYY-HH:mm');
}


export function isDBDateFormat(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(dateString);
}

export function convertDateFormatUSA(dateString) {
    // Parse the date string using moment and format it to MM-DD-YYYY
    const formattedDate = moment(dateString, 'YYYY-MM-DD').format('MM-DD-YYYY');
    return formattedDate;
}

export const isDateFormatWithTZ = (dateString) => {
    if (typeof dateString !== 'string') {
        return false;
    }
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (!iso8601Regex.test(dateString)) {
        return false; // Return false if the string does not match the format
    }
    return true;
}




export const convertToFullTime = (dateString) => {
    return moment(dateString).format("MM-DD-YYYY hh:mmA");
}


//------------------DATE FUNCTIONS----------------
export const getCurrentWeekDates = () => {
    const today = moment();
    const startOfWeek = today.clone().startOf('isoWeek'); // Monday as the first day of the week
    const endOfWeek = today.clone().endOf('isoWeek'); // Sunday as the last day of the week
    return [startOfWeek.format('YYYY-MM-DD'), endOfWeek.format('YYYY-MM-DD')];
}
export const getLastWeekDates = () => {
    const today = moment();
    const startOfLastWeek = today.clone().subtract(1, 'weeks').startOf('isoWeek'); // Start of last week (Monday)
    const endOfLastWeek = today.clone().subtract(1, 'weeks').endOf('isoWeek'); // End of last week (Sunday)
    return [startOfLastWeek.format('YYYY-MM-DD'), endOfLastWeek.format('YYYY-MM-DD')];
}

export const getCurrentMonthDates = () => {
    const today = moment();
    const startOfMonth = today.clone().startOf('month'); // Start of the current month
    const endOfMonth = today.clone().endOf('month'); // End of the current month
    return [startOfMonth.format('YYYY-MM-DD'), endOfMonth.format('YYYY-MM-DD')];
}
export const getLastMonthDates = () => {
    const today = moment();
    const startOfLastMonth = today.clone().subtract(1, 'months').startOf('month'); // Start of last month
    const endOfLastMonth = today.clone().subtract(1, 'months').endOf('month'); // End of last month
    return [startOfLastMonth.format('YYYY-MM-DD'), endOfLastMonth.format('YYYY-MM-DD')];
}

export const getCurrentYearDates = () => {
    const today = moment();
    const startOfYear = today.clone().startOf('year'); // Start of the current year
    const endOfYear = today.clone().endOf('year'); // End of the current year
    return [startOfYear.format('YYYY-MM-DD'), endOfYear.format('YYYY-MM-DD')];
}

export const getLastYearDates = () => {
    const today = moment();
    const startOfLastYear = today.clone().subtract(1, 'years').startOf('year'); // Start of last year
    const endOfLastYear = today.clone().subtract(1, 'years').endOf('year'); // End of last year
    return [startOfLastYear.format('YYYY-MM-DD'), endOfLastYear.format('YYYY-MM-DD')];
}