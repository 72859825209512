export const contractorStatus = [
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "Yes"
    },
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "No"
    }
]

export const terminationStatus = [
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "Yes"
    },
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "No"
    }
]

export const drivesStatus = [
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "Yes"
    },
    {
        "id": 1,
        "value": ["order_cheque", "is NULL", false],
        "fullName": "No"
    }
]