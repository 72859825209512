import React, { useState, useEffect } from 'react'
import TableComponent from '../../../../component/TableComponent';
import { shiftType } from '../../../../Utils/Variables/shifts'
import { shiftColumns } from "./columns/shifts"
import ConfirmModal from "../../../../component/confirm/confirmForOrder";
import CopyClient from '../../../../component/copyClient'
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../../component/Notiflix/notiflix'
import * as APIs from '../../../../APICall/index';
import * as URLs from '../../../../APICall/urls/settings';
import { getTempId, removeTempData } from '../../../../Utils/shifts';

const sampleObject = {
    "name": "",
    "start_time": "",
    "end_time": "",
    "type": 1,
    "color": "#000000",
    "is_active": 1,
    "work_code": 1,
}

const Shifts = (props) => {
    const [allShifts, setAllShifts] = useState([])
    const [selected, setSelected] = useState(new Set());
    const [deleteShift, setDeleteShift] = useState(null)
    const [copyClient, setCopyClient] = useState(false)

    useEffect(() => {
        if (props.allShifts && props.allShifts.length > 0 && props.allWorkCode && props.allWorkCode.length > 0) {
            let _shift = [...props.allShifts];
            _shift.sort(function (a, b) {
                return a.id - b.id;
            });
            setAllShifts(_shift);
        }
    }, [props])

    const handleChange = (e, i, allShifts, state, type) => {
        let _allShifts = [...allShifts];
        if (type === "checkbox") {
            if (e.target.checked) {
                _allShifts[i][state] = 0;
            }
            else {
                _allShifts[i][state] = 1;
            }
        }
        else {
            _allShifts[i][state] = e.target.value;
        }
        setAllShifts(_allShifts)
    }

    const handleSelectCheck = (e, id) => {
        let _selected = new Set([...selected])
        if (e.target.checked) {
            _selected.add(id)
        }
        else {
            _selected.delete(id)
        }
        setSelected(_selected)
    }

    const handleDeleteButton = () => {
        let _selected = [...selected]
        let _allShifts = [...allShifts]
        let _deleteFromAPI = []
        if (_selected.length >= 1) {
            for (let i = 0; i < _selected.length; i++) {
                let index = _allShifts.findIndex((a) => a.id === _selected[i])
                if (_allShifts[index].id?.toString()?.includes("temp")) {
                    _allShifts.splice(index, 1);
                }
                else {
                    _deleteFromAPI.push({
                        "id": _selected[i],
                        "deleted": 0
                    })
                }
            }
            _deleteFromAPI.length && props.deleteShifts(_deleteFromAPI)
            setAllShifts(_allShifts)
            setSelected(new Set())
            setDeleteShift(false)
        }
    }

    const onDeleteBtnClick = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a shift to delete.")
        }
        else {
            setDeleteShift(true)
        }
    }


    const saveShifts = () => {
        props.updateShifts(removeTempData(allShifts))
    }

    const copyRow = () => {
        if (selected.size === 1) {
            let _selected = [...selected]
            let _shift = allShifts.filter((a) => a.id === _selected[0]);
            let _single = { ..._shift[0] }
            _single.id = getTempId();
            let _allShifts = [...allShifts];
            _allShifts.push(_single)
            setSelected(new Set())
            setAllShifts(_allShifts)


        }
        else {
            notiflixStatus(false, "Please select a shift to copy.")
        }
    }

    const updateTable = (allShifts, selected) => {
        let _allShifts = [];
        allShifts?.forEach((p, i) => {
            if (!p.deleted)
                _allShifts.push({
                    sName: <input
                        style={{ width: 150 }}
                        type="text"
                        className="form-control"
                        value={p.name}
                        onChange={(e) => handleChange(e, i, allShifts, "name")}
                    />,
                    sStart: <input
                        style={{ width: 150 }}
                        type="time"
                        className="form-control"
                        value={p.start_time}
                        onChange={(e) => handleChange(e, i, allShifts, "start_time")}

                    />,
                    sEnd: <input
                        style={{ width: 150 }}
                        type="time"
                        className="form-control"
                        value={p.end_time}
                        onChange={(e) => handleChange(e, i, allShifts, "end_time")}

                    />,
                    type: <select
                        style={{ width: 100 }}
                        className="form-control"
                        value={p.type}
                        onChange={(e) => handleChange(e, i, allShifts, "type")}
                    >
                        {
                            shiftType.map(s => (
                                <option key={s.id} value={s.id}>{s.value}</option>
                            ))
                        }
                    </select>,
                    color: <input
                        type="color"
                        className="form-control"
                        value={p.color}
                        onChange={(e) => handleChange(e, i, allShifts, "color")}
                    />,
                    inactive: <input
                        style={{ width: 100 }}
                        type="checkbox"
                        className="form-control"
                        value={p.is_active}
                        checked={parseInt(p.is_active) === 0}
                        onChange={(e) => handleChange(e, i, allShifts, "is_active", "checkbox")}

                    />,
                    wCode: <select
                        type="text"
                        style={{ width: 150 }}
                        className="form-control"
                        value={p.work_code}
                        onChange={(e) => handleChange(e, i, allShifts, "work_code")}
                    >
                        {
                            props.allWorkCode?.map(s => (
                                <option key={s.id} value={s.id}>{s.des}</option>
                            ))
                        }
                    </select>,
                    select: <input
                        style={{ width: 100 }}
                        type="checkbox"
                        className="form-control"
                        checked={selected.has(p.id)}
                        onChange={(e) => handleSelectCheck(e, p.id)}
                    />,

                })

        })
        return _allShifts
    }

    const addRow = () => {
        let _allShifts = [...allShifts];
        _allShifts.push({ ...sampleObject, id: getTempId() })
        setAllShifts(_allShifts)
    }

    const handleSubmitCopy = async (clientIds) => {
        notiflixLoaderStart()
        let data = {
            "shiftIds": [...selected],
            "clientIds": [...clientIds],
            srcClientId: props.clientId
        }
        let res = await APIs.postAdminAPI(URLs.COPY_SHIFT, data)
        if (res.status) {
            setCopyClient(false)
            notificationStatus({ status: true, message: "Successlly copied the shifts" })
        }
        else
            notificationStatus({ status: false, message: "Failure" })

    }
    return (
        <>
            <TableComponent
                rows={updateTable(allShifts, selected)}
                columns={shiftColumns}
                addRow={addRow}
                deleteRow={onDeleteBtnClick}
                handleDeleteButton={onDeleteBtnClick}
                saveTable={saveShifts}
                copyRow={copyRow}
                className="shift-table"
                selected={selected}
                copyToClient={() => setCopyClient(true)}
            />
            {
                deleteShift
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this shift?",
                                proceed: true
                            }

                        }
                        title="Delete shifts"
                        toggle={() => setDeleteShift(null)}
                        click={handleDeleteButton}
                    />
                    : null
            }
            {
                copyClient && <CopyClient
                    handleClose={() => setCopyClient(false)}
                    title="Copy holiday to"
                    handleSubmit={handleSubmitCopy}
                />
            }
        </>
    )
};

export default Shifts;