import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { customToBasicTime, dateUSStandard, checkTime, compareTwoTime, dateArithmetic, dateTimeFormatFull } from '../../../../Utils/dateFunctions';
import { postAdminAPI } from "../../../../APICall/index";
import * as APIs from "../../../../APICall/urls";
import * as Notification from '../../../../component/Notiflix/notiflix';
import Custompay from "./customPay";
import "./styles.scss"

const initialOther = {
    "otherChargesType": "",
    "otherChargesAmt": "",
    "type": ""
}
const initialCustom = {
    setCustomAmount:false, 
    customRate:"", 
    isHourly:false, 
    customStartEnd:false, 
    customStart:"", 
    customEnd:""
}

const History = ({ allData, getData, ...props }) => {
    const [other, setOther] = useState({ ...initialOther })
    const [customPay, setCustomPay] = useState({...initialCustom})
    const [customBill, setCustomBill] = useState({...initialCustom})
    const [completeData, setCompleteData] = useState(null)
    const openPayroll = (id, section) => {
        if (section === "payroll") {
            window.open("/view_payroll/" + id)
        }
        else {
            window.open(process.env.REACT_APP_API_URL + "/invoice-pdf/" + id + ".pdf")
        }
    }

    useEffect(() =>{
        if(props.selectedOrder?.id){
            getDataInvoice()
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectedOrder?.id])

    const handleChange = (e) => {
        let _other = { ...other };
        _other[e.target.name] = e.target.value;
        setOther(_other);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        Notification.notiflixLoaderStart()
        let data = [
            ...allData.otherCharges,
        ]
        let finalState = {
            ...other,
            orderId: props.selectedOrder.id,
        }
        if (other.id) {
            let index = allData.otherCharges.findIndex((a) => a.id === other.id)
            if (index >= 0) {
                data[index] = other
            }
        }
        else {
            data = [
                ...data,
                finalState
            ]
        }
        let result = await postAdminAPI(APIs.OTHER_CHARGES, data)
        if (result.status) {
            getData();
            setOther({ ...initialOther })
        }
        Notification.notiflixLoaderEnd()
    }

    const handleDelete = async (id) => {
        Notification.notiflixLoaderStart()
        let result = await postAdminAPI(APIs.DELETE_OTHER_CHARGES, { id: id })
        if (result.status) {
            getData();
            Notification.notiflixStatus(true, "Successfully deleted")
        }
        else {
            Notification.notiflixStatus(result);
        }
    }


    // handle Custom
    const handleChangeCustom = (e, type, section) =>{
        let _custom = {...customPay}
        if(section === "bill"){
            _custom = {...customBill}
        }
        if(type === "checkbox")
            _custom[e.target.name] = e.target.checked
        else
            _custom[e.target.name] = e.target.value

        if(section === "bill"){
            setCustomBill(_custom)
        }
        else{
            setCustomPay(_custom)
        }
    }
    const handleCustomSubmit =async(e) =>{
        e.preventDefault()
        let data = {
            id: props.selectedOrder.id,
            customPay:{...customPay},
            customBill:{...customBill},
        }
        if(customPay.customStart){
            let times = updateCustomRate(customPay)
            data.customPay.customStart = times.customStart
            data.customPay.customEnd = times.customEnd
        }

        if(customBill.customStart){
            let times = updateCustomRate(customBill)
            data.customBill.customStart = times.customStart
            data.customBill.customEnd = times.customEnd
        }

        Notification.notiflixLoaderStart()
        let res =  await postAdminAPI(APIs.ORDER_CUSTOM_PAY_BILL, data);
        if(res.status){
            getDataInvoice()
        }
        Notification.notiflixLoaderEnd()
    }

    const updateCustomRate = (custom) =>{
        if(custom.customStart){
            let startDate = dateTimeFormatFull(completeData.startDate);
            let startTime = startDate.time;
            startDate = startDate.date
            
            let _startTime = startDate + "T" + checkTime(custom.customStart);
            let _endTime = startDate + "T" + checkTime(custom.customEnd);
            // console.log(compareTwoTime(startTime, custom.customStart), startTime, custom.customStart);
            //check start time
            if (!compareTwoTime(startTime, custom.customStart)) {
                _startTime = dateArithmetic(startDate, 1) + "T" + checkTime(custom.customStart)
            }
            //check end date
            if (!compareTwoTime(startTime, custom.customEnd)) {
                _endTime = dateArithmetic(startDate, 1) + "T" + checkTime(custom.customEnd)
            }

            // console.log(completeData.shiftTypeDes , custom.customStart, custom.customEnd, compareTwoTime(custom.customStart, custom.customEnd));
            // if next day night shift
            // if(completeData.shiftTypeDes === "Night" && compareTwoTime(custom.customStart, custom.customEnd)){
            //     _startTime = startDate + "T" + checkTime(custom.customStart);
            //     _endTime = startDate + "T" + checkTime(custom.customEnd);
                
            // }
            let shiftStartDate = dateTimeFormatFull(completeData.startDate);
            let shiftEndDate = dateTimeFormatFull(completeData.endDate);
            // console.log(compareTwoTime(shiftStartDate.time, shiftEndDate.time), compareTwoTime(custom.customStart, custom.customEnd));
            // if next day night shift
            if(completeData.shiftTypeDes === "Night" && compareTwoTime(shiftStartDate.time, shiftEndDate.time) &&  compareTwoTime(custom.customStart, custom.customEnd)){
                _startTime =startDate+ "T" + checkTime(custom.customStart);
                _endTime = startDate + "T" + checkTime(custom.customEnd);
            }
            else if(completeData.shiftTypeDes === "Night" && compareTwoTime(custom.customStart, custom.customEnd)){
                _startTime = dateArithmetic(startDate, 1)+ "T" + checkTime(custom.customStart);
                _endTime = dateArithmetic(startDate, 1) + "T" + checkTime(custom.customEnd);
            }
            return{
                customStart:_startTime,
                customEnd:_endTime
            }
        }
    }

    const getDataInvoice = async () => {
        // Notification.notiflixLoaderStart()
        let result = await postAdminAPI(APIs.VIEW_SINGLE_ORDER, { id: props.selectedOrder?.id })
        if (result.status) {
            let customBillAmt = JSON.parse(result.data.customBill)
            let customPayAmt = JSON.parse(result.data.customPay)
            // console.log(customBillAmt, customPayAmt, customBill, customPay);
            // Bill
            updateBillOrPay(customBillAmt, customBill, setCustomBill, result.data)
            //Pay
            updateBillOrPay(customPayAmt, customPay, setCustomPay, result.data)
            setCompleteData(result.data)
        }
        Notification.notiflixLoaderEnd()
    }

    const updateBillOrPay = (customAmt, customInitial, setCustom, completeData) =>{
        console.log(customAmt,completeData);
        if(completeData?.startDate){
            if(customAmt?.customRate){
                let startDate = customAmt.customStart ? dateTimeFormatFull(customAmt.customStart).time : customToBasicTime(completeData.startDate);
                let endDate = customAmt.customEnd ? dateTimeFormatFull(customAmt.customEnd).time: customToBasicTime(completeData.endDate);
                setCustom({
                    ...customAmt,
                    customStart:startDate,
                    customEnd:endDate
                })
            }
            else{
                let startDate = dateTimeFormatFull(completeData.startDate).time;
                let endDate = dateTimeFormatFull(completeData.endDate).time;
                console.log(endDate, completeData.endDate);
                setCustom({
                    ...customInitial,
                    customStart: startDate,
                    customEnd:endDate
                })
            }
        }
        
    }
    console.log(completeData, props.selectedOrder, customPay    );
    return (
        <>
            <div className={`font-weight-bold mt-2 custom-title`}>Custom Payment</div>
            <div>
                <form onSubmit={handleCustomSubmit}>
                    <Custompay name="Pay" handleChange={(e, type) =>handleChangeCustom(e, type, "pay")} value={customPay} />
                    <Custompay name="Bill" handleChange={(e, type) =>handleChangeCustom(e, type, "bill")} value={customBill} />
                    <div style={{textAlign:"center"}} className="mt-3">
                        <Button
                            type="submit"
                            color="primary"
                        >Submit</Button>
                    </div>
                </form>
            </div>
            <hr />
            <div className={`font-weight-bold mt-2 custom-title`}>Other Charges</div>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className={`col-1`}>For</div>
                    <div className={`col-5`}>
                        <input
                            className="form-control"
                            type="text"
                            required
                            name="otherChargesType"
                            onChange={(e) => handleChange(e)}
                            value={other.otherChargesType}
                        />
                    </div>
                    <div className={`col-1`}>Amount</div>
                    <div className={`col-5`}>
                        <input
                            className="form-control"
                            type="text"
                            required
                            name="otherChargesAmt"
                            onChange={(e) => handleChange(e)}
                            value={other.otherChargesAmt}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className={`col-1`}>Type</div>
                    <div className={`col-5`}>
                        <select
                            name={`type`}
                            required
                            className={`form-control`}
                            value={other.type}
                            onChange={(e) => handleChange(e)}
                        >
                            <option value="">Select type</option>
                            <option value="PAY">PAY</option>
                            <option value="BILL">BILL</option>
                        </select>
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-12 text-center`}>
                        <Button
                            type="submit"
                            color="primary">Add Other Charges</Button>
                    </div>
                </div>
            </form>



            {allData.otherCharges?.length > 0 && <div className="row">
                <div className={`col-12`}>
                    <table>
                        <tr>
                            <th>For</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>View</th>
                        </tr>
                        {allData.otherCharges.map((m, i) => {
                            return (
                                <tr >
                                    <td>{m.other_charges_type}</td>
                                    <td>{m.other_charges_amt}</td>
                                    <td>{m.type}</td>
                                    <td>
                                        <button onClick={() => setOther({
                                            "otherChargesType": m.other_charges_type,
                                            "otherChargesAmt": m.other_charges_amt,
                                            "type": m.type,
                                            id: m.id
                                        })} className="btn btn-info">Edit</button>
                                        &nbsp;
                                        <button onClick={() => handleDelete(m.id)} className="btn btn-danger">Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>}
            <div className="row">
                <div className={`col-3 font-weight-bold`}>Payroll Information</div>
                <div className={`col-12`}>
                    <table>
                        <tr>
                            <th>Payroll Number</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>View</th>
                        </tr>
                        {allData.payrollCheque &&
                            <tr >
                                <td>{allData.payrollCheque}</td>
                                <td>{dateUSStandard(allData.generatedPayrollData.cheque_date)}</td>
                                <td>{allData.payrollTotal}</td>
                                <td><button onClick={() => openPayroll(allData.payrollCheque, "payroll")} className="btn btn-info">View</button></td>
                            </tr>
                        }
                    </table>
                </div>
            </div>
            <div className="row">
                <div className={`col-3 font-weight-bold`}>Invoice Information</div>
                <div className={`col-12`}>
                    <table>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>View</th>
                        </tr>
                        {allData.billInvoice &&
                            <tr >
                                <td>{allData.billInvoice}</td>
                                <td>{dateUSStandard(allData.generatedInvoiceData.invoice_date)}</td>
                                <td>{allData.invoiceTotal}</td>
                                <td><button onClick={() => openPayroll(allData.billInvoice, "invoice")} className="btn btn-info">View</button></td>
                            </tr>
                        }
                    </table>
                </div>

            </div>
        </>
    );
};
export default History;
