import axios from '../shared/axios';

//Set the logged in user data in local session 
const setLoggeedInUser = (user, allContractors, allClients, selectedContractor, selectedClient) => {
    console.log(selectedContractor)
    localStorage.setItem('RNPlusToken', JSON.stringify(user));
    localStorage.setItem('RNPlusAllContractor', JSON.stringify(allContractors));
    localStorage.setItem('RNPlusAllClient', JSON.stringify(allClients));
    localStorage.setItem('RNPlusContractor', selectedContractor ? parseInt(selectedContractor) : 0);
    localStorage.setItem('RNPlusClient',selectedClient ?  parseInt(selectedClient) : 0);

    // localStorage.setItem('RNPlusName', user.name);
    localStorage.setItem('RNPlusType', 1);
}

const updateContractor = (allContractors, selectedContractor) => {
    console.log(selectedContractor)
    
    localStorage.setItem('RNPlusAllContractor', JSON.stringify(allContractors));
    localStorage.setItem('RNPlusContractor', selectedContractor ? parseInt(selectedContractor) : 0);
}

const updateClient = (allClients, selectedClient) => {
    // console.log(user)
    console.log(selectedClient, allClients)
    
    localStorage.setItem('RNPlusAllClient', JSON.stringify(allClients));
    localStorage.setItem('RNPlusClient', selectedClient ?  parseInt(selectedClient) : 0);
}

// Gets the logged in user data from local session 
// const getLoggedInUser = () => {
//     const user = localStorage.getItem('user');
//     if (user)
//         return JSON.parse(user);
//     return null;
// }

const getLoggedInUser = () => {
    // console.log(localStorage.getItem('RNPlusToken'));
    if(!localStorage.getItem('RNPlusToken')) return null;
    return JSON.parse(localStorage.getItem('RNPlusToken'));
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status ) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });

}

// Login Method
const postLogin = (url, data) => {
    console.log(data);
   
    return axios.post(url, data).then(response => {
        // console.log(response)
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// Login Method
const postDetails = (url, token) => {
    console.log(url);
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return axios.post(url, null, config).then(response => {
        // console.log(response)
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// postForgetPwd 
const postForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


export { setLoggeedInUser, getLoggedInUser, isUserAuthenticated, postRegister, postLogin, postForgetPwd, postDetails, updateContractor, updateClient }