import React, { useEffect, useState } from 'react';
import "./styles.css"
const Table = (props) => {


    const [state, setState] = useState("")
    const [columns, setColumns] = useState([])

    useEffect(() => {
        if (props.allColumns.length > 0)
            setColumns([...JSON.parse(JSON.stringify(props.allColumns))])
    }, [])

    const removeFilter = (index) => {
        let tempData = Array.from(columns);
        tempData[index].isSortActive = false
        tempData[index].sortValue = "asc"
        setColumns(tempData)
    }

    const updateFilter = (index) => {
        let tempData = Array.from(columns);
        tempData[index].sortValue = tempData[index].sortValue === "asc" ? "desc" : "asc"
        setColumns(tempData)
    }

    const addFilter = () => {
        if (state) {
            let tempData = Array.from(columns);
            let index = columns.findIndex((p) => p.field === state)
            // if(tempData[index]?.isSortActive)
            tempData[index].isSortActive = true
            setColumns(tempData)
            setState("")
        }
    }

    const removeUsedFilter = () => {
        let tempData = Array.from(columns);
        return tempData.filter((p) => !p.isSortActive && p.isVisible)

    }

    const updateColumns = () => {
        props.updateColumns(columns, true)
    }

    const checkActive = () => {
        let filter = columns.filter((f) => f.isSortActive)
        return filter.length
    }
    console.log(state);
    console.log(columns);

    return (
        <div className="mdb-datatable dt-bootstrap4">
            <div className='row'>
                <div className='col-md-9'>
                    <select
                        className={"form-control"}
                        title={""}
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    >
                        <option>Select</option>
                        {
                            removeUsedFilter()?.map((p, i) => (
                                p.isSort && <option key={i} value={p.field}>{p.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className='col-md-1 d-flex align-items-center justify-content-center'>
                    <button className='multi-btn' style={{ background: "grey" }} >
                        <i style={{ marginTop: 1, opacity: "0.5" }} className={"fa fa-arrow-down"}></i>
                    </button>
                    &nbsp;
                </div>
                <div className='col-md-1 d-flex align-items-center justify-content-center'>
                    <button className='multi-btn' >
                        <i class="fa fa-plus" onClick={() => addFilter()} style={state ? {} : { opacity: "0.5", cursor: "auto" }} aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            {
                columns.map((p, i) => (
                    p.isSortActive &&
                    <div key={i} className='row'>
                        <div className='col-md-9'>
                            <select
                                className={"form-control"}
                                value={p.field}
                                // onChange={(e) => handleChange(e, i)}
                                disabled
                            >
                                {
                                    columns.map((p, i) => (
                                        p.isSort && <option key={i} value={p.field}>{p.label}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className='col-md-1 d-flex align-items-center justify-content-center'>
                            <button className='multi-btn' style={{ background: "grey" }} >
                                <i style={{ marginTop: 1, cursor: "pointer" }} onClick={() => updateFilter(i)} className={p.sortValue === 'desc' ? "fa fa-arrow-up" : "fa fa-arrow-down"}></i>
                            </button>
                            &nbsp;
                        </div>
                        <div className='col-md-1 d-flex align-items-center justify-content-center'>
                            <button className='multi-btn' style={{ background: 'red' }}>
                                <i className="fa fa-times cursor-pointer" onClick={() => removeFilter(i)} aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                ))
            }
            <div className='row'>
                <div className='col'>
                    <button className='btn btn-primary' disabled={!checkActive()} onClick={() => updateColumns()}>Apply</button>
                </div>
            </div>
        </div>
    )
}

export default Table;