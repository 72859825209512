import React from 'react';
import Notiflix from 'notiflix';

const Loading = (props) =>{
    return(
        <>
        {
            Notiflix.Loading.Remove()
        }
        {
            props.type === "success"
            ?
            Notiflix.Notify.Success(props.msg)
            :
            Notiflix.Notify.Failure(props.msg)
        }
        </>
    )
}

export default Loading;