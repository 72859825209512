import React, { Component } from 'react';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import Info from './info';
import NumberSummaryClient from "./numberSummaryClient"
import NumberSummaryShifts from "./numberSummaryShifts"
import NumberSummaryContractor from "./numberSummaryContractor"
import Donut from "../../../component/Charts/donut";
import AreaChart from "../../../component/Charts/areaChart";
import { REPORT_SUMMARY } from '../../../APICall/urls/report';
import { postAdminAPI } from '../../../APICall/index';
import { dateStandard, dateArithmetic, weekDate, monthDate } from '../../../Utils/dateFunctions';

import Header from './header';

import InfoData from './info.json'

class ReportSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            startDate: '',
            endDate: '',
            allData: null,
            filter: "1",
        }
    }
    //----Basic
    componentDidMount() {
        let _today = dateStandard(new Date());
        this.setState({
            startDate: _today,
            endDate: dateArithmetic(_today, 0)
        })
        this.props.setBreadcrumbItems("Report", this.state.breadcrumbItems);
        this.getAPIData(_today, _today)
    }

    getAPIData = async (startDate, endDate) => {
        console.log(startDate, endDate)
        let result = await postAdminAPI(REPORT_SUMMARY, { startDate: startDate, endDate: endDate })
        console.log(result);
        if (result.status) {
            this.setState({ allData: result.data })
        }
    }

    updateFilter = (name, date) => {
        let { startDate, endDate } = this.state;
        if(name === "startDate"){
            this.setState({startDate:date})
            startDate = date;
        }
        else{
            this.setState({endDate:date})
            endDate = date;
        }
        // console.log(startDate, endDate, date)
        this.getAPIData(startDate, endDate)
        // if (filter == "1") {
            
        // }
        // else if (filter == "2"){
        //     let dates = weekDate();
        //     console.log(dates);
        //     this.getAPIData(dates.start, dates.end)
        // }
        // else if (filter == "3"){
        //     let dates = monthDate();
        //     console.log(dates);
        //     this.getAPIData(dates.start, dates.end)
        // }

    }





    render() {

        const { filter, allData, startDate, endDate } = this.state;

        return (
            <>
                <Header
                    title="Shift"
                    isDropdown={true}
                    onChange={this.updateFilter}
                    value={filter}
                    startDate={startDate}
                    endDate={endDate}
                />

                {
                    allData
                        ?
                        <>
                            <div className="row">
                                <div className="col-md-5">
                                    <Info
                                        title="Shift Info"
                                        data={allData.shifts}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <Donut
                                        title="Shifts"
                                        data={allData.shifts}
                                    />
                                </div>
                                <div className="col-md-12 mt-4">
                                    <NumberSummaryShifts 
                                        data={allData.shifts}
                                    />
                                </div>
                            </div>

                            <Header
                                title="Contractors"
                            />
                            <div className="row">
                                <div className="col-md-5">
                                    <Info
                                        title="Contractors"
                                        data={allData.contractors}
                                    />
                                </div>
                                <div className="col-md-7">
                                    <Donut
                                        title="Contractors"
                                        data={allData.contractors}
                                    />
                                </div>
                                {/* <div className="col-md-12 mt-4">
                                    <NumberSummaryContractor 
                                        data={allData.contractors}
                                    />
                                </div> */}
                            </div>

                            <Header
                                title="Clients"
                            />
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <NumberSummaryClient 
                                        data={allData.client}
                                    />
                                </div>
                            </div>

                            <Header
                                title="Shift Request Progress Chart"
                            />
                            <div className="row">
                                <div className="col-md-12 mt-4">
                                    <AreaChart
                                        data={allData.lastShiftRequest}
                                        title="Shifts Requests Over Time"
                                    />
                                </div>
                            </div>
                        </>
                        : null
                }


            </>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(ReportSummary);
