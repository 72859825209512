export const columns = [
    {
        label: 'Description',
        field: 'description',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Code',
        field: 'code',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Color',
        field: 'color',
        sort: 'disabled',
        width: 200
    },
    {
        label: 'Active',
        field: 'active',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Bill',
        field: 'bill',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Pay',
        field: 'pay',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Contractor Notify',
        field: 'contractor_notify',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Client Notify',
        field: 'client_notify',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 100
    }
]