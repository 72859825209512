export const clientForSelectTwo  = (data) =>{
    let _data = [];
    data && data.forEach(d => {
        _data.push({
            label: d.name,
            value:d.id
        })
    })
    return _data;
}

export const contractorForSelectTwo  = (data) =>{
    let _data = [];
    data && data.forEach(d => {
        _data.push({
            label: d.name+"("+d.code+")",
            value:d.id
        })
    })
    return _data;
}