import React from 'react'
import {
    Col,
    Row,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';

const TableComponent = (props) => {
    return (
        <Row>
            <Col xs="11" style={{ overflowY: 'auto' }}>
                <MDBDataTable
                    style={{
                        width: props.width ? props.width : '130vw',
                        overflowX: 'scroll'
                    }}
                    striped
                    hover
                    bordered
                    className={props.className}
                    data={{ columns: props.columns, rows: props.rows }}
                />
            </Col>
            <Col xs="1" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                <button
                    type="button"
                    className="btn btn-circle1 btn-outline-primary mb-2" title="Add"
                    onClick={props.addRow}
                >
                    <i className="fas fa-plus"></i>
                </button>

                <button
                    type="button"
                    className="btn btn-circle1 btn-outline-danger mb-2" id="del_shift"
                    title="Delete"
                    onClick={props.deleteRow}
                >
                    <i className="fas fa-trash"></i>
                </button>

                <button
                    type="button"
                    className="btn btn-circle1 btn-outline-warning mb-2" id="cpy_shift"
                    title="Copy row"
                    onClick={props.copyRow}
                >
                    <i className="fas fa-clipboard"></i>
                </button>

                {!props.hideSave ? <button
                    type="button"
                    className="btn btn-circle1 btn-outline-success mb-2" id="submit_shift"
                    title="Save"
                    onClick={props.saveTable}
                >
                    <i className="fas fa-check"></i>
                </button> : null}

                <button
                    type="button"
                    className="btn btn-circle1 btn-outline-success mb-2" id="cpy_shift"
                    title="Copy to new client"
                    onClick={props.copyToClient}
                    disabled={!props.selected?.size}
                >
                    <i className="fas fa-clone"></i>
                </button>

                {/* <button 
                            className="btn btn-circle1 btn-outline-dark" data-toggle="modal" 
                            data-target="#copy_modal" 
                            data-id1="shift" 
                            title="Copy to Other Clients"
                            onClick={props.copyToOtherClients}
                        >
                            <i className="fas fa-clone"></i>
                        </button> */}

            </Col>
        </Row>
    )
};

export default TableComponent;