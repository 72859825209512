//REQUEST FOR CHANGES
export const GET_ALL_CHANGES = '/system/get-request-for-change';
export const UPDATE_CHANGES = '/system/respond-to-request-for-change';
export const DELETE_CHANGES = '';

//NOTIFICATIONS
export const SEND_NOTIFICATION = "/notification/send";

//MANAGE LISTS
export const GET_ALL_LIST = '/system/get-list';
export const UPDATE_LIST = '/system/update-list';
export const DELETE_LIST = '/system/delete-from-list';

//GENERAL SHIFTS
export const GET_SHIFTS = '/system/get-general-shifts';
export const UPDATE_SHIFTS = '/system/update-general-shifts';
export const DELETE_SHIFTS = '';

//MANAGE STATUS
export const GET_STATUS = '';
export const UPDATE_STATUS = '';
export const DELETE_STATUS = '';

//MANAGE CREDENTIALS
export const GET_CRED = '/system/get-credentials';
export const UPDATE_CRED = '/system/update-credentials';

export const UPDATE_RATES = "/system/add-update-client-rate";
export const GET_STANDARD_RATES = "/system/get-standard-rate";
export const UPDATE_STANDARD_RATES = "/system/add-update-standard-rate";

export const GET_LABEL = "/system/order-label";
export const ADD_LABEL = "/system/add-update-order-label";

//Holidays
export const GET_HOLIDAYS = "/payroll/get-standard-holiday";
export const ADD_EDIT_HOLIDAYS = "/payroll/add-update-standard-holiday";
export const DELETE_HOLIDAYS = "/payroll/delete-standard-holiday";


//Holidays
export const COPY_HOLIDAYS = "/client/copy-holiday-by-id";
export const COPY_SHIFT = "/client/copy-shift-by-id";
export const COPY_BILL_RATE = "/client/copy-billpay-rate-by-id";

