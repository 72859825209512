import React, { useState, useEffect } from 'react';
import { MDBDataTable } from 'mdbreact';
import { getFilter } from "../report/api";
import {convertToFullTime } from '../../../../Utils/dateFunctions'
const filterColumn = [
    {
        label: 'Sr. NO.',
        field: 'sro',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Filter name',
        field: 'name',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Created date',
        field: 'date',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Action',
        field: 'action',
        sort: 'asc',
        width: 100
    }
]

const OrderFilter = (props) => {
    const [state, setState] = useState([]);

    useEffect(() =>{
        getFilterData()
    },[])

    const getFilterData = async () => {
        let res = await getFilter()
        if(res){
            let _data = [...res];
            _data = _data.filter((d) => d.report_type === props.reportType)
            setState(_data);
        }
    }

    const getRows = () => {
        return state?.map((s, i) =>{
            return({
                sro:i+1,
                name:s.report_name,
                date:convertToFullTime(s.created_at),
                action:<button className='btn btn-primary btn-sm' onClick={(e) => props.applyFilter(s.saved_report_data)}>Apply Filter</button>
            })
        })
    }

    return (

        <MDBDataTable
            style={{
                overflowX: 'scroll'
            }}
            striped
            hover
            bordered
            className={props.className}
            data={{ columns: filterColumn, rows: getRows() }}
        />
    )
}

export default OrderFilter;