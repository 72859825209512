export const allType = [
    {
        type:"CNA",
        value:0,
    },
    {
        type:"LPN",
        value:0,
    },
    {
        type:"RN",
        value:0,
    },
    {
        type:"RN/LPN",
        value:0,
    }
]