import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
} from "reactstrap";
const CustomInfo = (props) => {
    const [cCompany, setCCompany] = useState('');
    const [us, setUs] = useState(0);
    const [drives, setDrives] = useState(0);
    const [green, setGreen] = useState('');
    const [shift, setShift] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            "company": cCompany,
            "usCitizen": us == 1 ? true : false,
            "drives": drives == 1 ? true : false,
            "workPermit": green,
            "shiftPref": shift,
        }
        console.log(data);
        props.updateInfo(data);
    }


    useEffect(() => {
        if (props.customInfo && Object.keys(props.customInfo).length > 0) {
            setCCompany(props.customInfo.company)
            setUs(props.customInfo.us_citizen)
            setDrives(props.customInfo.drives)
            setGreen(props.customInfo.work_permit)
            setShift(props.customInfo.shift_pref)
        }
    }, [props])

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md="4">
                    Contractor Company
                </Col>
                <Col md="8">
                    <input
                        type="text"
                        className="form-control"
                        value={cCompany}
                        onChange={(e) => setCCompany(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="2" htmlFor="us">
                    US Citizen?
                </Col>
                <Col md="4" className="text-left">
                    <input
                        type="checkbox"
                        class="form-control checkbox-size"
                        value="1"
                        onChange
                        ={(e) => setUs(us == 1 ? 0 : 1)}
                        id="us"
                        checked={us == 1 ? true : false}
                    />
                </Col>
                <Col md="2" htmlFor="drives">
                    Drives
                </Col>
                <Col md="4" className="text-center">
                    <input
                        type="checkbox"
                        class="form-control checkbox-size"
                        value="1"
                        onChange={(e) => setDrives(drives == 1 ? 0 : 1)}
                        id="drives"
                        checked={drives == 1 ? true : false}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    Green Card/Work Permit #
                </Col>
                <Col md="8">
                    <input
                        type="text"
                        className="form-control"
                        value={green}
                        onChange={(e) => setGreen(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    Shift Preference
                </Col>
                <Col md="8">
                    <input
                        type="text"
                        className="form-control"
                        value={shift}
                        onChange={(e) => setShift(e.target.value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col md="12" className="text-center">
                    <button
                        class="btn btn-outline-primary"
                        type="submit"
                    >Save</button>
                </Col>
            </Row>
        </form>

    )
}

export default CustomInfo;