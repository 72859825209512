import * as actionTypes from './actionType';

const initialState ={
    bulkModal:false,
}

const reducer = (state = initialState, action) =>{
    switch (action.type){
        case actionTypes.BULK_MODAL_CLOSE:
            return {
              ...state,
              bulkModal:false
            };
        case actionTypes.BULK_MODAL_OPEN:
            return {
                ...state,
                bulkModal:true
            };
        default:
            return state;
    }
}

export default reducer;