import React, { Component, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import Table from '../../../../../component/DynamicTable';
import Search from '../../filter/contractorListing';
import EmptyComponent from '../../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../../store/actions";
import { getWorkSummarReport, saveFilter } from "../api";
import * as helper from "../helper";
import { generateFilter } from '../../filter/contractorListing'
import ModalLayout from "../../../../../component/ModalLayout";
import SaveFilter from "../../filter/saveFilter";
import GetFilter from "../../filter/getFilter";
import { notiflixLoaderStart, notiflixLoaderEnd, notificationStatus } from '../../../../../component/Notiflix/notiflix';

const defaultColumn = ["ctr_f_name", "ctr_l_name", "ctr_gender", "ctr_add_1", "ctr_add_2", "ctr_city", "ctr_zip","ctr_state", "cred_des", "cred_cert_short_name", "ctr_mob1", "ctr_mob1_desc", "cp_exp_date"]

const OrderReport = (props) => {

    const [allData, setAllData] = useState(null)
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [changeColumn, setChangeColumn] = useState(false)
    const [columns, setColumns] = useState([])
    const [modalFilter, setModalFilter] = useState(false)
    const [filters, setFilters] = useState([])
    const [filterFromApply, setFilterFromApply] = useState(null)
    const [extraFilter, setExtraFilter] = useState([])
    const [sort, setSort] = useState(false)
    const [modalFilterSave, setModalFilterSave] = useState(false)
    const [modalFilterGet, setModalFilterGet] = useState(false)
    useEffect(() => {
        props.setBreadcrumbItems("Reports", breadcrumbItems);
    }, [])

    useEffect(() => {
        // getData(filters, columns, true)
    }, [])

    const getData = async (filter, sort, isFirst) => {
        console.log(filter, "dfdsf");
        let request = {
            "report_name": "contractor_listing_summary",
            "filters": [
                ...filter,
            ],
            "sort": helper.convertColumnForSort(sort ? sort : columns)
        }
        let data = await getWorkSummarReport(request)
        if (data) {
            if (isFirst || columns.length === 0) {
                setColumns((helper.cretateColumn(data.columns, defaultColumn)))
                updateData(data.data)
            }
            else {
                updateData(data.data)
            }

        }
    }

    const updateData = (data) => {
        setAllData(data)
    }

    //From top filtres
    const handleFilter = async (filterData) => {
        setFilters(filterData)
        getData(helper.updateGeneralFilter(filterData, extraFilter))
    }

    //From colum and sort
    const updateColumns = (columns, isCallAPI) => {
        console.log(columns);
        setColumns(columns)
        if (isCallAPI)
            getData(null, columns)
    }
    //From colum and sort

    //From Popup filter
    const updateExtraFilter = (extra) => {
        setExtraFilter(extra)
        getData(helper.updateExtraFilter(filters, extra))
    }

    const saveFilterData = async (name) => {
        notiflixLoaderStart()
        let data = {
            "reportName": name.name,
            "reportType": "contractor_listing_summary",
            "savedReportData": JSON.stringify({ filters: modalFilterSave, extraFilter, columns })
        }
        let res = await saveFilter(data)
        if (res) {
            setModalFilterSave(false)
            notificationStatus({ status: true, message: "Succcessfully saved" })
        }
        else {
            notificationStatus({ status: false, message: "Error in saving filter" })
        }
        notiflixLoaderEnd()
    }

    const applyFilter = async (data) => {
        let _data = JSON.parse(data)
        console.log(_data);
        setFilters(generateFilter(_data.filters))
        setExtraFilter(_data.extraFilter)
        setColumns(_data.columns)
        setModalFilterGet(false)
        setFilterFromApply(_data.filters)
        getData([...generateFilter(_data.filters), ..._data.extraFilter], _data.columns)
    }
    return (
        <>
            <Search
                handleFilter={handleFilter}
                title="Contractor Listing Report"
                filterFromApply={filterFromApply}
                setModalFilter={setModalFilterSave}
                getModalFilterGet={setModalFilterGet}
            />
            {
                allData?.length > 0 && <Card>
                    <CardBody style={{ overflowX: 'scroll' }}>
                        <Table
                            rows={allData}
                            columns={columns}
                            onSearch={false}
                            fetchSelectedPage={null}
                            changeColumn={changeColumn}
                            handleChangeColumn={setChangeColumn}
                            updateColumns={updateColumns}
                            setSort={setSort}
                            sort={sort}
                            exportExcel={() => { }}
                            modalFilter={modalFilter}
                            setModalFilter={setModalFilter}
                            extraFilter={extraFilter}
                            updateExtraFilter={updateExtraFilter}
                            reportName="Contractor-Listing-Report"
                        />
                    </CardBody>
                </Card>
            }
            {
                allData?.length === 0 && <EmptyComponent />
            }
            {
                modalFilterSave
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterSave(null)}
                        title={'Save Filter'}
                        size="md"
                    >
                        <SaveFilter
                            saveFilter={saveFilterData}
                        />
                    </ModalLayout>
                    : null
            }
            {
                modalFilterGet
                    ?
                    <ModalLayout
                        modal={true}
                        toggle={() => setModalFilterGet(null)}
                        title={'View Filter'}
                        size="lg"
                    >
                        <GetFilter
                            applyFilter={applyFilter}
                            reportType={"contractor_listing_summary"}
                        />
                    </ModalLayout>
                    : null
            }
        </>
    );

}

export default connect(null, { setBreadcrumbItems })(OrderReport);;