import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import DatePicker from '../../../component/datePicker/datePicker';
import ReactAutoSuggest from "../../../component/autoSugggest"
import { GET_ALL_SHIFT } from '../../../APICall/urls'
import { postAdminAPI } from '../../../APICall/index';
import { updateShiftHours } from "../../../Utils/dateFunctions";
import { notiflixStatus } from '../../../component/Notiflix/notiflix'
import { dateArithmetic, changeTime } from '../../../Utils/dateFunctions';


const allMoreInfo = [
    {
        label: "Late cancellation",
        value: "Late cancellation"
    },
    {
        label: "Time clock",
        value: "Time clock"
    }
]

const AddTimeSlip = (props) => {


    const [allShifts, setAllShifts] = useState([])
    const [allShiftsRaw, setAllShiftsRaw] = useState([])

    const [state, setState] = useState({
        "contractorId": 0,
        "clientId": 0,
        "startDate": "",
        "startTime": "",
        "endDate": "",
        "endTime": "",
        "supervisorName": "",
        "supervisorSocialId": "",
        // "supervisorName": " ",
        // "supervisorSocialId": " ",
        "supervisorSignature": "text",
        "contractorSignature": "text",
        "shiftId": 0,
        "employeeId": " ",
        // "employeeId": " ",
        moreInfo: ""
    })

    const [hrs, setHrs] = useState(0);

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (props.editItem && Object.keys(props.editItem).length > 0) {
            // console.log(props.editItem, props.editItem.startDate, props.editItem.endDate)
            let start = props.editItem.startDate ? props.editItem.startDate.split(" ") : []
            let end = props.editItem.endDate.split(" ")
            setState({
                "contractorId": props.editItem.contractorId,
                "clientId": props.editItem.clientId,
                "startDate": start[0],
                "startTime": changeTime(start[1]),
                "endDate": start[0],
                "endTime": changeTime(end[1]),
                "supervisorName": props.editItem.supervisorName,
                "supervisorSocialId": props.editItem.supervisorSocialId,
                "supervisorSignature": props.editItem.supervisorSignature,
                "contractorSignature": props.editItem.contractorSignature,
                "shiftId": props.editItem.shiftId,
                "employeeId": props.editItem.employeeId,
                moreInfo: props.editItem.moreInfo ? props.editItem.moreInfo : ""
            })
        }
    }, [props.editItem])

    useEffect(() => {
        if (state.clientId) {
            getAllShifts(state.clientId)
        }
    }, [state.clientId])


    const handleChange = (e, type) => {
        let _state = { ...state }
        _state[type] = e.target.value;

        if (type === "shiftId") {
            let shift = allShiftsRaw.filter((a) => a.id == e.target.value)
            if (shift && shift.length) {
                let start = changeTime(shift[0].start_time);
                let end = changeTime(shift[0].end_time);

                _state['startTime'] = start;
                _state['endTime'] = end;
            }

        }
        // console.log(_state);
        setState(_state)
    }

    const handleDateChange = (name, date) => {
        // console.log(name, date);
        let _state = { ...state }
        if (name === "startDate") {
            _state["startDate"] = date;
        }
        else if (name === "endDate") {
            _state["endDate"] = date;
        }
        setState(_state)
    }

    useEffect(() => {
        if (state.startDate && state.startTime && state.endTime) {

            let endDate = state.startDate;
            if (state.startTime > state.endTime) {
                // console.log(state.startTime, state.endTime)
                endDate = dateArithmetic(state.startDate, 1)
            }

            setState({
                ...state,
                endDate: endDate,
            })

            let totalHours = updateShiftHours(state.startDate + " " + state.startTime, endDate + " " + state.endTime)
            setHrs(totalHours)
        }
    }, [state.startDate, state.startTime, state.endTime])


    const generateObject = (allData) => {
        let _all = []
        allData && allData.length > 0 && allData.forEach(r => {
            if (r.is_active) {
                _all.push({
                    name: r.name,
                    id: r.id,
                })
            }
        })
        // console.log(_all)
        return _all;
    }

    const getAllShifts = async (client) => {
        let data = {
            "client": client
        };
        let result = await postAdminAPI(GET_ALL_SHIFT, data)
        // console.log(result);
        if (result.status) {
            setAllShiftsRaw(result.data)
            let _allShifts = generateObject(result.data)
            // console.log(_allShifts)
            setAllShifts(_allShifts)
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        // props.handleSubmit(state)
        // console.log(state)

        if (state.contractorId && state.clientId && state.shiftId) {

            let totalHours = updateShiftHours(state.startDate + " " + state.startTime, state.endDate + " " + state.endTime)

            let _state = {
                ...state,
                startDate: state.startDate + "T" + state.startTime,
                endDate: state.endDate + "T" + state.endTime,
                totalHours: totalHours,
            }

            delete _state.endTime;
            delete _state.startTime;
            // console.log(_state);

            if(props.editItem?.id){
                _state.timeslipId = props.editItem.id;
                _state.shiftId = _state.shiftId.toString();
                _state.clientId = _state.clientId.toString();
                _state.contractorId = _state.contractorId.toString();
                _state.timeslipId = _state.timeslipId.toString();
                // console.log(_state)
                props.editTimeSlip(_state);
            }
            else{
                props.addNewTimeSlip(_state);
            }


        }
        else {
            notiflixStatus(false, "All fields are mandatory. Please enter all fields to continue.");
        }
    }

    const onAutoSuggestChange = async (newValue, name) => {
        // console.log(newValue)
        setState({
            ...state,
            [name]: newValue,
        })
    }

    // console.log(state)

    return (
        <Modal size="lg" isOpen={props.isOpen}>
            <ModalHeader>
                Add Timeslip
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Contractor</label>
                            <select
                                className="form-control"
                                value={state.contractorId}
                                onChange={(e) => handleChange(e, "contractorId")}
                            >
                                <option value={0}>Select Contractor</option>
                                {
                                    props.allContractor && props.allContractor.map((a, i) => (
                                        <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Client</label>
                            <select
                                className="form-control"
                                value={state.clientId}
                                onChange={(e) => handleChange(e, "clientId")}
                            >
                                <option value={0}>Select Client</option>
                                {
                                    props.allClients && props.allClients.map((a, i) => (
                                        <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="desc_id">Shift</label>
                            <select
                                className="form-control"
                                value={state.shiftId}
                                onChange={(e) => handleChange(e, "shiftId")}
                            >
                                <option value={0}>Select Shift</option>
                                {
                                    allShifts && allShifts.map((a, i) => (
                                        <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="desc_id">Shift Date</label>
                            <DatePicker
                                date={state.startDate}
                                name={"startDate"}
                                required={true}
                                handleDateChange={handleDateChange}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="desc_id">Start Time</label>
                            <input
                                type="time"
                                value={state.startTime}
                                className="ml-1 form-control"
                                onChange={(e) => handleChange(e, "startTime")}
                                required={true}
                            />
                        </div>

                        <div className="form-group col-md-3">
                            <label htmlFor="desc_id">End Time</label>
                            <input
                                type="time"
                                value={state.endTime}
                                className="ml-1 form-control"
                                onChange={(e) => handleChange(e, "endTime")}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="desc_id">Total Hrs</label>
                            <input
                                type="text"
                                disabled={true}
                                className="form-control"
                                value={hrs}
                                placeholder="Total Hrs"
                            />
                        </div>
                        {/* <div className="form-group col-md-4">
                            <label htmlFor="desc_id">Supervisor Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={state.supervisorName}
                                onChange={(e) => handleChange(e, "supervisorName")}
                                required={true}
                                placeholder="Supervisor Name"
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="desc_id">Supervisor Social Id</label>
                            <input
                                type="text"
                                className="form-control"
                                value={state.supervisorSocialId}
                                onChange={(e) => handleChange(e, "supervisorSocialId")}
                                required={true}
                                placeholder="Supervisor Social Id"
                            />
                        </div> */}
                        <div className="form-group col-md-4">
                            <label htmlFor="desc_id">More Info</label>
                            <ReactAutoSuggest
                                value={state.moreInfo}
                                SuggestedPrimaryCategory={allMoreInfo}
                                onChange={(e) => onAutoSuggestChange(e, "moreInfo")}
                                name='moreInfo'
                                placeholder="More Info"
                            />
                            {/* <input
                                type="text"
                                className="form-control"
                                value={state.employeeId}
                                onChange={(e) => handleChange(e, "employeeId")}
                                required={true}
                                placeholder="Employee Id"
                            /> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddTimeSlip;