export const contactColumns =  [
    {
        label: 'Date & Time',
        field: 'date',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Contacted By',
        field: 'contacted',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Reason',
        field: 'reason',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Result',
        field: 'result',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Comments',
        field: 'comments',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Mod By',
        field: 'mod_by',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Mod On',
        field: 'mod_on',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Select',
        field: 'select',
        sort: 'asc',
        width: 100
    }
]