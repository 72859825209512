import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import ReactSelect from '../ReactSelect/checkboxSelect';
import { ConvertForSelect, ConvertToArray } from "../../Utils/arrayFunction"
import { connect } from "react-redux";
import DatePicker from '../../component/datePicker/datePicker';
import { notificationStatus } from '../../component/Notiflix/notiflix'

const CopyClient = (props) => {

    const [selected, setSelected] = useState({
        "clientId": [],
        futureDate: ""
    })

    const handleChangeMultiple = (e, type) => {
        console.log(e, type);
        let _selected = { ...selected }
        if (type === "clientId" || type == "futureDate")
            _selected[type] = e;
        else
            _selected[type] = e.target.value;
        setSelected(_selected)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (selected.clientId?.length) {
            let clientId = ConvertToArray(selected.clientId)
            console.log(clientId);
            props.handleSubmit(clientId, selected.futureDate)
        }
        else{
            notificationStatus({status:false, message:"Please select atleast one client to preoceed."})
        }
    }

    console.log(selected);

    return (
        <Modal size="lg" isOpen={true}>
            <ModalHeader>
                {props.title}
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <>
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label htmlFor="desc_id">Client</label>
                                <ReactSelect
                                    options={ConvertForSelect(props.basicDetails.allClients, "name", "id")}
                                    onChange={(e) => handleChangeMultiple(e, "clientId")}
                                    value={selected.clientId}
                                    allowSelectAll={true}
                                    isMulti={true}
                                    noMin={true}
                                />
                            </div>
                            {
                                props.isDateRequired && <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="desc_id">Activation date</label>
                                    <DatePicker
                                        handleDateChange={(name, e) => handleChangeMultiple(e, "futureDate")}
                                        date={selected.futureDate}
                                        name={"futureDate"}
                                        required={true}
                                    />
                                </div>
                            }
                        </div>
                    </>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}
const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps)(CopyClient);;