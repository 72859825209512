import React from 'react';
import Notiflix from 'notiflix';

const Loading = () =>{
    return(
        <>
        {Notiflix.Loading.Circle()}
        </>
    )
}

export default Loading;