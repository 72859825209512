import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from "react-redux";

import Credentials from "../../../../Utils/reportjson/credentials.json"
import CredentialsType from "../../../../Utils/reportjson/credentialsType.json"
import DatePicker from '../../../../component/datePicker/datePicker';
import { notificationStatus } from '../../../../component/Notiflix/notiflix';
import ReactSelect from '../../../../component/ReactSelect/checkboxSelect';
import { postAdminAPI } from '../../../../APICall/index';
import { GET_ALL_CONTRACTOR, GET_ALL_CLIENT } from '../../../../APICall/urls';
import { ConvertForSelect } from "../../../../Utils/arrayFunction"
import * as helper from '../report/helper';
import { dateDrop } from "../../../../Utils/reportjson/dateDropdown"
import './style.scss'

const style = {
    'text-transform': 'uppercase',
    'font-size': 15,
    'font-weight': 'bolder'
}

export const generateFilter = (filter) => {
    let _filter = [];
    if (filter.client?.length) {
        let _f = helper.getArrayFilter(filter.client, "client_name")
        _filter = [..._filter, ..._f]
    }

    if (filter.invoiceStartDate) {
        _filter = [..._filter, ["invoice_invoice_date", ">=", filter.invoiceStartDate]]
    }
    if (filter.invoiceEndDate) {
        _filter = [..._filter, ["invoice_invoice_date", "<=", filter.invoiceEndDate]]
    }

    if (filter.invoiceStart) {
        _filter = [..._filter, ["order_invoice", ">=", parseInt(filter.invoiceStart)]]
    }
    if (filter.invoiceEnd) {
        _filter = [..._filter, ["order_invoice", "<=", parseInt(filter.invoiceEnd)]]
    }

    if (filter.shiftStart) {
        _filter = [..._filter, ["order_start_date", ">", filter.shiftStart]]
    }
    if (filter.shiftEnd) {
        _filter = [..._filter, ["order_end_date", "<", filter.shiftEnd]]
    }
    // if (filter.ctrType) {
    //     let _f = helper.getListFilter(filter.ctrType, props.basicDetails?.allContractorTypes, "code")
    //     _filter.push(_f)
    // }
    return _filter

}
const OrderFilter = (props) => {
    const [filter, setFilter] = useState({
        client: '',
        invoiceStartDate: '',
        invoiceEndDate: '',
        invoiceStart: '',
        invoiceEnd: '',
        shiftStart: "",
        shiftEnd: "",
        ctrType: ""
    });

    useEffect(() =>{
        if(props.filterFromApply){
            setFilter(props.filterFromApply)
        }
    },[props.filterFromApply])

    const handleSubmit = (e) => {
        e.preventDefault();
        let _filter = generateFilter(filter)
        if (_filter.length) {
            props.handleFilter(_filter);
        }
        else {
            notificationStatus({ status: false, message: "Please select at least one filter to proceed. " })
        }
    }

    const updateFilter = (e, name) => {
        let _filter = { ...filter };
        if (name === "ctr" || name === "client")
            _filter[name] = e;
        else
            _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        console.log(name, date);
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    const getTyeFromCred = (id) => {
        return CredentialsType.filter((c) => c.cred_id === id)
    }

    useEffect(() => {
        if (filter.dateDropdownShift) {
            let getDate = dateDrop.filter((d) => d.id == filter.dateDropdownShift)
            if (getDate.length && getDate[0].start) {
                setFilter({ ...filter, shiftStart: getDate[0].start[0], shiftEnd: getDate[0].start[1] })
            }
            else {
                setFilter({ ...filter, shiftStart: null, shiftEnd: null })
            }
        }
    }, [filter.dateDropdownShift])

    useEffect(() => {
        if (filter.dateDropdownInv) {
            let getDate = dateDrop.filter((d) => d.id == filter.dateDropdownInv)
            if (getDate.length && getDate[0].start) {
                setFilter({ ...filter, invoiceStartDate: getDate[0].start[0], invoiceEndDate: getDate[0].start[1] })
            }
            else {
                setFilter({ ...filter, invoiceStartDate: null, invoiceEndDate: null })
            }
        }
    }, [filter.dateDropdownInv])

    // console.log(props.basicDetails);

    return (

        <Card>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <div className='reportCard'>
                        <Row>
                            <Col md={3}>
                                <label>Client</label>
                            </Col>
                            <Col md={6}>
                                <ReactSelect
                                    options={ConvertForSelect(props.basicDetails?.allClients, "name", "id")}
                                    onChange={(e) => updateFilter(e, "client")}
                                    value={filter.ctr}
                                    allowSelectAll={true}
                                    isMulti={true}
                                    noMin={true}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                <label>Invoice Date</label>
                            </Col>
                            <Col md={2}>
                                <select
                                    className="form-control"
                                    value={filter.dateDropdownInv}
                                    onChange={(e) => updateFilter(e, "dateDropdownInv")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dateDrop?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.invoiceStartDate}
                                    name={"invoiceStartDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.invoiceEndDate}
                                    name={"invoiceEndDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Shift Date</label>
                            </Col>
                            <Col md={2}>
                                <select
                                    className="form-control"
                                    value={filter.dateDropdownShift}
                                    onChange={(e) => updateFilter(e, "dateDropdownShift")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dateDrop?.map((a, i) => (
                                            <option key={a.id} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.shiftStart}
                                    name={"shiftStart"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                            <Col md={2}>
                                <DatePicker
                                    date={filter.shiftEnd}
                                    name={"shiftEnd"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Invoice Number Range</label>
                            </Col>
                            <Col md={3}>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={filter.invoiceStart}
                                    name="invoiceStart"
                                    onChange={(e) => updateFilter(e, "invoiceStart")}
                                />
                            </Col>
                            <Col md={3}>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={filter.invoiceEnd}
                                    name="invoiceEnd"
                                    onChange={(e) => updateFilter(e, "invoiceEnd")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Contractor Type</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allContractorTypes?.map((a, i) => (
                                            <option key={i} value={a.code}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <hr className='hrTag' />
                    <Row>
                        <Col md={8} className="text-left">
                            <button type="button" onClick={() => props.setModalFilter(filter)} className="btn btn-primary">
                                Save Filter
                            </button>
                            &nbsp; &nbsp;
                            <button type="button" onClick={() => props.getModalFilterGet(true)} className="btn btn-primary">
                                Apply Filter
                            </button>


                        </Col>
                        <Col md={4} className="text-right">
                            <button type="submit" className="btn btn-primary">
                                Generate Report
                            </button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>

    )
}

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps)(OrderFilter);;