import React, { useEffect, useState } from 'react';
import { Collapse, CardBody, Card, CardHeader, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import DescriptionCard from "./descriptionCard";
import ShiftPeriod from "./shiftPeriod";
import _ from 'lodash';
const contractorCredentials = [0, 1, 2];

const RateSetup = (props) => {

    const [allRates, setAllRates] = useState([]);

    useEffect(() => {
        if (props.allRates?.length) {
            setAllRates([...props.allRates])
            if(props.setAllRates){
                props.setAllRates([...props.allRates])
            }
        }
    }, [props.allRates])


    const handleCheckBox = (e, i, j) => {
        let _allRates = [...allRates]
        if(j>= 0){
            _allRates[i].data[j].checked = _allRates[i].data[j].checked  ? false : true
        }
        else{
            if(_allRates[i]?.data?.length){
                let checked = !checkChecked(_allRates[i]?.data)
                for(let k = 0; k<_allRates[i]?.data?.length; k++){
                    _allRates[i].data[k].checked = checked;
                }
            }
        }
        setAllRates(_allRates)
        if(props.setAllRates){
            props.setAllRates(_allRates)
        }
    }

    const [collapse, setCollapse] = useState(null);
    // const [isActive, setIsActive] = useState(false);
    const handleSelected = (d) => {
        // console.log(d);
        props.handleSelected(d)
    }

    const handleShiftSelected = (id) => {
        setCollapse(id)
        props.handleSelected(null)
        props.handleShiftSelected(id)
    }

    const checkChecked = (data) =>{
        let checked = true;
        if(data.length){
            data.forEach((d) =>{
                if(!d.checked){
                    checked = false;
                }
            })
            return checked;
        }
        return false
        
    }
    return (
        <div className="row">
            <div className="col-lg-5 col-md-5">
                <div className="card">
                    <div className="card-header py-3">
                        <div>
                            <button
                                className="btn btn-primary btn-circle1"
                                disabled={props.shiftSelected ? false : true}
                                onClick={() => props.addRates()}
                            // disabled={isActive ? false : true}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                            &nbsp;
                            {
                                props.isMultiClient &&
                                <button
                                    title="Bulk update rates for selected clients."
                                    className="btn btn-info btn-circle1"
                                    disabled={props.shiftSelected ? false : true}
                                    onClick={() => props.customizeRates()}
                                >
                                    <i className="fas fa-edit"></i>
                                </button>
                            }
                             &nbsp;
                            <button
                                title="Bulk update rates for selected clients."
                                className="btn btn-success btn-circle1"
                                onClick={props.copyClent}
                            >
                                <i className="fas fa-copy"></i>
                            </button>

                            {/* &nbsp;
                            <button
                                className="btn btn-danger btn-circle1"
                                onClick={() => props.deleteRates()}
                                disabled={props.selected ? false : true}
                            >
                                <i className="fas fa-trash"></i>
                            </button> */}
                        </div>
                    </div>

                    <div className="card-body p-3">
                        <div id="accordion">
                            {
                                allRates && allRates.length > 0 && allRates.map((p, i) => {
                                    return (
                                        <div key={i} className="card mb-1 shadow-none">
                                            <div className="card-header p-3 d-flex" id="headingOne">
                                                <input
                                                    type="checkbox"
                                                    style={{ width: 30 }}
                                                    className="form-control"
                                                    onClick={(e) => handleCheckBox(e, i)}
                                                    checked={checkChecked(p.data)}
                                                />
                                                <h6 className="m-0 font-size-14">
                                                    <Link
                                                        to="#"
                                                        className="text-dark"
                                                        onClick={() => handleShiftSelected(p.id)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {p.name}
                                                    </Link>
                                                </h6>
                                            </div>
                                            <Collapse isOpen={collapse == p.id ? true : false}>
                                                {
                                                    p.data.map((d, j) => (
                                                        <CardBody key={j} style={{ padding: 5 }}>
                                                            <ul className="list-group">
                                                                <li
                                                                    style={props.selected && props.selected[props.id] == d[props.id] ? { backgroundColor: 'gainsboro', cursor: "pointer" } : {}}
                                                                    onClick={() => handleSelected(d)}
                                                                    className="list-group-item list-group-item-action list_item d-flex"
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        style={{ width: 30 }}
                                                                        className="form-control"
                                                                        onClick={(e) => handleCheckBox(e, i, j)}
                                                                        checked={d.checked}
                                                                    />
                                                                    <div>
                                                                        {d.description}
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </CardBody>
                                                    ))
                                                }
                                            </Collapse>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-lg-7 col-md-8">
                {
                    props.selected
                        ?
                        <DescriptionCard
                            id={props.id}
                            selected={props.selected}

                        />
                        : null
                }
            </div>

            <div className="col-lg-12 col-md-12 mt-4">
                {
                    props.selected
                        ?
                        <ShiftPeriod
                            selected={props.selected?.children}
                            modifyRates={props.modifyRates}
                            deleteRates={props.deleteRates}
                            hideDelete={props.hideDelete}
                        />
                        : null
                }
            </div>
        </div >
    )
}

export default RateSetup;