import React from "react";

const CustomPay = ({name, handleChange, value}) => {
    return (
        <>
            <div className="row align-items-center justify-content-center">
                <div className={`col-4 d-flex gap-3`} style={{ gap: 10,'justify-content':'space-between' }}>
                    <label htmlFor="custom-pay" className="font-weight-bold mb-0">Set Custom {name} Amount:</label>
                    <input
                        className="form-control checkbox-width"
                        type="checkbox"
                        id="custom-pay"
                        name="setCustomAmount"
                        onChange={(e) => handleChange(e, "checkbox")}
                        checked={value.setCustomAmount}
                    />
                </div>
                <div className="col-1"></div>
                <div className={`col-7 d-flex align-items-center justify-content-center`} style={{ gap: 10 }}>
                    <label htmlFor="custom-amount" className="font-weight-bold mb-0">Amount:</label>
                    <input
                        className="form-control"
                        type="text"
                        name="customRate"
                        onChange={(e) => handleChange(e)}
                        value={value.customRate}
                    />
                    <label htmlFor="custom-hourly" className="font-weight-bold mb-0" >Hourly:</label>
                    <input
                        className="form-control checkbox-width"
                        type="checkbox"
                        name="isHourly"
                        onChange={(e) => handleChange(e, "checkbox")}
                        checked={value.isHourly}
                    />
                </div>
            </div>
            <div className="row align-items-center justify-content-center">
                <div className={`col-4 d-flex gap-3`} style={{ gap: 10,'justify-content':'space-between' }}>
                    <label htmlFor="custom-pay" className="font-weight-bold mb-0">Custom {name} Start/End: &nbsp;&nbsp;&nbsp;&nbsp;</label>
                    <input
                        className="form-control checkbox-width"
                        type="checkbox"
                        id="custom-pay"
                        name="customStartEnd"
                        onChange={(e) => handleChange(e, "checkbox")}
                        checked={value.customStartEnd}
                    />
                </div>
                <div className="col-1"></div>
                <div className={`col-7 d-flex align-items-center justify-content-center`} style={{ gap: 10 }}>
                    <label htmlFor="custom-amount" className="font-weight-bold mb-0">Start:</label>
                    <input
                        className="form-control"
                        type="time"
                        name="customStart"
                        onChange={(e) => handleChange(e)}
                        value={value.customStart}
                    />
                    <label htmlFor="custom-hourly" className="font-weight-bold mb-0" >End:</label>
                    <input
                        className="form-control"
                        type="time"
                        name="customEnd"
                        onChange={(e) => handleChange(e)}
                        value={value.customEnd}
                    />
                </div>
            </div>
        </>
    )
}

export default CustomPay;