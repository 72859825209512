import React from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.css';

const getSuggestionValue = suggestion => suggestion.value;

const renderSuggestion = suggestion => (
    <div>
        {suggestion.value}
    </div>
);

class ReactAutoSuggest extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: [],
        };
    }

    componentDidMount() {

        //   console.log(this.props.SuggestedPrimaryCategory)
        this.setState({
            ...this.state,
            value: this.props.value,
        })
        this.onSuggestionsFetchRequested({ value: this.props.value })
    }

    componentDidUpdate(prevProps) {

        if (prevProps != this.props) {
            // console.log(prevProps)
            // console.log(this.props.SuggestedPrimaryCategory)
            this.setState({
                ...this.state,
                value: this.props.value,
            })
            this.onSuggestionsFetchRequested({ value: this.props.value })
        }

    }

    getSuggestions = (value) => {
        // console.log(value, "amrit")
        const inputValue = value ? value.trim().toLowerCase() : "";
        const inputLength = inputValue.length;

        // return inputLength === 0 ? [] : this.props.SuggestedPrimaryCategory.filter(d =>
        //     d.value.toLowerCase().slice(0, inputLength) === inputValue
        // );
        return inputLength === 0 ? [] : this.props.SuggestedPrimaryCategory.filter(d =>
            d.value.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    onChange = (event, { newValue }) => {
        // console.log(newValue)
        this.setState({
            value: newValue
        })
        this.props.onChange(newValue)


    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value, suggestions } = this.state;

        const inputProps = {
            placeholder: this.props.placeholder,
            value: this.props.value,
            name: this.props.name,
            onChange: this.onChange,
            className: "form-control"
        };

        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

export default ReactAutoSuggest;