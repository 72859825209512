import { combineReducers  } from 'redux';

// Front
import Layout from './layout/reducer';

import Breadcrumb from "./Breadcrumb/reducer";

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';
import Modal from './Modal/reducer';
import SelectUser from './selectedUser/reducer';
import BasicDetails from './basicDetails/reducer';



const rootReducer = combineReducers({

    // public
    Layout,
    //Breadcrumb items
    Breadcrumb,
    // Authentication
    Account,
    Login,
    Forget,
    Modal,
    SelectUser,
    BasicDetails

});

export default rootReducer;