import React from 'react';
import { CardBody, Card, CardHeader } from "reactstrap";
import { dateUSStandard } from "../../Utils/dateFunctions";


const ShiftPeriod = (props) => {
    return (
        <Card>
            <CardHeader className="card-header">
                Shift Period
            </CardHeader>
            <CardBody style={{ overflowX: 'scroll' }}>
                <table className="table table-bordered" width="100%" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>Monday</th>
                            <th>Tuesday</th>
                            <th>Wednesday</th>
                            <th>Thursday</th>
                            <th>Friday</th>
                            <th>Saturday</th>
                            <th>Sunday</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody id="rates_body">
                        {
                            props.selected?.map((s, i) => (
                                <tr key={i}>
                                    <td>{s.d0}</td>
                                    <td>{s.d1}</td>
                                    <td>{s.d2}</td>
                                    <td>{s.d3}</td>
                                    <td>{s.d4}</td>
                                    <td>{s.d5}</td>
                                    <td>{s.d6}</td>
                                    <td>
                                        <strong>Created On: </strong>{dateUSStandard(s.created_at)}
                                        <br />
                                        <strong>Effective Date: </strong>{dateUSStandard(s.future_date)}
                                        <br />
                                        <strong>Deactivated on: </strong>{dateUSStandard(s.deactivated_at)}
                                    </td>
                                    <td>{s.active ? "Yes" : "No"}</td>
                                    <td>
                                        <button
                                            className="btn btn-info btn-circle1"
                                            onClick={() => props.modifyRates(s)}
                                        >
                                            <i className="fas fa-cut"></i>
                                        </button>
                                        &nbsp;
                                        {!props.hideDelete && <button
                                            className="btn btn-danger btn-circle1"
                                            onClick={() => props.deleteRates(s)}
                                        >
                                            <i className="fas fa-trash"></i>
                                        </button>}

                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </CardBody>
        </Card>
    )
}

export default ShiftPeriod;