import React, {} from 'react';
import { dateStandard } from "../../../Utils/dateFunctions"
import DatePicker from '../../../component/datePicker/datePicker';

import "react-datepicker/dist/react-datepicker.css";
const Navigation = (props) => {

    const handleDate = (name, date) => {

        let _date = dateStandard(date);
        console.log(name, _date);
        props.handleDateChange(name, _date);
    }

    return (
        <div className={`calendar-nav-header`}>
            <div className="row" style={{ width: '100%' }}>
                <div className="col-md-3"></div>
                <div className="col-md-3">
                    <DatePicker
                        required={true}
                        date={props.startDate ? props.startDate : ""}
                        name="start"
                        handleDateChange={handleDate}
                    />
                </div>
                <div className="col-md-3">
                    <DatePicker
                        required={true}
                        date={props.endDate ? props.endDate : ""}
                        name="end"
                        handleDateChange={handleDate}
                    />
                </div>
                <div className="col-md-3">
                    <button
                        className="btn btn-circle1 btn-info"
                        title="1 Week Previous"
                        onClick={() => props.handleDateArithmetic(-7)}
                    >{"<<"}</button> &nbsp;

                    <button
                        className="btn btn-circle1 btn-info"
                        title="1 Week Previous"
                        onClick={() => props.handleDateArithmetic(-1)}
                    >{"<"}</button> &nbsp;

                    <button
                        className="btn btn-circle1 btn-info"
                        onClick={() => props.handleDateArithmetic(1)}
                        title="1 Week Previous"
                    >{">"}</button> &nbsp;

                    <button
                        className="btn btn-circle1 btn-info"
                        title="1 Week Previous"
                        onClick={() => props.handleDateArithmetic(7)}
                    >{">>"}</button>
                </div>
            </div>
        </div>
    )
}

export default Navigation;