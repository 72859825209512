import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import { postAdminAPI } from '../../../../APICall/index';
import { GET_ALL_CLIENT } from '../../../../APICall/urls';


const OrderFilter = (props) => {
    const [allClients, setAllClients] = useState([]);
    const [client, setClient] = useState(null);

    useEffect(() => {
        getApiData();
    }, [])

    const getApiData = async () => {
        let client = await postAdminAPI(GET_ALL_CLIENT);
        setAllClients(client.data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {};
        if (client) {
            data = {
                ...data,
                client: parseInt(client),
            }
        }
        props.handleFilter(data);
    }

    return (
        <>
            <Card>
                <CardHeader>
                    Generate Bill Rate Report
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={12}>
                                <label>Client</label>
                                <select
                                    className="form-control"

                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <option value="" className="allOptions">All Client</option>
                                    {
                                        allClients && allClients.map((a, i) => (
                                            <option key={i} value={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={12} className="text-center">
                                <hr />
                                <button type="submit" className="btn btn-primary">
                                    Generate Report
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default OrderFilter;