export const contactColumn = [
    {
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Description',
        field: 'description',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Main',
        field: 'main',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Cell',
        field: 'cell',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Email',
        field: 'email',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Fax',
        field: 'fax',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Note',
        field: 'note',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Select',
        field: 'select',
        sort: 'asc',
        width: 100
    }
]
