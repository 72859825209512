import axios from '../shared/axios';

const errorMessage = "Something went wrong. Please try again";
const errorObject = {
    status: false,
    message: errorMessage
};

const getToken = () => {
    let token = localStorage.getItem("RNPlusToken");
    if (token){
        token = JSON.parse(token)
        return token.token;
    }
    else
        return null;
}

export const getAdminAPI = async (url) => {
    let token = getToken();
    // console.log(token);
    let data;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    await axios.get(url, config)
        .then(res => {
            if (res.headers['x-error-message']) {
                data = {
                    status: false,
                    message: res.headers['x-error-message']
                };
            }
            else {
                data = res.data;
            }
        })
        .catch(err => {
            // console.log(err);
            data = errorObject;
        })
    // console.log(data);
    return data;
}

export const postAdminAPI = async (url, postData) => {
    let token = getToken();
    let data;
    // console.log(token)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    await axios.post(url, postData, config)
        .then(res => {
            // console.log(res)
            if (res.data) {
                data = {
                    data: res.data,
                    status: true,
                    message: "Success"
                };
            }
            else {
                data = {
                    status: false,
                    message: res.headers['x-error-message'] ? res.headers['x-error-message'] : "This action is not allowed"
                };
            }

        })
        .catch(err => {
            // console.log(err.response);
            if (err.response?.data) {
                data = {
                    status: false,
                    message: err.response.data
                };
            }
            else
                data = errorObject;
        })
    // console.log(data);
    return data;
}

export const postAdminAPIBlob = async (url, postData) => {
    let token = getToken();
    let data;
    // console.log(token)
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
    };
    await axios.post(url, postData, config)
        .then(res => {
            console.log(res);
            // if (res.headers['x-error-message']) {
            //     data = {
            //         status: false,
            //         message: res.headers['x-error-message']
            //     };
            // }
            // else
            //     data = res;

        })
        .catch(err => {
            console.log(err);
            data = errorObject;
        })
    // console.log(data);
    return data;
}

export const postMessageAdminAPI = async (url, postData) => {
    let token = getToken();
    let data;
    // console.log(token)
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    await axios.post(url, postData, config)
        .then(res => {
            // console.log(res);
            data = res.data
        })
        .catch(err => {
            console.log(err);
            data = errorObject;
        })
    // console.log(data);
    return data;
}

export const postAdminFormadataAPI = async (url, postData) => {
    let token = getToken();
    let data;
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
        }
    }
    // console.log(url, config, postData)
    await axios.post(url, postData, config)
        .then(res => {
            data = res.data;
        })
        .catch(err => {
            console.log(err);
            data = errorObject;
        })
    // console.log(data);
    return data;
}
