import React from 'react';

const RateInfoCard = (props) => {
    console.log(props)
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <h6 className="m-0 font-weight-bold text-primary">Rate Info</h6>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Starts At</th>
                                <th>Ends At</th>
                                <th>Monday</th>
                                <th>Tuesday</th>
                                <th>Wednesday</th>
                                <th>Thursday</th>
                                <th>Friday</th>
                                <th>Saturday</th>
                                <th>Sunday</th>
                                {/* <th>Unpaid Break</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.singleShift.start_time}</td>
                                <td>{props.singleShift.end_time}</td>
                                <td>{props.singleShift.d0}</td>
                                <td>{props.singleShift.d1}</td>
                                <td>{props.singleShift.d2}</td>
                                <td>{props.singleShift.d3}</td>
                                <td>{props.singleShift.d4}</td>
                                <td>{props.singleShift.d5}</td>
                                <td>{props.singleShift.d6}</td>
                                {/* <td>No</td> */}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default RateInfoCard;