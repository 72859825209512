import React, { Component } from 'react'
import StaffingRowHeader from './heading';
import SingleStaffignCard from '../SingleStaffignCard'
import { changeTime, dateArithmetic } from '../../../Utils/dateFunctions';
import './staffingRow.css';

// const json = {
//     name:"Amrit",
//     status:10,
//     title:"7:00A - 12:30P",
//     id:1
// }

const StaffingRow = (props) => {

    const getRowHeading = (title, startTime, endTime) => {
        let _quotient = parseInt(props.days) / 7;
        let _remainder = parseInt(props.days) % 7;
        let _heading = [];
        // console.log(_quotient, _remainder)
        if (_quotient === 0) {
            _heading.push(
                <td style="" className="staffingBodyRow" colspan={_remainder}>
                    <StaffingRowHeader
                        title={title}
                        startTime={startTime}
                        endTime={endTime}
                    />
                </td>
            )
        }
        else {
            let _heading = [];
            // console.log(_quotient)
            for (let i = 0; i < _quotient; i++) {
                // console.log(_heading)
                _heading.push(
                    <td key={i} className="staffingBodyRow" colSpan={7}>
                        <StaffingRowHeader
                            title={title}
                            startTime={startTime}
                            endTime={endTime}
                        />
                    </td>
                )
            }

            if (_remainder > 0) {
                _heading.push(
                    <td className="staffingBodyRow" colSpan={_remainder}>
                        <StaffingRowHeader
                            title={title}
                            startTime={startTime}
                            endTime={endTime}
                        />
                    </td>
                )
            }
            // console.log(_heading)
            return _heading;
        }

    }

    const getStaffingCard = (isEmpty, shift, shiftOrder, position) => {
        let _rows = [];
        let json = {};
        for (let i = 0; i < props.days; i++) {
            let currentDate = i > 0 ? dateArithmetic(props.startDate, i) : props.startDate;

            if (shiftOrder) {
                if (shiftOrder[currentDate] && shiftOrder[currentDate][position]) {
                    isEmpty = true;
                    // console.log(shiftOrder[currentDate][position])
                    json = shiftOrder[currentDate][position];
                }
                else {
                    isEmpty = false;
                    json = {};
                }

            }

            _rows.push(
                <SingleStaffignCard
                    data={isEmpty ? json : {}}
                    shift={shift}
                    count={i}
                    key={i}
                    date={currentDate}
                    handleModal={props.handleModal}
                    afterActionCalls={props.afterActionCalls}
                />
            )

        }
        return _rows;
    }

    const generateRowHeading = (s, allOrders) => {
        // console.log(s, allOrders)
        let _shiftOrder = allOrders[s.id] ? allOrders[s.id] : [];
        // console.log(s.id, _shiftOrder);
        let highestLength = getHighestLength(_shiftOrder);
        // console.log(highestLength);

        let _shifts = [];
        for (let i = 0; i < highestLength; i++) {
            _shifts.push(<>{<tr>{getStaffingCard(false, s, _shiftOrder, i)}</tr>}</>);
        }
        // console.log(_shifts);
        return _shifts;


    }

    const getHighestLength = (shiftOrder) => {
        let getLength = 0;
        let _dates = Object.keys(shiftOrder);
        for (let i = 0; i < _dates.length; i++) {
            getLength = shiftOrder[_dates[i]].length > getLength ? shiftOrder[_dates[i]].length : getLength
        }
        return getLength;
    }

    // console.log(props.allShifts)

    return (
        <>
            {
                props.allShifts && props.allShifts.length > 0 && props.allShifts.map((s, i) => {
                    if (s.is_active == 1) {
                        return (
                            <>
                                <tr style={{ textAlign: 'center', backgroundColor: s.color }}>
                                    {getRowHeading(s.name, changeTime(s.start_time), changeTime(s.end_time))}
                                </tr>

                                {generateRowHeading(s, props.allOrders)}

                                <tr>
                                    {getStaffingCard(false, s)}
                                </tr>
                            </>
                        )
                    }
                    
                })
            }
        </>
    )
}

export default StaffingRow;