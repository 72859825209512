import React from "react";
import "./History.scss";
import { TimeDiff, changeTime,dateTimeFormatFull, changeTzToNormal } from '../../../../Utils/dateFunctions';
import { getStatus } from '../../../../Utils/status';
const History = (props) => {
  return (
    <div className="row">
      <div className={`col-3`}>History of Changes</div>
      <div className = {`col-12`}>
      <table>
        <tr>
          {/* <th>Modified By</th> */}
          <th>Modified On</th>
          <th>Shift Status</th>
          <th>Start Time</th>
          <th>End Time</th>
          {/* <th>Status</th> */}
          {/* <th>Type</th> */}
          {/* <th>Bill</th>
          <th>Pay</th> */}
          <th>Contractor</th>
          <th>Contractor Confirmed</th>
          <th>Client Confirmed</th>
          <th>Confirmed with</th>
          {/* <th>Order</th>
          <th>Ordered By</th> */}
          <th>Filled By</th>
          {/* <th>Filed On</th> */}
          <th>Time Sheet</th>
          <th>Remarks</th>
        </tr>
        {props.selectedHistory && props.selectedHistory.map((h,i) =>(
            <tr key={i}>
                {/* <td>{h.modified_by}</td> */}
                <td>{changeTzToNormal(h.modified_at).date+" "+changeTzToNormal(h.modified_at).time}</td>
                <td>{getStatus(h.state) ? getStatus(h.state).name : ""}</td>
                <td>{dateTimeFormatFull(h.start_date).time}</td>
                <td>{dateTimeFormatFull(h.end_date).time}</td>
                {/* <td>Status</td> */}
                {/* <td>Type</td> */}
                {/* <td>{h.bill}</td>
                <td>{h.pay}</td> */}
                <td>{h.contractor_name}</td>
                <td>{getStatus(h.state).id === 2 ? "Yes" : "No"}</td>
                <td>{getStatus(h.state).id === 3 ? "Yes" : "No"}</td>
                <td>{h.confirmed_by}</td>
                {/* <td>{h.was_asked_to_create ? "Yes" : "No"}</td>
                <td>{h.creator_client}</td> */}
                <td>{h.filed_by}</td>
                {/* <td>{h.filed_on}</td> */}
                <td>{getStatus(h.state).id >=4  ? "Yes" : "No"}</td>
                <td>{h.message}</td>
            </tr>
        ))}
      </table>
      </div>
      
    </div>
  );
};
export default History;
