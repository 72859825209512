export const columns = [
    {
        label: 'Code',
        field: 'code',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Description',
        field: 'description',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 100
    }
]