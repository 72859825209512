import React from "react"
import "./styles.css";

const Loader = () => {
    return (
        <div className="small-loader"></div>
    )

}

export default Loader