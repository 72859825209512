import React, { Component } from "react";
import { Card, CardHeader, Input, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";
import AllHeaders from './allHeading.json';
import SelectedList from './selectedList';

import { GET_ALL_LIST, UPDATE_LIST, DELETE_LIST } from "../../../APICall/urls/settings";
import { getAdminAPI, postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from "../../../component/Notiflix/notiflix"

import "./index.css";


class ManageCredentials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            selected: null,
            selectedData: [],

            allData: {},
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Manage Lists", this.state.breadcrumbItems);
        this.getAllData();
    }

    //----APIS
    getAllData = async () => {
        let results = await getAdminAPI(GET_ALL_LIST)
        // console.log(results)
        if (results) {
            this.setState({ allData: results, selectedData:[], selected:null })
        }
    }

    deleteData = async (p, i) => {
        notiflixLoaderStart()
        // console.log(p, i);
        let data = {
            "table_name": this.state.selected.table_name,
            "id": p.id
        }
        let results = await postAdminAPI(DELETE_LIST, data);
        // console.log(results)
        notificationStatus(results)
        if (results.status) {
            this.getAllData();
        }
    }

    saveData = async (data) => {
        // console.log(data);
        let apiData = {
            "table_name": this.state.selected.table_name,
            "data": data
        }

        // console.log(apiData);
        let results = await postAdminAPI(UPDATE_LIST, apiData);
        // console.log(results)
        notificationStatus(results)
        if (results.status) {
            this.getAllData();
        }
    }

    //----Action
    handleList = (a) => {
        // console.log(a);
        let data = this.state.allData[a.api];
        // console.log(data);
        this.setState({ selected: a, selectedData: data })
    }

    render() {
        const { selected, selectedData } = this.state;
        // console.log(this.state);
        return (
            <div className="row">
                <div className="col-md-4">
                    <Card className="pt-3 pb-3">
                        {
                            AllHeaders.map((a, i) => (
                                <div className="setting-card" key={i} onClick={() => this.handleList(a)}>
                                    <span className="setting-card-title" >{a.name}</span>
                                </div>
                            ))
                        }

                    </Card>
                </div>
                <div className="col-md-8">
                    {
                        selected
                            ?
                            <SelectedList
                                selected={selected}
                                selectedData={selectedData}
                                deleteData={this.deleteData}
                                saveData={this.saveData}
                            />
                            : null
                    }
                </div>
            </div>
        );
    }

};

export default connect(null, { setBreadcrumbItems })(ManageCredentials);

