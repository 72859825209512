import { getAdminAPI, postAdminAPI } from '../../../../APICall/index';
import { notiflixLoaderStart,notiflixLoaderEnd, notificationStatus } from '../../../../component/Notiflix/notiflix'
import * as APIs from '../../../../APICall/urls/report';
import { columns } from '../allList/order';

export const getWorkSummarReport = async (apiData) => {
    notiflixLoaderStart()
    let result = await postAdminAPI(APIs.WORK_SUMMARY, apiData)
    if (result) {
        notiflixLoaderEnd()
        return result.data
    }
    else {
        return {
            data:[],
            columns:[]
        }
    }
}


export const saveFilter = async (apiData) => {
    notiflixLoaderStart()
    let result = await postAdminAPI(APIs.SAVE_REPORT, apiData)
    if (result) {
        return result.data
    }
    notiflixLoaderEnd()
}

export const getFilter = async (apiData) => {
    // notiflixLoaderStart()
    let result = await getAdminAPI(APIs.GET_REPORT)
    notiflixLoaderEnd()
    if (result) {
        return result
    }
    
}