import React from 'react';

const Info = (props) => {
    return (
        <div className="card card-text">
            <div className="card-body">
                <h4 className="card-title">{props.title}</h4>
                <div className="table-responsive">
                    <table className="table no-wrap">
                        <tbody>
                            {
                                props.title === "Shift Info"
                                    ?
                                    <>
                                        <tr>
                                            <td id="shift_total">{props.data.total ? props.data.total : 0}</td>
                                            <td className="text-right">Total Shifts</td>
                                        </tr>
                                        <tr>
                                            <td id="shift_total">{props.data.scheduled ? props.data.scheduled : 0}</td>
                                            <td className="text-right">Scheduled Shifts</td>
                                        </tr>
                                        <tr>
                                            <td id="shift_total">{props.data.completed ? props.data.completed : 0}</td>
                                            <td className="text-right">Completed Shifts</td>
                                        </tr>
                                        <tr>
                                            <td id="shift_total">{props.data.cancelled ? props.data.cancelled : 0}</td>
                                            <td className="text-right">Cancelled Shifts</td>
                                        </tr>
                                    </>
                                    : props.title === "Contractors"
                                        ?
                                        <>
                                            <tr>
                                                <td id="shift_total">{props.data.total}</td>
                                                <td className="text-right">Total</td>
                                            </tr>
                                            <tr>
                                                <td id="shift_total">{props.data.active}</td>
                                                <td className="text-right">Active</td>
                                            </tr>
                                            <tr>
                                                <td id="shift_total">{props.data.inActive}</td>
                                                <td className="text-right">Inactive</td>
                                            </tr>
                                            <tr>
                                                <td id="shift_total">{props.data.total - props.data.valid}</td>
                                                <td className="text-right">Profile Expired Count</td>
                                            </tr>
                                           
                                            <tr>
                                                <td id="shift_total">{props.data.pendingCheques}</td>
                                                <td className="text-right">Pending Cheques</td>
                                            </tr>
                                        </>
                                        : null

                            }

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    )
}

export default Info;