import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "reactstrap";
import "./General.scss";
import { TimeDiff, changeTime, dateTimeFormatFull, compareTwoTime, dateArithmetic, dateUSStandard, dateTimeBasicFormantSplit } from '../../../../Utils/dateFunctions';
import { staffingState } from '../../../../Utils/status';
import Star from '../../../../component/Required/star';
import { ConvertToArray } from "../../../../Utils/arrayFunction"
import { dateTimeBasicFormant, updateShiftHours, currentDate, updateDateIfNightShift } from "../../../../Utils/dateFunctions"
import { postAdminAPI } from "../../../../APICall/index";
import { VIEW_SINGLE_ORDER, GET_ALL_SHIFT, GET_AREA, VALIDATE_CONTRACTOR } from "../../../../APICall/urls";
import { GET_ONE_CONTRACTOR } from '../../../../APICall/urls/contractor';
import { GET_ONE_CLIENT } from '../../../../APICall/urls';
import BreakDown from "./breakdown";
import Information from "./information";
import { notiflixStatus, notiflixLoaderStart, notiflixLoaderEnd } from '../../../../component/Notiflix/notiflix';
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const replacementsArray = [5, 6, 7, 8, 9, "5", "6", "7", "8", "9"]
const skipOnUpdate = [7, 8, 10, 19]

const checkAvailable = (arr, item) =>{
    let avai = arr.filter((a) => parseInt(a) === parseInt(item))
    return avai?.length > 0
}

const General = (props) => {
    const [state, setState] = useState({
        selectedContractor: 0,
        startTime: "",
        endTime: "",
        noBrake: false,
        selectedType: "",
        confirmedBy: "",
        quantity: 1,
        order: true,
        orderedBy: "",
        noBill: false,
        noPay: false,
        status: 1,
        stateId: 1,
        bonusType: 1,
        clientBonusType: 0,
        clientBonusAmount: 0,
        contBonusType: 0,
        contBonusAmount: 0,
        filled: "",
        filledOn: "",
        clientUrgent: false,
        urgentReplace: false,
        modBy: "",
        modOn: "",
        signIn: "",
        signInBy: "",
        signOut: "",
        signOutBy: "",
        shiftId: null,
        area: "",
        label: null,
        allOrderData: null,
        message: "",
        selectedClient: props.selectedClient || 0,
        selectedShift: props.selectedShift,
        bonusTimes: 0,
        bonusRatio: 33.33,
        selectedDate: props.selectedDate,
        clientConfirm: false,
        ctrAcknowledge: false,
        timeSheetVerified: false,
        payableCtrType: 0,
        startDateOrig: null,
    })

    //for client and contractors 
    const [allShifts, setAllShifts] = useState([]);
    const [allOrderData, setAllOrderData] = useState(null);
    const [billBreakDown, setBillBreakDown] = useState(null)
    const [payrollBreakDown, setPayrollBreakDown] = useState(null)
    const [allContractor, setAllContractor] = useState(props.contractors ? [...props.contractors] : [])
    const [confirmModal, setConfirmModal] = useState(false)

    const [allData, setAllData] = useState({
        payrollData: '',
        invoiceData: '',
        payrollRate: '',
        invoiceRate: ''
    })
    const [billingToolOpen, setBillingToolOpen] = useState(false);
    const [clientToolOpen, setClientToolOpen] = useState(false);
    const [clientTooltip, setClientTooltip] = useState(null)
    const [contractorToolOpen, setContractorToolOpen] = useState(false);
    const [contractorTooltip, setContractorTooltip] = useState(null)
    const [payrollToolOpen, setPayrollToolOpen] = useState(false);
    const [timeDiff, setTimeDiff] = useState(false);
    const [allArea, setAllArea] = useState([]);
    const [callOut, setCallOut] = useState(false);
    const [isTriedCallOut, setIsTriedCallOut] = useState(false);

    const handleChangeData = (e, type, name) => {
        let _state = { ...state };
        if (e.target.name === "selectedContractor") {
            let index = allContractor.findIndex((f) => f.inactive === 1 && f.id == e.target.value)
            if (index >= 0) {
                notiflixStatus(false, "This contractor is inactive. Please select different contractor.")
                return true;
            }
        }
        if (type === "checkbox") {
            if(parseInt(_state.status) <= 4){
                if (e.target.name === "timeSheetVerified" && e.target.checked) {
                    _state.status = 4;
                }
                else if (e.target.name === "timeSheetVerified" && !e.target.checked) {
                    _state.status = 3;
                }
                if (e.target.name === "ctrAcknowledge" && e.target.checked) {
                    _state.status = 3;
                }
                else if (e.target.name === "ctrAcknowledge" && !e.target.checked) {
                    _state.status = 2;
                }
                else if (e.target.name === "clientConfirm" && e.target.checked) {
                    _state.status = 2;
                }
                else if (e.target.name === "clientConfirm" && !e.target.checked) {
                    _state.status = 1;
                    _state.ctrAcknowledge = false;
                }
            }
            _state[e.target.name] = _state[e.target.name] ? false : true;   
        }
        else if (type === "multi") {
            _state[name] = e
        }
        else {
            _state[e.target.name] = e.target.value
        }

        //To check timesheet
        if (e.target.name === "stateId" && replacementsArray.includes(e.target.value)) {
            setIsTriedCallOut(true)
        }
        else if (e.target.name !== "stateId" && replacementsArray.includes(_state.status)) {

        }
        else {
            setIsTriedCallOut(false)
        }

        //To change Status by id
        if (e.target.name === "stateId") {
            let status = props.allState.filter((f) => f.id == e.target.value)
            _state.status = status.length ? status[0].state : 1;

        }

        if (e.target.name === "selectedType") {
            _state.selectedContractor = ""

            let order = props.contractorType.filter((f) => f.id == e.target.value)
            if (order.length && order[0].order > 0) {
                _state.payableCtrType = e.target.value;
            }
            else {
                _state.payableCtrType = "";
            }
        }

        if (e.target.name === "selectedContractor") {
            let cont = props.contractors.filter(c => c.id === parseInt(e.target.value))
            if (cont.length > 0) {
                _state.payableCtrType = cont[0].type
            }
            else {
                _state.payableCtrType = ""
            }
        }

        if(e.target.name === "clientBonusAmount"){
            _state.contBonusAmount = e.target.value
            _state.contBonusType = state.clientBonusType
        }
        else if(e.target.name === "clientBonusType"){
            _state.contBonusAmount = state.clientBonusAmount
            _state.contBonusType = e.target.value
        }

        setState(_state)
        if (props.handleChange)
            props.handleChange()
    }

    useEffect(() => {
        //filter client by type
        if (state.selectedType) {
            if (state.selectedType == 5) {
                let c = props.contractors.filter((f) => f.code == "RN" || f.code == "LPN")
                setAllContractor(c)
            }
            else {
                let c = props.contractors.filter((f) => f.type == state.selectedType)
                setAllContractor(c)
            }

        }
        else {
            setAllContractor(props.contractors)
        }
    }, [state.selectedType])


    useEffect(() => {
        if (props.selectedOrder && props.allState.length && Object.keys(props.selectedOrder).length > 0) {
            getApiData(props.selectedOrder.id);
        }
    }, [props.allState])

    const getApiData = async (orderId) => {
        if (orderId) {


            let result = await postAdminAPI(VIEW_SINGLE_ORDER, { id: orderId })
            if (result.status) {
                let data = result.data;

                setAllOrderData(result.data);
                let startDate = dateTimeFormatFull(data.startDate);
                let endDate = dateTimeFormatFull(data.endDate);
                let status = staffingState(data.state);


                let payrollTotal = '';
                let payrollRate = 0;
                if (data.payrollResult) {
                    let _payroll = data.payrollResult;
                    let custom = []
                    if (_payroll.customBonusAmt) {
                        custom = [{
                            amount: _payroll.customBonusAmt,
                            rate: _payroll.customBonus,
                            type: _payroll.customBonusType == 1 ? "Overall" : "Hourly",
                            bonusRatio: 0
                        }]
                    }

                    // console.log(_payroll)
                    payrollTotal = _payroll.total;
                    if (_payroll.totalHours) {
                        payrollRate = parseFloat(_payroll.total / _payroll.totalHours).toFixed(2);
                    }



                    if (_payroll.intervals && _payroll.intervals.length > 0) {
                        setPayrollBreakDown(<BreakDown data={_payroll.intervals} custom={custom} other={_payroll.otherCharges} />)
                    }
                }

                let invoiceTotal = '';
                let invoiceRate = 0;
                if (data.billResult) {
                    let _bill = data.billResult
                    invoiceTotal = _bill.total
                    let custom = []
                    if (_bill.customBonus) {
                        custom = [{
                            amount: _bill.customBonusAmt,
                            rate: _bill.customBonus,
                            type: _bill.customBonusType == 1 ? "Overall" : "Hourly",
                            bonusRatio:_bill.bonusRatio
                        }]
                    }
                    if (_bill.totalHours) {
                        invoiceRate = parseFloat(_bill.total / _bill.totalHours).toFixed(2);
                    }

                    if (_bill.intervals && _bill.intervals.length > 0) {

                        setBillBreakDown(<BreakDown other={_bill.otherCharges} custom={custom} data={_bill.intervals} />)

                    }

                }
                if (!props.selectedOrder?.responses && props.updateResponse) {
                    props.updateResponse(startDate, endDate, props.selectedClient, data.ctrType);
                }

                setAllData({
                    ...allData,
                    payrollTotal: payrollTotal,
                    invoiceTotal: invoiceTotal,
                    payrollRate: payrollRate,
                    invoiceRate: invoiceRate
                })


                let _state = { ...state };
                _state.shiftId = orderId;
                _state.selectedContractor = data.assignedCtr
                _state.payableCtrType = data.payableCtrType;
                _state.startTime = startDate.time;
                _state.endTime = endDate.time;


                setTimeDiff(TimeDiff(startDate.time, endDate.time, !data.hasBreak, data.ctrType));

                _state.selectedType = data.ctrType;
                _state.noBrake = !data.hasBreak;

                _state.order = !data.wasAskedToCreate;

                _state.noBill = !data.billable;
                _state.noPay = !data.payable;
                _state.status = data.state;
                _state.stateId = data.stateId;
                _state.bonusType = data.bonusType;
                _state.bonusTimes = data.bonusTimes;

                if (_state.bonusType == 4) {
                    _state.clientBonusType = data.bonusByClientType;
                    _state.clientBonusAmount = data.bonusByClient;
                    _state.contBonusType = data.bonusToCtrType;
                    _state.contBonusAmount = data.bonusToCtr;
                    _state.bonusRatio = data.bonusRatio ?  parseFloat(data.bonusRatio * 100).toFixed(2) : "";
                }
                else {
                    _state.bonusRatio = 0;
                }

                _state.clientUrgent = !!data.isUrgent;
                _state.urgentReplace = !!data.isUrgentReplacement;

                _state.modBy = data.modifiedByName;
                _state.modOn = dateTimeFormatFull(data.modifiedAt).date;

                _state.signIn = data.contractorSigninEnteredDate;
                _state.signInBy = data.contractorSigninId;
                _state.signOut = data.contractorSignoutEnteredDate;
                _state.signOutBy = data.contractorSignoutActor;
                _state.message = data.message;

                _state.area = data.area;
                _state.orderedBy = data.orderedBy;
                _state.confirmedBy = data.confirmedBy;

                _state.filled = data.filedBy;
                _state.startDateOrig = data.startDateOrig

                setState({
                    ..._state,
                    clientConfirm: data.clientConfirm === "YES",
                    ctrAcknowledge: data.ctrAcknowledge === "YES",
                    timeSheetVerified: data.timeSheetVerified === "YES",
                })
            }
        }
    }

    useEffect(() => {
        let _state = { ...state }
        _state.selectedShift = state.selectedShift ? state.selectedShift : {};
        _state.selectedClient = props.selectedClient ? props.selectedClient : 0;
        _state.selectedContractor = props.selectedContractor ? props.selectedContractor : 0;

        setAllShifts(props.shifts ? props.shifts : [])

        if (state.selectedShift) {
            _state.startTime = changeTime(state.selectedShift.start_time)
            _state.endTime = changeTime(state.selectedShift.end_time)
        }
        setState(_state)
    }, [])

    useEffect(() => {
        setTimeDiff(TimeDiff(state.startTime, state.endTime, state.noBrake, state.selectedType))
    }, [state.startTime, state.endTime, state.noBrake, state.selectedType])

    const onShiftChange = (e) => {
        if (e.target.value) {
            let shift = allShifts.filter(a => a.id == e.target.value)
            // console.log(shift)
            if (shift && shift.length > 0) {
                let _state = { ...state }
                _state.startTime = shift[0].start_time
                _state.endTime = shift[0].end_time
                _state.selectedShift = shift[0]
                setState(_state)
            }
        }
    }

    useEffect(() => {
        if (state.selectedClient && parseInt(state.selectedClient) > 0) {
            // console.log("4", state.selectedClient);
            updateClient(state.selectedClient)
        }
    }, [state.selectedClient])

    useEffect(() => {
        // console.log(props.contractors, selectedContractor)
        let _state = { ...state }
        if (state.selectedContractor) {
            let cont = props.contractors.filter(c => c.id === parseInt(state.selectedContractor))
            if (cont.length > 0) {
                _state.selectedType = cont[0].type
                _state.payableCtrType = cont[0].type
            }
            else {
                _state.selectedType = ""
                _state.payableCtrType = ""

            }
            //update status on contractor change
            // if (state.status === 1) {
            //     _state.status = 2
            // }
            setState(_state)
        }

    }, [state.selectedContractor])

    const checkTime = (time) => {
        if (time) {
            let _time = time.split(":");
            return _time[0] + ":" + _time[1];
        }
        return time;
    }

    const handleSubmit = async (e, isValidated) => {
        if (e)
            e.preventDefault();
        // console.log(startTime, endTime)


        let actionAllowed = true;
        let actionMessage = null;
        let _startTime = state.selectedDate + "T" + checkTime(state.startTime);
        let _endTime = state.selectedDate + "T" + checkTime(state.endTime)
        if (!compareTwoTime(state.startTime, state.endTime)) {
            _endTime = dateArithmetic(state.selectedDate, 1) + "T" + checkTime(state.endTime)
        }

        //If both time is on next day in ngt shift
        // if(state.selectedShift?.shift_type_code === "NGT" && compareTwoTime(state.startTime, state.endTime)){
        //     _startTime = dateArithmetic(state.selectedDate, 1) + "T" + checkTime(state.startTime);
        //     _endTime = dateArithmetic(state.selectedDate, 1) + "T" + checkTime(state.endTime)
            
        // }

        // console.log();
        let data = {
            bonusType: parseInt(state.bonusType),
            wasAskedToCreate: state.order,
            createdByPerson: 1,
            approvedByPerson: 1,
            startDate: _startTime,
            endDate: _endTime,
            hasBreak: !state.noBrake,
            isUrgent: state.clientUrgent,
            isUrgentReplacement: state.urgentReplace,
            "billable": !state.noBill,
            "payable": !state.noPay,
            orderedBy: state.orderedBy,
            confirmedBy: state.confirmedBy,
            clientConfirm: state.clientConfirm ? "YES" : "NO",
            ctrAcknowledge: state.ctrAcknowledge ? "YES" : "NO",
            timeSheetVerified: state.timeSheetVerified ? "YES" : "NO",
            stateId: state.stateId,
            payableCtrType: state.payableCtrType

            //filedBy:filled,
            //filedOn:filledOn
        }
        if (state.label && state.label.length) {
            data = {
                ...data,
                label: JSON.stringify(ConvertToArray(state.label)),
            }
        }

        if (state.filled) {
            data = {
                ...data,
                filedBy: state.filled,
            }
        }

        //messages
        if (state.message) {
            data = {
                ...data,
                message: state.message,
            }
        }


        //new order with selected contractor
        if (state.selectedContractor) {
            data = {
                ...data,
                assignedCtr: state.selectedContractor,
            }
        }

        //bonus
        if (parseInt(state.bonusType) === 4) {
            if (parseInt(state.clientBonusType)) {
                data = {
                    ...data,
                    bonusByClientType: parseInt(state.clientBonusType),
                    bonusByClient: parseFloat(state.clientBonusAmount),
                }
            }
            if (parseInt(state.contBonusType)) {
                data = {
                    ...data,
                    bonusToCtr: parseFloat(state.contBonusAmount),
                    bonusToCtrType: parseInt(state.contBonusType),
                }
            }

            data = {
                ...data,
                bonusRatio: state.bonusRatio / 100,
            }

        }

        if(!state.contBonusType){
            data = {
                ...data,
                bonusToCtr: 0,
                bonusToCtrType: 1,
            }
        }

        
        data = {
            ...data,
            state: parseInt(state.status),
            stateId: state.stateId,
        }

        //if bonus times
        if (state.bonusType == 5) {
            data = {
                ...data,
                bonusTimes: state.bonusTimes ? state.bonusTimes.toString() : ""
            }
        }

        //shift
        if (state.shiftId) {
            data = {
                ...data,
                shift: parseInt(state.selectedShift && state.selectedShift.id ? state.selectedShift.id : 0),
                ctrType: state.selectedType,
            }
        }
        else {
            data = {
                ...data,
                shift: parseInt(state.selectedShift && state.selectedShift.id ? state.selectedShift.id : 0),
                count: state.quantity,
                client: state.selectedClient,
                ctrType: state.selectedType,
                isShiftReplacement: false,
                state: state.status,
            }
        }
        //update correct status
        data = {
            ...data,
            state: checkFinalState(state.status, parseInt(state.stateId)),
        }

        // if new order is created and state is 1
        if (parseInt(state.status) === 1 && !state.shiftId) {
            // console.log(status, shiftId)
            delete data.state;
            // console.log(data)
        }

        //if there is no state change only data is changed
        if (state.allOrderData && state.allOrderData.state === state.status) {
            delete data.state;
            delete data.assignedCtr;
        }

        if (state.area) {
            data = {
                ...data,
                area: state.area,
            }
        }

        if ((state.status > 1 && !state.selectedContractor) && !checkAvailable(skipOnUpdate, state.status)) {
            notiflixStatus(false, "Please select contractor to proceed.");
            return false;
        }

        if (isValidated) {
            props.handleSubmitShift(data, state.shiftId, actionAllowed, actionMessage, callOut && replacementsArray.includes(state.stateId), getApiData);
            setConfirmModal(false)
        }
        else {
            let output = await checkContactorIsValid(_startTime, _endTime, state.shiftId)
            if (output) {
                props.handleSubmitShift(data, state.shiftId, actionAllowed, actionMessage, callOut && replacementsArray.includes(state.stateId), getApiData);
            }
        }
    }

    const checkFinalState = (status, stateId) => {
        if (stateId >= 4) {
            return status;
        }
        else if (state.timeSheetVerified) {
            return 4;
        }
        else if (state.ctrAcknowledge) {
            return 3;
        }
        else if (state.clientConfirm) {
            return 2;
        }
        else
            return 1;
    }


    const checkValidateStatus = () => {
        if (state.status) {
            if ([1, 2, 3].includes(parseInt(state.status)) && allOrderData.state != state.status) {
                return false;
            }
        }
        return true;
    }

    const checkContactorIsValid = async (startTime, endTime, shiftId) => {
        notiflixLoaderStart();
        let data = {
            "ctrType": state.selectedType,
            "assignedCtr": state.selectedContractor,
            "startDate": updateDateIfNightShift(state.selectedDate, state.startTime,state.selectedShift,allShifts),
            "endDate": endTime,
            "client": state.selectedClient,
            newState: state.status,
            "shift": parseInt(state.selectedShift && state.selectedShift.id ? state.selectedShift.id : 0),
            breakHour: !state.noBrake
        }
        if (shiftId) {
            data = {
                ...data,
                ignoreOrderIds: [shiftId]
            }
        }
        let res = await postAdminAPI(VALIDATE_CONTRACTOR, data);
        if (res.status) {
            if (res.data?.proceed && !res.data?.message) {
                notiflixLoaderEnd()
                return true;
            }
            else if (res.data?.message) {
                let str = []
                let schText = "scheduled";
                if (res.data.extra?.pastOrders?.length) {
                    let date = dateTimeBasicFormantSplit(res.data.extra.pastOrders[0].start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.pastOrders[0].end_date)
                    schText = (res.data.extra.pastOrders[0].state <= 2) ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText}  at   <strong>{res.data.extra.pastOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.pastOrders[0].created_at)}
                    </>)
                }
                else if (res.data.extra?.futureOrders?.length) {
                    let date = dateTimeBasicFormantSplit(res.data.extra.futureOrders[0].start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.futureOrders[0].end_date)
                    schText = res.data.extra.futureOrders[0].state <= 2 ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText} at   <strong>{res.data.extra.futureOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.futureOrders[0].created_at)}
                    </>)
                }
                else if (res.data.extra?.id) {
                    let date = dateTimeBasicFormantSplit(res.data.extra.start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.end_date)
                    schText = res.data.extra.state <= 2 ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText} at   <strong>{res.data.extra.name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.created_at)}
                    </>)
                }
                else if (res.data.message) {
                    str.push(res.data.message)
                }
                // console.log(str);
                setConfirmModal({
                    proceed: res.data?.proceed,
                    message: res.data?.proceed ? <>
                        {str}. Do you want to proceed?
                    </>
                        : <>
                            You cannot proceed because of {str}
                        </>


                })
            }
        }
        else {
            notiflixStatus(false, res.message)
        }
        notiflixLoaderEnd()
        return false;
    }

    const handleStatus = (e, status) => {
        let _state = { ...state }
        if (state.ctrAcknowledge && state.clientConfirm) {
            if (e.target.checked) {
                _state.status = status
            }
            else {
                _state.status = status - 1;
            }
            setState(_state);
        }
        else {
            notiflixStatus(false, "Please check Contractor Acknowledge and Client Confirmed to verify timesheet.")
        }

    }

    const handleBonusType = (e) => {
        let _state = { ...state }
        console.log(e.target.value);
        _state.bonusType = e.target.value
        if (e.target.value != 4) {
            // setBonus(false);
            _state.clientBonusType = 0
            _state.clientBonusAmount = 0
            _state.contBonusType = 0
            _state.contBonusAmount = 0
            _state.bonusRatio = 0
        }
        else if (e.target.value == 4) {
            _state.bonusRatio = _state.bonusRatio ? _state.bonusRatio : 33.33
        }
        console.log(_state);
        setState(_state);
    }

    const updateClient = async (client) => {
        if (client) {
            let shifts = await postAdminAPI(GET_ALL_SHIFT, { "client": client });
            // console.log(shifts)
            if (shifts.status) {
                setAllShifts(shifts.data)
            }

            let res = await postAdminAPI(GET_AREA, { "clientId": client });
            if (res.status) {
                setAllArea(res.data)
            }

        }

    }

    const handleBillTool = () => {
        // console.log(billingToolOpen)
        setBillingToolOpen(billBreakDown ? !billingToolOpen : false);
    }

    const handleClientTool = async () => {
        setClientToolOpen(!clientToolOpen);
        let result = await postAdminAPI(GET_ONE_CLIENT, { "id": state.selectedClient })
        // console.log(result.data);
        if (result && result.data) {
            setClientTooltip(<Information data={result.data} type={"client"} />)
        }

    }

    const handleContractorTool = async () => {
        if (state.selectedContractor) {
            setContractorToolOpen(!contractorToolOpen);
            let result = await postAdminAPI(GET_ONE_CONTRACTOR, { id: state.selectedContractor })
            // console.log(result)
            if (result.status) {
                setContractorTooltip(<Information data={result.data} type={"contractor"} />)
            }

        }

    }

    const handlePayTool = () => {
        // console.log(payrollToolOpen)
        setPayrollToolOpen(payrollBreakDown ? !payrollToolOpen : false);
    }

    const getRate = (data, allData) => {
        if (data?.length) {
            let r = 0;
            let h = 0;
            data.forEach((d) => {
                if (!d.isBreakHour) {
                    // console.log(calcRate(d), d.hours);
                    r = r + d.finalRate * d.hours;
                    h = h + d.hours
                }

            })
            // console.log(r);
            if (allData.customBonusAmt) {
                r = r + allData.customBonusAmt
            }
            // console.log(r);
            if (allData.otherCharges) {
                allData.otherCharges.map((o) => {
                    r = r + o.other_charges_amt
                })
            }

            // console.log(r);

            return (r / h).toFixed(2);
        }

        return "0";


    }

    const checkStatus = () => {
        return state.stateId
    }

    const generatePayableContType = (selected) => {
        let _row = []
        let order = props.contractorType.filter((f) => f.id == selected)
        if (order.length && order[0].order > 0) {
            props.contractorType.map((type) => {
                if (type.order >= order[0].order)
                    _row.push(<option value={parseInt(type.id)}>{type.code}</option>)
            })
        }
        return _row;

    }

    const checkTimeSheet = () => {
        if (allOrderData?.endDate) {
            if (updateShiftHours(currentDate(), allOrderData.endDate) <= 0) {
                return props.isDisabled ? true : false;
            }
            else
                return false;
        }
    }

    return (
        <>
            <form style={{marginTop:5}} onSubmit={handleSubmit}>
                <div className="general-form">
                    <div className="row row-general">
                        <div className={`col-8`}>
                            <div className="d-flex align-items-center">
                                <label className="staffing-level pr-2">Client</label>
                                <div className="input-group">
                                    <select
                                        style={state.shiftId ? { backgroundColor: "#e0e0e0", minHeight: '29px' } : {minHeight: '29px'}}
                                        className={`form-control form-general`}
                                        value={state.selectedClient ? state.selectedClient : state.selectedClient}
                                        disabled={state.shiftId || props.isDisabled ? true : false}
                                        name="selectedClient"
                                        onChange={(e) => handleChangeData(e)}
                                    >
                                        <option value="">Select client</option>
                                        {props.clients.map((c, i) => {
                                            return <option key={i} value={c.id}>{c.name}</option>;
                                        })}
                                    </select>
                                    <div className="input-group-prepend" href="#" id="clientTooltip">
                                        <div
                                            className="input-group-text"
                                            style={{ backgroundColor: "#36b9cc" }}
                                        >
                                            <span>!</span>
                                        </div>
                                    </div>
                                    <Tooltip placement="top" isOpen={clientToolOpen} target="clientTooltip" toggle={handleClientTool}>
                                        {clientTooltip}
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`billing-div`} href="#" id="billingToolOpenId">
                                <div>Billing Amt: ${allData.invoiceTotal ? parseFloat(allData.invoiceTotal).toFixed(2) : 0}</div>
                                {/* <div>Billing Rate ${allData.invoiceRate ? parseFloat(allData.invoiceRate).toFixed(2) : 0}</div> */}
                                <div>
                                    Billing Rate: ${getRate(allOrderData?.billResult?.intervals, allOrderData?.billResult)}
                                </div>

                            </div>
                            <Tooltip placement="top" isOpen={billingToolOpen} target="billingToolOpenId" toggle={handleBillTool}>
                                {billBreakDown}
                            </Tooltip>
                            {/* <UncontrolledTooltip placement="right" target="billingToolOpenId">
                        Hello world!
                    </UncontrolledTooltip> */}
                        </div>

                    </div>

                    <div className="row row-general mt-2">
                        <div className={`col-8`}>
                            <div className="d-flex align-items-center">
                                <label className="staffing-level pr-2">Contractor</label>
                                <div className="input-group">
                                    <select
                                        name={`selectedContractor`}
                                        style={props.selectedClient ? {minHeight: '29px'} : { backgroundColor: "#e0e0e0", minHeight: '29px' }}
                                        id={`contractors-select`}
                                        className={`form-control form-general`}
                                        disabled={props.isDisabled ? true : (props.selectedClient ? false : true)}
                                        value={state.selectedContractor ? state.selectedContractor : ''}
                                        onChange={(e) => handleChangeData(e)}
                                    >
                                        <option value="">Select contractor</option>
                                        {allContractor.map((c, i) => {
                                            return <option key={i} value={c.id}>{c.name + "(" + c.code + ")"}</option>;
                                        })}
                                    </select>
                                    <div className="input-group-prepend" id="contractorTooltip">
                                        <div
                                            style={{ backgroundColor: "#36b9cc" }}
                                            className="input-group-text"
                                        >
                                            <span>!</span>
                                        </div>
                                    </div>
                                    <Tooltip placement="top" isOpen={contractorToolOpen} target="contractorTooltip" toggle={handleContractorTool}>
                                        {contractorTooltip}
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className={`billing-div`} href="#" id="payrollToolOpenId">
                                <div>Payroll Amt: ${allData.payrollTotal ? parseFloat(allData.payrollTotal).toFixed(2) : 0}</div>
                                {/* <div>Payroll Rate ${allData.payrollRate ? parseFloat(allData.payrollRate).toFixed(2) : 0}</div> */}
                                <div>
                                    Payroll Rate: ${getRate(allOrderData?.payrollResult?.intervals, allOrderData?.payrollResult)}
                                </div>
                            </div>
                            <Tooltip placement="top" isOpen={payrollToolOpen} target="payrollToolOpenId" toggle={handlePayTool}>
                                {payrollBreakDown}
                            </Tooltip>
                        </div>
                    </div>

                    <div className="row row-general ">
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Date</label>
                            <input
                                // style={{ backgroundColor: "#e0e0e0" }}
                                className="form-control form-general"
                                type="date"
                                // disabled
                                name="selectedDate"
                                value={state.selectedDate}
                                onChange={(e) => handleChangeData(e)}
                                disabled={props.isDisabled}
                            />
                        </div>
                        <div className="col-3 staffing-flex">
                            <label className="staffing-level">Shift</label>
                            <select
                                // style={props.selectedClient ? { backgroundColor: "#e0e0e0" } : {}}
                                className="form-control form-general"
                                value={state.selectedShift ? state.selectedShift.id : 0}
                                name="selectedShift"
                                // disabled={props.selectedClient ? true : false}
                                onChange={(e) => onShiftChange(e)}
                                disabled={props.isDisabled}
                            >
                                <option value={0}>Select</option>
                                {allShifts?.map((s) => {
                                    return <option key={s.id} value={s.id}>{s.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className="col-2 staffing-flex">
                            <label className="staffing-level">Pay Start<Star /></label>
                            <input
                                className={`form-control form-general`}
                                type={`time`}
                                value={state.startTime}
                                name="startTime"
                                onChange={(e) => handleChangeData(e)}
                                required
                                disabled={props.isDisabled}
                            />
                        </div>
                        <div className="col-2 staffing-flex">
                            <label className="staffing-level">Pay End<Star /></label>
                            <input
                                className={`form-control form-general`}
                                value={state.endTime}
                                name="endTime"
                                onChange={(e) => handleChangeData(e)}
                                required
                                type={`time`}
                                disabled={props.isDisabled}
                            />
                        </div>
                        {
                            timeDiff && state.shiftId ?
                                <div className="col-2 staffing-flex">
                                    <label className="staffing-level">Payable hour</label>
                                    <span className="timeDiff" style={timeDiff.h > 12  ? {color:"red", fontWeight:"900"} :{}}>{timeDiff.h + "Hrs " + timeDiff.m + "Min"}</span>
                                </div>
                                : null
                        }

                    </div>

                    <div className="row row-general ">
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Type<Star /></label>
                            <select
                                value={state.selectedType ? state.selectedType : ''}
                                onChange={(e) => handleChangeData(e)}
                                required
                                name="selectedType"
                                className={`form-control form-general`}
                                disabled={props.isDisabled}
                            >
                                <option value={""} disabled selected>Select Type</option>
                                {props.contractorType.map((type) => {
                                    return <option value={parseInt(type.id)}>{type.code}</option>;
                                })}
                            </select>

                        </div>
                        <div className={`col-3 staffing-flex`}>

                            <label className="staffing-level">Pay As<Star /></label>
                            <select
                                value={state.payableCtrType ? state.payableCtrType : ''}
                                onChange={(e) => handleChangeData(e)}
                                // required={state.shiftId && state.selectedContractor}
                                required={true}
                                name="payableCtrType"
                                className={`form-control form-general`}
                                disabled={props.isDisabled}
                            >
                                <option value={""} disabled selected>Pay As</option>
                                {generatePayableContType(state.selectedType)}
                            </select>

                        </div>

                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Confirmed With</label>
                            <input
                                id={`Confirmed-With`}
                                type={`text`}
                                placeholder="Confirmed With"
                                className={`form-control form-general`}

                                value={state.confirmedBy}
                                onChange={(e) => handleChangeData(e)}
                                name="confirmedBy"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>

                    <div className="row row-general ">
                        <div className={`col-2 styled-checkbox`}>
                            <input
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="noBrake"
                                checked={state.noBrake ? true : false}
                                id="no-break"
                                type={`checkbox`}
                                disabled={props.isDisabled}
                            />
                            <label htmlFor={`no-break`}>No Break</label>
                        </div>
                        <div className={`col-3 styled-checkbox`}>
                            <input
                                id={`client-confirmed`}
                                type={`checkbox`}
                                name={`clientConfirm`}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                checked={state.clientConfirm ? true : false}
                                disabled={props.isDisabled}

                            />
                            <label htmlFor={`client-confirmed`}>Client Confirmed</label>
                        </div>
                        <div className={`col-3 styled-checkbox`}>
                            <input
                                id={`contractor-acknowledge`}
                                type={`checkbox`}
                                name={`ctrAcknowledge`}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                checked={state.ctrAcknowledge ? true : false}
                                disabled={state.status < 2}
                            />
                            <label htmlFor={`contractor-acknowledge`}>Contractor Acknowledge</label>
                        </div>
                        {/* <div className={`col-3 styled-checkbox`}>
                    <input
                        id={`contracter-acknowledge`}
                        type={`checkbox`}
                        name={`contractorAcknowledge`}
                        onChange={(e) => handleStatus(e, 3)}
                        checked={staffingState(status).contVerify === 1 ? true : false}
                        disabled={status > 3 ? true : false}
                    />
                    <label htmlFor={`contracter-acknowledge`}>Contractor Acknowledge</label>
                </div> */}
                        {/* <div className={`col-3 styled-checkbox`}>
                    <input
                        id={`client-confirmed`}
                        type={`checkbox`}
                        name={`clientConfirmed`}
                        onChange={(e) => handleStatus(e, 2)}
                        checked={staffingState(status).clientVerify === 1 ? true : false}
                        disabled={status > 2 ? true : false}

                    />
                    <label htmlFor={`client-confirmed`}>Client Confirmed</label>
                </div> */}
                        <div className={`col-2 styled-checkbox `}>
                            <input
                                id={`timesheet-checkbox`}
                                name={`timeSheetVerified`}
                                // onChange={(e) => handleStatus(e, 4)}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                checked={state.timeSheetVerified ? true : false}
                                disabled={checkTimeSheet()}
                                type={`checkbox`}
                            />
                            <label htmlFor={`timesheet-checkbox`}>Time Sheet </label>
                        </div>
                    </div>

                    <div className="row row-general ">
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Quantity</label>
                            <input
                                type={`number`}
                                className="form-control form-general"
                                required
                                value={state.quantity}
                                onChange={(e) => handleChangeData(e)}
                                name="quantity"
                                disabled={props.isDisabled}
                            />
                        </div>
                        <div className={`col-2 styled-checkbox`}>
                            <input
                                id={`order-checkbox`}
                                type="checkbox"

                                checked={state.order ? true : false}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="order"
                                disabled={props.isDisabled}
                            />
                            <label htmlFor={`order-checkbox`}>Order</label>
                        </div>
                        {/* <div className={`col-1`}>Ordered By</div> */}
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Ordered By</label>
                            <input
                                id={`ordered-by`}
                                type={`text`}
                                placeholder="Ordered By"
                                className={`form-control form-general`}

                                value={state.orderedBy}
                                onChange={(e) => handleChangeData(e)}
                                name="orderedBy"
                                disabled={props.isDisabled}
                            />
                            {/* <select
                        onChange={(e) => setOrderedBy(e.target.value)}
                        value={orderedBy}
                        className="form-control form-general"
                    >
                        <option value={0}>Ordered By</option>
                        {
                            props.allContacts && props.allContacts.map((a, i) => (
                                <option value={a.cont_id}>{a.cont_name}</option>
                            ))
                        }
                    </select> */}
                        </div>
                        <div className={`col-2 styled-checkbox`}>
                            <input
                                id={`bill-checkbox`}
                                type="checkbox"

                                checked={state.noBill ? true : false}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="noBill"
                                disabled={props.isDisabled}

                            />
                            <label htmlFor={`bill-checkbox`}>Do not bill</label>
                        </div>
                        <div className={`col-2 styled-checkbox`}>
                            <input
                                id={`pay-checkbox`}
                                type="checkbox"

                                checked={state.noPay ? true : false}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="noPay"
                                disabled={props.isDisabled}
                            />
                            <label htmlFor={`pay-checkbox`}>Do not pay</label>
                        </div>
                    </div>

                    <div className="row row-general ">
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Status<Star /></label>
                            <select
                                className="form-control form-general"
                                value={checkStatus()}
                                onChange={(e) => handleChangeData(e)}
                                name="stateId"
                                disabled={props.isDisabled}
                            >
                                {
                                    props.allState?.map((p) => {
                                        if (p.show_in_dash === "YES")
                                            return <option
                                                disabled={state.shiftId ? false : true}
                                                className="others" value={p.id}
                                            >{p.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Bonus Type</label>
                            <select
                                className="form-control form-general"

                                value={state.bonusType}
                                onChange={(e) => handleBonusType(e)}
                                name="bonusType"
                                disabled={props.isDisabled}
                            >
                                <option value={1}>Select Bonus</option>
                                <option value={2}>Time and Half</option>
                                <option value={3}>Double Pay</option>
                                <option value={4}>Custom Bonus</option>
                                <option value={5}>Custom Times</option>
                            </select>
                        </div>
                        {
                            state.bonusType && state.bonusType == 5
                                ?
                                <div className={`col-3 staffing-flex`}>
                                    <label className="staffing-level">Bonus times</label>
                                    <input
                                        type="number"
                                        step={"0.1"}
                                        value={state.bonusTimes}
                                        onChange={(e) => handleChangeData(e)}
                                        name="bonusTimes"
                                        className="form-control form-general"
                                        placeholder="Enter Bonus times"
                                        disabled={props.isDisabled || (state.bonusType && state.bonusType != 5)}
                                    />
                                </div>
                                : null
                        }

                    </div>

                    <div className="row row-general staffing-bonus">
                        <div className={`col-5 staffing-flex`}>
                            <label className="staffing-level">Client Bonus Type</label>
                            <select
                                // style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                disabled={props.isDisabled ? true : (state.bonusType == 4 ? false : true)}
                                value={state.clientBonusType ? state.clientBonusType : 0}
                                onChange={(e) => handleChangeData(e)}
                                name="clientBonusType"

                            >
                                <option value={0} selected disabled>Type</option>
                                <option value={1}>Overall</option>
                                <option value={2}>Hourly</option>
                            </select>
                        </div>
                        <div className={`col-5 staffing-flex`}>
                            <label className="staffing-level">Client Bonus Amount</label>
                            <input
                                // style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                placeholder="Amount"
                                disabled={props.isDisabled ? true : (state.bonusType == 4 ? false : true)}
                                value={state.clientBonusAmount}
                                onChange={(e) => handleChangeData(e)}
                                name="clientBonusAmount"
                                type="number"
                            />
                        </div>
                        <div className={`col-2`}>
                            <label className="staffing-level">Bonus Ratio</label>
                            <input
                                // style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                placeholder="Bonus ration"
                                disabled={props.isDisabled ? true : (state.bonusType == 4 ? false : true)}
                                value={state.bonusRatio}
                                onChange={(e) => handleChangeData(e)}
                                name="bonusRatio"
                                type="number"
                                step={0.01}
                            />
                        </div>
                    </div>
                    <div className="row row-general staffing-bonus">
                        <div className={`col-5 staffing-flex`}>
                            <label className="staffing-level">RNPlus Bonus Type</label>
                            <select
                                className="form-control form-general"
                                disabled={props.isDisabled ? true : (state.bonusType == 4 ? false : true)}
                                value={state.contBonusType}
                                onChange={(e) => handleChangeData(e)}
                                name="contBonusType"
                            >
                                <option value={0} selected disabled>Type</option>
                                <option value={1}>Overall</option>
                                <option value={2}>Hourly</option>
                            </select>
                        </div>
                        <div className={`col-5 staffing-flex`}>
                            <label className="staffing-level">RNPlus Bonus Amount</label>
                            <input
                                // style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                placeholder="Amount"
                                disabled={props.isDisabled ? true : (state.bonusType == 4 ? false : true)}
                                value={state.contBonusAmount}
                                onChange={(e) => handleChangeData(e)}
                                name="contBonusAmount"
                                type="number"
                            />
                        </div>
                        <div className={`col-2`}>
                            <div className={`row row-general`}>
                                {/* <div className={`col-12`}>Invoice no :---</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row row-general">
                        <div className="form-group col-md-3 staffing-flex">
                            <label className="staffing-level">Area</label>
                            <select
                                className={`form-control form-general`}
                                value={state.area}
                                onChange={(e) => handleChangeData(e)}
                                name="area"
                                disabled={props.isDisabled}
                            >
                                <option value="">Select Area</option>
                                {allArea.map((c, i) => {
                                    return <option key={i} value={c.id}>{c.name}</option>;
                                })}
                            </select>
                        </div>
                        <div className={`col-3 styled-checkbox`}>
                            <input
                                id={`client-urgent-check`}
                                type={`checkbox`}

                                checked={state.clientUrgent ? true : false}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="clientUrgent"
                                disabled={props.isDisabled}
                            />
                            <label htmlFor={`client-urgent-check`}>Client urgent</label>

                        </div>
                        <div className={`col-3 styled-checkbox`}>
                            <input
                                id={`client-urgent-replacement`}
                                type={`checkbox`}

                                checked={state.urgentReplace ? true : false}
                                onChange={(e) => handleChangeData(e, "checkbox")}
                                name="urgentReplace"
                                disabled={props.isDisabled}
                            />
                            <label htmlFor={`client-urgent-replacement`}>Urgent replacement</label>
                        </div>
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Filled By</label>
                            <input
                                className="form-control form-general"
                                style={{ width: "100%" }}
                                placeholder="Who filled"
                                type="text"
                                disabled
                                value={state.filled}
                                onChange={(e) => handleChangeData(e)}
                                name="filled"
                            />
                        </div>
                    </div>
                    <div className="row row-general">
                        <div className={`col-5 staffing-flex`}>
                            <label className="staffing-level">Last Modified By</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                type="text"
                                disabled
                                placeholder="Modified by"
                                value={state.modBy}
                            />
                        </div>
                        <div className={`col-4 staffing-flex`}>
                            <label className="staffing-level">Last Modified On</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                type="text"
                                disabled
                                placeholder="Modified on"
                                value={state.modOn ? dateUSStandard(state.modOn) : ""}
                            />
                        </div>
                    </div>
                    <div className="row row-general">
                        <div className={`col-12`} style={{padding:"0px 12px"}}>
                            <label className="staffing-level">Message</label>
                            <input
                                className="form-control form-general"
                                type="text"
                                placeholder="Message"

                                value={state.message}
                                onChange={(e) => handleChangeData(e)}
                                name="message"
                                disabled={props.isDisabled}
                            />
                        </div>
                    </div>
                    <div className="row row-general">
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Sign In</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                disabled
                                placeholder="Signed in"
                                value={state.signIn}
                            />
                        </div>
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Signed in by</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                disabled
                                placeholder="Signed in by"
                                value={state.signInBy}
                            />

                        </div>
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Signed out</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                className="form-control form-general"
                                disabled
                                placeholder="Signed out"
                                value={state.signOut}
                            />

                        </div>
                        <div className={`col-3 staffing-flex`}>
                            <label className="staffing-level">Signed out by</label>
                            <input
                                style={{ backgroundColor: "#eaecf4" }}
                                disabled
                                className="form-control form-general"
                                placeholder="Signed out by"
                                value={state.signOutBy}
                            />

                        </div>
                    </div>
                </div>
                <div className={`row row-general mt-1`}>
                    <div className={`col-6`}>
                        <Button
                            onClick={() => props.toggle()}
                            color="primary">Close Modal</Button>
                    </div>
                    <div className={`col-6 text-right`} style={{ display: 'flex', justifyContent: 'end' }}>
                        {
                            isTriedCallOut && 
                                <div style={{ display: "flex" }}>
                                    <input
                                        id={`callOut-replacement`}
                                        type={`checkbox`}
                                        checked={callOut}
                                        onChange={(e) => setCallOut(!callOut)}
                                        name="callOut"
                                        disabled={props.isDisabled}
                                    />
                                    <label htmlFor={`callOut-replacement`}>Replacement required?</label>
                                </div>
                            
                        }

                        <Button
                            color="danger"
                            disabled={props.isDisabled}
                            title={props.isDisabled ? "You cannot edit this order as it is already billed or invoiced." : ""}
                        >{allOrderData ? "Update Shift" : "Submit New Shift"}
                        </Button>
                    </div>
                </div>
            </form >
            {
                confirmModal
                    ?
                    <ConfirmModal
                        modal={{
                            message: confirmModal.message,
                            proceed: confirmModal.proceed
                        }
                        }
                        title={""}
                        toggle={() => setConfirmModal(null)}
                        click={(e) => handleSubmit(null, true)}
                        message={confirmModal.message}
                        button="Schedule"
                    />
                    : null
            }
        </>
    );
};
export default General;
