import React, { Component } from 'react';
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../../store/actions";
import SettingTable from '../../../component/SettingTable';
import { columns } from "./columns";
import { GET_STATUS, UPDATE_STATUS, DELETE_STATUS } from "../../../APICall/urls/settings";
import { getAdminAPI, postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from "../../../component/Notiflix/notiflix"


let sample = [{
    description:"a",
    code:10,
    color:'#ffffff',
    active:1,
    bill:1,
    pay:1,
    contractor_notify:1,
    client_notify:1,
    id:1,
    is_active:1,
}]

let first = {
    description:"",
    code:'',
    color:'',
    active:1,
    bill:0,
    pay:0,
    contractor_notify:0,
    client_notify:0,
    is_active:1,
}

class ManageStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            rows: [],
            allData:[]
        }
    }
    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Manage Status", this.state.breadcrumbItems);
        this.setState({allData:sample})
        this.updateTable(sample)
    }

    //APIS
    getAllData = async() =>{
        let results = await getAdminAPI(GET_STATUS)
    }

    deleteData = async(p, i) =>{
        let _allData = [...this.state.allData];
        if(p.id){
            // let results = await getAdminAPI(DELETE_STATUS)
        }
        else{
            _allData.splice(i, 1);
            this.setState({allData: _allData})
            this.updateTable(_allData)
        }
    }

    saveData = async() =>{
        console.log(this.state.allData)
        // let results = await getAdminAPI(SAVE_STATUS)
    }

    //ACTIONS
    updateTable = (allData) => {
        let _allData = [];
        console.log(allData)
        allData.map((p, i) => {
                _allData.push({
                    description: <input
                        type="text"
                        className="form-control"
                        value={p.description}
                        onChange={(e) => this.handleChange(e, i, allData, "description")}
                    />,
                    code: <input
                        type="text"
                        disabled={true}
                        className="form-control"
                        value={p.code}
                        onChange={(e) => this.handleChange(e, i, allData, "code")}

                    />,
                    color: <input
                        type="color"
                        className="form-control"
                        value={p.color}
                        onChange={(e) => this.handleChange(e, i, allData, "color")}

                    />,
                    active: <input
                        type="checkbox"
                        className="form-control"
                        value={p.active}
                        checked={p.active == 1 ? true : false}
                        onChange={(e) => this.handleChange(e, i, allData, "active", "checkbox")}

                    />,
                    bill: <input
                        type="checkbox"
                        className="form-control"
                        value={p.bill}
                        checked={p.bill == 1 ? true : false}
                        onChange={(e) => this.handleChange(e, i, allData, "bill", "checkbox")}

                    />,
                    pay: <input
                        type="checkbox"
                        className="form-control"
                        checked={p.pay == 1 ? true : false}
                        onChange={(e) => this.handleChange(e, i, allData, "pay", "checkbox")}

                    />,
                    contractor_notify: <input
                        type="checkbox"
                        className="form-control"
                        checked={p.contractor_notify == 1 ? true : false}
                        onChange={(e) => this.handleChange(e, i, allData, "contractor_notify", "checkbox")}

                    />,
                    client_notify: <input
                        type="checkbox"
                        className="form-control"
                        checked={p.client_notify == 1 ? true : false}
                        onChange={(e) => this.handleChange(e, i, allData, "client_notify", "checkbox")}

                    />,
                    delete: <button
                        className="btn btn-circle1 btn-outline-danger mb-2"
                        title="Delete"
                        onClick={() => this.deleteData(p, i)}
                    >
                        <i className="fas fa-trash"></i>
                    </button>,

                })
        })
        console.log(_allData)
        this.setState({rows:_allData})
    }

    handleChange = (e, i, allData, state, type) => {
        console.log(allData)
        let _allData = [...allData];
        console.log(_allData, _allData[i], i, state)
        if (type === "checkbox") {
            // console.log(e.target.checked)
            if (e.target.checked) {
                _allData[i][state] = 1;
            }
            else {
                _allData[i][state] = 0;
            }
            // console.log(_allShifts[i][state])
        }
        else {
            _allData[i][state] = e.target.value;
        }
        this.setState({rows:_allData})
        this.updateTable(_allData)
        
    }

    addRow = () => {
        let _userData = [...this.state.allData];
        _userData.push({ ...first })
        console.log(_userData)
        this.setState({ allData: _userData })
        this.updateTable(_userData)
    }

    render() {

        const { rows } = this.state;

        return (
            <SettingTable
                rows={rows}
                columns={columns}
                addRow={this.addRow}
                saveTable={this.saveData}
                title={'Manage Status'}
            />
        )
    }
}

export default connect(null, { setBreadcrumbItems })(ManageStatus);

