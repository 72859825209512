import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Form, Button } from "reactstrap";
import { ConvertForSelect, ConvertToArray } from '../../../../Utils/arrayFunction';

import "./Notifications.scss";

import ReactSelect from "../../../../component/ReactSelect";


const Notification = (props) => {

    const [allContractor, setAllContractor] = useState([]);
    const [selectedContractor, setSelectedContractor] = useState([]);
    const [type, setType] = useState(1);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('')

    useEffect(() => {
        console.log(props.contractors)
        let contractors= ConvertForSelect(props.contractors, "name", "id")
        console.log(contractors);
        setAllContractor(contractors)
    },[])

    const handleSubmit = (e) =>{
        e.preventDefault()
        let data = {
            "type": type,
            "title": title,
            "text": message,
            "notifyType":"both",
            "contractors": ConvertToArray(selectedContractor)
        }
        console.log(data)
        props.handleAddNotification(data);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className={`col-md-12 `}>
                        <label>Contractor</label>
                        <ReactSelect
                            value={selectedContractor}
                            onChange={(e) => setSelectedContractor(e)}
                            options={allContractor}
                            isMulti={true}
                            allowSelectAll={true}
                        />
                    </div>
                    <div className={`col-md-12 mt-4`}>
                        <label>Type</label>
                        <select
                            className="form-control"
                            value={type}
                            required
                            onChange={(e) => setType(e.target.value)}
                        >
                            <option value={1}>General</option>
                            <option value={2}>Shift request notification</option>
                        </select>
                    </div>
                    <div className={`col-md-12 mt-4`}>
                        <label>Title</label>
                        <div className="input-group-prepend">
                            <Input 
                                className="form-control" 
                                required
                                type="text"
                                value={title}
                                placeholder="Enter Notification title."
                                onChange={(e) =>setTitle(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={`col-md-12 mt-4`}>
                        <label>Text</label>
                        <div className="input-group-prepend">
                            <Input 
                                className="form-control" 
                                type="textarea" 
                                required
                                placeholder="Enter notification to be Sent"
                                value={message}
                                onChange={(e) =>setMessage(e.target.value)}
                            />
                        </div>
                    </div>
                <div className={`col-md-12 mt-4`}>
                    <Button type="submit" color={`primary`}>Send Notification</Button>
                </div>
                </div>
            </form>
        </>
    );
};
export default Notification;
