import React, {useState, useEffect} from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";

import "./CreateNewModal.scss";
import { TableRow } from "@material-ui/core";
import {  changeTime } from '../../../Utils/dateFunctions';

import {postAdminAPI} from '../../../APICall/index';
import { GET_ALL_CONTRACTOR, GET_ALL_SHIFT, GET_ALL_CLINET, GET_CONTRACTOR_TYPE, CREATE_SHIFT, EDIT_SHIFT, GET_SHIFT_MINI, TOAST_TIME} from '../../../APICall/urls';

const sample = ["GreenLeafNursing", "BriarLeaf", "Allbright"];

const tableHeadData = [
    "Date",
    "Shift",
    "Start time",
    "End time",
    "Cont type",
    "Count",
    "Bonus",
    "Bonus type",
    "Bonus amount",
];

const t = {
    shiftDate: "",
    shift: "",
    startTime: "",
    endTime: "",
    contType: "",
    count: "",
    bonus: 1,
    bonusType: "",
    bonusAmount: "",
}

const CreateNewModal = (props) => {
    const [tableRow, setTableRow] = React.useState([]);
    // console.log(tableRow);
    const [selectedClient, setSelectedClient] = React.useState("");
    const [selectedClientName, setSelectedClientName] = React.useState("");

    const [shifts, setShifts] = useState([]);
    const [contractorTypes, setContractorTypes] = useState([]);



    // console.log(tableRowData);

    useEffect(() =>{
        let selectedClient = parseInt(localStorage.getItem('RNPlusClient'));
        let allClients = JSON.parse(localStorage.getItem('RNPlusAllClient'));
        let client = allClients.filter(a => a.id === parseInt(selectedClient))
        setSelectedClientName(client[0].name);
        setSelectedClient(selectedClient);

        fetchShiftsAndTypes(selectedClient);

    }, [parseInt(localStorage.getItem('RNPlusClient'))])

    const fetchShiftsAndTypes = async (client) =>{
        let shifts  = await postAdminAPI(GET_ALL_SHIFT, {"client": parseInt(client)});
        let contractorType  = await postAdminAPI(GET_CONTRACTOR_TYPE);
        // console.log(shifts.data, contractorType.data);
        setShifts(shifts.data);
        setContractorTypes(contractorType.data);
    }

    const closeCreateOrderModal = () => {
        props.onClose(false);
    };

    const addNewTableRow = () => {
        // let _tempTableRow = [...tableRow];
        let _tempTableRow = [
            ...tableRow,
        ];
        // console.log(_tempTableRow)
        // console.log(t);
        _tempTableRow[_tempTableRow.length] = {...t};
        // console.log(_tempTableRow)
        setTableRow(_tempTableRow);
        
    };


    const handleInputChange = (e, inputType, id) => {
        let _tableRow = [...tableRow];
        // console.log(e, inputType, id)
        _tableRow[id][inputType] = e.target.value;
        // console.log(e.target.value, _tableRow)
        if(inputType === 'shift'){
            let _shift = shifts.filter(s => s.id === parseInt(e.target.value))
            console.log(_shift)

            _tableRow[id].startTime =changeTime( _shift[0].start_time)
            _tableRow[id].endTime =changeTime( _shift[0].end_time)

            // console.log(_tableRow[id])
        }
        
        setTableRow(_tableRow);
    };

    const onSubmitHandler = (e) =>{
        e.preventDefault();
        let data = [];
        tableRow.forEach((t,i) =>{
            let _tempData = {
                "shift": parseInt(t.shift),
                "count": parseInt(t.count),
                "ctrType": parseInt(t.contType),
                "bonusType": t.bonus ? parseInt(t.bonus) : 0,
                "startDate": t.shiftDate+"T"+t.startTime,
                "endDate": t.shiftDate+"T"+t.endTime
            }
            if(t.bonusType){
                _tempData = {
                    ..._tempData,
                    "bonusByClientType": t.bonusType ? parseInt(t.bonusType) : 0,
                }
            }
            if(t.bonusAmount){
                _tempData = {
                    ..._tempData,
                    "bonusByClient": t.bonusAmount ? parseFloat(t.bonusAmount) : 0,
                }
            }
            console.log(t)
            if(t.bonus == 5){
                console.log("bonus")
                _tempData = {
                    ..._tempData,
                    bonusTimes: t.bonusAmount
                }

                delete _tempData.bonusAmount;
                delete _tempData.bonusByClient
            }

            data.push(_tempData)
        })  

        console.log(data);
        // closeCreateOrderModal();
        props.handleBulk({
            client:parseInt(selectedClient),
            orderConfigs:data
        })


    }

    // console.log(tableRow, contractorTypes, t)

    return (
        <Modal size="xl" isOpen={props.isModalOpen} style={{ maxWidth: "1500px" }}>
            <ModalHeader>
                <div className={`create-order-modal-header`}>
                    <span>New Order</span>
                    <CloseIcon
                        onClick={() => closeCreateOrderModal()}
                        className={`create-modal-close-icon`}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <form onSubmit={onSubmitHandler}>
                <div className={`create-order-modal-body`}>
                    <div className={`modal-body-header`}>
                        {/* <select
                            value={selectedClient}
                            onChange={(e) => selectedElementChanged(e)}
                            className={`form-control`}
                        >
                            <option value="">Select Client</option>
                            {sample.map((client, index) => {
                                return (
                                    <option key={index} value={client}>
                                        {client}
                                    </option>
                                );
                            })}
                        </select> */}
                        <input 
                            value={selectedClientName}
                            disabled
                            style={{width:'50%'}}
                            className={`form-control`}
                        />
                        <button
                            className={`btn btn-primary`}
                            onClick={() => addNewTableRow()}
                            type={"button"}
                        >
                            Add
                        </button>
                        <button className={`btn btn-primary`}>Submit</button>
                    </div>
                    <div className={`row`}>
                        <div className={`col-12`}>
                            <table>
                                <thead>
                                    <tr>
                                        {tableHeadData.map((thead) => {
                                            return <th>{thead}</th>;
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRow
                                        .map((tableRowData, id) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <input
                                                            placeholder={`Shift Date`}
                                                            className={`form-control`}
                                                            type={`date`}
                                                            required
                                                            value={tableRowData.shiftDate}
                                                            onChange={(e) =>
                                                                handleInputChange(e, "shiftDate", id)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            value={tableRowData.shift}
                                                            onChange={(e) =>
                                                                handleInputChange(e, "shift", id)
                                                            }
                                                            required
                                                            className={`form-control`}
                                                        >
                                                            <option value={``}>Select Shift</option>
                                                            {shifts && shifts.length > 0 && shifts.map((s,i) =>(
                                                                <option key={i} value={s.id}>{s.name}</option>
                                                            ))}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={`form-control`}
                                                            type={`time`}
                                                            required
                                                            value={tableRowData.startTime}
                                                            onChange={(e) =>
                                                                handleInputChange(e, "startTime", id)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <input
                                                            className={`form-control`}
                                                            type={`time`}
                                                            required
                                                            value={tableRowData.endTime}
                                                            onChange={(e) =>
                                                                handleInputChange(e, "endTime", id)
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            onChange={(e) =>
                                                                handleInputChange(e, "contType", id)
                                                            }
                                                            className={`form-control`}
                                                            value={tableRowData.contType}
                                                            required
                                                        >
                                                            <option value={``}>Select Cont Type</option>
                                                            {
                                                                contractorTypes && contractorTypes.length > 0 && contractorTypes.map((c,i) =>(
                                                                    <option key={i} value={c.id}>{c.code}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            onChange={(e) =>
                                                                handleInputChange(e, "count", id)
                                                            }
                                                            className={`form-control`}
                                                            type={`number`}
                                                            required   
                                                            value={tableRowData.count}
                                                        />
                                                    </td>
                                                    <td>
                                                        <select
                                                            onChange={(e) =>
                                                                handleInputChange(e, "bonus", id)
                                                            }
                                                            className={`form-control`}
                                                            value={tableRowData.bonus}
                                                        >
                                                            <option value={1}>Select Bonus</option>
                                                            <option value={2}>Time and Half</option>
                                                            <option value={3}>Double Pay</option>
                                                            <option value={4}>Custom Bonus</option>
                                                            <option value={5}>Custom Times</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select
                                                            onChange={(e) =>
                                                                handleInputChange(e, 'bonusType', id)
                                                            }
                                                            className={`form-control`}
                                                            disabled={tableRowData.bonus == 4 ? false : true}
                                                            value={tableRowData.bonusType}
                                                        >
                                                            <option value={``}>Select Bonus Type</option>
                                                            <option value={1}>Overall</option>
                                                            <option value={2}>Hourly</option>
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <input
                                                            onChange={(e) =>
                                                                handleInputChange(e, 'bonusAmount', id)
                                                            }
                                                            className={`form-control`}
                                                            type={`number`}
                                                            step={0.1}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                </form>
            </ModalBody>
            <ModalFooter></ModalFooter>
        </Modal>
    );
};

export default CreateNewModal;
