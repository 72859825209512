export const shiftColumns = [
    {
        label: 'Shift Name',
        field: 'sName',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Shift Start Time',
        field: 'sStart',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Shift End Time',
        field: 'sEnd',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Type',
        field: 'type',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Color',
        field: 'color',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Inactive',
        field: 'inactive',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Work Code',
        field: 'wCode',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Select',
        field: 'select',
        sort: 'asc',
        width: 100
    }]
    