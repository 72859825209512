import React, { Component } from "react";
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../../../../store/Breadcrumb/actions";
import Filter from "./Filter";

import { TOGGLE_CLIENT_ORIENTATION, GET_CLIENT_ORIENTATION } from "../../../../../APICall/urls";
import { postAdminAPI } from "../../../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../../../component/Notiflix/notiflix'

class Availability extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            allContractor: [],
            selected: [],
            initialSelected: [],
            selectedClient: 0,
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Contractor DNR", this.state.breadCrumbItems);
        let allContractor = JSON.parse(localStorage.getItem("RNPlusAllContractor"))
        let selectedClient = parseInt(localStorage.getItem("RNPlusClient"))

        if (allContractor) {
            this.setState({ allContractor: allContractor, selectedClient })
        }
        this.getData(allContractor, selectedClient)
    }

    getData = async (allContractor, selectedClient) => {
        let result = await postAdminAPI(GET_CLIENT_ORIENTATION, { client_id: selectedClient })
        if (result.status && result.data) {
            let data = result.data
            let _data = [];
            // console.log(dnr, allClient)
            if (data.length > 0) {
                data.forEach((d) => {
                    let item = allContractor.filter((c) => c.id == d.contractor_id && d.orientation == 1)
                    if (item.length > 0) {
                        _data.push({
                            label: item[0].name,
                            value: item[0].id,
                            id: d.id
                        })
                    }
                })
                console.log(_data)
            }
            this.setState({ selected: _data, initialSelected: _data })
        }
    }
    handleSubmit = async (data) => {
        // // notiflixLoaderStart()
        console.log(data);
        let _finalData = {
            "client_id": this.state.selectedClient,
            added: this.getSelectedData(this.state.initialSelected, data),
            removed: this.getDiffOfJson(this.state.initialSelected, data)
        }
        console.log(_finalData);
        let result = await postAdminAPI(TOGGLE_CLIENT_ORIENTATION, _finalData)

        if (result) {
            notificationStatus(result)
            this.getData(this.state.allContractor, this.state.selectedClient)
        }
    }

    getSelectedData = (initials, newData) => {
        let _new = [];
        newData?.forEach((n) => {
            let id = initials.filter((i) => i.value === n.value)
            if (id.length === 0) {
                _new.push({ contractor_id: n.value })
            }
        })
        return _new;
    }

    getDiffOfJson = (initials, newData) => {
        let diff = [];
        console.log(initials, newData);
        initials?.forEach((i) => {
            let id = newData?.filter((n) => n.value === i.value)
            if (id?.length === 0) {
                diff.push({ contractor_id: i.value, id: i.id })
            }
        })
        return diff;
    }



    render() {
        console.log(this.state.selected)
        return (
            <div>
                <div style={{ paddingLeft: 15, paddingTop: 15 }}>Please select the contractor who are eligible for orientation</div>
                <Filter
                    options={this.state.allContractor}
                    selected={this.state.selected}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        )
    }
};

export default connect(null, { setBreadcrumbItems })(Availability);

