import React, { useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

const OrderFilter = (props) => {
    const [state, setState] = useState({
        name: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault()
        props.saveFilter(state)
    }

    const updateFilter = (e, name) => {
        let _filter = { ...state };
        _filter[name] = e.target.value;
        setState(_filter)
    }

    return (
        
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md={12}>
                    <label>Filter Name</label>
                    <input
                        className="form-control"
                        value={state.name}
                        placeholder='Enter Filter Name'
                        onChange={(e) => updateFilter(e, "name")}
                        required
                    />
                </Col>
            </Row>
            <Row>
                <Col md={12} className="text-center">
                    <button type="submit" className="btn btn-primary">
                        Save Filter
                    </button>
                </Col>
            </Row>
        </form>
    )
}

export default OrderFilter;