import React, { useState } from 'react';
import DocumentComponent from '../../../../component/Documents';
import { notiflixStatus } from '../../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const Documents = (props) => {
    console.log(props)
    const [selected, setSelected] = useState(null)
    const [deleteDoc, setDeleteDoc] = useState(null)

    const handleSelected = (e, id) => {
        console.log(e.target, id)
        if (e.target.checked) {
            setSelected(id)
        }
        else {
            setSelected(null)
        }
    }

    const handleDelete = () => {
        props.deleteDocuments({
            id: selected
        }, () => setSelected(null))
        setDeleteDoc(null)
    }

    const onDeleteBtnClick = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a file to delete")
        }
        else {
            setDeleteDoc(true)
        }
    }
    const handleUpload = (e) => {
        console.log(e.target.files[0])
        props.uploadDocuments(e.target.files[0]);
        setDeleteDoc(null)
    }
    return (
        <>
            <DocumentComponent
                files={props.files}
                selected={selected}
                handleSelected={handleSelected}
                handleDelete={onDeleteBtnClick}
                handleUpload={handleUpload}
            />
            {
                deleteDoc
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this document?",
                                proceed: true
                            }

                        }
                        title="Delete holiday"
                        toggle={() => setDeleteDoc(null)}
                        click={handleDelete}
                    />
                    : null
            }
        </>
    )
}
export default Documents;