import React from 'react';
import CommentsComponent from '../../../component/Comments';

const Comments = (props) => {
    return (
        <CommentsComponent
            addComments={props.addComments}
            comments={props.comments}
        />
    )
}
export default Comments;