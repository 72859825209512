import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RateSetup from "../../../component/RateSetup"
import ModalRateCard from "../../../component/RateSetup/addRateCard";
import { postAdminAPI, getAdminAPI } from '../../../APICall/index';
import { UPDATE_RATES, GET_SHIFTS, GET_STANDARD_RATES, UPDATE_STANDARD_RATES, COPY_BILL_RATE } from '../../../APICall/urls/settings';
import { generateCommonJsonActiveByType, generateObject, ConvertToArray } from '../../../Utils/arrayFunction';

import { setBreadcrumbItems } from '../../../store/Breadcrumb/actions';
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import { notiflixStatus } from '../../../component/Notiflix/notiflix'
import login from "../../../store/auth/login/reducer";
import CopyClient from '../../../component/copyClient'
import { getSelectedRateIds } from '../../../Utils/shifts'

const userType = "contractor"
const methodType = "pay";
const title = "Pay Rate"

// const userType = "client"
// const methodType = "bill";
// const title = "Bill Rate"

const style = {
    fontSize: '1.5rem',
    fontWeight: 600,
    textAlign: 'center',
    paddingBottom: 10
}

const PayRates = () => {
    const dispatch = useDispatch();
    const [breadCrumbItems, setBreadCrumbItem] = useState([
        { title: "Client", link: "#" },
        { title: "PayRates", link: "#" },
    ]);
    const [modal, setModal] = useState(false)
    const [selected, setSelected] = useState(null)

    const [allPayRates, setAllPayRates] = useState([])
    const [allShifts, setAllShifts] = useState([])
    const [addModal, setAddModal] = useState([])

    const [shiftSelected, setShiftSelected] = useState(null)
    const [deleteRate, setDeleteRate] = useState(null)
    const [selectedRate, setSelectedRate] = useState(null)
    const [copyClient, setCopyClinet] = useState(false)
    const [allRates, setAllRates] = useState([])

    useEffect(() => {
        dispatch(setBreadcrumbItems("PayRates", breadCrumbItems));
        getAPI()
    }, []);

    const allReducer = useSelector((s) => {
        return s.BasicDetails;
    })

    useEffect(() => {
        if (allShifts && allShifts.length > 0) {
            getPayRates()
        }
    }, [allShifts])

    const getAPI = async () => {
        setSelected(null)
        await getAllShifts()
    }

    const getAllShifts = async () => {
        let result = await getAdminAPI(GET_SHIFTS)
        if (result) {
            //Get only 8.5 hours shifts
            let _allShifts = result.filter((a) => a.hours < 9);
            _allShifts = generateObject(_allShifts)
            setAllShifts(_allShifts)
        }
    }

    const getPayRates = async () => {
        let result = await postAdminAPI(GET_STANDARD_RATES, { "type": userType })
        if (result.status) {
            let _allRates = generateCommonJsonActiveByType(result.data, allShifts);
            setAllPayRates(_allRates)
        }
    }

    const addRates = () => {
        setModal("default");
        setSelectedRate(null)
        setAddModal(true);
    }


    const customizeRates = () => {
        setModal("multiple");
        setSelectedRate(null)
        setAddModal(true);
    }

    const modifyRates = (s) => {
        setAddModal(false);
        setModal("default");
        setSelectedRate(s)
    }

    const onDeleteBtnClick = (c) => {
        setDeleteRate(c)
    }

    const handleSelected = (id) => {
        setSelected(id ? { ...id } : null)
    }

    const handleShiftSelected = (id) => {
        setShiftSelected(id)
    }

    const handleSubmit = async (data) => {
        if (modal === "default") {
            let futureDate = data.futureDate;
            data = {
                ...data,
                shift_type: data.shift[0],
                contractor_type: data.ctrType[0],
            }
            if (selectedRate?.id) {
                data.id = selectedRate.id;
            }
            delete data.active;
            delete data.ctrType;
            delete data.description;
            delete data.futureDate;
            delete data.shift;
            if(futureDate){
                data.future_date = futureDate
            }
            let _post = {
                type: userType,
                data: [data]
            }
            let result = await postAdminAPI(UPDATE_STANDARD_RATES, _post)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    // getAPI()
                    // setModal(false);
                    // setAddModal(false);
                    // setSelected(null)
                    window.location.reload();
                }
            }

        }
        else {
            data = {
                ...data,
                "type": methodType,
                client_id: ConvertToArray(data.client_id),
                contractor_type: data.ctrType[0],
            }
            delete data.shift;
            delete data.ctrType;
            let result = await postAdminAPI(UPDATE_RATES, data)
            if (result) {
                if (result.status) {
                    notificationStatus({ status: true, message: "We have updated the new rates for all the mentioned client." })
                    getAPI()
                    setModal(false);
                    setAddModal(false);
                    setSelected(null)
                }
                else {
                    notificationStatus(result)
                }
            }
        }
    }

    const handleSubmitCopy = async (clientIds, futureDate) => {
        let ids = getSelectedRateIds(allRates)
        if (ids.length) {
            let data = {
                "ids": [...ids],
                "clientIds": [...clientIds],
                tableName: "contractor_standard_pay_rate",
                rateType: "pay",
            }
            notiflixLoaderStart()
            let res = await postAdminAPI(COPY_BILL_RATE, data)
            if (res.status) {
                setCopyClinet(false)
                notificationStatus({ status: true, message: "Success" })
            }
            else
                notificationStatus({ status: false, message: "Failure" })
        }
    }

    console.log(selected);
    return (
        <div>
            <div style={{ ...style }}>{title}</div>
            <RateSetup
                addRates={addRates}
                modifyRates={modifyRates}
                deleteRates={onDeleteBtnClick}
                selected={selected}
                handleSelected={handleSelected}
                handleShiftSelected={handleShiftSelected}
                shiftSelected={shiftSelected}
                allRates={allPayRates}
                id="ctr_type"
                customizeRates={customizeRates}
                isMultiClient="true"
                hideDelete={true}
                copyClent={() => setCopyClinet(true)}
                setAllRates={setAllRates}
            />
            {
                modal
                    ?
                    <ModalRateCard
                        isOpen={modal}
                        handleClose={() => setModal(false)}
                        selected={{
                            ...selectedRate,
                            ctr_type: selectedRate?.contractor_type,
                        }}
                        handleSubmit={handleSubmit}
                        contType={allReducer.allUniqueContractorTypes}
                        shiftSelected={shiftSelected}
                        isMultiClient={modal == "default" ? false : true}
                        isSingleType={true}
                        allClients={allReducer.allClients?.map((s) => {
                            return {
                                label: s.name,
                                value: s.id
                            }
                        })}
                        allShiftTypes={allReducer.allShiftTypes}
                    />
                    : null
            }
            {
                copyClient && <CopyClient
                    handleClose={() => setCopyClinet(false)}
                    title="Copy Pay Rare"
                    isDateRequired={true}
                    handleSubmit={handleSubmitCopy}
                />
            }
        </div>
    );
};
export default PayRates;
