import React from 'react'
const NumberSummaryClient = (props) => {
    return (
        <div className="card">
            <div className="row">
                <div className="col-lg-4 mt-3">
                    <div className="p-3">
                        <h5 className="font-medium text-inverse text-center" id="shift_3">{props.data.total}</h5>
                        <h6 className="card-subtitle text-center">TOTAL REGISTERED</h6>
                    </div>
                </div>
                <div className="col-lg-4 mt-3 border-left">
                    <div className="p-3">
                        <h5 className="font-medium text-inverse text-center" id="shift_4">{props.data.clientsWithRequest}</h5>
                        <h6 className="card-subtitle text-center">CLIENTS WITH SHIFT REQUEST</h6>
                    </div>
                </div>

                {/* <div className="col-lg-4 border-left mt-3">
                    <div className="p-3">
                        <h5 className="font-medium text-inverse text-center" id="shift_5">{props.data.clientsWithRequest}</h5>
                        <h6 className="card-subtitle text-center">FREQUENT CLIENTS</h6>
                    </div>
                </div> */}

                <div className="col-lg-4 mt-3 border-left">
                    <div className="p-3">
                        <h5 className="font-medium text-inverse text-center" id="shift_6">{props.data.pendingInvoices}</h5>
                        <h6 className="card-subtitle text-center">PENDING INVOICES</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NumberSummaryClient;