import React, {useState, useEffect} from 'react';
import { Row, Col, Card, CardBody, CardHeader} from 'reactstrap';
import Required from '../../../component/Required/star';
import DatePicker from '../../../component/datePicker/datePicker';

import { postAdminAPI } from '../../../APICall/index';
import { GET_ALL_CLIENT } from '../../../APICall/urls';


const SearchInvoice = ({allClients, ...props}) =>{

    const [client, setClient] = useState(0);
    const [first, setFirst] = useState(null);
    const [last, setLast] = useState(null);
    const [invoice, setInvoice] = useState(null);

    const handleSubmit = (e) =>{
        e.preventDefault();
        let _client = [];
        if(client == 0){
            allClients.forEach(c =>{
                _client.push((parseInt(c.id)));
            })
        }
        else{
            _client.push(parseInt(client))
        }

        let data = {
            client:_client,
            startDate:first,
            endDate:last,
            invoice:invoice
        };
        props.handleFilter(data);
    }

    const handleDateChange = (name, date) => {
        // console.log(name, date);
        if (name === "first") {
            setFirst(date);
        }
        else if (name === "last") {
            setLast(date);
        }
        else if (name === "invoice") {
            setInvoice(date)
        }

    }

    return (
        
           <Card style={{marginBottom:80}}>
                <CardHeader>
                    Generate new invoice
                </CardHeader>
                <CardBody className="overflow-auto">
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6}>
                                <label>Invoice Date<Required /></label>
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    required
                                    value={invoice}
                                    onChange={(e) => setInvoice(e.target.value)}
                                /> */}
                                <DatePicker
                                    date={invoice}
                                    name={"invoice"}
                                    required={true}
                                    handleDateChange={handleDateChange}

                                />
                            </Col>
                            <Col md={6}>
                                <label>Client for which to Generate Invoices<Required /></label>
                                <select 
                                    className="form-control"
                                    required
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <option value={0} className="allOptions">All Clients</option>
                                    {
                                        allClients && allClients.map((a,i) =>(
                                            <option key={i} value={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label>First Shift Date to Include on Invoice<Required /></label>
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    required
                                    value={first}
                                    onChange={(e) => setFirst(e.target.value)}
                                /> */}
                                <DatePicker
                                    date={first}
                                    name={"first"}
                                    required={true}
                                    handleDateChange={handleDateChange}

                                />
                            </Col>
                            <Col md={4}>
                                <label>Last Shift Date to Include on Invoice<Required /></label>
                                <DatePicker
                                    date={last}
                                    name={"last"}
                                    required={true}
                                    handleDateChange={handleDateChange}

                                />
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    required
                                    value={last}
                                    onChange={(e) => setLast(e.target.value)}
                                /> */}
                            </Col>
                            <Col md={4}>
                                <label>Starting Invoice Number</label>
                                <input
                                    className="form-control"
                                    disabled={props.selected.size === 1 ? false : true}
                                    value={props.invNumber}
                                    onChange={(e) => props.setInvNumber(e.target.value)}
                                />
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    required
                                    value={last}
                                    onChange={(e) => setLast(e.target.value)}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className="text-center">
                                <hr />
                                <button type="submit" className="btn btn-success">
                                    Fetch List
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        
    )
}

export default SearchInvoice;