import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';

import { PAYROLL_REPORT } from "../../../../APICall/urls/report";
import { postAdminAPI } from "../../../../APICall/index";

import Search from '../filter/payrollFilter';
import {columns} from '../allList/payroll';
import EmptyComponent from '../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../store/actions";

class PayrollReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: null,
        }
    }

    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Reports", this.state.breadcrumbItems);
    }

    // Filter
    handleFilter = async(filterData) => {
        console.log(filterData);
        // this.fetchAllData();
        let results = await postAdminAPI(PAYROLL_REPORT, filterData)
        console.log(results);
        if(results.status){
            this.setState({allData: results.data})
        }

    }

    render() {

        const { allData } = this.state;

        return (
            <>
                <Search
                    handleFilter={this.handleFilter}
                />
                {
                    allData
                    ?
                        allData.length > 0
                        ?
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    striped
                                    hover
                                    bordered
                                    data={{ columns: columns, rows: allData }}
                                />
                            </CardBody>
                        </Card>
                        : <EmptyComponent />
                    : null

                }

            </>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(PayrollReport);;