import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import ReactSelect from '../ReactSelect';
import { ConvertForSelect, ConvertToArray } from "../../Utils/arrayFunction"

const size = [0, 1, 2, 3, 4, 5, 6];

const AddRateCard = (props) => {

    const [selected, setSelected] = useState({
        "ctrType": [],
        "d0": '',
        "d1": '',
        "d2": '',
        "d3": '',
        "d4": '',
        "d5": '',
        "d6": '',
        "description": '',
        futureDate: ""
    })

    const [allCont, setAllCont] = useState([])
    const [shift, setShift] = useState('')

    useEffect(() => {
        let _data;
        if (props.contType) {
            _data = ConvertForSelect(props.contType, 'code', 'id');
            setAllCont(_data)
        }
        if (props.selected) {
            let _selected = _data.filter(d => d.value === props.selected.ctr_type)
            let selectedData = {
                ...selected,
                "ctrType": _selected,
                "d0": props.selected.d0,
                "d1": props.selected.d1,
                "d2": props.selected.d2,
                "d3": props.selected.d3,
                "d4": props.selected.d4,
                "d5": props.selected.d5,
                "d6": props.selected.d6,
                "description": props.selected.description,
                futureDate: props.selected.future_date ? props.selected.future_date.split(" ")[0] : "",
            }
            setSelected(selectedData)
        }
        if (props.shiftSelected) {
            setShift(props.shiftSelected)
        }

    }, [])


    const handleChange = (e, type) => {
        let _selected = { ...selected }
        _selected[type] = e.target.value;
        setSelected(_selected)
    }

    const handleChangeMultiple = (e, type) => {
        let _selected = { ...selected }
        _selected[type] = e;
        setSelected(_selected)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        if (selected.ctrType) {
            let ctrType = ConvertToArray(selected.ctrType)
            let _selected = { ...selected }
            _selected.ctrType = ctrType;
            _selected.shift = [shift];
            if (!_selected.futureDate) {
                delete _selected.futureDate;
                delete _selected.active
            }
            else {
                _selected.active = 0;
                _selected.futureDate = _selected.futureDate + " 00:00:00";
            }
            props.handleSubmit(_selected)
        }

    }

    return (
        <Modal size="lg" isOpen={true}>
            <ModalHeader>
                Add/Update rate
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    {props.isMultiClient &&
                        <>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="desc_id">Client</label>
                                    <ReactSelect
                                        options={props.allClients}
                                        onChange={(e) => handleChangeMultiple(e, "client_id")}
                                        value={selected.client_id}
                                        allowSelectAll={true}
                                        isMulti={true}
                                        noMin={true}
                                    /></div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="desc_id">Shift Type</label>
                                    <select
                                        name={`type`}
                                        required
                                        className={`form-control`}
                                        value={selected.shift_type}
                                        onChange={(e) => handleChange(e, "shift_type")}
                                    >
                                        <option value="">Select type</option>
                                        {
                                            props.allShiftTypes.map((s) => (
                                                <option value={s.id}>{s.code}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </>
                    }

                    <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="desc_id">Contractor Type</label>
                            <ReactSelect
                                options={allCont}
                                onChange={(e) => handleChangeMultiple(e, 'ctrType')}
                                value={selected.ctrType}
                                allowSelectAll={true}
                                isMulti={props.isSingleType ? false : true}
                                noMin={true}
                            />

                        </div>
                    </div>
                    {!props.isSingleType && <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="desc_id">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                name="desc_name"
                                id="desc_id"
                                placeholder="Name"
                                required
                                value={selected.description}
                                onChange={(e) => handleChange(e, "description")}
                            />
                        </div>
                    </div>}
                    <div className="row">
                        <table className="table table-bordered" >
                            <thead>
                                <tr>
                                    <th>Monday</th>
                                    <th>Tuesday</th>
                                    <th>Wednesday</th>
                                    <th>Thursday</th>
                                    <th>Friday</th>
                                    <th>Saturday</th>
                                    <th>Sunday</th>
                                </tr>
                            </thead>
                            <tbody id="rates_body">
                                <tr>
                                    {
                                        size.map((s, i) => (
                                            <td key={i}>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    step={0.00001}
                                                    required
                                                    value={selected["d" + s]}
                                                    onChange={(e) => handleChange(e, "d" + s)}
                                                />
                                            </td>
                                        ))
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {!props.hideFutureData && <div className="row">
                        <div className="form-group col-md-12">
                            <label htmlFor="desc_id">Date from when the rate become active</label>
                            <input
                                type="date"
                                className="form-control"
                                name="futureDate"
                                id="futureDate"
                                placeholder="Name"
                                required={!props.futureNotRequired}
                                value={selected.futureDate}
                                onChange={(e) => handleChange(e, "futureDate")}
                            />
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddRateCard;