import React, { useState } from 'react';
import DocumentComponent from '../../../component/Documents';
import { notiflixStatus } from '../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../component/confirm/confirmForOrder";

const Documents = (props) => {
    console.log(props)
    const [selected, setSelected] = useState(null)
    const [deleteDoc, setDeleteDoc] = useState(null)

    // const handleSelected = (e, id) => {
    //     let __selected = [...selected]
    //     __selected = new Set(__selected)
    //     if (e.target.checked) {
    //         __selected.add(id)
    //     }
    //     else {
    //         __selected.delete(id)
    //     }
    //     setSelected(__selected)
    // }

    const handleSelected = (e, id) => {
        if (e.target.checked) {
            setSelected(id)
        }
        else {
            setSelected(null)
        }
    }



    const handleDelete = () => {
        props.deleteDocuments({
            id: selected,

        })
    }
    const onDeleteBtnClick = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a file to delete")
        }
        else {
            setDeleteDoc(true)
        }
    }
    const handleUpload = (e) => {
        console.log(e.target.files[0])
        props.uploadDocuments(e.target.files[0]);
        setDeleteDoc(null)
    }
    return (
        <>
            <DocumentComponent
                files={props.allFiles}
                selected={selected}
                handleSelected={handleSelected}
                handleDelete={onDeleteBtnClick}
                handleUpload={handleUpload}
            />
            {
                deleteDoc
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this document?",
                                proceed: true
                            }

                        }
                        title="Delete holiday"
                        toggle={() => setDeleteDoc(null)}
                        click={handleDelete}
                    />
                    : null
            }
        </>
    )
}
export default Documents;