import React, { Component } from 'react';
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { connect } from "react-redux";

import Search from './filter';
import Table from '../../../component/Table/index';
import EmptyComponent from '../../../component/EmptyData';
import AddTimeSlip from "./addTimeSlip";

import { setBreadcrumbItems } from "../../../store/actions";
import { GET_TIMESLIP, ADD_TIMESLIP, INVOICE_TIMESLIP, EDIT_TIMESLIP, TOGGLE_TIMESLIP } from "../../../APICall/urls/invoicePayroll";
import { GET_CONTRACTOR_TYPE } from "../../../APICall/urls"
import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../component/Notiflix/notiflix';
import { dateTimeBasicFormant, dateUSStandard, timeFormat } from '../../../Utils/dateFunctions';
import { columnsInvoiced } from "./columnsInvoiced";
import Sort from "./sort";
import ImageModal from "../../../component/modal/imageModal"
import ConfirmModal from "../../../component/confirm";


// const tabTag = ["Timeslip", "Invoiced"];
const tabTag = ["Received"];

class DirectPayroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [],
            allContractor: [],
            allClients: [],
            allTypes: [],
            apiData: [],
            isEmpty: false,
            filterData: {},
            columns: [
                {
                    label: 'Shift Date',
                    field: 'shift_date',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Shift Name',
                    field: 'shift_name',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Contractor',
                    field: 'contractorName',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Facility',
                    field: 'clientName',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Start Time',
                    field: 'startDate',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'End Time',
                    field: 'endDate',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Type',
                    field: 'contractorType',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'T Hrs',
                    field: 't_hrs',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Employee Id',
                    field: 'employee_id',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Supervisor',
                    field: 'supervisor',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Supr. Signature',
                    field: 'signature',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: <input
                        type="checkbox"
                        checked={this.checkSelectAll()}
                        onChange={(e) => this.updateSelectAll(e)}
                    />,
                    field: 'select',
                    sort: 'disabled',
                    width: 150,
                },
                {
                    label: '',
                    field: 'action',
                    sort: 'disabled',
                    width: 150
                },
            ],
            sort: [
                {
                    "startDate": "asc"
                },
                {
                    "contractorType": "asc"
                },
                {
                    "shiftType": "asc"
                }
            ],
            selectedItem: new Set(),
            activeTab: "Received",
            addTimeSlip: false,

            //invoiced
            allInvoicedData: [],
            allInvoicedAPI: [],
            imageModal: false,
            contractorSignature: '',

            editItem: null,
            deleteItem: null,
            deleteModal: false,
        }
    }

    checkSelectAll = () => {
        if (this.state)
            // console.log(this.state.apiData.length, this.state.selectedItem.size);

            if (this.state && this.state.apiData && this.state.apiData.length === this.state.selectedItem.size && this.state.apiData.length > 0) {
                return true;
            }
            else {
                return false;
            }

    }

    updateSelectAll = (e) => {
        let _selected = new Set();
        if (e.target.checked) {

            this.state.apiData && this.state.apiData.map((a) => {
                _selected.add(a.id)
            })
            // console.log(_selected)
            this.setState({ selectedItem: _selected })
        }
        else {
            this.setState({ selectedItem: new Set() })
        }
        this.fetchAllData(this.state.apiData, _selected)
    }

    updateColumn = () => {
        let { columns } = this.state;
        // console.log(columns[11])
        columns[11].label = (
            <>
                <input
                    type="checkbox"
                    checked={this.checkSelectAll()}
                    onChange={(e) => this.updateSelectAll(e)}
                />
            </>

        );
        this.setState({ columns: columns });
    }


    componentDidUpdate(prevProps, prevState) {

        if (prevState.selectedItem != this.state.selectedItem) {
            // console.log(this.state.selectedItem)
            this.updateColumn()
        }
    }
    // Basic
    componentDidMount() {
        this.handleFilter({}, this.state.sort)
        this.getBasicInfo();
    }

    getBasicInfo = async () => {
        this.props.setBreadcrumbItems("Timeslip", this.state.breadcrumbItems);
        let allContractor = JSON.parse(localStorage.getItem("RNPlusAllContractor"))
        let allClients = JSON.parse(localStorage.getItem("RNPlusAllClient"));
        let allTypes = await postAdminAPI(GET_CONTRACTOR_TYPE, {})
        let _allTypes = [];
        if (allTypes && allTypes.status) {
            _allTypes = allTypes.data;
        }
        // console.log(allContractor, allClients)
        this.setState({ allContractor: allContractor, allClients: allClients, allTypes: _allTypes })
    }

    // Filter
    handleFilter = async (filterData, sort) => {
        filterData = filterData ? filterData : this.state.filterData;
        sort = sort ? sort : this.state.sort;
        this.setState({ filterData: filterData, sort: sort })

        let filter = {
            "filter": filterData,
            "sort": sort,
        }
        // console.log(filter)
        let result = await postAdminAPI(GET_TIMESLIP, filter)
        // console.log(result)
        if (result.status) {
            if (result.data.timeslip && result.data.timeslip.length > 0) {

                let _selected = new Set();
                result.data.timeslip && result.data.timeslip.map((t) => {
                    _selected.add(t.id)
                })
                this.fetchAllData(result.data.timeslip, _selected);
                this.setState({ apiData: result.data.timeslip, selectedItem: _selected })
            }
            else {
                this.fetchAllData([], new Set());
                this.setState({ apiData: [] })
            }

            if (result.data.invoiced) {
                this.fetchAllDataInvoiced(result.data.invoiced)
                this.setState({ allInvoicedAPI: result.data.invoiced })
            }
            else {
                this.fetchAllDataInvoiced([], []);
                this.setState({ allInvoicedAPI: [] })
            }
        }

    }

    handleCheckbox = (e, a) => {

        let _selected = [...this.state.selectedItem];

        // console.log(e, a)
        if (e.target.checked) {
            _selected.push(a.id)
        }
        else {
            let index = _selected.indexOf(a.id)
            _selected.splice(index, 1)

        }
        // console.log(_selected)
        var _selectedSet = new Set(_selected);
        this.setState({ selectedItem: _selectedSet })
        this.fetchAllData(this.state.apiData, _selectedSet)
    }

    handleDeleteClick = (a) => {
        this.setState({
            deleteItem: a,
            deleteModal: true,
        })
    }

    handleDelete = async () => {
        console.log(this.state.deleteItem)
        if (this.state.deleteItem) {
            notiflixLoaderStart();
            let data = {
                "timeslipId": this.state.deleteItem.id,
                "status": "deleted"
            }
            console.log(data)
            let result = await postAdminAPI(TOGGLE_TIMESLIP, data)
            console.log(result)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    this.setState({
                        deleteItem: null,
                        deleteModal: false,
                    })
                    this.handleFilter(this.state.filterData, this.state.sort)
                }
            }
        }
        else {
            notiflixStatus(false, "Please select at least one item to continue.")
        }

    }

    // AllNewData
    fetchAllData = (apiData, selected) => {
        let _allData = []
        apiData && apiData.length > 0 && apiData.map(a => {
            let start = a.startDate.split(" ");
            let end = a.endDate.split(" ");
            // console.log(a)
            // console.log(a.moreInfo ? "amrit" : "anand")
            _allData.push({
                shift_date: dateUSStandard(start[0]),
                shift_name: a.shiftType,
                contractorName: a.fName + " " + a.lName,
                clientName: a.name,
                startDate: timeFormat(start[1]),
                endDate: timeFormat(end[1]),
                contractorType: a.code,
                t_hrs: a.totalHours,
                employee_id: a.employeeId,
                supervisor: a.supervisorName,
                signature: a.moreInfo ? a.moreInfo : <img style={{ height: 40 }} src={"data:image/png;base64, " + a.supervisorSignature} alt="" />,
                sort: <input
                    type="checkbox"
                    checked={(selected && selected.has(a.id)) ? true : false}
                    onChange={(e) => this.handleCheckbox(e, a)}
                />,
                action: <>


                    {
                        this.props.login?.user?.super === "YES"
                            ?
                            <>
                                <i
                                    onClick={() => this.handleEdit(a)}
                                    style={{ cursor: "pointer", fontSize: 18, fontWeight: "bold", color: "blue" }} title="Edit Timeslip" className="mdi mdi-pencil"
                                ></i>
                                &nbsp;&nbsp;&nbsp;
                                <i
                                    onClick={() => this.handleDeleteClick(a)}
                                    style={{ cursor: "pointer", fontSize: 18, fontWeight: "bold", color: "red" }} title="Delete Timeslip" className="mdi mdi-delete"
                                ></i>

                            </>
                            : null

                    }
                    <i
                        onClick={() => this.handleImageModal(a)}
                        style={{ cursor: "pointer", fontSize: 18, fontWeight: "bold", color: "blue" }} title="Contractor Signature" className="mdi mdi-image"
                    ></i>

                </>
                ,
            })
        })
        this.setState({ allData: _allData })
    }

    handleEdit = (a) => {
        this.setState({ addTimeSlip: true, editItem: a })
    }

    handleImageModal = (a) => {
        this.setState({ imageModal: true, contractorSignature: a.contractorSignature })
    }

    fetchAllDataInvoiced = (apiData) => {
        let _allData = []
        apiData && apiData.length > 0 && apiData.map(a => {
            let start = a.startDate.split(" ");
            let end = a.endDate.split(" ");
            _allData.push({
                shift_date: dateUSStandard(start[0]),
                shift_name: a.shiftType,
                contractorName: a.fName + " " + a.lName,
                clientName: a.name,
                startDate: timeFormat(start[1]),
                endDate: timeFormat(end[1]),
                contractorType: a.code,
                t_hrs: a.totalHours,
                employee_id: a.employeeId,
                supervisor: a.supervisorName,
                signature: a.moreInfo ? a.moreInfo : <img style={{ height: 40 }} src={"data:image/png;base64, " + a.supervisorSignature} alt="" />,
                paidDate: dateTimeBasicFormant(a.invoicedAt)
            })
        })
        this.setState({ allInvoicedData: _allData })
    }

    handleGeneratePayroll = async () => {

        const { selectedItem } = this.state;
        if (selectedItem && selectedItem.size > 0) {
            notiflixLoaderStart();
            let data = {
                "timeslips": [...this.state.selectedItem],
            }
            // console.log(data)
            let result = await postAdminAPI(INVOICE_TIMESLIP, data)
            // console.log(result)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    this.handleFilter(this.state.filterData, this.state.sort)
                }
            }
        }
        else {
            notiflixStatus(false, "Please select at least one item to continue.")
        }
    }

    addNewTimeSlip = async (state) => {
        let result = await postAdminAPI(ADD_TIMESLIP, state)
        // console.log(result)
        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.setState({ addTimeSlip: false })
                this.handleFilter(this.state.filterData, this.state.sort)
            }
        }
    }
    editTimeSlip = async (state) => {
        let result = await postAdminAPI(EDIT_TIMESLIP, state)
        // console.log(result)
        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.setState({ addTimeSlip: false, editItem: null })
                this.handleFilter(this.state.filterData, this.state.sort)
            }
        }
    }

    toggle = (tab) => {
        return this.state.activeTab !== tab ? this.setState({ activeTab: tab }) : "";
    };

    handleSortSubmit = (sort) => {
        this.setState({ sort: sort })
        this.handleFilter(this.state.filterData, sort)
    }

    handleFilterData = (filterData) => {
        this.setState({ filterData: filterData, selectedItem: new Set() })
        this.handleFilter(filterData, this.state.sort)
    }

    render() {

        const { allData, allClients, allContractor, isEmpty, allTypes, activeTab, allInvoicedData, addTimeSlip, columns, imageModal, contractorSignature, editItem, deleteModal } = this.state;

        // console.log(this.props.login.user.super)

        return (
            <>
                <Search
                    handleFilterData={this.handleFilterData}
                    allClients={allClients}
                    allContractor={allContractor}
                    allTypes={allTypes}
                    openAdd={() => { this.setState({ addTimeSlip: true, editItem: null }) }}
                    title={"Timeslip Management"}
                />
                <div className={`card`} style={{ marginTop: -180 }}>
                    <Sort
                        handleSubmit={this.handleSortSubmit}
                    />

                    <Nav tabs>
                        {
                            tabTag.map((t, i) => (
                                <NavItem>
                                    <NavLink
                                        key={i}
                                        onClick={() => this.toggle(t)}
                                        className={classnames({
                                            active: activeTab === t,
                                        })}
                                    >
                                        {t}
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={activeTab}>
                            {
                                activeTab === "Received"
                                    ?
                                    <>
                                        {
                                            allData && allData.length > 0
                                                ?
                                                <>

                                                    <Table
                                                        rows={allData}
                                                        columns={columns}
                                                        handleFilter={() => { }}
                                                        handleGeneratePayroll={this.handleGeneratePayroll}
                                                        isSubmit={true}
                                                        title={"Process timeslip"}
                                                        content={"Do you want to process these timeslip?"}
                                                    />
                                                </>
                                                : <EmptyComponent
                                                    className="mt-4 ml-1 mr-1"
                                                />

                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            allInvoicedData && allInvoicedData.length > 0
                                                ?
                                                <Table
                                                    rows={allInvoicedData}
                                                    columns={columnsInvoiced}
                                                    handleFilter={() => { }}
                                                    isSubmit={false}
                                                />
                                                : <EmptyComponent
                                                    className="mt-4 ml-1 mr-1"
                                                />

                                        }
                                    </>
                            }
                        </TabPane>
                    </TabContent>
                </div>
                {
                    addTimeSlip
                        ?
                        <AddTimeSlip
                            isOpen={addTimeSlip}
                            handleClose={() => { this.setState({ addTimeSlip: false }) }}
                            allClients={allClients}
                            allContractor={allContractor}
                            addNewTimeSlip={this.addNewTimeSlip}
                            editTimeSlip={this.editTimeSlip}
                            editItem={editItem}
                        />
                        : null
                }
                {
                    imageModal
                        ?

                        <ImageModal
                            isOpen={imageModal}
                            handleClose={() => this.setState({ imageModal: false })}
                            image={contractorSignature}
                            base64={true}
                            title={'Contractor Signature'}
                        />

                        : null
                }
                {
                    deleteModal
                        ?
                        <ConfirmModal
                            modal={deleteModal}
                            title="Delete Timeslip"
                            content="Do you want to delete this Timeslip."
                            toggle={() => this.setState({ deleteModal: false })}
                            click={() => this.handleDelete()}
                        />
                        : null
                }


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.Login
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(DirectPayroll);;