import React from 'react';
import { timeFormat } from "../../../Utils/dateFunctions";
const StaffingRowHeader = (props) =>{
    return(
        <h5 style={{textAlign: 'center', color: 'white', fontSize:20, marginBottom:'0rem'}}>
            {"("+props.title+") "+ timeFormat(props.startTime) +" to "+ timeFormat(props.endTime)}
        </h5>
    )
}


export default StaffingRowHeader;