import React, { useEffect } from 'react';
import './payrollStyle.css';
import { capitalizeFirstLetter } from "../../../Utils/stringManupulation";
import { dateTimeFormatFull, changeTzToNormal, dateUSStandard } from "../../../Utils/dateFunctions"
const PayrollTable = (props) => {

    useEffect(() => {
        console.log(props.allData)
    }, [])


    const restrictDecimal = (d) => {
        return d ? d.toFixed(2) : 0;
    }

    const getName = () => {
        if (props.allData.pay_as_corporation == 1 && props.allData.corp_name && props.allData.corp_tax_id) {
            return props.allData.corp_name
        }
        else {
            return props.allData.f_name + " " + props.allData.l_name;
        }
    }

    const getHoliday = (interval) => {
        let amt = 0;
        interval?.map((a) => {
            if (a.holidayAmt) {
                amt += a.holidayAmt
            }
        })
        return amt;
    }

    console.log(props.allData.shifts);

    return (
        <div style={props.style}>
            <table style={{ width: '100%' }} className="t2">
                <tr>
                    <td></td>
                    <td><strong>Check No: </strong>{props.payrollId}</td>
                    <td></td>
                    <td></td>
                    <td><strong>Name:</strong> {getName()}</td>
                    <td></td><td></td><td></td><td></td><td></td><td></td>
                </tr>
                <tr>
                    <td></td>
                    <td><strong>Gross Pay:</strong> $ {props.total?.toFixed(2)}</td>
                    <td><strong>Net Pay:</strong> $ {props.total?.toFixed(2)}</td>
                    <td></td>
                    <td><strong>Date:</strong> {changeTzToNormal(props.allData.chequeDate).date}</td>
                    <td></td><td></td><td></td><td></td><td><td>
                    </td>
                    <strong>YTD: </strong>
                        $
                        <>
                        {
                            props.allData.shifts?.[0]?.ytd ? restrictDecimal(parseFloat(restrictDecimal(props.allData.shifts?.[0]?.ytd)) + parseFloat(restrictDecimal(props.total))) : restrictDecimal(props.total)
                        }
                        </>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td><strong>{capitalizeFirstLetter(props.string) + " dollar only"}</strong></td>
                </tr>
            </table>
            <table style={{ width: '100%' }} className="t3">
                <tr>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Description</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Payable Hours</th>
                    <th>Rate</th>
                    <th>OT Pay</th>
                    <th>Bonus Pay</th>
                    <th>Holiday Pay</th>
                    <th>Deduct Amt.</th>
                    <th>Earnings</th>
                    {/* <th>YTD</th> */}
                </tr>

                {
                    props.allData.shifts && props.allData.shifts.map((s, i) => (
                        <tr>
                            <td>{dateUSStandard(dateTimeFormatFull(s.shift.startDate).date)}</td>
                            <td>{s.clnt_name}</td>

                            <td>{s.shiftName}</td>
                            <td>{dateTimeFormatFull(s.shift.startDate).time}</td>
                            <td>{dateTimeFormatFull(s.shift.endDate).time}</td>
                            <td>{s.shift.regularHours + s.shift.holidayHours - s.shift.breakHours}</td>
                            <td>${s.shift.regularRate ? s.shift.regularRate : s.shift.holidayRate}</td>
                            <td>${s.shift.otherChargesCost ? s.shift.otherChargesCost : "0"}</td>
                            <td>${restrictDecimal(s.shift.customBonusAmt)}</td>

                            <td>${getHoliday(s.shift?.intervals)}</td>
                            <td>$0</td>
                            <td>${restrictDecimal(s.shift.total)}</td>
                            {/* <td> ${s.ytd ? restrictDecimal(parseFloat(restrictDecimal(s.ytd)) + parseFloat(restrictDecimal(props.total))) : restrictDecimal(props.total)}</td> */}
                        </tr>
                    ))
                }


            </table>
        </div>
    )
}

export default PayrollTable;