import React, { useState, useEffect } from 'react';
import ReactSelect from '../../../component/ReactSelect';
import DatePicker from '../../../component/datePicker/datePicker';

import { GET_ALL_CLIENT, GET_CONTRACTOR_TYPE } from '../../../APICall/urls';
import { postAdminAPI } from '../../../APICall/index';
import { ConvertForSelect, ConvertToArray } from '../../../Utils/arrayFunction';
import { updateLocalStorage, getLocalStorage } from '../../../Utils/localStorage';
import { checkFilter } from "../../../Utils/generalFunction";

const NotificationFilter = (props) => {

    const [all, setAll] = useState([[], [], []]);
    const [selected, setSelected] = useState([[], [], []]);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [bonus, setBonus] = useState(0)
    const [action, setAction] = useState(1)

    const handleChange = (e, index) => {

        let _selected = [...selected];
        _selected[index] = e;
        setSelected(_selected);

        updateLocalStorage(index, e)
    }



    useEffect(() => {
        getAllData();
    }, [])

    const getAllData = async () => {
        let client = await postAdminAPI(GET_ALL_CLIENT);
        let contactor = await postAdminAPI(GET_CONTRACTOR_TYPE);
        // console.log(props.filterData)
        setSelected([[...props.filterData.clients], [...props.filterData.ctrTypes], [...props.filterData.shiftTypes]])
        setStart(props.filterData.startDate)
        setEnd(props.filterData.endDate)

        let _all = [...all];
        _all[0] = ConvertForSelect(client.data, "name", "id")
        _all[1] = ConvertForSelect(contactor.data, "code", "id")
        _all[2] = [{ label: "DAY", value: 1 }, { label: "Evening", value: 2 }, { label: "Night", value: 3 }]

        setAll(_all);

        // console.log(client.data, contactor.data)
    }

    const handleSubmit = () => {
        let filter = {
            clients: selected[0]?.length ? ConvertToArray(selected[0]) :  ConvertToArray(all[0]),
            shiftTypes: ConvertToArray(selected[2]),
            ctrTypes: ConvertToArray(selected[1]),
            startDate: start,
            endDate: end,
        }



        filter = checkFilter(filter)
        if (bonus > 0) {
            filter = {
                ...filter,
                bonus: bonus == 1 ? true : false,
            }
        }
        filter = {
            ...filter,
            isToday: action == 1 ? true : false,
        }
        console.log(filter, bonus)
        props.handleFilter(filter)
    }

    const handleDateChange = (name, e) => {
        if (name === "start") {
            setStart(e);
            updateLocalStorage(3, e)
        }
        else {
            setEnd(e);
            updateLocalStorage(4, e)
        }


    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <label>Clients</label>
                    <ReactSelect
                        options={all[0]}
                        onChange={(e) => handleChange(e, 0)}
                        value={selected[0]}
                        allowSelectAll={true}
                        isMulti={true}
                        noMin={true}
                    />
                </div>
                <div className="col-md-4">
                    <label>Contractor Types</label>
                    <ReactSelect
                        options={all[1]}
                        onChange={(e) => handleChange(e, 1)}
                        value={selected[1]}
                        allowSelectAll={true}
                        isMulti={true}
                        noMin={true}
                    />
                </div>
                <div className="col-md-4">
                    <label>Shift Type</label>
                    <ReactSelect
                        options={all[2]}
                        onChange={(e) => handleChange(e, 2)}
                        value={selected[2]}
                        allowSelectAll={true}
                        isMulti={true}
                        noMin={true}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-md-4">
                    <label>Start Date</label>
                    <DatePicker
                        date={start}
                        name={"start"}
                        required={true}
                        handleDateChange={handleDateChange}
                    />
                </div>
                <div className="col-md-4">
                    <label>End Date</label>
                    <DatePicker
                        date={end}
                        name={"end"}
                        required={true}
                        handleDateChange={handleDateChange}
                    />
                </div>
                <div className="col-md-4">
                    <label>Bonus Shift</label>
                    <select
                        onChange={(e) => setBonus(e.target.value)}
                        className="form-control"
                    >
                        <option value={0}>All</option>
                        <option value={1}>Bonus Shift</option>
                        {/* <option value={2}>Non Bonus Shift</option> */}
                    </select>
                </div>
                <div className="col-md-4" style={{ marginTop: '2rem' }}>
                    <label>Action</label>
                    <select
                        onChange={(e) => setAction(e.target.value)}
                        className="form-control"
                        value={action}
                    >
                        <option value={0}>All</option>
                        <option value={1}>Today</option>
                        {/* <option value={2}>Non Bonus Shift</option> */}
                    </select>
                </div>
                <div className="col-md-4 text-center" style={{ marginTop: '4rem' }}>
                    <button
                        className="btn btn-primary"
                        onClick={handleSubmit}

                    >Submit</button>
                </div>
            </div>
        </>
    )
}

export default NotificationFilter;