import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';

import { GET_GENERATED_TIMESLIP, GET_ONE_GENERATED_TIMESLIP_DETAILS, DOWNLOAD_TIMESLIP } from "../../../../APICall/urls/invoicePayroll";
import { postAdminAPI, postAdminAPIBlob } from "../../../../APICall/index";
import { changeTzToNormal } from "../../../../Utils/dateFunctions";
import logo from "../../../../assets/images/RN_logo.png";
import { dateTimeBasicFormant, dateUSStandard, timeFormat } from '../../../../Utils/dateFunctions';
import { notiflixLoaderStart, notificationStatus, notiflixStatus, notiflixLoaderEnd } from '../../../../component/Notiflix/notiflix'

import './styles.css';

const InvoiceSummary = (props) => {


    const [invoiceId, setInvoiceId] = useState(null);
    const [allData, setAllData] = useState([])
    const [total, setTotal] = useState(0)
    const [string, setString] = useState('')
    const [allClients, setAllC] = useState('')
    const [print, setPrint] = useState('')

    let params = useParams();


    useEffect(() => {
        // console.log(params)
        setInvoiceId(params.group_id);
        fetchAllData(params.group_id)

    }, [])

    const fetchAllData = async (id) => {
        // console.log(id)
        let result = await postAdminAPI(GET_ONE_GENERATED_TIMESLIP_DETAILS, { groupId: id });
        // console.log(result)
        if (result.status) {
            let allData = result.data;
            if (allData.invoicePath) {
                // console.log(allData.invoicePath)
                setPrint(allData.invoicePath)
            }
            setAllData(result.data);
        }
    }

    const generateInvoice = () => {

        let rows = [];
        if (allData) {
            let client = Object.keys(allData);
            client && client.forEach((c, i) => {
                if (c != "invoicePath") {
                    let single = allData[c]
                    // console.log(single)
                    single && single.forEach((a) => {

                        let start = a.startDate.split(" ");
                        let end = a.endDate.split(" ");

                        rows.push(< tr >
                            <td>{dateUSStandard(start[0])}</td>
                            <td>{a.shiftType}</td>
                            <td>{a.fName + " " + a.lName}</td>
                            <td>{a.code}</td>
                            <td>{a.name}</td>
                            <td>{timeFormat(start[1])}</td>
                            <td>{timeFormat(end[1])}</td>
                            <td>{a.totalHours}</td>
                            <td>{a.supervisorName}</td>
                            <td>{a.moreInfo ? a.moreInfo : <img style={{ height: 40 }} src={"data:image/png;base64, " + a.supervisorSignature} alt="" />} </td>
                        </tr >)
                    })
                }
                else {
                    // 
                }
            })
        }


        return rows;

    }

    const openPdf = () => {
        window.open(print, '_blank');
    }

    const fetchOneData = async () => {
        notiflixLoaderStart();
        let result = await postAdminAPI(GET_GENERATED_TIMESLIP, { groupId: params.group_id });
        // console.log(result)
        if (result.status) {
            downloadPdf(result.data)
            
        }
        else {
            notiflixStatus(true, "Something went wrong.Please try again later.")
        }
        notiflixLoaderEnd()
    }

    const downloadPdf = async(allFiles) => {
        // console.log(allFiles)
        for(let i = 0; i < allFiles.length; i++){
            let result = await postAdminAPIBlob(DOWNLOAD_TIMESLIP, { fileName: allFiles[i]});
            // console.log(result)
            fileDownload(result, allFiles[i]);
        }
    }

    return (
        <>

            <div className="direct-invoice">
                <div className="row">
                    <div className="col-md-6 direct-invoice-slip">TIMESLIP</div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-6">

                                RNPlus Inc.<br />
                                515 Pennsylvania Ave, Suit 103.<br />
                                Fort Washington, PA 19034<br />
                                www.rnplus.net<br />
                            </div>
                            <div className="col-md-6">
                                <img className="direct-invoice-logo" src={logo} alt="logo" />
                                <br />
                                Tel: 215-887-8050<br />
                                Fax: 215-887-8057<br />
                                Timeslips@rnplus.net<br />
                            </div>
                        </div>
                    </div>
                </div>
                <table className="direct-invoice-table">
                    <thead>
                        <tr>
                            <td>Start Date</td>
                            <td>Shift Name</td>
                            <td>Contactor</td>
                            <td>Type</td>
                            <td>Facility</td>
                            <td>S Time</td>
                            <td>E Time</td>
                            <td>T Hrs</td>
                            <td>Supervisor</td>
                            <td>Supr. Signature</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            generateInvoice()
                        }
                    </tbody>
                </table>
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            onClick={fetchOneData}
                            type="button"
                            className="btn btn-primary">Download</button>

                        &nbsp;&nbsp;&nbsp;
                        <button
                            onClick={openPdf}
                            type="button"
                            className="btn btn-primary">Print</button>
                    </div>
                </div>
            </div>

        </>
    )
}

export default InvoiceSummary