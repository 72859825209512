import React from 'react';
import {
    Card,
    CardBody,
} from "reactstrap";

const EmptyData = (props) =>{
    return(
        <Card className={props.className}>
            <CardBody className="text-center">
                <h4>No data available</h4>
            </CardBody>
        </Card>
    )
}

export default EmptyData;