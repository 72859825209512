export const basicColumns = [
    {
        label: 'Id',
        field: 'id',
        width: 150,
        isSort:true, //if true it is allowed to be sort
        sortValue:"asc", // Sort value "asc", "desc"
        isSortActive:false, //if true sort will be appled to the current filter
        isVisible: true, // if checkd it will be visible on table,
        isVariable:false
    },
    {
        label: 'Contractor Name',
        field: 'name',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
    {
        label: 'Client Name',
        field: 'cloudRuntimeId',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
    {
        label: 'Shift Name',
        field: 'state',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
    {
        label: 'Start Time',
        field: 'state',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
    {
        label: 'End Time',
        field: 'state',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
    {
        label: 'Created On',
        field: 'createdOn',
        sort: 'enabled',
        width: 150,
        isSort:true,
        sortValue:"asc",
        isSortActive:false,
        isVisible: true,
        isVariable:true
    },
];
