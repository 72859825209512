import React, { Component } from "react";
import { Card, CardHeader, Input, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";

import ReactSelect from "../../../component/ReactSelect";

import { ConvertForSelect, ConvertToArray } from '../../../Utils/arrayFunction';
import { clientForSelectTwo, contractorForSelectTwo } from '../../../Utils/jsonManipulation';

import { setBreadcrumbItems } from "../../../store/actions";
import { SEND_NOTIFICATION } from "../../../APICall/urls/settings";
import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from "../../../component/Notiflix/notiflix"


class Notification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allContractors: [],
            selectedContractors: [],
            allClients: [],
            selectedClients: [],
            type: 1,
            title: '',
            message: '',
            user: 'both'
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Notifications", this.state.breadcrumbItems);
        this.updateUserDetails();
    }

    updateUserDetails = () => {
        // console.log(this.props.basicDetails.allContractor)
        let contractors = contractorForSelectTwo(this.props.basicDetails.allContractor)
        let clients = clientForSelectTwo(this.props.basicDetails.allClients)
        // console.log(contractors, clients, "sadsadsa")
        this.setState({ allClients: clients, allContractors: contractors })
    }

    getData = async () => {
        // console.log(props.contractors)
        // let contractors = ConvertForSelect(props.contractors, "name", "id")
        // console.log(contractors);
        // setAllContractor(contractors)
    }

    handleSubmit = async(e) => {
        const { selectedClients, selectedContractors, type, title, message, user } = this.state;
        e.preventDefault()
        let postData = {
            "type": type,
            "title": title,
            "text": message,
            "contractors": ConvertToArray(selectedContractors),
            "notifyType": user,
            "client": ConvertToArray(selectedClients)
        }
        console.log(postData)

        let result = await postAdminAPI(SEND_NOTIFICATION, postData)
        console.log(result)
        if(result){
            notificationStatus({status:true, message:"Success"})
        }
        else{
            notificationStatus({status:false, message:"Something went wrong. Please try again later."})
        }
        // this.props.handleAddNotification(data);
    }

    handleChange = (e, state) => {
        this.setState({ [state]: e.target.value })
    }

    handleMultipleChange = (e, state) => {
        this.setState({ [state]: e })
    }

    render() {

        const { allContractors, selectedContractors, type, title, message, user, selectedClients, allClients } = this.state;
        // console.log(allContractors, allClients);
        return (
            <Card>
                <CardHeader style={{ padding: '0.25rem 1.25rem' }}>
                    <Row>
                        <Col md={6}>
                            Generate Notification
                        </Col>
                    </Row>
                </CardHeader>
                <form onSubmit={this.handleSubmit}>
                    <div className="row mt-2">
                        <div className="col-md-4 d-flex">
                            <input
                                name="user"
                                type="radio"
                                value="Clients"
                                onChange={(e) => this.handleChange(e, "user")}
                                checked={user === "clients" ? true : false}

                            />
                            &nbsp; Clients

                        </div>
                        <div className="col-md-4 d-flex">
                            <input
                                name="user"
                                type="radio"
                                value="Contractors"
                                onChange={(e) => this.handleChange(e, "user")}
                                checked={user === "contractors" ? true : false}

                            />
                            &nbsp; Contractors

                        </div>
                        <div className="col-md-4 d-flex">
                            <input
                                name="user"
                                type="radio"
                                value="Both"
                                onChange={(e) => this.handleChange(e, "user")}
                                checked={user === "both" ? true : false}
                            />
                            &nbsp; Both

                        </div>
                        <div className="col-md-12 mt-4">
                            <label>Contractors</label>
                            <ReactSelect
                                value={selectedContractors}
                                onChange={(e) => this.handleMultipleChange(e, "selectedContractors")}
                                options={allContractors}
                                isMulti={true}
                                allowSelectAll={true}
                            />
                        </div>
                        <div className="col-md-12 mt-4">
                            <label>Clients</label>
                            <ReactSelect
                                value={selectedClients}
                                onChange={(e) => this.handleMultipleChange(e, "selectedClients")}
                                options={allClients}
                                isMulti={true}
                                allowSelectAll={true}
                            />
                        </div>
                        <div className="col-md-12 mt-2">
                            <label>Title</label>
                            <div className="input-group-prepend">
                                <Input
                                    className="form-control"
                                    required
                                    type="text"
                                    value={title}
                                    placeholder="Enter Notification title."
                                    onChange={(e) => this.handleChange(e, "title")}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-2">
                            <label>Text</label>
                            <div className="input-group-prepend">
                                <Input
                                    className="form-control"
                                    type="textarea"
                                    required
                                    placeholder="Enter notification to be Sent"
                                    value={message}
                                    onChange={(e) => this.handleChange(e, "message")}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mt-4">
                            <Button type="submit" color={`primary`}>Send Notification</Button>
                        </div>
                    </div>
                </form>
            </Card>
        );
    }

};

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(Notification);

