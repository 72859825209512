import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import { postAdminAPI } from '../../../../APICall/index';
import { GET_ALL_CONTRACTOR } from '../../../../APICall/urls';


const OrderFilter = (props) => {
    const [allContractor, setAllContractor] = useState([]);
    const [contractor, setContractor] = useState(null);

    useEffect(() => {
        getApiData();
    }, [])

    const getApiData = async () => {
        let contractors = await postAdminAPI(GET_ALL_CONTRACTOR);
        setAllContractor(contractors.data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {};
        if (contractor) {
            data = {
                ...data,
                ctr: parseInt(contractor),
            }
        }
        props.handleFilter(data);
    }

    return (
        <>
            <Card>
                <CardHeader>
                    Generate Pay Rate Report
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={12}>
                                <label>Contractor</label>
                                <select
                                    className="form-control"

                                    value={contractor}
                                    onChange={(e) => setContractor(e.target.value)}
                                >
                                    <option value="" className="allOptions">All Contractors</option>
                                    {
                                        allContractor && allContractor.map((a, i) => (
                                            <option key={i} value={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={12} className="text-center">
                                <hr />
                                <button type="submit" className="btn btn-primary">
                                    Generate Report
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default OrderFilter;