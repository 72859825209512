import React, { useState, useEffect } from 'react';


const PayRateCard = (props) => {
    return (
        <div className="card shadow mb-4">
            <div className="card-header py-3">
                <nav className="navbar">
                    <h6 className="m-0 font-weight-bold text-primary ">Pay Rates</h6>
                    <div>
                        <button
                            className="btn btn-primary btn-circle1"
                            onClick={() => props.handleChangeAdd()}
                        ><i className="fas fa-plus"></i></button>
                    </div>
                </nav>
            </div>

            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Rate ID</th>
                                <th>Client</th>
                                <th>Shift</th>
                                {/* <th>Area</th> */}
                                <th>Last Modified On &amp; By</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="client_body">
                            {
                                props.allRates && props.allRates.length > 0 && props.allRates.map((a, i) =>(
                                    <tr key={i}>
                                        <td>{a.id}</td>
                                        <td>{a.client_name}</td>
                                        <td>{a.name}</td>
                                        {/* <td>CCU</td> */}
                                        <td>{a.modified_by}</td>
                                        {/* <td>{a.modified_by} &amp; {a.modified_at}</td> */}
                                        <td>
                                            <>
                                                <button
                                                    className="btn btn-primary btn-circle1"
                                                    onClick={() => props.handleView(a)}
                                                >
                                                    <i className="fas fa-eye"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-info btn-circle1"
                                                    onClick={() => props.handleEdit(a)}

                                                >
                                                    <i className="fas fa-cut"></i>
                                                </button>
                                                &nbsp;
                                                <button
                                                    className="btn btn-danger btn-circle1"
                                                    onClick={() => props.handleDelete(a)}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default PayRateCard;