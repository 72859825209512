import React, { useState, useEffect } from 'react';
import ModalConfirm from "../../component/confirm";


import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css"

const Table = (props) => {



    const [allLabels, setAllLabels] = useState([])
    const [modalConfirm, setModalConfirm] = useState(false)

    const [sortSelected, setSortSelected] = useState({})

    // console.log(props)
    useEffect(() => {
        if (props.rows && props.rows.length > 0) {
            let allLabels = Object.keys(props.rows[0]);
            setAllLabels(allLabels)
        }
    }, [props.rows])

    const handleSort = (e) => {
        let _sortSelected = { ...sortSelected }
        if (_sortSelected[e]) {
            _sortSelected = {
                ..._sortSelected,
                [e]: _sortSelected[e] === "asc" ? "desc" : "asc"
            }
        }
        else {
            _sortSelected = {
                ..._sortSelected,
                [e]: "asc"
            }
        }

        // console.log(_sortSelected)
        setSortSelected(_sortSelected);
        props.handleFilter(null, _sortSelected)
        // props.updateSort({
        //     "attribute": e,
        //     "sort": _sortType,
        // })
    }


    // console.log(props)
    return (
        <div className="mdb-datatable dt-bootstrap4 mt-4" style={props.style}>
            {
                props.isSubmit
                    ?

                    <div className="col-md-12 mt-1 mb-2 pr-3 text-right">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setModalConfirm(true)}
                        >{props.title}</button>
                    </div>
                    : null
            }
            <div className="row" style={{ overflowX: 'scroll' }}>
                <div className="col-sm-12">
                    <div className="">
                        <table className="custom-table">
                            <thead className="mdb-dataTable-head">
                                <tr>
                                    {
                                        props?.columns?.map((h, i) => (
                                            <th key={h.field}>
                                                <div
                                                    onClick={
                                                        h.sort !== "disabled"
                                                            ?
                                                            () => handleSort(h.field)
                                                            : () => { }
                                                    }
                                                    className="custom-table-head"
                                                >
                                                    {h.label}
                                                    {
                                                        (sortSelected[h.field] && (h.sort !== "disabled"))
                                                            ?
                                                            <i style={{ marginTop: 6 }} className={sortSelected[h.field] === 'desc' ? "ml-1 fa fa-arrow-up" : "ml-1 fa fa-arrow-down"}></i>
                                                            : null
                                                    }

                                                </div>

                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props?.rows?.map((r, i) => (
                                        <tr key={r} style={r.contractorName === "" ? { backgroundColor: "black" } : {}}>
                                            {
                                                allLabels?.map((a, j) => (
                                                    <td key={r[a]} style={r[a] ? {} : { padding: 2 }}>
                                                        {r[a]}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
            {
                modalConfirm
                    ?
                    <ModalConfirm
                        modal={modalConfirm}
                        toggle={() => setModalConfirm(false)}
                        content={props.content}
                        title={props.title}
                        click={() => {
                            setModalConfirm(false)
                            props.handleGeneratePayroll();
                        }}
                    />
                    : null
            }
        </div>
    )
}

export default Table;