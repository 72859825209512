import React, { useState, useEffect } from 'react';
import './singleStaffingcard.css';
import { timeFormat, dateArithmetic, compareTwoTime, dateTimeBasicFormantSplit, getTzDateTime } from '../../../Utils/dateFunctions';
import moment from 'moment';
import { objectLength } from '../../../Utils/generalFunction';
import { staffingState, getStatusTest, getLabel } from '../../../Utils/status';
import { stringAbbreviation, stringAbbreviationForClient } from "../../../Utils/stringManupulation";

import { notiflixLoaderStart, notiflixStatus, notiflixLoaderEnd } from '../../../component/Notiflix/notiflix';
import { postAdminAPI } from '../../../APICall/index';
import { ORDER_DRAG, VALIDATE_CONTRACTOR } from '../../../APICall/urls';
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import { dateTimeBasicFormant } from "../../../Utils/dateFunctions"

const SingleStaffignCard = (props) => {

    const [selected, setSelected] = useState(null)
    const [count, setCount] = useState(0)
    const [cardStyle, setCardStyle] = useState({})
    const [status, setStatus] = useState({})
    const [statusNo, setStatusNo] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)

    const onDragOver = (e) => {
        e.preventDefault();
        // console.log(e)
    }

    const onDragStart = (e, data) => {
        setSelected(data)
        e.dataTransfer.setData("data", JSON.stringify(data))
    }

    const checkTime = (time) => {
        if (time) {
            let _time = time.split(":");
            return _time[0] + ":" + _time[1];
        }
        return time;
    }

    const onDrop = async (e, status, shift) => {
        console.log(e.dataTransfer)
        let data = e.dataTransfer.getData("data") ? JSON.parse(e.dataTransfer.getData("data")) : {};
        // console.log(props.isContractor);

        if (props.isContractor) {
            shift = {
                id: data.shift,
                start_time: getTzDateTime(data.start_date),
                // start_time: changeTzToNormal(data.start_date).time + ":00",
                end_time: getTzDateTime(data.end_date)
            }
            data = {
                ...data,
                startDate: data.start_date,
            }
        }
        console.log(data, shift, props.date)


        let _startTime = props.date + "T" + checkTime(shift.start_time);
        let _endTime = props.date + "T" + checkTime(shift.end_time)
        // console.log(_startTime + ":00.000Z", data.startDate);
        if (!compareTwoTime(shift.start_time, shift.end_time)) {
            _endTime = dateArithmetic(props.date, 1) + "T" + checkTime(shift.end_time)
        }
        if (data.contractor_name && (_startTime + ":00.000Z" === data.startDate)) {
            notiflixStatus(false, "Duplicate Shift")
        }
        else {
            let _data = {
                "startDate": _startTime,
                "endDate": _endTime,
                "shift": parseInt(shift.id ? shift.id : 0),
                "orderId": data.id
            }
            console.log(_data, data);
            let output = true;
            if (data.assigned_ctr)
                output = await checkContactorIsValid(_startTime, _endTime, _data.shift, data, shift, data)

            if (output) {
                handleSubmitShift(_data)
            }
        }


    }

    const onDropSubmit = async (data, shift) => {
        console.log(data, shift)

        let _startTime = props.date + "T" + checkTime(shift.start_time);
        let _endTime = props.date + "T" + checkTime(shift.end_time)
        if (!compareTwoTime(shift.start_time, shift.end_time)) {
            _endTime = dateArithmetic(props.date, 1) + "T" + checkTime(shift.end_time)
        }

        let _data = {
            "startDate": _startTime,
            "endDate": _endTime,
            "shift": parseInt(shift.id ? shift.id : 0),
            "orderId": data.id
        }
        // console.log(_data);
        setConfirmModal(false);
        handleSubmitShift(_data)
    }

    const checkContactorIsValid = async (startTime, endTime, shift, data, allShift, event) => {
        notiflixLoaderStart();
        console.log(data);
        let _data = {
            "ctrType": data.ctr_type,
            "assignedCtr": data.assigned_ctr,
            "startDate": startTime,
            "endDate": endTime,
            "client": data.client,
            "shift": shift,
            breakHour:data.has_break ? true  : false,
        }
        let res = await postAdminAPI(VALIDATE_CONTRACTOR, _data);
        console.log(res);
        if (res.status) {
            if (res.data?.proceed && !res.data?.message) {
                notiflixLoaderEnd()
                return true;
            }
            else if (res.data?.message) {

                let str = []
                let schText = "scheduled";
                if (res.data.extra?.pastOrders?.length) {
                    let date = dateTimeBasicFormantSplit(res.data.extra.pastOrders[0].start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.pastOrders[0].end_date)
                    schText = (res.data.extra.pastOrders[0].state <= 2) ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText} at   <strong>{res.data.extra.pastOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.pastOrders[0].created_at)}
                    </>)
                }
                else if (res.data.extra?.futureOrders?.length) {
                    console.log(res.data.extra.pastOrders);
                    let date = dateTimeBasicFormantSplit(res.data.extra.futureOrders[0].start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.futureOrders[0]?.end_date)
                    schText = res.data.extra.futureOrders[0].state <= 2 ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText} at   <strong>{res.data.extra.futureOrders[0].name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.futureOrders[0].created_at)}
                    </>)
                }
                else if (res.data.extra?.id) {
                    let date = dateTimeBasicFormantSplit(res.data.extra.start_date)
                    let dateE = dateTimeBasicFormantSplit(res.data.extra.end_date)
                    schText = res.data.extra.state <= 2 ? "applied" : "scheduled"
                    str.push(<>
                        Contractor already {schText} at   <strong>{res.data.extra.name}</strong>  from   <strong>{date.date + " " + date.time + "-" + dateE.time}</strong>
                        <br />
                        Shift Created at: {dateTimeBasicFormant(res.data.extra.created_at)}
                    </>)
                }
                else if (res.data.message) {
                    str.push(res.data.message)
                }

                setConfirmModal({
                    e: event,
                    shift: allShift,
                    proceed: res.data?.proceed,
                    message: res.data?.proceed ? <>
                        {str}. Do you want to proceed?
                    </>
                        : <>
                            You cannot proceed because of {str}
                        </>


                })
            }
            else {
                notiflixStatus(false, "You cannot proceed because " + res.data.message)
            }
        }
        else {
            notiflixStatus(false, res.message)
        }
        notiflixLoaderEnd()
        return false;
    }

    const handleSubmitShift = async (shiftInfo) => {
        // console.log(shiftInfo, shiftId, actionAllowed, actionMessage);
        notiflixLoaderStart();
        let content;
        content = await postAdminAPI(ORDER_DRAG, shiftInfo);
        if (content.status) {
            console.log(content.data)
            if (content.data === true) {
                props.afterActionCalls(content);
            }
            else if (content.data === false) {
                notiflixStatus(false, "Something went wrong.");
            }
            else if (typeof content.data === "string") {
                notiflixStatus(false, content.data);

            }
            else {
                props.afterActionCalls(content);
            }
        }
        else {
            notiflixStatus(false, content.message);
        }

    }

    const countStyle = {
        border: "1px solid blue",
        paddingTop: "2px",
        paddingRight: "3px",
        paddingLeft: "3px",
        paddingBottom: "2px",
        borderRadius: "5px",
        position: "absolute",
        top: -3,
        left: 4

    }

    useEffect(() => {
        handleStyle();
        let state;
        if (props.data.state >= 5 && props.data.state <= 11 || props.data.state == 15) {
            state = props.data.last_state;
        }
        else {
            state = props.data.state;
        }
        // console.log(state);
        let status = staffingState(parseInt(state));
        // console.log(status);
        setStatusNo(state)
        // console.log(props.data.state, status)
        setStatus(status);
        setCount(0)
    }, [props.data])

    useEffect(() => {

        // if(statusNo != props.data.state){
        //     let _status = staffingState(parseInt(props.data.state));
        //     // console.log(_status, statusNo, props);
        //     setStatus(_status);
        // }

    }, [props.data])

    const handleStyle = () => {
        // setInterval(() => {
        //     // console.log(cardStyle);
        //     setCardStyle(Object.keys(cardStyle).length === 0 ?  {backgroundColor:'#ADFF2F', transition:'background 1s ease-in-out'} : {})
        // }, 1500);
    }

    const getStateImage = (state) => {

        if (state.order_state_colour_code) {
            return (
                <div onDragStart={(e) => onDragStart(e, props.data)} className='errorMessage' style={{ color: state.order_state_colour_code }}>
                    <div>{state.order_state_abbr}</div>
                </div>
            )
        }
        return null;

    }

    // console.log(confirmModal);

    const generateTimeZone = (timezone) => {
        if (timezone) {
            let arr = timezone.split('/');
            if (arr.length > 1) {
                return arr[1];
            }
        }
        return "";
    }

    useEffect(() => {
        if (props.data?.responses?.length > 0) {
            checkRes()
        }
    }, [props.data.responses, props.data?.contractor_name])

    const checkRes = () => {
        let count = 0;
        if (!props.data?.contractor_name || props.data?.contractor_name?.length <= 0) {
            props.data.responses && props.data.responses.map((r) => {
                if (r.res == 1) {
                    count++;
                }
            })
            // console.log(props.data.contractor_name, count)
            if (count > 0) {
                setCount(count)
            }
        }

    }




    // console.log(props,99);
    const checkClour = () => {
        if (props.data?.state >= 3) {
            return { backgroundColor: '#fff2f2' }
        }
        if ((props.data?.state == 1 && props.data.contractor_name) || props.data?.state == 2) {
            return { backgroundColor: '#fbfae2' }
        }

    }
    return (
        <>
            {
                objectLength(props.data)
                    ?
                    <td
                        className={(count > 0 ? "singleStaffingCardData card-animate" : "singleStaffingCardData")}
                        style={checkClour()}
                        onClick={() => props.handleModal(props.shift, props.count, props.data)}
                    >
                        <div
                            className="singleStaffingCard"
                            draggable
                            onDragStart={(e) => onDragStart(e, props.data)}


                        >
                            <div className="row" style={{ padding: '2px 3px', fontSize: 14 }}>
                                <div className="col-md-4 text-left" style={{ height: 20 }}>
                                    {props.data.bonus_type > 1 ? "$$" : null}
                                </div>
                                {
                                    props.data.isContractor
                                        ?
                                        <div className="col-md-8 text-right" style={{ height: 20 }}>
                                            <strong>{stringAbbreviationForClient(props.data.client_name)}</strong>
                                        </div>
                                        : <div className="col-md-8 text-right" style={{ height: 20 }}>
                                            <strong>{stringAbbreviation(props.data.contractor_name)}</strong>
                                        </div>

                                }

                                {/* <div className="col-md-5 text-right" style={{ height: 20, fontSize: 12 }}>
                                    <strong>{generateTimeZone(props.data.timezone)}</strong>
                                </div> */}
                                <div className="col-md-2 mt-1 text-left">
                                    <input
                                        type="checkbox"
                                        onChange={() => { }}
                                        // checked={status && status.clientVerify ? true : false}
                                        checked={props.data?.client_confirm == "YES" ? true : false}
                                    />
                                </div>
                                <div className="col-md-8 mt-1 text-center singleStaffingCard_heading" style={{ padding: 0 }}>
                                    <span>{props.data.start_time + " - " + props.data.end_time}</span>
                                </div>
                                <div className="col-md-2 mt-1 text-right">
                                    <input
                                        type="checkbox"
                                        onChange={() => { }}
                                        // checked={status && status.contVerify ? true : false}
                                        checked={props.data?.ctr_acknowledge == "YES" ? true : false}
                                    />
                                </div>
                                <div className="col-md-12 text-right">
                                    {props.data.contractor_type_code ? props.data.contractor_type_code : props.data.code}
                                </div>
                                {/* {(props.data.responses && props.data.responses.length) && (props.data.contractor_name || props.data.contractor_name.length <= 0) > 0
                                    ?
                                    <div className="col-md-2  text-left">
                                        <div style={countStyle}>
                                            {props.data.responses.length}
                                        </div>
                                    </div>
                                    : null
                                } */}
                                {
                                    count > 0
                                        ?
                                        <div className="col-md-2  text-left">
                                            <div style={countStyle}>
                                                {count}
                                            </div>
                                        </div>
                                        : null

                                }
                                <div className="col-md-6  text-left">
                                    {props.data.contractor_area_name ? props.data.contractor_area_name : ""}
                                </div>
                                <div className="col-md-6  text-right">
                                    {getStatusTest(props.data.state ? props.data.state : 0).name}
                                </div>

                            </div>

                        </div>
                        {
                            props.data.state
                                ?
                                getStateImage(props.data)
                                : null

                        }
                    </td>
                    :
                    <td className="singleStaffingCardData">
                        <div
                            className="singleStaffingCard droppable"
                            onDragOver={(e) => onDragOver(e)}
                            onDrop={(e) => onDrop(e, "complete", props.shift)}
                            onClick={() => props.handleModal(props.shift, props.count, props.data)}
                        >
                        </div>
                    </td>

            }
            {
                confirmModal
                    ?
                    <ConfirmModal
                        modal={{
                            message: confirmModal.message,
                            proceed: confirmModal.proceed
                        }}
                        title={""}
                        toggle={() => setConfirmModal(null)}
                        click={(e) => onDropSubmit(confirmModal.e, confirmModal.shift)}
                        message={confirmModal.message}
                        button="Schedule"
                    />
                    : null
            }
        </>

    )
}

export default SingleStaffignCard;