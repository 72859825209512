import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";

const ImageModal = (props) => {

    return (
        <Modal size="lg" isOpen={props.isOpen}>
            <ModalHeader>
                {props.title ? props.title : "Image"}
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <div className="" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    {
                        props.base64
                            ?
                            <img style={{ height: 200 }} src={"data:image/png;base64, " + props.image} alt="" />
                            :
                            <img style={{ width: '100%' }} src={props.image} alt="image" />
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ImageModal;