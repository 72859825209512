import { getAdminAPI } from "../index";

//PAYROLL 
export const GET_PAYROLL_BASED_ON_FILTER = '/payroll/get-payroll-list';
export const GENERATE_PAYROLL = '/payroll/generate-payroll';
export const GET_ALL_PAYROLL = '/payroll/get-cheque-list';
export const GET_SINGLE_PAYROLL = '/payroll/get-cheque';
export const DELETE_SINGLE_PAYROLL = '/payroll/delete-payroll';

//INVOICE
export const GET_INVOICE_BASED_ON_FILTER = '/payroll/get-bill-list'
export const GENERATE_INVOICE = '/payroll/generate-bill'
export const GET_ALL_INVOICE = '/payroll/get-invoice-list'
export const GET_SINGLE_INVOICE = '/payroll/get-invoice'
export const EDIT_INVOICE_FETCH = '/payroll/edit-invoice-fetch';
export const EDIT_INVOICE = '/payroll/edit-invoice'
export const DELETE_INVOICE = '/payroll/delete-invoice'
export const MAIL_INVOICE = '/payroll/email-invoice'

export const NEXT_INVOICE_PAYROLL = "payroll/next-invoice-payroll-number"

//NEW TIMESLIP
export const ADD_TIMESLIP = '/timeslip/add-timeslip';
export const EDIT_TIMESLIP = '/timeslip/edit-timeslip';
export const GET_TIMESLIP = '/timeslip/get-timeslips'
export const INVOICE_TIMESLIP = '/timeslip/invoice-timeslip'
export const GET_INVOICED_TIMESLIP = '/timeslip/get-invoiced-timeslips'
export const GENERATE_TIMESLIP = '/timeslip/generate-timeslip'
export const GET_GENERATED_TIMESLIP = '/timeslip/get-generated-timeslip-details'
export const GET_ONE_GENERATED_TIMESLIP_DETAILS = '/timeslip/get-one-generated-timeslip-details'
export const DOWNLOAD_TIMESLIP = '/timeslip/download-timelslip-pdf'
export const GET_DELETED_TIMESLIP = '/timeslip/get-deleted-timeslip'
export const TOGGLE_TIMESLIP = "/timeslip/toggle-timeslip-delete"





