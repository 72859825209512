import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import AddModal from './addArea';
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const Areas = (props) => {

    const [areaData, setAreaData] = useState([])
    const [deleteArea, setDeleteArea] = useState(null)

    useEffect(() => {
        if (props.allAreas && props.allAreas.length > 0) {
            let _allAreas = [];
            props.allAreas.map((u, i) => {

                _allAreas.push(
                    {
                        sno: i + 1,
                        name: u.name,
                        action: <>

                            <button
                                class="btn btn-circle1 1btn-sm btn-outline-danger"
                                onClick={() => setDeleteArea(u)}
                            >
                                <i class="fas fa-trash"></i>
                            </button>
                        </>

                    },
                )

            })

            setAreaData(_allAreas)

        }
    }, [props.allAreas])

    const columns = [
        {
            label: 'Sr No',
            field: 'sno',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 100
        }
    ]


    return (
        <>
            <div className="row">
                <div className="col-md-12 text-right">
                    <button
                        className="btn btn-outline-success float-right"
                        onClick={() => props.setAreaModal(true)}
                    >Add Area</button>
                </div>
            </div>
            <div style={{ width: "50%" }}>
                <MDBDataTable
                    responsive
                    bordered
                    data={{ columns: columns, rows: areaData }}
                />
            </div>
            {
                props.areaModal
                    ?
                    <AddModal
                        addArea={props.addArea}
                        modal={props.areaModal}
                        toggle={() => props.setAreaModal(false)}
                    />
                    : null
            }
            {
                deleteArea
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this area?",
                                proceed: true
                            }

                        }
                        title="Delete area"
                        content="Do you want to delete this area?"
                        toggle={() => setDeleteArea(null)}
                        click={() => props.deleteArea(deleteArea, () => setDeleteArea(null))}
                    />
                    : null
            }

        </>
    )
};

export default Areas;