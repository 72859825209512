import React, { Component } from 'react';

//user Defined Functions
import { dateArithmetic, changeDateByTimezone } from '../../../Utils/dateFunctions'

//Component
import StaffingRow from '../../../component/StaffingSheet/StaffingRow';
import './calender.scss';
import { dateStandard, dateUSStandard } from '../../../Utils/dateFunctions';
import { days } from "../../../Utils/Variables/days";

const Calendar = (props) => {

    // console.log(props.days)

    const getHeader = () => {
        let _head = [];
        let current = props.startDate;
        let today = dateStandard(new Date());

        for (let i = 0; i < props.days; i++) {
            let style = today === current ? { backgroundColor: '#f0e68c', textAlign: 'center' } : { textAlign: 'center' };
            var d = changeDateByTimezone(current);
            var dayName = days[d.getDay()];
            _head.push(<th key={i} style={style} >
                {dayName}
                <br />
                {dateUSStandard(current)}
            </th>)
            current = dateArithmetic(current, 1);
        }
        // console.log(_head)
        return _head;
    }

    const getHeaderDays = () => {
        let _head = [];
        let current = props.startDate;
        // console.log(current, today);

        for (let i = 0; i < props.days; i++) {
            let style = { backgroundColor: '#ffffff', textAlign: 'center' }

            var d = new Date(current);
            var dayName = days[d.getDay()];
            _head.push(<th key={i} style={style} >{dayName}</th>)
            current = dateArithmetic(current, 1);
        }
        // console.log(_head)
        return _head;
    }

    return (
        <div className="calendertable">
            <table className="table table-bordered sticky-thc" cellSpacing="10" >
                <thead>
                    {/* <tr>
                        {getHeaderDays()}
                    </tr> */}
                    <tr>
                        {getHeader()}
                    </tr>

                </thead>
                <tbody>
                    <StaffingRow
                        days={props.days}
                        handleModal={props.handleModal}
                        allShifts={props.allShifts}
                        allOrders={props.allOrders}
                        startDate={props.startDate}
                        afterActionCalls={props.afterActionCalls}
                    />
                </tbody>

            </table>
        </div>

    )
}

export default Calendar;