import React, { Component, useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import Table from '../../../../../component/DynamicTable';
import { capitalizeAttribute } from "../../../../../Utils/stringManupulation";
import { changeTzToNormal } from "../../../../../Utils/dateFunctions";
import { ORDER_REPORT } from "../../../../../APICall/urls/report";
import { postAdminAPI } from "../../../../../APICall/index";
import Search from '../../filter/orderFilter';
import { basicColumns } from './columns';
import EmptyComponent from '../../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../../store/actions";

const OrderReport = (props) => {

    const [allData, setAllData] = useState([])
    const [breadcrumbItems, setBreadcrumbItems] = useState([])
    const [changeColumn, setChangeColumn ] = useState(false)
    const [columns, setColumns] = useState([...basicColumns])
    const [filters, setFilters] = useState(false)
    

    useEffect(() => {
        props.setBreadcrumbItems("Reports", breadcrumbItems);
    }, [])

    const handleFilter = async (filterData) => {
        console.log(filterData);
        let results = await postAdminAPI(ORDER_REPORT, filterData)
        updateData(results?.data)
    }

    const updateData = (data) => {
        let _data = []
        if (data && data.length > 0) {
            data.forEach((d, i) => {
                let sDate = changeTzToNormal(d.start_date);
                let eDate = changeTzToNormal(d.end_date);
                _data.push({
                    ...d,
                    start_date: sDate.time,
                    end_date: eDate.time,
                    date: sDate.date,
                    short_name: capitalizeAttribute(d.short_name)
                })
            })
        }
        setAllData({ allData: _data })
    }

    const generateComponent = () => {
        let content;
        if (allData) {
            if (allData.length > 0) {
                content = (
                    <Card>
                        <CardBody style={{ overflowX: 'scroll' }}>
                            <Table
                                rows={allData}
                                columns={columns}

                            />
                        </CardBody>
                    </Card>
                );
            } else {
                content = <EmptyComponent />;
            }
        } else {
            content = null;
        }
        return content
    }

    const updateColumns      = (columns) =>{
        setColumns(columns)
    }
    return (
        <>
            <Search
                handleFilter={handleFilter}
            />
            <Card>
                <CardBody style={{ overflowX: 'scroll' }}>
                    <Table
                        rows={allData}
                        columns={columns}
                        onSearch={() =>{}}
                        fetchSelectedPage={() =>{}}
                        changeColumn={changeColumn}
                        handleChangeColumn={setChangeColumn}
                        updateColumns={updateColumns}
                        setFilters={setFilters}
                        filters={filters}
                        exportExcel={() =>{}}
                    />  
                </CardBody>
            </Card>
            {generateComponent()}
        </>
    );

}

export default connect(null, { setBreadcrumbItems })(OrderReport);;