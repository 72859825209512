import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
    Card,
    CardBody
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import AddModal from './addUserModal';
import ConfirmModal from "../../../../component/confirm/confirmForOrder";

const userType = [
    {
        id: 1,
        label: 'Admin'
    },
    {
        id: 2,
        label: 'Coordinator'
    },
    {
        id: 3,
        label: 'Supervisor'
    }
]

const Users = (props) => {

    const [userData, setUserData] = useState([])
    const [deleteUser, setDeleteUser] = useState(null)

    const handleCheckbox = (e, id) => {
        let data = {
            id: id,
            active: false
        }
        if (e.target.checked) {
            data = {
                ...data,
                active: true
            }
        }
        props.toggleUser(data)
    }

    useEffect(() => {
        if (props.allUsers && props.allUsers.length > 0) {
            let _allUsers = [];
            props.allUsers.map(u => {
                if (u.deleted == 0) {
                    let _type = userType.filter(t => parseInt(t.id) === parseInt(u.type))

                    _allUsers.push(
                        {
                            type: _type.length > 0 ? _type[0].label : '',
                            name: u.name,
                            mobile: u.mobile,
                            lPin: u.password,
                            email: u.email,
                            masterPassword: u.master_password,
                            cStatus: u.deleted ? 'Offline' : 'Online',
                            active: <input
                                type="checkbox"
                                className="form-control"
                                value={u.active ? true : false}
                                onChange={(e) => handleCheckbox(e, u.id)}
                                checked={u.active ? true : false}
                            />,
                            action: <>
                                <button
                                    class="btn btn-circle1 btn-sm btn-outline-warning"
                                    onClick={() => props.resetPasswordUser({
                                        id: u.id
                                    })}
                                >
                                    <i class="fa fa-key"></i>
                                </button>&nbsp;&nbsp;&nbsp;

                                <button
                                    class="btn btn-circle1 1btn-sm btn-outline-danger"
                                    // onClick={() => props.deleteUser({
                                    //     id: u.id,
                                    //     deleted: true
                                    // })}
                                    onClick={() => setDeleteUser(u.id)}
                                >
                                    <i class="fas fa-trash"></i>
                                </button>
                            </>

                        },
                    )
                }
            })

            setUserData(_allUsers)

        }
    }, [props.allUsers])

    const columns = [
        {
            label: 'Type',
            field: 'type',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Name',
            field: 'name',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Mobile',
            field: 'mobile',
            sort: 'asc',
            width: 200
        },
        {
            label: 'Login Pin',
            field: 'lPin',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Master Password',
            field: 'masterPassword',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Current Status',
            field: 'cStatus',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Active',
            field: 'active',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 100
        }
    ]


    return (
        <>
            <div className="row">
                <div className="col-md-12 text-right">
                    <button
                        className="btn btn-outline-success float-right"
                        onClick={() => props.setUserModal(true)}
                    >Add User</button>
                </div>
            </div>
            <MDBDataTable
                responsive
                bordered
                data={{ columns: columns, rows: userData }}
            />
            {
                props.userModal
                    ?
                    <AddModal
                        addUserData={props.addUserData}
                        modal={props.userModal}
                        toggle={() => props.setUserModal(false)}
                        userType={userType}
                    />
                    : null
            }
            {
                deleteUser
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message:"Do you want to delete this user?",
                                proceed:true
                            }

                        }
                        title="Delete user"
                        content="Do you want to delete this user?"
                        toggle={() => setDeleteUser(null)}
                        click={() => props.deleteUser({
                            id: deleteUser,
                            deleted: true
                        }, () =>setDeleteUser(null))}
                    />
                    : null
            }

        </>
    )
};

export default Users;