import React from 'react';
import Notiflix from 'notiflix';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import { UncontrolledAlert } from 'reactstrap';

export const notiflixLoaderStart = () => {
    Notiflix.Notify.Init({ width: '300px', fontSize: '14px', timeout: 4000, messageMaxLength: 200, });
    Notiflix.Loading.Standard('...');
}

export const notiflixLoaderEnd = () => {
    Notiflix.Loading.Remove()
}

export const notiflixStatus = (status, msg) => {
    console.log("asdsad")
    if (status) {
        Notiflix.Loading.Remove()
        Notiflix.Notify.Success(msg)
    }
    else {
        console.log("asdsad")
        Notiflix.Loading.Remove()
        Notiflix.Notify.Failure(msg)
    }

}

export const notificationStatus = (result) => {
    
    Notiflix.Loading.Remove()
    if (result.status) {
        Notiflix.Notify.Success(result.message)
    }
    else {        
        Notiflix.Notify.Failure(result.message)
    }

}

//RealTime Notifications
export const realTimeNotification = (status, msg) => {
    if (status) {
        Notiflix.Loading.Remove()
        Notiflix.Notify.Success(msg)
    }
    else {
        console.log("asdsad")
        Notiflix.Loading.Remove()
        Notiflix.Notify.Failure(msg)
    }

}

export const notif = () => {
    console.log("sadsad")
    return (
        <UncontrolledAlert color="info" fade={false}>
            I am an alert and I can be dismissed!
        </UncontrolledAlert>
    )
}

//Informative Notifications
