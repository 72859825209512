import React from 'react';

const NotificationImportant = (props) =>{
    return (
        <div className="">
            <table className="table table-bordered" >
                <tbody>
                    <tr>
                        <td style={{width:'15%', backgroundColor:'white'}}>adsadsadsad</td>
                        <td>adsadsadsad</td>
                    </tr>
                </tbody>
            
            </table>
        </div>
    )
}

export default NotificationImportant;