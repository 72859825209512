import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';

import { INVOICE_REPORT } from "../../../../APICall/urls/report";
import { postAdminAPI } from "../../../../APICall/index";

import Search from '../filter/invoiceFilter';
import {columns} from '../allList/invoice';
import EmptyComponent from '../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../store/actions";
import "./style.css";
import { toFixed } from '../../../../Utils/stringManupulation';
import { dateUSStandard } from '../../../../Utils/dateFunctions';

class PayRateReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: null,
        }
    }

    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Reports", this.state.breadcrumbItems);
        this.handleFilter({
            "client": "",
            "startDate": "2024-01-01",
            "endDate": "2024-03-30",
            "filter": "2024-03-30"
        })
    }

    // Filter
    handleFilter = async(filterData) => {
        console.log(filterData);
        // this.fetchAllData();
        let results = await postAdminAPI(INVOICE_REPORT, filterData)
        console.log(results);
        if(results.status){
            this.generateData(results.data)
        }

    }

    generateData = (data) =>{
        let _row = [];

        let amount = 0;
        let regular = 0;
        let holiday = 0;

        data.map((d) =>{
            amount += d.amount;
            regular += d.regular_hours;
            holiday += d.holiday_hours;
            _row.push({
                ...d,
                invoice_date:dateUSStandard(d.invoice_date),
                amount:toFixed(d.amount),
                regular_hours:toFixed(d.regular_hours),
                holiday_hours:toFixed(d.holiday_hours),
                total_hours:toFixed(d.regular_hours+d.holiday_hours)
            })
        })
        _row.push({
            name: "",
            invoice_date: <strong>TOTAL</strong>,
            amount: toFixed(amount),
            regular_hours: toFixed(regular),
            holiday_hours: toFixed(holiday),
            total_hours: toFixed(regular+holiday),
        });
        this.setState({...this.state, allData: _row})
    }

    render() {

        const { allData } = this.state;

        return (
            <>
                <Search
                    handleFilter={this.handleFilter}
                />
                {
                    allData
                    ?
                        allData.length > 0
                        ?
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    striped
                                    hover
                                    bordered
                                    data={{ columns: columns, rows: allData }}
                                    className='invoiceReportClass'
                                />
                            </CardBody>
                        </Card>
                        : <EmptyComponent />
                    : null

                }

            </>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(PayRateReport);;