import React, { useState, useEffect } from 'react';
import C3Chart from 'react-c3js';
import { Col, Row, Card, CardHeader } from "reactstrap";
import 'c3/c3.css';

const donut = {
    width: 30,
    label: { show: !1 }
};

const color = {
    pattern: ["#f0f1f4", "#7a6fbe", "#28bbe3", "#f6c23e"]
};

const size = {
    height: 300,
    width: 700
};

const shift = {
    columns: [
        ["Scheduled", 0],
        ["Completed", 0],
        ["Cancelled", 0]
    ],
    type: "donut",
}

const contractors = {
    columns: [
        ["Active", 0],
        ["Inactive", 0],
        ["Profile Expired", 0],
        ["On the Go", 0]
    ],
    type: "donut",
}

const MonthlyEarnings = (props) => {


    const [data, setData] = useState(null)

    

    useEffect(() => {
        console.log(props)
        if (props.data) {
            if (props.title === "Shifts") {
                let _data = {
                    ...shift,
                }
                _data.columns[0][1] = props.data.scheduled ? props.data.scheduled : 0;
                _data.columns[1][1] = props.data.completed ? props.data.completed : 0;
                _data.columns[2][1] = props.data.cancelled ? props.data.cancelled : 0;
                console.log(_data);
                setData(_data);
            }
            else if(props.title === "Contractors"){
                
                    let _data = {
                        ...contractors,
                    }
                    _data.columns[0][1] = props.data.active ? props.data.active : 0;
                    _data.columns[1][1] = props.data.inActive  ? props.data.inActive : 0;
                    _data.columns[2][1] = props.data.pendingCheques ? props.data.pendingCheques  :0;
                    _data.columns[3][1] = props.data.valid ?  props.data.valid  :0;
                    console.log(_data);
                    setData(_data);
                
            }

        }
    }, [props])

    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <Row>
                        <Col md={6}>
                            {props.title}
                        </Col>
                    </Row>
                </CardHeader>
                {
                    data
                        ?
                        <C3Chart data={data} donut={{ ...donut, title: props.title }} color={color} size={size} />
                        : null
                }

            </Card>
        </React.Fragment>
    );

}

export default MonthlyEarnings;
