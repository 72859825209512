import React from "react";
import { GoogleMap, StandaloneSearchBox, Marker } from "@react-google-maps/api";
const google = window.google;

let markerArray = [];
class Map extends React.Component {
    state = {
        currentLocation: null,
        current:{},
        markers: [],
        bounds: null,
        zoom:5,
    };

    onMapLoad = map => {
        navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
                const pos = { lat, lng };
                this.setState({ current: pos });
            }
        );
        new window.google.maps.event.addListener(map, "bounds_changed", () => {
            // console.log(map.getBounds());
            this.setState({ bounds: map.getBounds() });
        });
    };

    onSBLoad = ref => {
        this.searchBox = ref;
    };

    onPlacesChanged = () => {
        markerArray = [];
        let results = this.searchBox.getPlaces();
        // console.log(results)
        let place = results[0].geometry.location;
        markerArray.push(place);
        this.setState({ markers: markerArray });

        let location = { lat: markerArray[0].lat(), lng: markerArray[0].lng() }
        this.updateCurrent(location, results[0].formatted_address);
    };

    updateCurrent = (location, address) =>{
        this.setState({ currentLocation: location, zoom:5 });
        
    }

    sendCurrentLocation = () =>{
        let location = {...this.state.currentLocation};
        this.props.updateLocation(location.lat, location.lng);
    }

    componentDidMount() {
        // console.log(this.props)
        if(this.props.location){
            // console.log(this.props.location)
            let marker = {...this.props.location}
            this.setState({ markers:[{ lat: marker.lat, lng: marker.lng }],currentLocation:{ lat: marker.lat, lng: marker.lng }, zoom:5 });
        }
        else{
            navigator?.geolocation.getCurrentPosition(
                ({ coords: { latitude: lat, longitude: lng } }) => {
                    const pos = { lat, lng };
                    this.setState({ currentLocation: pos });
                }
            );
        }
    }

    render() {
        return (
            <div>
                <div id="searchbox">
                    <StandaloneSearchBox
                        onLoad={this.onSBLoad}
                        onPlacesChanged={this.onPlacesChanged}
                        bounds={this.state.bounds}
                    >
                        <input
                            type="text"
                            placeholder="Customized your placeholder"
                            style={{
                                boxSizing: `border-box`,
                                border: `1px solid transparent`,
                                height: `42px`,
                                padding: `0 12px`,
                                borderRadius: `3px`,
                                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                fontSize: `14px`,
                                outline: `none`,
                                textOverflow: `ellipses`,
                                position: "absolute",
                                left: "40%",
                                zIndex: 999,
                            }}
                        />
                    </StandaloneSearchBox>
                </div>
                <br />
                <div>
                    <GoogleMap
                        center={this.state.currentLocation}
                        zoom={this.state.zoom}
                        onLoad={map => this.onMapLoad(map)}
                        mapContainerStyle={{ height: '100vh', width: "100%" }}
                    >
                        {this.state.markers.map((mark, index) => (
                            <Marker key={index} position={mark} />
                        ))}
                    </GoogleMap>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-md-12 text-center">
                            <button className="btn btn-primary" onClick={this.sendCurrentLocation}>Update Location</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Map;