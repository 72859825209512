export const cretateColumn = (data, defaultColumn) => {
    return data.map((d, i) => {
        return {
            label: titleCase(d),
            field: d,
            width: 150,
            isSort: true, //if true it is allowed to be sort
            sortValue: "asc", // Sort value "asc", "desc"
            isSortActive: false, //if true sort will be appled to the current filter
            isVisible: defaultColumn.findIndex((dc) => dc === d) >= 0 ? true : false, // if checkd it will be visible on table,
            isVariable: true
        }
    })
}

export const titleCase = (s) =>
    s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_

export const removeFirstLastChar = (str) => str.slice(1, -1);

export const convertColumnForSort = (column) => {
    let sortValue = []
    column.forEach((c) => {
        if (c.isSortActive)
            sortValue.push([c.field, c.sortValue])
    })
    return sortValue;
}

export const getContractorFilter = (cont) => {
    let _data = []
    cont.forEach((d) => {
        let c = d.label.split(" ");
        _data.push(["ctr_f_name", "LIKE", `%${c[0]}%`])
        _data.push(["ctr_l_name", "LIKE", `%${c[1]}%`])
    })
    return _data;
}

export const getArrayFilter = (data, key) => {
    let _data = []
    data.forEach((d) => {
        _data.push([key, "LIKE", `%${d.label}%`])
    })
    return _data;
}

export const getListFilter = (data, list, code) => {
    code = code || "id"
    let field = list.filter((l) => l[code] == data)
    if (field.length > 0)
        return field[0].value
}

export const updateGeneralFilter = (filterData, extraFilter) => {
    let _allFilter = []
    if (filterData.length)
        _allFilter = [
            ..._allFilter,
            ...filterData
        ]
    if (extraFilter.length)
        _allFilter = [
            ..._allFilter,
            ...extraFilter
        ]
    return _allFilter
}

export const updateExtraFilter = (filters, extra) => {
    let _allFilter = []
    if (filters.length)
        _allFilter = [
            ..._allFilter,
            ...filters
        ]
    if (extra.length)
        _allFilter = [
            ..._allFilter,
            ...extra
        ]
    return _allFilter
}

export const generateCountForTable = (data, countColumn) => {
    let total = {}
    data.forEach((item) => {
        countColumn.forEach((c) => {
            total[c] = (total[c] ? total[c] : 0) + item[c]
        })
        // total["shift_count"] = (total["shift_count"] ? total["shift_count"] : 0) + item["shift_count"]
        // total["hours_worked"] = (total["hours_worked"] ? total["hours_worked"] : 0) + item["hours_worked"]
    })
    return total
}

export const cleanColumn = (data) => {
    let _data = []
    data.forEach((d) => {
        _data.push( d.replace(/^`|`$/g, ''))
    })
    return _data
}