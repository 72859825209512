export const columns = [
    {
        label: 'Shift Name',
        field: 'shift_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Shift Start Time',
        field: 'shift_start_time',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Shift End Time',
        field: 'shift_end_time',
        sort: 'disabled',
        width: 200
    },
    {
        label: 'Type',
        field: 'type',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Color',
        field: 'color',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Inactive',
        field: 'inactive',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Work Code',
        field: 'work_code',
        sort: 'asc',
        width: 100
    },
    // {
    //     label: 'Delete',
    //     field: 'delete',
    //     sort: 'asc',
    //     width: 100
    // },
    {
        label: 'Select',
        field: 'select',
        sort: 'asc',
        width: 100
    }
]