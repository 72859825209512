import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from '../../../component/datePicker/datePicker';
import Required from '../../../component/Required/star';

import { postAdminAPI } from '../../../APICall/index';
import { GET_ALL_CONTRACTOR, GET_ALL_CLIENT } from '../../../APICall/urls';


const SearchPayroll = (props) => {

    const [contractor, setContractor] = useState(0);
    const [client, setClient] = useState(0);
    const [first, setFirst] = useState(null);
    const [last, setLast] = useState(null);
    const [check, setCheck] = useState(null);
    const [starting, setStarting] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        let _contactor = [];
        let _client = [];
        if (contractor == 0) {
            props.allContractor.forEach(c => {
                _contactor.push((parseInt(c.id)));
            })
        }
        else {
            _contactor.push(parseInt(contractor))
        }

        if (client == 0) {
            props.allClients.forEach(c => {
                _client.push((parseInt(c.id)));
            })
        }
        else {
            _client.push(parseInt(client))
        }


        let data = {
            contractor: _contactor,
            client: _client,
            startDate: first,
            endDate: last,
            chequeDate: check,
            // starting:starting
        };
        console.log(data)
        props.handleFilter(data);
    }

    const handleDateChange = (name, date) => {
        console.log(name, date);
        if (name === "first") {
            setFirst(date);
        }
        else if (name === "last") {
            setLast(date);
        }
        else if (name === "check") {
            setCheck(date)
        }

    }

    return (
        <Card style={{ marginBottom: 80 }}>
            <CardHeader>
                Generate new payroll
            </CardHeader>
            <CardBody className="overflow-auto">
                <form onSubmit={handleSubmit}>
                    <div>
                        <Row>
                            <Col md={6}>
                                <label>Contractor for which to Generate Checks<Required /></label>
                                <select
                                    className="form-control"
                                    value={contractor}
                                    onChange={(e) => setContractor(e.target.value)}
                                >
                                    <option value={0} className="allOptions">All Contractors</option>
                                    {
                                        props.allContractor?.map((a, i) => (
                                            <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={6}>
                                <label>Client for which to Generate Checks<Required /></label>
                                <select
                                    className="form-control"
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <option value={null} className="allOptions">All Clients</option>
                                    {
                                        props.allClients && props.allClients.map((a, i) => (
                                            <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <label>First Shift Date to Include on Checks<Required /></label>
                                <br />
                                <DatePicker
                                    date={first}
                                    name={"first"}
                                    required={true}
                                    handleDateChange={handleDateChange}

                                />
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    required
                                    value={first}
                                    
                                    onChange={(e) => setFirst(e.target.value)}
                                /> */}
                            </Col>
                            <Col md={6}>
                                <label>Last Shift Date to Include on Checks<Required /></label>

                                <DatePicker
                                    date={last}
                                    name={"last"}
                                    required={true}
                                    handleDateChange={handleDateChange}

                                />
                                {/* <input
                                    type="date"
                                    className="form-control"
                                    required
                                    value={last}
                                    onChange={(e) => setLast(e.target.value)}
                                /> */}
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <label>Check Date<Required /></label>

                                <DatePicker
                                    date={check}
                                    name={"check"}
                                    required={true}
                                    handleDateChange={handleDateChange}
                                    popperPlacement="top"

                                />
                                {/* <input
                                    type="date"
                                    className="form-control"
                                    required
                                    value={check}
                                    onChange={(e) => setCheck(e.target.value)}
                                /> */}
                            </Col>
                            <Col md={6}>
                                <label>Starting Check Number<Required /></label>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={props.chqNumber}
                                    disabled={props.selected.size === 1 ? false : true}
                                    onChange={(e) => props.setChqNumber(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col md={12} className="text-center">
                            <hr />
                            <button type="submit" className="btn btn-success">
                                Fetch List
                            </button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>

    )
}

export default SearchPayroll;