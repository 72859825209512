export const shiftType = [{
    id: 1,
    value: "DAY"
},
{
    id: 2,
    value: "EVE"
},
{
    id: 3,
    value: "NGT"
}];
