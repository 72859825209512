import React, { useState, useEffect } from "react";
import Required from "../../../../component/Required/star";

const stateList = ["Bihar", "Kerala", "UP"];

const AddressFrom = (props) => {


    useState(() => {
        console.log(props)
    });



    return (
        <div className={`row`}>
            <div className={`col-12`}>
                <div className={`row`}>
                    <div className={`col-2`}>Name<Required /></div>
                    <div className={`col-10`}>
                        <input
                            className={`form-control`}
                            placeholder={`Enter Name`}
                            onChange={(e) => props.handleChange(e, props.name.name)}
                            value={props.name.value}
                        />
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-2`}>
                        Address<Required />
                    </div>
                    <div className={`col-5`}>
                        <input
                            className={`form-control`}
                            placeholder={`Enter Address Line 1`}
                            onChange={(e) => props.handleChange(e, props.add1.name)}
                            value={props.add1.value}
                        />
                    </div>
                    <div className={`col-5`}>
                        <input
                            className={`form-control`}
                            placeholder={`Enter Address Line 2 `}
                            onChange={(e) => props.handleChange(e, props.add2.name)}
                            value={props.add2.value}
                        />
                    </div>
                </div>
                <div className={`row`}>
                    <div className={`col-2`}> City State Zip</div>

                    <div className={`col-3`}>
                        <input
                            className={`form-control`}
                            placeholder={`Enter City`}
                            onChange={(e) => props.handleChange(e, props.city.name)}
                            value={props.city.value}
                        />
                    </div>
                    <div className={`col-4`}>
                        <select
                            className={`form-control`}
                            onChange={(e) => props.handleChange(e, props.state.name)}
                            value={props.state.value}
                        >
                            <option value={``}>Select State</option>
                            {props.allState && props.allState.length > 0 && props.allState.map((s) => {
                                return (
                                    <option key={s.id} value={s.id}>
                                        {s.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className={`col-3`}>
                        <input
                            className={`form-control`}
                            placeholder={`Enter Zip`}
                            type="number"
                            onChange={(e) => props.handleChange(e, props.zip.name)}
                            value={props.zip.value}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddressFrom;
