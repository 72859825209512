import React, { Component } from "react";
import { Card, CardHeader, Input, Row, Col, Button } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/actions";

import { GET_CRED, UPDATE_CRED } from "../../../APICall/urls/settings";
import { getAdminAPI, postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from "../../../component/Notiflix/notiflix"


class ManageCredentials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [],
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Manage Credentials", this.state.breadcrumbItems);
        this.getAllData();
    }

    getAllData = async () => {
        let result = await getAdminAPI(GET_CRED)
        // console.log(result);
        if (result) {
            this.setState({ allData: result });
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault()

        let result = await postAdminAPI(UPDATE_CRED, this.state.allData)
        console.log(result);
        notificationStatus(result)
    }

    checkChecked = (ctrType, credType) => {
        // console.log(credType, ctrType);
        let _allData = [...this.state.allData];
        // console.log(this.state.allData)
        let data = this.findObject(_allData, ctrType, credType);
        // console.log(data)
        if(data && data.index >=0 && Object.keys(data.obj).length > 0){
            return data.obj.required == 1 ? true : false;
        }
        else{
            return false;
        }
    }

    handleChange = (e, ctrType, credType) => {
        // console.log( ctrType, credType);
        let _allData = [...this.state.allData];

        let data = this.findObject(_allData, ctrType, credType);
        // console.log(data)
        if(data && data.index >= 0){
            // console.log(data.index, _allData[data.index].required)
            _allData[data.index].required = _allData[data.index].required == 1 ?  0 : 1;
            // console.log(_allData)
            this.setState({allData: _allData});
        }
        else{
            _allData.push( {"ctr_type": ctrType, "cred_type": credType, "required": 1})
            this.setState({allData: _allData});
        }
        
        
    }

    findObject = (_allData, ctrType, credType) =>{
        let index = -1
        index = _allData.findIndex( (item, i) => {
            // console.log(item.ctr_type, ctrType, item.cred_type, credType)
            return item.cred_type == credType && item.ctr_type == ctrType
                
        });
        return{
            index: index,
            obj: _allData[index]
        }
    }

    render() {

        const { allData } = this.state;

        // console.log(allData)

        return (
            <Card>
                <CardHeader style={{ padding: '0.25rem 1.25rem' }}>
                    <Row>
                        <Col md={6}>
                            Mandatory Credentials based on Contractor Types
                        </Col>
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                    <table className="table table-bordered" width="100%">
                        <thead className="text-center">
                            <tr>
                                <th>Contractor Type</th>
                                {
                                    this.props.basicDetails.allCredentialsType && this.props.basicDetails.allCredentialsType.map((a, i) => (
                                        <th key={i}>{a.code}</th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="text-center" id="tbody">
                            {
                                this.props.basicDetails.allContractorTypes && this.props.basicDetails.allContractorTypes.map((c, j) => {
                                    if(true){
                                        return(
<tr>
                                        <td className="text-center">{c.code}</td>
                                        {
                                            this.props.basicDetails.allCredentialsType && this.props.basicDetails.allCredentialsType.map((a, i) => (
                                                <td>
                                                    {/* <label>{a.id}{c.id}</label> */}
                                                    <input
                                                        type="checkbox" className="form-control"
                                                        checked={this.checkChecked(c.id,a.id)}
                                                        onChange={(e) =>this.handleChange(e, c.id, a.id)}
                                                    />
                                                </td>
                                            ))
                                        }


                                    </tr>
                                        )
                                    }
                                    
                                })
                            }

                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <button 
                                type="button" 
                                className="btn btn-primary"
                                onClick={this.handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

};

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(ManageCredentials);

