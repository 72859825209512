import React, { Component } from "react";
import { connect } from "react-redux";
import CreateNewModal from "../../pages/Orders/CreateNewModal/CreateNewModal";
import { dateMonthFormat,timeFormat } from '../../Utils/dateFunctions';
import { getBulkDetails, updateBulkDetails } from '../../Utils/orderfunctions';
import BulkOrderComp from './BulkOrderComp/BulkOrderComp';
import { postAdminAPI } from '../../APICall/index';
import { CREATE_BULK_ORDER, GET_BULK_ORDER, GET_ALL_SHIFT, ASSIGN_BULK_ORDER } from '../../APICall/urls';
import { setBreadcrumbItems } from "../../store/actions";
import { closeBulkModal } from "../../store/Modal/action";
import Alert from '../../component/Notiflix/Alert';
import Spinner from "../../component/Notiflix/Loading";
import './dashboard.scss';

const bulkOrderData = {
    time: "3:00pm - 11:00am",
    shift: "NGT",
    type: "LPN",
    status: "Open",
    clientConfirmed: "Yes",
    contracterConfirmed: "No"
}

class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            selectedClient: null,
            shifts:[],
            bulkOrders: {},

            message: false,
            error: null,
            loading: false,
        };
    }

    handleStatus = (loading, error, message) => {
        this.setState({loading:loading, error:error, message:message})
    }

    afterActionCalls = (content) =>{
        
        if(content.status){
            this.handleStatus(false, null, content.message);
            this.fetchBulkOrder(this.state.selectedClient);
        }
        else{
            console.log(content)
            this.handleStatus(false, content.message, null);
        }
        this.handleStatus(false, null, null)
        
    }


    componentDidMount() {
        this.props.setBreadcrumbItems("Bulk Orders", this.state.breadcrumbItems);
        let selectedClient = parseInt(localStorage.getItem('RNPlusClient'));

        this.setState({
            selectedClient: selectedClient
        })
        
        this.fetchShifts(selectedClient)
        this.fetchBulkOrder(selectedClient);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.basicDetails &&(prevProps.basicDetails.realtimeTimeStamp != this.props.basicDetails.realtimeTimeStamp)){
            this.fetchBulkOrder(this.state.selectedClient);
        }
    }

    fetchShifts = async (selectedClient) =>{
        let shifts  = await postAdminAPI(GET_ALL_SHIFT, {"client": selectedClient});
        this.setState({shifts: shifts.data})
        // console.log(shifts)
    }

    fetchBulkOrder = async (selectedClient) =>{
        let orders  = await postAdminAPI(GET_BULK_ORDER, { "client": selectedClient});
        console.log(orders)
        // console.log(getBulkDetails(orders.data))
        this.setState({bulkOrders:getBulkDetails(orders.data)})

    }

    handleBulkAdd = async(formData) =>{
        this.handleStatus(true, null, null);
        console.log(formData);
        let orders  = await postAdminAPI(CREATE_BULK_ORDER, formData);
        console.log(orders);
        if(orders){
            this.props.closeBulkModal();
        }
        this.afterActionCalls(orders);
    }

    getShiftDetails = (shiftId) =>{
        // console.log(this.state.shifts);

        let shift = this.state.shifts.filter(s => parseInt(s.id) === parseInt(shiftId))
        console.log(shift)
        if(shift.length > 0)
            return timeFormat(shift[0].start_time)+ " - "+ timeFormat(shift[0].end_time) 
        else
            return ''
    }

    generateBulkOrder = (bulkOrders) =>{
        let components = [];
        console.log(bulkOrders)
        let _dates = Object.keys(bulkOrders);

        _dates.map((b,i) =>{
            let _date = bulkOrders[b];
            let _shifts = Object.keys(_date);
            _shifts.map((s,j) =>{
                let _shift = _date[s];
                components.push(<div className="col-12 date-header">{dateMonthFormat(b)} - ({this.getShiftDetails(s)})</div>)
                _shift.map((o,k) =>{
                    components.push(<BulkOrderComp 
                        onChangeClients = {this.onChangeClients}
                        date = {b}
                        shift = {s}
                        position = {k}
                        key={k} data={o} />)
                })
            })
        })
        console.log(components)
        return components;
    }

    onChangeClients  = (date, shift,position,  contractor) =>{
        let _bulkOrder = {...this.state.bulkOrders};
        _bulkOrder[date][shift][position].selectedSingle = contractor;
        this.setState({bulkOrders: _bulkOrder})
        this.generateBulkOrder(_bulkOrder)
    }

    handleUpdateBulk = async() =>{
        this.handleStatus(true, null, null);
        let orders = updateBulkDetails(this.state.bulkOrders);
        if(orders.length > 0){
            let response = await postAdminAPI(ASSIGN_BULK_ORDER, orders)
            console.log(response)
            this.afterActionCalls(response);
        }
        else{
            console.log(orders);
            this.afterActionCalls({status:false, message:"Please select at least one contractor"});
        }

        
    }



    render() {
        const {error, loading, message, bulkOrders} = this.state;
        console.log(bulkOrders);
       
        return( 
            <>
            { error ? <Alert msg={error} type="danger" /> : null }
            { loading ? <Spinner /> : null }
            { message ? <Alert msg={message} type="success" /> : null }
            
            <div className={`row`}>
                <div className={`col-12`} style={{position:'absolute','left':'91%', 'margin-top':'8px', zIndex:1000}}>
                    <button 
                        className="btn btn-primary"
                        onClick={this.handleUpdateBulk}
                    >
                        Submit
                    </button>
                </div>
                <div className={`col-12`}>
                    {
                        bulkOrders && Object.keys(bulkOrders).length > 0 ? this.generateBulkOrder(bulkOrders) : null
                    }
                    
                </div>
            </div>
            {
                this.props.bulkModal.bulkModal ?
                    <CreateNewModal
                        onClose={this.props.closeBulkModal}
                        isModalOpen={this.props.bulkModal.bulkModal}
                        modalData={this.state.selectedClient}
                        handleBulk={this.handleBulkAdd}
                    />
                    : null

            }
            </>
        )
    }
}

const mapStateToProps = state =>{
    return {
        basicDetails: state.BasicDetails,
        bulkModal: state.Modal
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems, closeBulkModal })(Order);
