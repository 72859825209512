import React from 'react';
//user Defined Functions
import { dateArithmetic, changeDateByTimezone } from '../../Utils/dateFunctions'
//Component
import AvailabilityRow from './AvailabilityRow';
import { days } from "../../Utils/Variables/days";

const Availability = (props) => {

    // console.log(props.days)

    const getHeader = () => {
        let _head = [];
        let current = props.startDate;
        // console.log(current, today);

        for (let i = 0; i < 7; i++) {
            let style = { backgroundColor: '#ffffff' }
            
            var d = changeDateByTimezone(current);
            var dayName = days[d.getDay()];
            _head.push(<th key={i} style={style} >{dayName}</th>)
            current = dateArithmetic(current, 1);
        }
        // console.log(_head)
        return _head;
    }

    const getAlwaysHeader = () => {
        // console.log(props.alwaysAvailability)
        let _head = [];
        for (let i = 0; i < 7; i++) {
            let style = { backgroundColor: '#ffffff' }
            _head.push(<th key={i} style={style} >{days[i]}</th>)
        }
        // console.log(_head)
        return _head;
    }
    // console.log(props.dailyAvailability)
    return (
        <div className="calendertable">
            <table className="table table-bordered" style={{marginBottom:0}} >
                <thead>
                    <tr>
                        {
                            props.alwaysAvailable
                            ?
                            getAlwaysHeader()
                            : 
                            getHeader()
                        
                        }
                    </tr>
                </thead>
                <tbody>
                    <AvailabilityRow
                        startDate={props.startDate}
                        showDate={props.showDate}
                        numberOfRow={props.numberOfRow}
                        handleModal={props.handleModal}

                        alwaysAvailable={props.alwaysAvailable}

                        alwaysAvailability={props.alwaysAvailability}
                        dailyAvailability={props.dailyAvailability}
                    />
                </tbody>

            </table>
        </div>

    )
}

export default Availability;