import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { currencyToString } from "../../../Utils/currency"

import { setBreadcrumbItems } from "../../../store/actions";
import PayrollTable from "./payrollTable";
import { useParams } from 'react-router-dom';
import { GET_SINGLE_PAYROLL } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI } from "../../../APICall/index";
import { changeTzToNormal } from "../../../Utils/dateFunctions"


import './payrollStyle.css';

const PayrollSummary = (props) =>{

    const [breadCrumbItems, setBreadCrumbItem] = useState([
        { title: "Payroll", link: "#" },
        { title: "View", link: "#" },
    ]);
    const [payrollId, setPayrollId] = useState(null);
    const [allData, setAllData] = useState(null)
    const [total, setTotal] = useState(0)
    const [string, setString] = useState('')

    const dispatch = useDispatch();
    let params = useParams();


    useEffect(() =>{
        dispatch(setBreadcrumbItems("SetUp", breadCrumbItems));
        setPayrollId(params.payroll_id);
        fetchAllData(params.payroll_id)

    },[])

    const fetchAllData = async (id) =>{
        let result = await postAdminAPI(GET_SINGLE_PAYROLL, {chequeNumber:id});
        console.log(result)
        if(result.status){
            if(result.data && Object.keys(result.data).length > 0)
                parseShifts(result.data)
        }
    }

    const parseShifts = (allData) =>{
        let shifts = [...allData.shifts]
        let _shifts = [];
        let _allData = {};
        let sum = 0
        shifts.map((s, i) =>{
            let _temp = JSON.parse(s.shift);
            _shifts.push({
                ...s,
                shift: _temp
            })
            sum = parseFloat(sum) + parseFloat(_temp.total)
        })
        console.log(_shifts)
        _allData = {
            ...allData,
            shifts: _shifts
        }
        setTotal(sum)
        setString(currencyToString(parseInt(sum)))
        setAllData(_allData);
    }

    const getName = () => {
        if (allData.pay_as_corporation == 1 && allData.corp_name && allData.corp_tax_id) {
            return allData.corp_name
        }
        else {
            return allData.f_name + " " + allData.l_name;
        }
    }

    return(
    <>
    {
        allData && Object.keys(allData).length > 0
        ?
        <div style={{backgroundColor:'white'}}>
            <table style={{width:'100%', marginTop:20}} className="t1">
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{paddingBottom:30}}>{changeTzToNormal(allData.chequeDate).date}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td style={{paddingBottom:20}}>{getName()}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>$ {total?.toFixed(2)}</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>{getName()}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>{allData.add_1+", "+allData.add_2}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td>{allData.city+", "+allData.state+", "+allData.zip}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </table>
        <br />
        <PayrollTable
            allData={allData}
            total={total}
            string={string}
            payrollId={payrollId}
        />
        <PayrollTable 
            allData={allData}
            total={total}
            string={string}
            payrollId={payrollId}
            style={{marginTop:50}} 
        />
        <br />
        <br />
        </div>
        
    : null
    }  
    </> 
    )
}

export default PayrollSummary