import React, { Component } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, CardHeader } from 'reactstrap';


import Table from '../../../component/Table/index';
import EmptyComponent from '../../../component/EmptyData';

import { setBreadcrumbItems } from "../../../store/actions";
import { GET_DELETED_TIMESLIP, TOGGLE_TIMESLIP } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../component/Notiflix/notiflix';
import { dateUSStandard, timeFormat } from '../../../Utils/dateFunctions';
import ImageModal from "../../../component/modal/imageModal"
import ConfirmModal from "../../../component/confirm";


class TrashPayroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [],
            apiData: [],
            isEmpty: false,
            columns: [
                {
                    label: 'Shift Date',
                    field: 'shift_date',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Shift Name',
                    field: 'shift_name',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Contractor',
                    field: 'contractorName',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Facility',
                    field: 'clientName',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Start Time',
                    field: 'startDate',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'End Time',
                    field: 'endDate',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'Type',
                    field: 'contractorType',
                    sort: 'enable',
                    width: 150
                },
                {
                    label: 'T Hrs',
                    field: 't_hrs',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Employee Id',
                    field: 'employee_id',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Supervisor',
                    field: 'supervisor',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: 'Supr. Signature',
                    field: 'signature',
                    sort: 'disabled',
                    width: 150
                },
                {
                    label: '',
                    field: 'action',
                    sort: 'disabled',
                    width: 150
                },
            ],

            imageModal: false,
            contractorSignature: '',

            editItem: null,
            deleteItem: null,
            deleteModal: false,
        }
    }


    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Deleted Timeslip", this.state.breadcrumbItems);
        this.getBasicInfo();
    }


    // Filter
    getBasicInfo = async () => {
        // console.log(filter)
        let result = await postAdminAPI(GET_DELETED_TIMESLIP, {})
        // console.log(result)
        if (result.status) {
            if (result.data.deleted && result.data.deleted.length > 0) {


                this.fetchAllData(result.data.deleted);
                this.setState({ apiData: result.data.deleted })
            }
            else {
                this.fetchAllData([], new Set());
                this.setState({ apiData: [] })
            }


        }

    }



    handleDeleteClick = (a) => {
        this.setState({
            deleteItem: a,
            deleteModal: true,
        })
    }

    handleDelete = async () => {
        console.log(this.state.deleteItem)
        if (this.state.deleteItem) {
            notiflixLoaderStart();
            let data = {
                "timeslipId": this.state.deleteItem.id,
                "status": "timeslip"
            }
            console.log(data)
            let result = await postAdminAPI(TOGGLE_TIMESLIP, data)
            console.log(result)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    this.setState({
                        deleteItem: null,
                        deleteModal: false,
                    })
                    this.getBasicInfo()
                }
            }
        }
        else {
            notiflixStatus(false, "Please select at least one item to continue.")
        }

    }

    // AllNewData
    fetchAllData = (apiData) => {
        let _allData = []
        apiData && apiData.length > 0 && apiData.map(a => {
            let start = a.startDate.split(" ");
            let end = a.endDate.split(" ");
            // console.log(a)
            // console.log(a.moreInfo ? "amrit" : "anand")
            _allData.push({
                shift_date: dateUSStandard(start[0]),
                shift_name: a.shiftType,
                contractorName: a.fName + " " + a.lName,
                clientName: a.name,
                startDate: timeFormat(start[1]),
                endDate: timeFormat(end[1]),
                contractorType: a.code,
                t_hrs: a.totalHours,
                employee_id: a.employeeId,
                supervisor: a.supervisorName,
                signature: a.moreInfo ? a.moreInfo : <img style={{ height: 40 }} src={"data:image/png;base64, " + a.supervisorSignature} alt="" />,
                action: <>
                    {
                        this.props.login?.user?.super === "YES"
                            ?
                            <>
                                <i
                                    onClick={() => this.handleDeleteClick(a)}
                                    style={{ cursor: "pointer", fontSize: 18, fontWeight: "bold", color: "green" }} title="Restore Timeslip" className="mdi mdi-file-restore"
                                ></i>

                            </>
                            : null

                    }
                    <i
                        onClick={() => this.handleImageModal(a)}
                        style={{ cursor: "pointer", fontSize: 18, fontWeight: "bold", color: "blue" }} title="Contractor Signature" className="mdi mdi-image"
                    ></i>

                </>
                ,
            })
        })
        this.setState({ allData: _allData })
    }

    handleImageModal = (a) => {
        this.setState({ imageModal: true, contractorSignature: a.contractorSignature })
    }

    render() {

        const { allData, columns, imageModal, contractorSignature, deleteModal } = this.state;

        // console.log(this.props.login.user.super)

        return (

            <Card style={{ marginBottom: 200 }}>
                <CardHeader>
                    <div className="row">
                        <div className="col-md-6">
                            Deleted Timeslip
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    {
                        allData && allData.length > 0
                            ?
                            <>

                                <Table
                                    rows={allData}
                                    columns={columns}
                                    handleFilter={() => { }}
                                    handleGeneratePayroll={() => { }}
                                    isSubmit={false}
                                />
                            </>
                            : <EmptyComponent
                                className="mt-4 ml-1 mr-1"
                            />

                    }
                </CardBody>
                {
                    imageModal
                        ?

                        <ImageModal
                            isOpen={imageModal}
                            handleClose={() => this.setState({ imageModal: false })}
                            image={contractorSignature}
                            base64={true}
                            title={'Contractor Signature'}
                        />

                        : null
                }
                {
                    deleteModal
                        ?
                        <ConfirmModal
                            modal={deleteModal}
                            title="Restore Timeslip"
                            content="Do you want to restore this Timeslip."
                            toggle={() => this.setState({ deleteModal: false })}
                            click={() => this.handleDelete()}
                        />
                        : null
                }
            </Card>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        login: state.Login
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(TrashPayroll);;