import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';

import DatePicker from '../../../../component/datePicker/datePicker';
import Required from '../../../../component/Required/star';

import { postAdminAPI } from '../../../../APICall/index';
import { GET_ALL_CLIENT } from '../../../../APICall/urls';


const InvoiceFilter = (props) => {

    const [allClients, setAllClients] = useState([]);

    const [filter, setFilter] = useState({
        client: '',
        startDate: '',
        endDate: '',
    });


    useEffect(() => {
        getApiData();
    }, [])

    const getApiData = async () => {
        let clients = await postAdminAPI(GET_ALL_CLIENT);

        setAllClients(clients.data);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let _filter = {};
        let list = Object.keys(filter);
        list.map((l) => {
            if (filter[l]) {
                _filter = {
                    ...filter,
                    filter: filter[l]
                }
            }
        })

        console.log(_filter)
        props.handleFilter(_filter);
    }

    const updateFilter = (e, name) => {
        let _filter = { ...filter };
        _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    return (
        <>
            <Card>
                <CardHeader>
                    Generate invoice Report
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4}>
                                <label>Clients<Required /></label>
                                <select
                                    className="form-control"
                                    value={filter.client}
                                    onChange={(e) => updateFilter(e, "client")}
                                >
                                    <option value="" className="allOptions">All Clients</option>
                                    {
                                        allClients && allClients.map((a, i) => (
                                            <option key={i} value={a.id}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={4}>
                                <label>Start Date</label>
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    value={filter.invoiceDate}
                                    onChange={(e) => updateFilter(e, "invoiceDate")}
                                /> */}
                                <DatePicker
                                    date={filter.startDate}
                                    name={"startDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                            <Col md={4}>
                                <label>End Date</label>
                                {/* <input  
                                    type="date" 
                                    className="form-control"
                                    value={filter.invoiceDate}
                                    onChange={(e) => updateFilter(e, "invoiceDate")}
                                /> */}
                                <DatePicker
                                    date={filter.endDate}
                                    name={"endDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>

                        </Row>

                        <Row>
                            <Col md={12} className="text-center">
                                <hr />
                                <button type="submit" className="btn btn-primary">
                                    Generate Report
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default InvoiceFilter;