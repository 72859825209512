import React from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import "./styles.css"

const Table = (props) => {

    console.log(props);
    const handleCheckbox = (e, index) => {
        let tempData = Array.from(props.columns);
        tempData[index].isVisible = e.target.checked
        props.updateColumns(tempData)
    }

    const handleDragEnd = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(props.columns);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        props.updateColumns(tempData);
    };

    return (
        <div className="mdb-datatable dt-bootstrap4">
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className="row mb-2 mt-4">
                    <Droppable droppableId="droppable-1">
                        {(provider) => (

                            <div ref={provider.innerRef}
                                {...provider.droppableProps} className="col-md-12">
                                {
                                    props.columns.map((p, i) => (
                                        p.isVariable
                                            ?
                                            <Draggable
                                                key={p.label}
                                                draggableId={p.label}
                                                index={i}
                                            >
                                                {(provider) => (
                                                    <div className="input-group justify-content-between"  {...provider.draggableProps} ref={provider.innerRef}>
                                                        <div className='d-flex align-items-center'>
                                                            <input
                                                                key={i}
                                                                className={"input-field input-checkbox"}
                                                                title={p.label}
                                                                type="checkbox"
                                                                checked={p.isVisible}
                                                                onChange={(e) => handleCheckbox(e, i)}
                                                            />
                                                            <label htmlFor={"htmlFor"} className={"input-label"}>
                                                                {p.label}
                                                            </label>
                                                        </div>
                                                        <span style={{cursor: 'grab', fontSize:12}} {...provider.dragHandleProps}> = </span>
                                                    </div>
                                                )}

                                            </Draggable>

                                            : null
                                    ))
                                }
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>

        </div >
    )
}

export default Table;