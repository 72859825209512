import React, { useState, useEffect } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import { generateCellNumber } from '../../../../shared/numberFormat';


const AddUser = (props) => {
    const [name, setName] = useState('');

    const onSubmitHandler = (e) => {
        e.preventDefault();
        let data = [{
            "name": name,
            "status": "ACTIVE"
        }]
        props.addArea(data);
    }

    // console.log(tableRow, contractorTypes, t)

    return (
        <Modal size="md" isOpen={props.modal}>
            <ModalHeader>
                <div className={`create-order-modal-header`}>
                    <span>Area</span>
                    <CloseIcon
                        onClick={() => props.toggle()}
                        className={`create-modal-close-icon`}
                    />
                </div>
            </ModalHeader>

            <ModalBody>
                <form onSubmit={onSubmitHandler}>
                    <div className={`create-order-modal-body`}>
                        <div className={`row`}>
                            <div className={`col-md-3 mb-2`}>Name</div>
                            <div className={`col-md-9 mb-2`}>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    placeholder="Enter Name"
                                />
                            </div>
                            <div className={`col-md-12 text-center`} >
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

export default AddUser;
