import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import DatePicker from '../../../component/datePicker/datePicker';
import { dateArithmetic } from "../../../Utils/dateFunctions"
const SearchPayroll = (props) => {

    const [contractor, setContractor] = useState(0);
    const [client, setClient] = useState(0);
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [type, setType] = useState(0);



    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            contractorId: parseInt(contractor),
            clientId: parseInt(client),
            start_date: first,
            end_date: last ? dateArithmetic(last,1) : "",
            ctr_type: parseInt(type),
        };
        // console.log(data)
        props.handleFilterData(data);
    }

    const handleDateChange = (name, date) => {
        // console.log(name, date);
        if (name === "first") {
            setFirst(date);
        }
        else if (name === "last") {
            setLast(date);
        }
    }

    return (
        <>
            <Card style={{ marginBottom: 200 }}>
                <CardHeader>
                    <div className="row">
                        <div className="col-md-6">
                            {props.title}
                        </div>
                        {
                            props.openAdd
                                ?
                                <div className="col-md-6 text-right">
                                    <button type="button" className="btn btn-primary" onClick={props.openAdd}>Add New</button>
                                </div>
                                : null

                        }

                    </div>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={4}>
                                <label>Contractor</label>
                                <select
                                    className="form-control"
                                    value={contractor}
                                    onChange={(e) => setContractor(e.target.value)}
                                >
                                    <option value={0}>Select Contractor</option>
                                    {
                                        props.allContractor && props.allContractor.map((a, i) => (
                                            <option key={i} value={parseInt(a.id)}>{a.name+" ("+a.code+")"}</option>
                                        )) 
                                    }
                                </select>
                            </Col>
                            <Col md={4}>
                                <label>Client</label>
                                <select
                                    className="form-control"
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                >
                                    <option value={null}>Select Client</option>
                                    {
                                        props.allClients && props.allClients.map((a, i) => (
                                            <option key={i} value={parseInt(a.id)}>{a.name}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={4}>
                                <label>Type</label>
                                <select
                                    className="form-control"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                >
                                    <option value={null}>Select Type</option>
                                    {
                                        props.allTypes && props.allTypes.map((a, i) => (
                                            <option key={i} value={parseInt(a.id)}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <label>{props.invoice ? "Processed" : ""} Start Date</label>
                                <br />
                                <DatePicker
                                    date={first}
                                    name={"first"}
                                    required={false}
                                    handleDateChange={handleDateChange}

                                />
                            </Col>
                            <Col md={4}>
                                <label>{props.invoice ? "Processed" : ""} End Date</label>

                                <DatePicker
                                    date={last}
                                    name={"last"}
                                    required={false}
                                    handleDateChange={handleDateChange}

                                />
                            </Col>
                            <Col md={4} className="text-center">
                                <br />
                                <button type="submit" className="btn btn-success mt-2">
                                    Submit
                                </button>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>
        </>
    )
}

export default SearchPayroll;