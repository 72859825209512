import React, { Component } from "react";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import AddRateModal from "../../../component/contractorRate/addRateModal";
import PayRareCard from "../../../component/contractorRate/payRareCard";
import RateInfoCard from "../../../component/contractorRate/rateInfoCard";
import { GET_ALL_SHIFT } from '../../../APICall/urls';
import { GET_PAY_RATE, UPDATE_PAY_RATE, DELETE_PAY_RATE } from '../../../APICall/urls/contractor';
import { postAdminAPI } from '../../../APICall/index';
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import ConfirmModal from "../../../component/confirm/confirmForOrder";


class PayRatesSetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            addModal: false,
            allRates: [],
            singleShift: {},
            allClients: [],
            selectedClient: null,
            allShifts: [],

            selectedContractor: null,
            selectedShift: null,
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Pay Rates Setup", this.state.breadCrumbItems);
        let allClients = JSON.parse(localStorage.getItem('RNPlusAllClient'));

        let contractor = parseInt(JSON.parse(localStorage.getItem('RNPlusContractor')));
        console.log(contractor)
        this.setState({ allClients: allClients, selectedContractor: contractor })
        this.getAllRates(contractor);
    }



    getAllRates = async (contractor) => {
        let data = {
            "ctr": parseInt(contractor)
        };
        console.log(data);
        let result = await postAdminAPI(GET_PAY_RATE, data)
        console.log(result);
        if (result.status) {
            this.setState({ allRates: result.data })
        }
    }

    getAllShifts = async (client) => {
        let data = {
            "client": client
        };
        console.log(data)
        let result = await postAdminAPI(GET_ALL_SHIFT, data)
        console.log(result);
        if (result.status) {
            console.log(result.data)
            this.setState({ allShifts: result.data })
        }
    }

    handleModal = () => {
        this.setState({ addModal: !this.state.addModal })
    }

    handleChangeAdd = () => {
        this.setState({ singleShift: {} })
        this.handleModal();
    }

    handleSave = async (shift) => {
        notiflixLoaderStart()
        shift = {
            ...shift,
            ctr: this.state.selectedContractor
        }
        console.log(shift)
        let result = await postAdminAPI(UPDATE_PAY_RATE, shift)
        console.log(result);
        if (result) {
            notificationStatus(result)
            console.log(result.data)
            if (result.status) {
                this.getAllRates(this.state.selectedContractor)
                this.handleModal();
            }
        }
    }

    handleView = (shift) => {
        this.setState({ singleShift: shift })
    }

    handleEdit = (shift) => {
        this.setState({ singleShift: shift })
        this.handleModal();
    }

    onDeleteClick = (shift) => {
        this.setState({ selectedShift: shift.id })
    }

    

    handleDelete = async () => {
        notiflixLoaderStart();
        let data = {
            id: this.state.selectedShift,
            status: 0
        }
        // console.log(shift, data);
        let result = await postAdminAPI(DELETE_PAY_RATE, data)
        console.log(result);
        if (result) {
            console.log(result.data)
            notificationStatus(result)
            if (result.status)
                this.getAllRates(this.state.selectedContractor)

            this.setState({ selectedShift: null })
        }
    }

    render() {

        const { addModal, allRates, singleRate, allClients, allShifts, selectedClient, singleShift } = this.state;
        console.log(allRates)
        return (
            <div>
                <PayRareCard
                    allRates={allRates}
                    handleChangeAdd={this.handleChangeAdd}
                    handleEdit={this.handleEdit}
                    handleDelete={this.onDeleteClick}
                    handleView={this.handleView}

                />
                {
                    singleShift && Object.keys(singleShift).length > 0
                        ?
                        <RateInfoCard
                            singleShift={singleShift}
                        />
                        : null

                }


                {
                    addModal
                        ?
                        <AddRateModal
                            isOpen={addModal}
                            handleClose={this.handleModal}
                            handleSave={this.handleSave}
                            getAllShifts={this.getAllShifts}
                            allClients={allClients}
                            selectedClient={selectedClient}
                            allShifts={allShifts}
                            singleShift={singleShift}
                        />
                        : null
                }
                {
                    this.state.selectedShift
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to delete this rate?",
                                    proceed: true
                                }

                            }
                            title="Delete rate"
                            toggle={() => this.setState({ selectedShift: null })}
                            click={this.handleDelete}
                        />
                        : null
                }
            </div>
        )
    }
};

export default connect(null, { setBreadcrumbItems })(PayRatesSetup);

