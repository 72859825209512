import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
} from "reactstrap";
const CustomInfo = (props) => {
    const [state, setState] = useState({
        // "id": 1,
        // "contractorId": 1,
        "mode": "direct",
        "directAcct": "",
        "directRouting": "",
        "payAsCorporation": false,
        "corpName": "",
        "corpTaxId": ""
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            ...state
        }
        // console.log(data);
        props.updatePaySlip(data);
    }


    useEffect(() => {
        console.log(props.paySlip.mode);
        if (props.paySlip && Object.keys(props.paySlip).length > 0) {
            setState({
                // "id": 1,
                // "contractorId": 1,
                "mode": props.paySlip.mode,
                "directAcct": props.paySlip.direct_acct,
                "directRouting": props.paySlip.direct_routing,
                "payAsCorporation": props.paySlip.pay_as_corporation,
                "corpName": props.paySlip.corp_name,
                "corpTaxId": props.paySlip.corp_tax_id,
            })
        }
    }, [props])


    const handleChange = (e, name, type) => {
        let value = '';
        if (type === "checkbox")
            value = !state[name]
        else
            value = e.target.value;
        let _state = { ...state };
        _state[name] = value;
        setState(_state)
    }
    // console.log(state);

    return (
        <form onSubmit={handleSubmit}>
            <Row>
                <Col md="2" htmlFor="us">
                    Pay as Corporate
                </Col>
                <Col md="2" className="text-left">
                    <input
                        type="checkbox"
                        class="form-control checkbox-size"
                        onChange
                        ={(e) => handleChange(e, "payAsCorporation", "checkbox")}
                        id="us"
                        checked={state.payAsCorporation}
                    />
                </Col>
                <Col md="1" htmlFor="drives">
                    Tax Id
                </Col>
                <Col md="3" className="text-center">
                    <input
                        type="text"
                        class="form-control"
                        value={state.corpTaxId}
                        onChange={(e) => handleChange(e, "corpTaxId", "")}
                        id="drives"
                    />
                </Col>
                <Col md="1" htmlFor="drives">
                    Corp. Name
                </Col>
                <Col md="3" className="text-center">
                    <input
                        type="text"
                        class="form-control"
                        value={state.corpName}
                        onChange={(e) => handleChange(e, "corpName", "")}
                        id="drives"
                    />
                </Col>
            </Row>
            <Row>
                <Col md="4">
                    Mode of payment
                </Col>
                <Col md="8">
                    <select
                        type="text"
                        className="form-control"
                        value={state.mode}
                        onChange={(e) => handleChange(e, "mode", "")}

                    >
                        <option value="direct">Direct Deposit</option>
                        <option value="card">Card payment</option>
                        <option value="cheque">Cheque</option>
                    </select>
                </Col>
            </Row>
            {
                state.mode === "direct" || state.mode === 'card'
                    ?
                    <>
                        <Row>
                            <Col md="4">
                                Account Number
                            </Col>
                            <Col md="8">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state.directAcct}
                                    onChange={(e) => handleChange(e, "directAcct", "")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4">
                                Routing Number
                            </Col>
                            <Col md="8">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={state.directRouting}
                                    onChange={(e) => handleChange(e, "directRouting", "")}
                                />
                            </Col>
                        </Row>
                    </>
                    : null
            }

            <Row>
                <Col md="12" className="text-center">
                    <button
                        class="btn btn-outline-primary"
                        type="submit"
                    >Save</button>
                </Col>
            </Row>
        </form>

    )
}

export default CustomInfo;