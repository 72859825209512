export const columns = [
    {
        label: 'Client',
        field: 'client_name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Shift',
        field: 'name',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Sunday',
        field: 'd0',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Monday',
        field: 'd1',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Tuesday',
        field: 'd2',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Wednesday',
        field: 'd3',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Thursday',
        field: 'd4',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Friday',
        field: 'd5',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Saturday',
        field: 'd6',
        sort: 'asc',
        width: 100
    }
]