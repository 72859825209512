import React, {useState, useEffect} from 'react';
import {
    Col,
    Row,
} from "reactstrap";

const FollowUp  = () =>{

    const [cCompany, setCCompany] = useState('');
    const [us, setUs] = useState(false);
    const [drives, setDrives] = useState(false);
    const [green , setGreen] = useState('');
    const [shift , setShift] = useState('');

    return (
        <Row>
            <Col md="4">
                Contractor Company
            </Col>
            <Col md="8">
                <input
                    type="text"
                    className="form-control"
                    value={cCompany}
                    onChange={(e) => setCCompany(e.target.value)}
                />
            </Col>
        </Row>
    )
}

export default FollowUp;