export const holidayColumns = [
    {
        label: 'Holiday Name',
        field: 'hName',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Start Date',
        field: 'sDate',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Start Time',
        field: 'sTime',
        sort: 'asc',
        width: 200
    },
    {
        label: 'End Date',
        field: 'eDate',
        sort: 'asc',
        width: 100
    },
    {
        label: 'End Time',
        field: 'eTime',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Recurring',
        field: 'recurring',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Bill @',
        field: 'bill',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Pay @',
        field: 'pay',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Select',
        field: 'select',
        sort: 'asc',
        width: 100
    }
]