export const getSelectedRateIds = (allRates) => {
    let ids = []
    allRates.forEach((s) => {
        s.data.forEach((c) => {
            if (c.checked) {
                let _temp = []
                c.children.forEach((t) => {
                    t.active && _temp.push(t.id)
                })
                ids.push(..._temp)
            }
        })
    })
    return ids
}

export const getTempId  = () =>{
    return "temp"+Date.now();
}

export const removeTempData = (allHolidays) =>{
    let _avai = allHolidays.filter((a) => !a.id.toString().includes("temp"));
    let _new = allHolidays.filter((a) => a.id.toString().includes("temp"));
    console.log(_new);
    _new = _new.map((n) => {
        let _ = {...n}
        delete _.id 
        return _;
    })
    console.log(_new);
    return [..._avai, ..._new]
}