import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
    Card,
    CardBody,Row, Col, 
} from "reactstrap";
import { connect } from 'react-redux';
import { useDispatch } from "react-redux";
import { MDBDataTable } from 'mdbreact';
import { setBreadcrumbItems } from "../../../store/actions";
import { GET_ALL_PAYROLL, DELETE_SINGLE_PAYROLL } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI } from "../../../APICall/index";
import { dateUSStandard } from "../../../Utils/dateFunctions";
import ConfirmModal from "../../../component/confirm";
import { notiflixLoaderStart, notiflixLoaderEnd, notiflixStatus, notificationStatus } from '../../../component/Notiflix/notiflix';
import { toFixed } from '../../../Utils/stringManupulation';
import { currentDate, dateStandard, dateArithmetic } from '../../../Utils/dateFunctions';
import DatePicker from '../../../component/datePicker/datePicker';
import Required from '../../../component/Required/star';
const ViewAllPayroll = (props) => {

    const [breadCrumbItems, setBreadCrumbItem] = useState([
        { title: "Payroll", link: "#" },
        { title: "View", link: "#" },
    ]);
    const [allData, setAllData] = useState([]);
    const [selected, setSelected] = useState(new Set());
    const [modalConfirm, setModalConfirm] = useState(false)

    const [columns, setColumns] = useState([
        {
            label: <label className="container">
                <input
                    type="checkbox"
                    value={"all"}
                    className="form-control"
                    onClick={(e) => handleCheckboxAll(e, allData)}
                // checked={() => this.checkAllChech()}
                />
                <span className="checkmark"></span>
            </label>,
            field: 'select',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Cheque Number',
            field: 'cheque',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Issued To',
            field: 'issued',
            sort: 'asc',
            width: 270
        },
        {
            label: 'Cheque Date',
            field: 'date',
            sort: 'asc',
            width: 200
        },
        {
            label: 'Total Amount($)',
            field: 'amount',
            sort: 'asc',
            width: 100
        },
        {
            label: 'Regular Hours',
            field: 'regular',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Holiday Hours',
            field: 'holiday',
            sort: 'asc',
            width: 150
        },
        {
            label: 'Total Hours',
            field: 'hours',
            sort: 'asc',
            width: 150
        },
        {
            label: 'View',
            field: 'action',
            sort: 'asc',
            width: 100
        }
    ])
    const [filter, setFilter] = useState({
        contractors: '',
        startDate: dateArithmetic(currentDate(), -60),
        endDate: dateStandard(currentDate()),
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const updateFilter = (e, name) => {
        let _filter = { ...filter };
        _filter[name] = e.target.value;
        setFilter(_filter)
    }

    const updateFilterDate = (name, date) => {
        let _filter = { ...filter };
        _filter[name] = date;
        setFilter(_filter)
    }

    useEffect(() => {
        if(props.basicDetails.allContractor){
            dispatch(setBreadcrumbItems("SetUp", breadCrumbItems));
            fetchAllData(null, filter)
        }
    }, [props.basicDetails.allContractor])

    useEffect(() => {
        if (allData.length) {
            let _column = [...columns]
            _column[0] = {
                label: <label className="container">
                    <input
                        type="checkbox"
                        value={"all"}
                        className="form-control"
                        onClick={(e) => handleCheckboxAll(e, allData)}
                    // checked={() => this.checkAllChech()}
                    />
                    <span className="checkmark"></span>
                </label>,
                field: 'select',
                sort: 'asc',
                width: 150
            }
            setColumns(_column)
        }

    }, [allData])

    const fetchAllData = async (e, f) => {
        notiflixLoaderStart()
        if(e){
            e.preventDefault()
        }
        let filter = f ? {...f}: {}
        if(!filter.contractors){
            delete filter.contractors;
        }
        if(filter.contractors && typeof filter.contractors == "string"){
            filter.contractors = [filter.contractors]
        }

        let result = await postAdminAPI(GET_ALL_PAYROLL ,filter);
        if (result.status) {
            setAllData(result.data);
        }
        notiflixLoaderEnd()
    }


    // const updateDelete = (id) => {
    //     setSelected(id);
    //     setModalConfirm(true);
    // }
    const handleDelete = async () => {
        notiflixLoaderStart();
        setModalConfirm(false);
        let result = await postAdminAPI(DELETE_SINGLE_PAYROLL, { payroll_ids: [...selected] });
        // console.log(result)
        notiflixStatus(result.status, result.message);
        if (result.status) {
            setSelected(new Set())
            fetchAllData(null, filter);

        }
    }
    const fetchAllPayroll =  (allData, selected) => {
        console.log(allData, selected);
        let _allData = [];
        let amount = 0;
        let regular = 0;
        let holiday = 0;
        allData.forEach(h => {
            amount += h.amount;
            regular += h.regular_hours;
            holiday += h.holiday_hours;
            _allData.push({
                select: <input
                    type="checkbox"
                    checked={selected.has(h.id) ? true : false}
                    className="form-control"
                    onChange={(e) => handleChange(e, Number(h.id), allData)}
                />,
                cheque: h.id,
                issued: h.contractor_name,
                date: h.cheque_date ? dateUSStandard(h.cheque_date) : "",
                amount: toFixed(h.amount),
                regular: toFixed(parseFloat(h.regular_hours)),
                holiday: toFixed(parseFloat(h.holiday_hours)),
                hours: toFixed(parseFloat(h.regular_hours + h.holiday_hours)),
                action:
                    <>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleViewPayroll(h.id)}
                        >View</button>
                    </>
                ,
            });
        })

        _allData.push({
            select: "",
            cheque: "",
            issued: <strong>TOTAL</strong>,
            date: "",
            amount: toFixed(amount),
            regular: toFixed(regular),
            holiday: toFixed(holiday),
            hours: toFixed(regular + holiday),
            action: ""
        });

        // console.log(_allData);
        // return []
        return _allData
    }

    const handleViewPayroll = (id) => {
        history.push("/view_payroll/" + id);
    }

    const updateDelete = (id) => {
        if ([...selected].length) {
            setModalConfirm(true);
        }
        else {
            notificationStatus({ status: false, message: "Please select atleast one payroll to proceed." })

        }
    }

    const handleCheckboxAll = (event, rawData) => {
        let _selected = new Set([...selected])
        console.log(event.target.checked, rawData);
        if (event.target.checked) {
            rawData.map((q, i) => {
                _selected.add(Number(q.id));
            })
        }
        else {
            rawData.map((q, i) => {
                _selected.delete(Number(q.id));
            })
        }
        setSelected(_selected)
    }

    const handleChange = (e, id) => {
        let _selected = new Set([...selected])
        if (e.target.checked) {
            _selected.add(id)
            setSelected(_selected)
        }
        else {
            _selected.delete(id)
            setSelected(_selected)
        }
        // fetchAllInvoice(allData, _selected)
    }

    console.log(props.basicDetails);

    return (
        <Card>
            <CardBody>
                <div className="row">
                    <div className="col-md-12 text-right">
                        <button
                            className="btn btn-danger"
                            onClick={() => updateDelete()}
                        >Delete</button>
                    </div>
                </div>
                <form onSubmit={(e) => fetchAllData(e, filter)}>
                    <Row>
                        <Col md={4}>
                            <label>Contractors<Required /></label>
                            <select
                                className="form-control"
                                value={filter.contractors}
                                onChange={(e) => updateFilter(e, "contractors")}
                            >
                                <option value="" className="allOptions">All Contractors</option>
                                {
                                    props.basicDetails.allContractor && props.basicDetails.allContractor.map((a, i) => (
                                        <option key={i} value={a.id}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </Col>
                        <Col md={3}>
                            <label>Payroll Start Date</label>
                            <DatePicker
                                date={filter.startDate}
                                name={"startDate"}
                                required={false}
                                handleDateChange={updateFilterDate}
                            />
                        </Col>
                        <Col md={3}>
                            <label>Payroll End Date</label>
                            <DatePicker
                                date={filter.endDate}
                                name={"endDate"}
                                required={false}
                                handleDateChange={updateFilterDate}
                            />
                        </Col>
                        <Col md={2} className="text-center mt-4">
                            <button type="submit" className="btn btn-primary">
                               Filter
                            </button>
                        </Col>

                    </Row>
                </form>
                {
                    allData.length >0 &&
                    <MDBDataTable
                        striped
                        hover
                        bordered
                        className='invoiceTableClass'
                        data={{
                            columns: columns,
                            // rows:[]
                            rows: fetchAllPayroll(allData, selected)
                        }}
                    />
                }

            </CardBody>

            {
                modalConfirm
                    ?
                    <ConfirmModal
                        modal={modalConfirm}
                        title="Delete Payroll"
                        content="Do you want to delete this payroll."
                        toggle={() => setModalConfirm(false)}
                        click={() => handleDelete()}
                    />
                    : null
            }
        </Card>
    )
}

const mapStateToProps = state => {

    return {
        basicDetails: state.BasicDetails
    }

}

export default connect(mapStateToProps)(ViewAllPayroll);
