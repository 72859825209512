import React from 'react';
import { timeFormatFull } from "../../../../Utils/dateFunctions";
import { toFixed } from '../../../../Utils/stringManupulation'

const BreakDown = (props) => {
    const calcRate = (d) => {
        let rate = 1;
        if (d.bonusFactor) {
            rate = d.bonusFactor;
        }
        if (d.holidayFactor) {
            rate = d.holidayFactor
        }
        return (parseFloat(rate) * parseFloat(d.rate)).toFixed(2)
    }

    const manipulateBreakHrs = (data) => {
        let _rows = [];
        data.forEach((d) => {
            if (d.isBreakHour) {
                let len = _rows.length;
                _rows[len - 1].end = d.end;
            }
            else {
                _rows.push(d)
            }
        })
        return _rows;

    }

    return (
        <table className="breakdown-table" style={{ width: '100%' }}>
            <thead>
                <tr>
                    <td>Start</td>
                    <td>End</td>
                    <td>Rate</td>
                    <td>Payable Hrs.</td>
                    <td>Amount</td>
                </tr>
            </thead>
            <tbody>
                {
                    props.data?.length && manipulateBreakHrs(props.data).map((d, i) => {
                        if (d.hours)
                            return (<tr key={i}>
                                <td>{timeFormatFull(d.start)}</td>
                                <td>{timeFormatFull(d.end)}</td>
                                <td>{d.rate ? d.finalRate : "0"}</td>
                                <td>{d.hours}</td>
                                <td>{toFixed(d?.amt)}</td>
                            </tr>)
                        else    
                            return null
                    })
                }
                {
                    props?.custom.map((d, i) => {
                        if (d.type === "Overall") {
                            return (<tr key={d.type}>
                                <td colSpan="4" style={{ textAlign: "center" }}>Custom Bonus - {d.type}</td>
                                <td>{d.amount?.toFixed(2)}</td>
                            </tr>)
                        }
                        else {
                            return (
                                <tr key={d.type}>
                                    <td colSpan="2" style={{ textAlign: "center" }}>Custom Bonus - {d.type}</td>
                                    <td>{d.rate + (d.rate * d.bonusRatio)}</td>
                                    <td></td>
                                    <td>{d.amount?.toFixed(2)}</td>
                                </tr>
                            )
                        }
                    })
                }
                {
                    props.other?.map((d, i) => (
                        <tr key={d.type}>
                            <td colSpan="4" style={{ textAlign: "center" }}>OTHER - {d.other_charges_type}</td>
                            <td>{d.other_charges_amt?.toFixed(2)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default BreakDown;