import React from 'react';
import Availability from "../../../../component/Availability";

const DailyAvailability = (props) => {
    console.log(props.alwaysAvailability)
    return (
        <>
            <h2 class="h2 text-center mt-2">Permanent Availability Schedule</h2>
            <Availability
                startDate={props.startDate}
                showDate={false}
                numberOfRow={1}
                handleModal={props.handleModal}
                alwaysAvailability={props.alwaysAvailability}
                alwaysAvailable={true}
            />
            <h2 class="h2 text-center mt-5">Availability Exceptions</h2>
            <Availability
                startDate={props.startDate}
                showDate={true}
                numberOfRow={1}
                handleModal={props.handleModal}
                dailyAvailability={props.dailyAvailability}
            />
        </>
    )

}

export default DailyAvailability;