import React from 'react';
import DatePicker from '../../../component/datePicker/datePicker';

const Header = (props) => {
    // console.log(props)
    return (
        <div className="row">
            <div className="col-md-4">
                <h3 className="h3 mb-2 col-md-9 text-gray-800">{props.title}</h3>
            </div>
            <div className="col-md-4">
                <DatePicker
                    date={props.startDate}
                    name={"startDate"}
                    required={false}
                    handleDateChange={props.onChange}
                />
            </div>
            <div className="col-md-4">
                <DatePicker
                    date={props.endDate}
                    name={"endDate"}
                    required={false}
                    handleDateChange={props.onChange}
                />
            </div>
            {/* {
                props.isDropdown
                ?

                    <div className="col-md-6">
                        <select
                            className="form-control"
                            onChange={props.onChange}
                            value={props.value}
                        >
                            
            <option value="1" selected="">Today</option>
            <option value="2">This Week</option>
            <option value="3">This Month</option>
                        </select>
                    </div >
                : null
            }           */}

        </div >
    )
}

export default Header