import React, { useState } from 'react';
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../component/Notiflix/notiflix';
let allSort = [1, 2, 3, 4];
let defaultFilter = ['shiftType', 'contractorType', 'clientName', 'startDate'];


const camelToWord = (text) => {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}

const Sort = (props) => {

    const [selected, setSelected] = useState([
        {
            "startDate": "asc"
        },
        {
            "contractorType": "asc"
        },
        {
            "shiftType": "asc"
        }
    ])
    const [value, setValue] = useState(['startDate', 'contractorType', 'shiftType'])
    const [activeFilter, setActiveFilter] = useState([...defaultFilter])

    const handleChange = (e, i) => {

        //check
        let _active = [];
        let position = 0;
        selected.forEach((d, j) => {
            let key = Object.keys(d)
            if (key[0] === e.target.value) {
                _active.push(key[0])
                position = j;
            }
        })

        let _selected = [...selected];
        let _value = [...value];

        if (_active && _active.length > 0) {
            _value[position] = 0;
            _selected[position] = {}
        }

        _selected[i] = { [e.target.value]: "asc" }
        _value[i] = e.target.value;
        setSelected(_selected);
        setValue(_value);

    }

    const checkDefault = (i) => {
        if (i > 0) {
            if (value[i - 1])
                return false;
            else
                return true;
        }
        else
            return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let _selected = [];

        selected && selected.map((a) => {
            if (a && Object.keys(a).length > 0 && Object.keys(a) != 0) {
                _selected.push(a)

            }
        })
        // console.log(_selected);

        props.handleSubmit(_selected)
    }

    // console.log(value, selected)


    const checkSort = (item, i) => {
        if (item) {
            let obj = { ...selected[i] };
            let key = Object.keys(obj);
            return obj[key[0]];
        }
        return "asc"
    }

    const handleSort = (item, i) => {
        let sort = "asc"
        // console.log(item, i)
        if (item) {
            let obj = { ...selected[i] };
            let key = Object.keys(obj);
            if (obj[key[0]] == "asc")
                sort = "desc"

            // console.log(key, sort)
            let _selected = [...selected]
            let _name = key[0];
            _selected[i] = { [_name]: sort }

            // console.log(_selected)

            setSelected(_selected)
        }
    }



    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-10">
                    <div className="row">
                        {
                            allSort.map((a, i) => (
                                <div className="col-md-3 d-flex">
                                    <select
                                        className="form-control"
                                        value={value[i]}
                                        // disabled={checkDefault(i)}
                                        onChange={(e) => handleChange(e, i)}
                                    >
                                        <option value={0}>Select Filter</option>
                                        {
                                            activeFilter && activeFilter.map((a, i) => (
                                                <option key={i} value={a}>{camelToWord(a)}</option>
                                            ))
                                        }
                                    </select>
                                    {/* <i
                                        onClick={() => handleSort(value[i], i)}
                                        style={{ marginTop: 6, cursor: 'pointer' }}
                                        className={checkSort(value[i], i) === 'desc' ? "ml-2 fa fa-arrow-up" : "ml-2 fa fa-arrow-down"}></i> */}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="col-md-2">
                    <button type="submit" className="btn btn-primary mt-2">Sort</button>
                </div>

            </div>
        </form>
    )
}

export default Sort;