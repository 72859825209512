import React, { useState, useEffect } from 'react';
import ReactSelect from '../../../../../component/ReactSelect';


const Filter = (props) => {

    const [selected, setSelected] = useState([])

    const handleChange = (e) => {
        setSelected(e)
    }

    useEffect(() => {
        setSelected(props.selected)
    }, [props.selected])

    const handleSubmit = () => {
        props.handleSubmit(selected)
    }

    return (
        <div className={`calendar-nav-header`} style={{ minHeight: "70vh" }}>
            <div className="row" style={{ width: '100%' }}>
                <div className="col-md-12" style={{ width: '100%' }}>
                    <ReactSelect
                        options={props.options.map((o) => {
                            return {
                                label: o.name,
                                value: o.id
                            }
                        })}
                        onChange={(e) => handleChange(e)}
                        value={selected}
                        allowSelectAll={true}
                        isMulti={true}
                        noMin={true}
                    />
                </div>
                <div className="col-md-12 mt-4">
                    <button
                        class="btn btn-info"
                        title=""
                        onClick={() => handleSubmit()}
                    >{"Submit"}</button>
                </div>
            </div>
        </div>
    )
}
export default Filter;