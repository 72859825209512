import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardBody,
} from "reactstrap";
import { MDBDataTable } from 'mdbreact';

import { capitalizeAttribute } from "../../../../Utils/stringManupulation";
import { changeTzToNormal } from "../../../../Utils/dateFunctions";

import { ORDER_REPORT } from "../../../../APICall/urls/report";
import { postAdminAPI } from "../../../../APICall/index";


import Search from '../filter/orderFilter';
import {columns} from '../allList/order';
import EmptyComponent from '../../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../../store/actions";

class OrderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: null,
        }
    }

    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Reports", this.state.breadcrumbItems);
    }

    // Filter
    handleFilter = async(filterData) => {
        console.log(filterData);
        // this.fetchAllData();
        let results = await postAdminAPI(ORDER_REPORT, filterData)
        this.updateData(results.data)
        console.log(results)

    }

    updateData = (data) => {
        let _data = []
        if(data && data.length > 0){
            data.map((d,i) =>{
                let sDate = changeTzToNormal(d.start_date);
                let eDate = changeTzToNormal(d.end_date);
                _data.push({
                    ...d,
                    start_date:sDate.time,
                    end_date:eDate.time,
                    date:sDate.date,
                    short_name: capitalizeAttribute(d.short_name)
                })
            })
        }
        console.log(_data)
        this.setState({allData: _data})
    }

    render() {

        const { allData } = this.state;

        return (
            <>
                <Search
                    handleFilter={this.handleFilter}
                />
                {
                    allData
                    ?
                        allData.length > 0
                        ?
                        <Card>
                            <CardBody style={{overflowX:'scroll'}}>
                                <MDBDataTable
                                    striped
                                    hover
                                    bordered
                                    data={{ columns: columns, rows: allData }}
                                />
                            </CardBody>
                        </Card>
                        : <EmptyComponent />
                    : null

                }

            </>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(OrderReport);;