import React, { useState, useEffect } from 'react'
import TableComponent from '../../../../component/TableComponent';
import { holidayColumns } from "./columns/holiday"
import { dateTimeFormatFull } from '../../../../Utils/dateFunctions';
import ConfirmModal from "../../../../component/confirm/confirmForOrder";
import DatePicker from "../../../../component/datePicker/datePicker"
import CopyClient from '../../../../component/copyClient'
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from '../../../../component/Notiflix/notiflix'
import * as APIs from '../../../../APICall/index';
import * as URLs from '../../../../APICall/urls/settings';
import {getTempId, removeTempData} from '../../../../Utils/shifts';

const sampleObject = {
    "name": "",
    "is_recurring": 1,
    "client_bill_factor": 1,
    "client_pay_factor": 1,
    "is_active": 1,
    "sDate": "",
    "sTime": "",
    "eDate": "",
    "eTime": ""
}



const Holiday = (props) => {
    const [allHolidays, setAllHolidays] = useState([]);
    const [selected, setSelected] = useState(new Set());
    const [deleteHoliday, setDeleteHoliday] = useState(null)
    const [copyClient, setCopyClient] = useState(false)

    useEffect(() => {
        if (props.allHolidays && props.allHolidays.length >= 0) {
            let _allHoliday = modifyData(props.allHolidays)
            setAllHolidays(_allHoliday);
        }
    }, [props])

    const modifyData = (allHolidays) => {
        let _allHoliday = [];
        allHolidays.forEach(h => {
            let sDate = dateTimeFormatFull(h.start_date)
            let eDate = dateTimeFormatFull(h.end_date)
            _allHoliday.push({
                ...h,
                sDate: sDate.date,
                sTime: sDate.time,
                eDate: eDate.date,
                eTime: eDate.time
            })
        })
        return _allHoliday
    }

    const updateTable = (allHolidays, selected) => {
        let _allHoliday = [];
        allHolidays.map((p, i) => {
            if (p.is_active == 1) {
                _allHoliday.push({
                    hName: <input
                        style={{width:'auto'}}
                        type="text"
                        className="form-control"
                        value={p.name}
                        onChange={(e) => handleChange(e, i, allHolidays, "name")}
                    />,
                    sDate: <DatePicker
                        className="holiday-date"
                        type="date"
                        date={p.sDate}
                        name="sDate"
                        handleDateChange={(name, date) => handleChange(date, i, allHolidays, name)}

                    />,
                    sTime: <input
                        style={{width:150}}
                        type="time"
                        className="form-control"
                        value={p.sTime}
                        onChange={(e) => handleChange(e, i, allHolidays, "sTime")}

                    />,
                    eDate:
                        <DatePicker
                            className="holiday-date"
                            type="date"
                            date={p.eDate}
                            name="eDate"
                            handleDateChange={(name, date) => handleChange(date, i, allHolidays, name)}
                        />
                    ,
                    eTime: <input
                        style={{width:150}}
                        type="time"
                        className="form-control"
                        value={p.eTime}
                        onChange={(e) => handleChange(e, i, allHolidays, "eTime")}

                    />,
                    recurring: <input
                        style={{width:70}}
                        type="checkbox"
                        className="form-control"
                        value={p.is_recurring}
                        checked={p.is_recurring == 1 ? true : false}
                        onChange={(e) => handleChange(e, i, allHolidays, "is_recurring", "checkbox")}

                    />,
                    bill: <input
                        style={{width:70}}
                        type="number"
                        step={0.01}
                        className="form-control"
                        value={p.client_bill_factor}
                        onChange={(e) => handleChange(e, i, allHolidays, "client_bill_factor")}

                    />,
                    pay: <input
                        style={{width:70}}
                        type="number"
                        step={0.01}
                        className="form-control"
                        value={p.client_pay_factor}
                        onChange={(e) => handleChange(e, i, allHolidays, "client_pay_factor")}

                    />,
                    select: <input
                        style={{width:70}}
                        type="checkbox"
                        className="form-control"
                        checked={selected.has(p.id)}
                        onChange={(e) => handleSelectCheck(e, p.id, allHolidays, selected)}
                    />,

                })
            }
        })
        return _allHoliday;
    }

    const handleChange = (e, i, allHoliday, state, type) => {
        let _allHoliday = [...allHoliday];
        if (type === "checkbox") {
            if (e.target.checked) {
                _allHoliday[i][state] = 1;
            }
            else {
                _allHoliday[i][state] = 0;
            }
        }
        else if (state === "sDate" || state === "eDate") {
            _allHoliday[i][state] = e;
        }
        else {
            _allHoliday[i][state] = e.target.value;
        }
        setAllHolidays(_allHoliday)
    }

    const handleSelectCheck = (e, id) => {
        let _selected = new Set([...selected])
        if (e.target.checked) {
            _selected.add(id)
        }
        else {
            _selected.delete(id)
        }
        setSelected(_selected)
    }

    const handleDeleteButton = () => {
        let _selected = [...selected]
        let _allHolidays = [...allHolidays];
        let _deleteFromAPI = []
        if (_selected.length >= 1) {
            for (let i = 0; i < _selected.length; i++) {
                let index = _allHolidays.findIndex((a) => a.id === _selected[i])
                if (_allHolidays[index].id?.toString()?.includes("temp")) {
                    _allHolidays.splice(index, 1);
                }
                else {
                    _deleteFromAPI.push({
                        "id": _selected[i],
                        "deleted": 0
                    })
                }
            }
            _deleteFromAPI.length && props.deleteHoliday(_deleteFromAPI)
            setAllHolidays(_allHolidays)
            setSelected(new Set())
            setDeleteHoliday(false)
        }
    }

    const onDeleteBtnClick = () => {
        if (selected.size !== 1) {
            notiflixStatus(false, "Please select a holiday to delete.")
        }
        else {
            setDeleteHoliday(true)
        }
    }

    const saveData = () => {
        props.updateHoliday(removeTempData(allHolidays))
    }

    const copyRow = () => {
        if (selected.size === 1) {
            let _selected = [...selected]
            let _holiday = allHolidays.filter((a) => a.id === _selected[0]);
            let _single = {..._holiday[0]}
            _single.id = getTempId();
            let _allHoliday = [...allHolidays];
            _allHoliday.push(_single)
            setSelected(new Set())
            setAllHolidays(_allHoliday)

        }
        else {
            notiflixStatus(false, "Please select one holiday to copy.")
        }
    }

    const addRow = () => {
        let _allHolidays = [...allHolidays];
        _allHolidays.push({ ...sampleObject, id :getTempId() })
        setAllHolidays(_allHolidays)
    }

    const handleSubmitCopy = async (clientIds) => {
        notiflixLoaderStart()
        if(selected.size && clientIds.length){
            let data = {
                "shiftIds": [...selected],
                "clientIds": [...clientIds],
                srcClientId:props.clientId
            }
            let res = await APIs.postAdminAPI(URLs.COPY_HOLIDAYS, data)
            if (res.status) {
                setCopyClient(false)
                notificationStatus({status:true, message:"Successfully copied the holidays"})
            }
            else
            notificationStatus({status:false, message:"Failure"})
        }
        else{
            notificationStatus({status:false, message:"Please select atleast one holiday and one client to preoceed."})
        }
    }
    return (
        <>
            <TableComponent
                rows={updateTable(allHolidays, selected)}
                columns={holidayColumns}
                addRow={addRow}
                deleteRow={onDeleteBtnClick}
                handleDeleteButton={handleDeleteButton}
                saveTable={saveData}
                copyRow={copyRow}
                className="shift-table"
                copyToClient={() => setCopyClient(true)}
                selected={selected}
            />
            {
                deleteHoliday
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this holiday?",
                                proceed: true
                            }

                        }
                        title="Delete holiday"
                        toggle={() => setDeleteHoliday(null)}
                        click={handleDeleteButton}
                    />
                    : null
            }
            {
                copyClient && <CopyClient
                    handleClose={() => setCopyClient(false)}
                    title="Copy holiday to"
                    handleSubmit={handleSubmitCopy}
                />
            }
        </>

    )
};

export default Holiday;