import React from 'react';
import Availability from  "../../../../component/Availability";

const DailyAvailability = (props) =>{
    return(
        <>
            <Availability
                startDate={props.startDate}
                showDate={props.showDate}
                numberOfRow={4}
                handleModal={props.handleModal}
                dailyAvailability={props.dailyAvailability}
            />
        </>
    )

}

export default DailyAvailability;