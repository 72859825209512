import React from 'react';
import { generateNotificationString, generateNotificationStringWithName } from "../../../Utils/notifications";
import { stringAbbreviation } from "../../../Utils/stringManupulation";
import { dateUSStandard } from "../../../Utils/dateFunctions";


const NotificationImportant = (props) => {
    // console.log(props.allData?.credentials?.expired)

    const generateCred = (e) => {
        return (<div style={{ fontSize: 14, marginBottom: 5 }}>
            <div><strong>E: {dateUSStandard(e.exp_date)}</strong></div>
            <div>
                <strong>
                    {e.code}
                </strong>&nbsp;&nbsp;
                {stringAbbreviation(e.f_name + " " + e.l_name)}
            </div>
            <div>
                {e.credCode}
            </div>
        </div>)
    }

    return (
        <div className="">
            <table className="table table-bordered" >
                <thead>
                    <tr>
                        <th>Signed In</th>
                        <th>Time Sheet Verified</th>
                        {/* <th>Signed Out</th> */}
                        <th>Canceled</th>
                        <th>Credentials Expired</th>
                        <th>Credentials New</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="notification-row">
                        <td className="">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.contractor_signed_in
                                    ?
                                    generateNotificationStringWithName(props.allData.contractor_signed_in, props.type, props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['signedIn'], "signedIn", props.handleSingleDate)
                                    : null}
                            </div>
                        </td>
                        <td className="" >
                            <div className="notification-scrolling">
                                {props.allData && props.allData.timesheet_verified ? generateNotificationStringWithName(props.allData.timesheet_verified, props.type, props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['timeSheet'], "timeSheet", props.handleSingleDate) : null}
                            </div>
                        </td>
                        {/* <td className="">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.contractor_signed_out ? generateNotificationStringWithName(props.allData.contractor_signed_out, props.type, props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['singedOut'], "singedOut", props.handleSingleDate) : null}
                            </div>
                        </td> */}
                        <td className="">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.cancels ? generateNotificationStringWithName(props.allData.cancels, props.type, props.handleSingleOrder, props.selectedNotifications, props.allActiveDates['cancel'], "cancel", props.handleSingleDate) : null}
                            </div>
                        </td>
                        <td className="">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.credentials && props.allData.credentials.expired ?
                                    props.allData.credentials.expired.map((e, i) => (
                                        generateCred(e)
                                    ))

                                    : null}
                            </div>

                        </td>
                        <td className="">
                            <div className="notification-scrolling">
                                {props.allData && props.allData.credentials && props.allData.credentials.new ?
                                    props.allData.credentials.new.map((e, i) => (
                                        generateCred(e)
                                    ))

                                    : null}
                            </div>

                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    )
}

export default NotificationImportant;