import React, { Component } from 'react';
import { connect } from "react-redux";

import { setBreadcrumbItems } from "../../../store/actions";
import SettingTable from '../../../component/SettingTable';
import { timeFormatWithoutSeconds } from "../../../Utils/dateFunctions";
import { columns } from "./columns";
import { GET_SHIFTS, UPDATE_SHIFTS, COPY_SHIFT } from "../../../APICall/urls/settings";
import { getAdminAPI, postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus, notiflixStatus } from "../../../component/Notiflix/notiflix"
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import CopyClient from '../../../component/copyClient'
import { getTempId } from '../../../Utils/shifts';


let first = {
    name: "",
    start_time: "",
    end_time: '',
    type: 0,
    is_active: 1,
    color: "#000000",
    work_code: 0,
}

class GeneralShifts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            rows: [],
            allData: [],
            selected: new Set(),
            copyClient: false,
            deleteModal:false
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("general Shifts", this.state.breadcrumbItems);
        this.getAllData();
    }

    //APIS
    getAllData = async () => {
        let results = await getAdminAPI(GET_SHIFTS);
        if (results) {
            this.setState({...this.state, allData: results });
        }
    }

    deleteData = async () => {
        let _allData = [...this.state.allData];
        let _selected = [...this.state.selected];
        if (_selected.length === 1) {
            let index = _allData.findIndex((a) => a.id == _selected[0])
            _allData[index].deleted = 1;
            this.setState({...this.state, allData: _allData, selected: new Set(), deleteModal:false })
        }
        else {
            notiflixStatus(false, "Please only one shift to delete.")
        }
    }

    convertData = (data) => {
        let _data = []
        data?.forEach(d => {
            let single = {
                "name": d.name,
                "startTime": timeFormatWithoutSeconds(d.start_time),
                "endTime": timeFormatWithoutSeconds(d.end_time),
                "type": d.type,
                "color": d.color,
                "isActive": parseInt(d.is_active) === 1,
                "workCode": d.work_code,
            }
            if (d.id && !d.id.toString().includes("temp")) {
                single.id = d.id
            }
            _data.push(single)
        })

        return _data;
    }

    saveData = async () => {
        notiflixLoaderStart();
        let apiData = this.convertData(this.state.allData)
        let results = await postAdminAPI(UPDATE_SHIFTS, apiData)
        notificationStatus(results)
    }

    //ACTIONS
    updateTable = (allData, selected) => {
        let _allData = [];
        allData.forEach((p, i) => {
            _allData.push({
                shift_name: <input
                    type="text"
                    className="form-control"
                    value={p.name}
                    onChange={(e) => this.handleChange(e, i, allData, "name")}
                />,
                shift_start_time: <input
                    type="time"
                    className="form-control"
                    value={p.start_time}
                    onChange={(e) => this.handleChange(e, i, allData, "start_time")}

                />,
                shift_end_time: <input
                    type="time"
                    className="form-control"
                    value={p.end_time}
                    onChange={(e) => this.handleChange(e, i, allData, "end_time")}

                />,
                type:

                    <select
                        type="text"
                        className="form-control"
                        value={p.type}
                        onChange={(e) => this.handleChange(e, i, allData, "type")}
                    >
                        {
                            this.props.basicDetails.allShiftTypes?.map((t) => (
                                <option key={t.id} value={t.id}>{t.code}</option>
                            ))
                        }
                    </select>

                ,
                color: <input
                    type="color"
                    className="form-control"
                    value={p.color}
                    onChange={(e) => this.handleChange(e, i, allData, "color")}

                />,
                inactive: <input
                    type="checkbox"
                    className="form-control"
                    checked={parseInt(p.is_active) !== 1}
                    onChange={(e) => this.handleChange(e, i, allData, "is_active", "checkbox")}

                />,
                work_code: <select
                    type="text"
                    className="form-control"
                    style={{ width: 100 }}
                    value={p.work_code}
                    onChange={(e) => this.handleChange(e, i, allData, "work_code")}
                >
                    {
                        this.props.basicDetails.allWorkCode?.map((t, i) => (
                            <option key={t.id} value={t.id}>{t.code}</option>
                        ))
                    }
                </select>,
                select: <input
                    type="checkbox"
                    style={{ width: 70 }}
                    className="form-control"
                    checked={!!selected.has(p.id)}
                    onChange={(e) => this.handleSelectCheck(e, p.id)}
                />,

            })
        })
        // console.log(_allData)
        return _allData
    }

    handleSelectCheck = (e, id) => {
        let _selected = new Set([...this.state.selected])
        if (e.target.checked) {
            _selected.add(id)
        }
        else {
            _selected.delete(id)
        }
        this.setState({ ...this.state, selected: _selected })
    }

    handleChange = (e, i, allData, state, type) => {
        let _allData = [...allData];
        if (type === "checkbox") {
            _allData[i][state] = _allData[i][state] == 1 ? 0 : 1;
        }
        else {
            _allData[i][state] = e.target.value;
        }
        this.setState({...this.state, rows: _allData })
    }

    addRow = () => {
        let _userData = [...this.state.allData];
        _userData.push({ ...first, id: getTempId() })
        this.setState({...this.state, allData: _userData })
    }

    handleSubmit = async (clientIds) => {
        let data = {
            "shiftIds": [...this.state.selected],
            "clientIds": [...clientIds],
        }
        let res = await postAdminAPI(COPY_SHIFT, data)
        notiflixLoaderStart()
        if (res.status) {
            this.setState({ ...this.state, copyClient: false, selected:new Set() })
            notificationStatus({ status: true, message: "Success" })
        }
        else
            notificationStatus({ status: false, message: "Failure" })

    }

    render() {
        const { rows, copyClient, selected, allData } = this.state;
        return (
            <>
                <SettingTable
                    rows={this.updateTable(allData, selected)}
                    columns={columns}
                    addRow={this.addRow}
                    saveTable={this.saveData}
                    title={'General Shifts'}
                    handleCopy={() => this.setState({ ...this.state, copyClient: true })}
                    selected={selected}
                    className="shift-table"
                    copyToClient={() => this.setState({ ...this.state, copyClient: false })}
                />
                {
                    this.state.deleteModal
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to delete this item?",
                                    proceed: true
                                }

                            }
                            title="Delete"
                            toggle={() => this.setState({...this.state, selected: new Set(), deleteModal:false })}
                            click={this.deleteData}
                        />
                        : null
                }
                {
                    copyClient && <CopyClient
                        handleClose={() => this.setState({ ...this.state, copyClient: false })}
                        title="Copy Shifts to"
                        handleSubmit={this.handleSubmit}
                    />
                }
            </>

        )
    }
}

const mapStateToProps = state => {
    return {
        basicDetails: state.BasicDetails
    };
};
export default connect(mapStateToProps, { setBreadcrumbItems })(GeneralShifts);

