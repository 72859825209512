import * as actionTypes from './actionTypes';
const initialState = {
    allContractor: [],
    allClients: [],
    allShifts: [],
    allContractorTypes: [],
    allUniqueContractorTypes: [],

    allShiftTypes: [],
    allWorkCode: [],
    allCredentialsType: [],
    allCarriers: [],

    realtimeTimeStamp: 0,
    realtimeTimeStampCheck: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_CONTRACTORS:
            return {
                ...state,
                allContractor: action.payload
            };
        case actionTypes.GET_ALL_CLIENTS:
            return {
                ...state,
                allClients: action.payload
            };
        case actionTypes.GET_ALL_CONTRACTOR_TYPES:
            return {
                ...state,
                allContractorTypes: action.payload
            };
        case actionTypes.GET_ALL_UNIQUE_CONTRACTOR_TYPES:
            return {
                ...state,
                allUniqueContractorTypes: action.payload
            };
        case actionTypes.GET_ALL_SHIFTS:
            return {
                ...state,
                allShifts: action.payload
            };
        case actionTypes.REALTIME_UPDATE:
            // console.log(action)
            return {
                ...state,
                realtimeTimeStamp: action.payload
            };
        case actionTypes.REALTIME_UPDATE_CHECK:
            return {
                ...state,
                realtimeTimeStampCheck: action.payload
            };
        case actionTypes.GET_ALL_SYSTEM_LIST:
            return {
                ...state,
                allShiftTypes: action.shiftTypes,
                allWorkCode: action.workCode,
                allCredentialsType: action.credentialsType,
                allCarriers: action.carriers,
            };


        default:
            return state;
    }
}

export default reducer;