export const ConvertForSelect = (array, label, value) => {
    let data = []
    array && array.length > 0 && array.map((a, i) => {
        data.push({ label: a[label], value: a[value] })
    })
    return data;
}

export const ConvertToArray = (array, label, value) => {
    let data = []
    if (Array.isArray(array)) {
        array && array.length > 0 && array.map((a, i) => {
            data.push(a.value)
        })
    }
    else if(array?.value) {
        data.push(array.value)
    }

    return data;
}

export const ConvertStringToMultiArray = (string, allData) => {
    let data = JSON.parse(string)
    let _item = []
    console.log(data, allData);
    data.length > 0 && data.map((a, i) => {
        let index = allData.findIndex(f => f.value == a)
        if (index >= 0) {
            _item.push(allData[index])
        }

    })
    console.log(_item);
    return _item;
}


export const distinctDataFromJson = (items, type) => {
    var lookup = {};
    var result = [];

    for (var item, i = 0; item = items[i++];) {
        var name = item[type];

        if (!(name in lookup)) {
            lookup[name] = 1;
            result.push(name);
        }
    }

    return result;
}

export const generateCommonJson = (json, allShifts) => {
    let _allShifts = [...allShifts];
    json.map((d, i) => {
        _allShifts.forEach((h, j) => {
            if (h.name == d.name) {
                _allShifts[j].data.push(d)
            }
        })
    })

    return _allShifts;
}

export const generateCommonJsonActive = (json, allShifts, futureDate) => {
    let _allShifts = JSON.parse(JSON.stringify([...allShifts]))
    // console.log(_allShifts);
    // console.log(json);
    json.map((d, i) => {
        _allShifts.forEach((h, j) => {
            if (h.name == d.name) {
                // console.log(d);
                if (d.active == 1 || (d.deleted == 0 && d.active == 0 && (futureDate ? d.future_date : true))) {
                    // console.log(_allShifts[j].data, d);
                    let index = _allShifts[j].data.findIndex((a) => a.ctr_type == d.ctr_type)
                    // console.log(index);
                    if (index >= 0) {
                        _allShifts[j].data[index] = {
                            ..._allShifts[j].data[index],
                            children: [..._allShifts[j].data[index].children, d]
                        }
                    }
                    else {
                        if (!_allShifts[j].data) {
                            _allShifts[j].data = []
                        }
                        _allShifts[j].data.push({
                            ctr_type: d.ctr_type,
                            description: d.code,
                            children: [d]
                        })
                    }

                }
            }
        })
    })

    // console.log(_allShifts);

    return _allShifts;
}

export const generateCommonJsonActiveByType = (json, allShifts) => {
    let _allShifts = JSON.parse(JSON.stringify([...allShifts]))
    console.log(json, allShifts);

    json.map((d, i) => {
        _allShifts.forEach((h, j) => {
            if (h.shift_type == d.shift_type) {
                // console.log(d);
                if (d.active == 1 || (d.deleted == 0 && d.active == 0 && d.future_date)) {
                    // console.log(_allShifts[j].data, d);
                    let index = _allShifts[j].data.findIndex((a) => a.ctr_type == d.contractor_type)
                    // console.log(index);
                    if (index >= 0) {
                        _allShifts[j].data[index] = {
                            ..._allShifts[j].data[index],
                            children: [..._allShifts[j].data[index].children, d],
                            checked: false
                        }
                    }
                    else {
                        if (!_allShifts[j].data) {
                            _allShifts[j].data = []
                        }
                        _allShifts[j].data.push({
                            ctr_type: d.contractor_type,
                            description: d.ctr_type_name,
                            children: [d],
                            checked: false
                        })
                    }

                }
            }
        })
    })

    // console.log(_allShifts);

    return _allShifts;
}

export const generateObject = (allData) => {
    let _all = []
    allData && allData.length > 0 && allData.forEach(r => {
        _all.push({
            name: r.name,
            data: new Array(),
            shift_type: r.type,
            id: r.id,
        })
    })
    // console.log(_all)
    return _all;
}

export const generateAllJson = (json, allData, name) => {
    let _allData = [...allData];
    // console.log(json)
    json && json.map((d, i) => {
        // console.log(d)
        _allData.forEach((h, j) => {
            // console.log(h)
            if (h[name] == d[name]) {
                _allData[j].files = d.uploaded
            }
        })
    })

    return _allData;
}

export const getIdsInArray = (data, key) =>{
    let arr = []
    data.forEach((d) =>{
        arr.push(d[key])
    })
    return arr;

}