import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Pagesregister from "../pages/Authentication/Register";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import LockScreen from "../pages/Authentication/pages-lock-screen";

//Dashboard
// import Dashboard from "../pages/Dashboard/dashboard";
import Dashboards from "../pages/Orders/dashboard";

//Client
import SetUp from "../pages/Client/SetUp/SetUp";
import BillingRates from "../pages/Client/BillingRates/BillingRates";
import PayRates from "../pages/Client/PayRates/PayRates";
import ClientSchedule from "../pages/StaffingSheet/index";
import ClientCred from "../pages/Client/ManageCredentials";


//Contractor
import ContractorSetup from "../pages/Contractor/Setup";
import ContractorAvailability from "../pages/Contractor/Availability/Availability";
import ContractorPayRatesSetup from "../pages/Contractor/PayRatesSetup/PayRatesSetup";
import ContractorSchedule from "../pages/Contractor/Schedule/index";
import ContractorDNR from "../pages/Contractor/DNR/DNR";



//Calender
import StaffingSheet from "../pages/StaffingSheet";

//Notifications
import Notification from "../pages/Notification";

//Payroll
import NewPayroll from "../pages/Payroll/New/New";
import TrashPayroll from "../pages/Payroll/Trash";

import ViewAllPayroll from "../pages/Payroll/View/View";
import DirectPayroll from "../pages/Payroll/Direct";

//Invoice
import NewInvoice from "../pages/Invoice/New/New";
import ViewAllInvoice from "../pages/Invoice/View/View";
import DirectInvoice from "../pages/Invoice/Direct";
import DirectInvoiceView from "../pages/Invoice/Direct/view/view";


//Reports
import ReportSummary from "../pages/Reports/ReportSummary/ReportSummary";
import OrderReport from "../pages/Reports/Download/report/order";
import PayRateReport from "../pages/Reports/Download/report/payRate";
import BillRateReport from "../pages/Reports/Download/report/billRate";
import PayrollReport from "../pages/Reports/Download/report/payroll";
import InvoiceReport from "../pages/Reports/Download/report/invoice";
import NewOrderReport from "../pages/Reports/Download/report/orderReport";
import WorkSummaryReport from "../pages/Reports/Download/report/workSummaryReport";
import ShiftStatusReport from "../pages/Reports/Download/report/shiftStatusReport";
import ClientListingReport from "../pages/Reports/Download/report/clientListingReport";
import ContListingReport from "../pages/Reports/Download/report/contListingReport";
import InvoiceRegisterReport from "../pages/Reports/Download/report/invoiceReport";
import PayrollRegisterReport from "../pages/Reports/Download/report/payrollReport";


//Systems
import RequestOfChange from "../pages/Systems/RequestOfChange/RequestOfChange";
import SettingNotification from "../pages/Systems/Notifications/Notification";
import ManageLists from "../pages/Systems/ManageLists/ManageLists"
import GeneralShifts from "../pages/Systems/GeneralShifts/GeneralShifts";
import ManageStatus from "../pages/Systems/ManageStatus/ManageStatus";
import ManageCredentials from "../pages/Systems/ManageCredentials/ManageCredentials"
import PayRatesSystem from "../pages/Systems/PayRates/PayRates"
import BillRateSystem from "../pages/Systems/BillRate/BillRate"
import Holiday from "../pages/Systems/Holiday"




//Extra Pages

import Pages404 from "../pages/Extra Pages/pages-404";

const authProtectedRoutes = [
  // DashBoard
  // { path: "/dashboard", component: Dashboard },

  //Calendar
  { path: "/staffing_sheet", component: ClientSchedule },

  //Order
  { path: "/order", component: Dashboards },

  //Client
  { path: "/client_setup", component: SetUp },
  { path: "/billing_rates", component: BillingRates },
  { path: "/pay_rates", component: PayRates },
  { path: "/client_cred", component: ClientCred },


  //Contractor
  { path: "/contractor_setup", component: ContractorSetup },
  { path: "/contractor_schedule", component: ContractorSchedule },
  { path: "/contractor_availability", component: ContractorAvailability },
  { path: "/contractor_pay_rates", component: ContractorPayRatesSetup },
  { path: "/contractor_dnr", component: ContractorDNR },


  //notifications
  { path: "/notifications", component: Notification },

  //Payroll
  { path: "/new_payroll", component: NewPayroll },
  { path: "/trash_payroll", component: TrashPayroll },
  { path: "/view_all_payroll", component: ViewAllPayroll },
  { path: "/direct_payroll", component: DirectPayroll },




  //Invoice
  { path: "/new_invoice", component: NewInvoice },
  { path: "/view_all_invoice", component: ViewAllInvoice },
  { path: "/direct_invoice", component: DirectInvoice },
  { path: "/invoice/direct/:id", component: DirectInvoiceView },


  //Report
  { path: "/work_summary_report", component: WorkSummaryReport },
  { path: "/shift_status_report", component: ShiftStatusReport },
  { path: "/client_listing_report", component: ClientListingReport },
  { path: "/contractor_listing_report", component: ContListingReport },
  { path: "/invoice_register_report", component: InvoiceRegisterReport },
  { path: "/cheque_register_report", component: PayrollRegisterReport },
  { path: "/order_report", component: OrderReport },
  { path: "/new_order_report", component: NewOrderReport },
  { path: "/pay_rate_report", component: PayRateReport },
  { path: "/bill_rate_report", component: BillRateReport },
  { path: "/payroll_report", component: PayrollReport },
  { path: "/invoice_report", component: InvoiceReport },
  { path: "/report_summary", component: ReportSummary },

  //Settings
  { path: "/request_of_change", component: RequestOfChange },
  { path: "/setting_notifications", component: SettingNotification },
  { path: "/manage_lists", component: ManageLists },
  { path: "/general_shifts", component: GeneralShifts },
  { path: "/manage_status", component: ManageStatus },
  { path: "/manage_credentials", component: ManageCredentials },
  { path: "/setting/pay_rates", component: PayRatesSystem },
  { path: "/setting/bill_rates", component: BillRateSystem },
  { path: "/setting/holiday", component: Holiday },


  { path: "/", exact: true, component: () => <Redirect to="/staffing_sheet" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: "/register", component: Pagesregister },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/pages-lock-screen", component: LockScreen },
  { path: "/pages-404", component: Pages404 },
];

export { authProtectedRoutes, publicRoutes };
