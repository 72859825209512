import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";

const MySelect = React.forwardRef((props) => {
  const customStyles = {
    control: base => ({
      ...base,
      maxHeight:'65px',
      width: '100%',
      minHeight: '65px',
      overflow:'auto'
    })
  };

  const customStylesNoMin = {
    control: base => ({
      ...base,
      maxHeight:'65px',
      overflow:'auto'
    })
  };

  

  if (props.allowSelectAll) {
    


    return (
      <ReactSelect
        {...props}
        styles={props.noMin ? customStylesNoMin :  customStylesNoMin}
        options={[props.allOption, ...props.options]}
        // onKeyDown={props.handleKeyDown} 
        // handleKeyUp={props.handleKeyUp}
        // isDisabled = {props.isDisabled}
        // required={props.required}
        onChange={selected => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return props.onChange(props.options);
          }
          //console.log(selected)
          return props.onChange(selected);
        }}
      />
    );
  }

  return <ReactSelect {...props} styles={props.noMin ? customStylesNoMin :  customStyles} />;
});

MySelect.propTypes = {
  isDisabled:PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

MySelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*"
  }
};

export default MySelect;
