export const columns = [
    {
        label: 'Client',
        field: 'name',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Invoice Date',
        field: 'invoice_date',
        sort: 'asc',
        width: 270
    },
    {
        label: 'Amount',
        field: 'amount',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Regular Hours',
        field: 'regular_hours',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Holiday Hours',
        field: 'holiday_hours',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Total Hours',
        field: 'total_hours',
        sort: 'asc',
        width: 150
    }
]