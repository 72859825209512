import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { connect } from "react-redux";
import ReactSelect from '../../../../component/ReactSelect/checkboxSelect';
import { ConvertForSelect } from "../../../../Utils/arrayFunction"

import Credentials from "../../../../Utils/reportjson/credentials.json"
import * as dropdown from "../../../../Utils/reportjson/contractor"

import CredentialsType from "../../../../Utils/reportjson/credentialsType.json"
import DatePicker from '../../../../component/datePicker/datePicker';
import { notificationStatus } from '../../../../component/Notiflix/notiflix';
import './style.scss'

const style = {
    'text-transform': 'uppercase',
    'font-size': 15,
    'font-weight': 'bolder'
}

export const generateFilter = (filter) => {
    let _filter = [];
    filter.forEach((f) => {
        if (f.type || f.startDate || f.endDate) {
            let _single = []
            _single.push([
                "cred_short_name",
                "LIKE",
                f.profile
            ])
            if (f.type) {
                _single.push([
                    "cred_cert_short_name",
                    "LIKE",
                    f.type
                ])
            }
            if (f.startDate) {
                _single.push([
                    "cp_exp_date",
                    "<=",
                    f.startDate
                ])
            }
            if (f.endDate) {
                _single.push([
                    "cp_exp_date",
                    ">=",
                    f.endDate
                ])
            }
            _single.push(["ctr_inactive", "=", 0])
            _single.push(["ctr_deleted", "=", "NO"])
            _filter.push([_single, "OR"])
        }
    })

    return _filter
}
const OrderFilter = (props) => {
    const [filter, setFilter] = useState([]);
    const [simpleFilter, setSimpleFilter] = useState({})

    useEffect(() => {
        if (props.filterFromApply) {
            setFilter(props.filterFromApply)
        }
    }, [props.filterFromApply])

    const handleSubmit = (e) => {
        e.preventDefault();
        let _filter = generateFilter(filter)
        if (_filter.length) {
            props.handleFilter(_filter);
        }
        else {
            notificationStatus({ status: false, message: "Please select at least one filter to proceed. " })
        }
    }

    const updateFilter = (e, i, name, profile) => {
        let _filter = [...filter];
        if (!_filter[i])
            _filter[i] = {}
        _filter[i][name] = e.target.value
        _filter[i]["profile"] = profile
        setFilter(_filter)
    }

    const updateFilterDate = (name, date, i, profile) => {
        let _filter = [...filter];
        if (!_filter[i])
            _filter[i] = {}
        _filter[i][name] = date;
        _filter[i]["profile"] = profile
        setFilter(_filter)
    }

    const getTyeFromCred = (id) => {
        return CredentialsType.filter((c) => c.cred_id === id)
    }
    return (

        <Card>
            <CardHeader>
                {props.title}
            </CardHeader>
            <CardBody>
                <form onSubmit={handleSubmit}>
                    <div className='workCodeCard'>
                        <Row >
                            <Col md={2} style={{ ...style }}>
                                Profile
                            </Col>
                            <Col md={2} style={{ ...style }}>
                                Type
                            </Col>
                            <Col md={2} style={{ ...style }}>
                                Start Date
                            </Col>
                            <Col md={2} style={{ ...style }}>
                                End Date
                            </Col>
                        </Row>
                        {
                            Credentials.map((c, i) => (
                                <Row key={i}>
                                    <Col md={3}>
                                        {c.des}
                                    </Col>
                                    <Col md={2}>
                                        <select
                                            className="form-control"
                                            value={filter[i]?.type}
                                            onChange={(e) => updateFilter(e, i, "type", c.short_name)}
                                        >
                                            <option value="" className="allOptions">--All--</option>
                                            {
                                                getTyeFromCred(c.id)?.map((a, i) => (
                                                    <option key={i} value={a.short_name}>{a.short_name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={2}>
                                        <DatePicker
                                            date={filter[i]?.startDate ? filter[i]?.startDate : ""}
                                            name={"startDate"}
                                            required={false}
                                            handleDateChange={(name, date) => updateFilterDate(name, date, i, c.short_name)}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <DatePicker
                                            date={filter[i]?.endDate ? filter[i]?.endDate : ""}
                                            name={"endDate"}
                                            required={false}
                                            handleDateChange={(name, date) => updateFilterDate(name, date, i, c.short_name)}
                                        />
                                    </Col>
                                </Row>
                            ))
                        }
                        <Row>
                            <Col md={3}>
                                <label>Contractor Type</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allContractorTypes?.map((a, i) => (
                                            <option key={i} value={a.code}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label htmlFor="Contractor">Orientation</label>
                            </Col>
                            <Col md={6}>
                                <ReactSelect
                                    options={ConvertForSelect(props.basicDetails?.allClients, "name", "id")}
                                    onChange={(e) => updateFilter(e, "client")}
                                    value={filter.client}
                                    allowSelectAll={true}
                                    isMulti={true}
                                    noMin={true}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Contractor Type</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        props.basicDetails?.allContractorTypes?.map((a, i) => (
                                            <option key={i} value={a.code}>{a.code}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Status</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dropdown.contractorStatus?.map((a, i) => (
                                            <option key={i} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Green card</label>
                            </Col>
                            <Col md={6}>
                                <input
                                    className="form-control"
                                    placeholder='Enter green card number'
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Termination Status</label>
                            </Col>
                            <Col md={3}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dropdown.terminationStatus?.map((a, i) => (
                                            <option key={i} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                            <Col md={3}>
                                <DatePicker
                                    date={filter.invoiceStartDate}
                                    name={"invoiceStartDate"}
                                    required={false}
                                    handleDateChange={updateFilterDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>Does he/she drives?</label>
                            </Col>
                            <Col md={6}>
                                <select
                                    className="form-control"
                                    value={filter.ctrType}
                                    onChange={(e) => updateFilter(e, "ctrType")}
                                >
                                    <option value="" className="allOptions">--All--</option>
                                    {
                                        dropdown.drivesStatus?.map((a, i) => (
                                            <option key={i} value={a.id}>{a.fullName}</option>
                                        ))
                                    }
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <hr className='hrTag' />
                    <Row>
                        <Col md={8} className="text-left">
                            <button type="button" onClick={() => props.setModalFilter({filter, simpleFilter})} className="btn btn-primary">
                                Save Filter
                            </button>
                            &nbsp; &nbsp;
                            <button type="button" onClick={() => props.getModalFilterGet(true)} className="btn btn-primary">
                                Apply Filter
                            </button>


                        </Col>
                        <Col md={4} className="text-right">
                            <button type="submit" className="btn btn-primary">
                                Generate Report
                            </button>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>

    )
}


const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps)(OrderFilter);;