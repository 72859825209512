import React from 'react'
import { Col, Row, Card, CardHeader, Table } from "reactstrap";

const SettingTable = (props) => {
    // console.log(props.selected);
    // console.log(props.rows);
    return (
        <Card>
            <CardHeader style={{ padding: '0.25rem 1.25rem' }}>
                <Row>
                    <Col md={6}>
                        {props.title}
                    </Col>
                    <Col md={6} className="text-right">

                        <button
                            className="btn btn-circle1 btn-success mb-2" id="submit_shift"
                            title="Save"
                            onClick={props.handleCopy}
                            disabled={!props.selected?.length}
                        >
                            <i className="fas fa-copy"></i>
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-circle1 btn-outline-primary mb-2" title="Add"
                            onClick={props.addRow}
                        >
                            <i className="fas fa-plus"></i>
                        </button>
                        &nbsp;
                        <button
                            className="btn btn-circle1 btn-outline-success mb-2" id="submit_shift"
                            title="Save"
                            onClick={props.saveTable}
                        >
                            <i className="fas fa-check"></i>
                        </button>
                    </Col>
                </Row>

            </CardHeader>
            <Row>
                <Col xs="12">
                    {
                        props.columns?.length && props.rows?.length >= 0 &&

                        <Table striped>
                            <thead>
                                <tr>
                                    {
                                        props.columns.map((p, i) => {
                                            return <th key={i}>{p.label}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.rows.map((p, i) => {
                                        return <tr key={i}>
                                            {
                                                props.columns.map((a, j) => {
                                                    return <td key={j}>{p[a.field]}</td>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
        </Card>
    )
};

export default SettingTable;