import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class ModalConfirm extends Component {


    render() {
        return (
            <div>
                <Modal size="md" isOpen={true} toggle={this.props.toggle} >
                    <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                    <ModalBody>
                        <div className="card-body">
                            <form onSubmit={this.submitHandler}>
                                <div className="row">
                                    <div className="col-sm-8">
                                        <div className="form-group">
                                            {this.props.modal?.message}
                                        </div>
                                    </div>
                                    {
                                        this.props.modal?.proceed
                                            ?
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <button type="button" onClick={this.props.click} className="btn btn-danger waves-effect waves-light">{this.props.button ? this.props.button : "Confirm"}</button>
                                                </div>
                                            </div>
                                            : null
                                    }

                                </div>
                            </form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.props.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalConfirm;
