import React, { useEffect, useState } from 'react';
import { Collapse, CardBody, Card, CardHeader, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import { dateUSStandard } from '../../../Utils/dateFunctions'
import ConfirmModal from "../../../component/confirm/confirmForOrder";
import { notiflixStatus } from '../../../component/Notiflix/notiflix'
import { downloadImage, openInNewTab } from "../../../shared/saveAs";
import { postAdminAPIBlob } from '../../../APICall/index';
import {
    GET_CRED_FILE
} from '../../../APICall/urls/contractor';
const credentials = [0, 1, 2];

const Credentials = (props) => {
    const [deleteCred, setDeleteCred] = useState(null)

    const [collapse, setCollapse] = useState(null);
    const [pdf, setPdf] = useState(null);
    // const [isActive, setIsActive] = useState(false);
    const handleSelected = (d, sectionId) => {
        console.log(d);
        props.handleSelected(d, sectionId);
    }

    const onDeleteBtnClick = () => {
        if (props.selected === null) {
            notiflixStatus(false, "Please select a credentials to delete.")
        }
        else {
            setDeleteCred(true)
        }
    }

    const handleDelete = () => {
        if (props.selected) {
            props.delete(props.selected.id)
            setDeleteCred(null)
        }
    }

    const handleModify = () => {
        props.modify(props.selected.id)
        // if(props.selected){
        //     props.modify(props.selected.id)
        // }
    }

    useEffect(() => {
        getFile()
    }, [props.selected?.file_type])

    const getFile = async () => {
        setPdf("")
        if (props.selected?.file_type === "application/pdf") {
            let file = await postAdminAPIBlob(GET_CRED_FILE, { id: props.selected.id })
            console.log(file);
            // const url = URL.createObjectURL(file)
            // console.log(url);
            // setPdf(url)
        }
    }

    console.log(props.selected)

    return (
        <div className="row">
            <div className="col-lg-4 col-md-4">
                <div className="card">
                    <div className="card-header py-3">
                        <div>
                            <button
                                className="btn btn-primary btn-circle1"
                                onClick={() => props.add()}
                            // disabled={isActive ? false : true}
                            >
                                <i className="fas fa-plus"></i>
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-info btn-circle1"
                                disabled={props.selected ? false : true}
                                onClick={() => handleModify()}
                            >
                                <i className="fas fa-cut"></i>
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-danger btn-circle1"
                                onClick={() => onDeleteBtnClick()}
                                disabled={props.selected ? false : true}
                            >
                                <i className="fas fa-trash"></i>
                            </button>
                        </div>
                    </div>

                    <div className="card-body p-3">
                        <div id="accordion">
                            {
                                props.contractorCredentials && props.contractorCredentials.length > 0 && props.contractorCredentials.map((p, i) => {
                                    return (
                                        <div key={i} className="card mb-1 shadow-none">
                                            <div className="card-header p-3" id="headingOne">
                                                <h6 className="m-0 font-size-14">
                                                    <Link
                                                        to="#"
                                                        className="text-dark"
                                                        onClick={() => setCollapse(p.code)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        {p.code}
                                                    </Link>
                                                </h6>
                                            </div>
                                            <Collapse isOpen={collapse === p.code ? true : false}>
                                                {
                                                    p.files.map((d, j) => (
                                                        <CardBody key={j} style={{ padding: 5 }}>
                                                            <ul class="list-group">
                                                                <li
                                                                    onClick={() => handleSelected(d, p.id)}
                                                                    style={props.selected && props.selected.id == d.id ? { backgroundColor: 'gainsboro', cursor: "pointer" } : {}}
                                                                    class="list-group-item list-group-item-action list_item"
                                                                >{d.name}</li>
                                                            </ul>
                                                        </CardBody>
                                                    ))
                                                }

                                            </Collapse>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-lg-7 col-md-8">
                {
                    props.selected
                        ?
                        <Card>
                            <CardHeader className="card-header">
                                {props.selected.name}
                            </CardHeader>
                            <CardBody>
                                <div style={{ textAlign: 'center' }} >
                                    {
                                        props.selected.file_type === "application/pdf"
                                            ?
                                            <iframe
                                                src={props.selected.filename}
                                                height="600" width="100%"
                                                scrolling="auto"
                                            ></iframe>
                                            :
                                            <img
                                                style={{ width: '150px' }}
                                                src={props.selected.filename} alt="sadsa"
                                            />
                                    }

                                </div>
                                <Row className="mt-4">
                                    <Col md={12}>
                                        <h4>Expiry Date: {dateUSStandard(props.selected.exp_date)} </h4>
                                    </Col>
                                    <Col md={412}>
                                        <button
                                            className="btn btn-primary btn-lg"
                                            onClick={() => props.modify()}
                                        >Renew</button> &nbsp;&nbsp;
                                        <button
                                            className="btn btn-success btn-lg"
                                            onClick={() => openInNewTab(props.selected.filename)}
                                        >View and Download</button>&nbsp;&nbsp;
                                        {/* <button
                                            className="btn btn-info btn-lg"
                                            onClick={() => downloadImage(props.selected.filename, props.selected.filename)}
                                        >Download</button> */}
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                        : null
                }
                {
                    deleteCred
                        ?
                        <ConfirmModal
                            modal={
                                {
                                    message: "Do you want to delete this credentials?",
                                    proceed: true
                                }

                            }
                            title="Delete credentials"
                            toggle={() => setDeleteCred(null)}
                            click={handleDelete}
                        />
                        : null
                }


            </div>
        </div>
    )
}

export default Credentials;