import React, { useState, useEffect } from "react";
import { timeFormatFull, timeFormat } from '../../../Utils/dateFunctions';
import "./BulkOrderComp.scss";

const contracters = [
  "contracter1",
  "contracter2",
  "contracter3",
  "contracter3",
  "contracter4",
];

const BulkOrderComp = (props) => {
  const [selectedContracters, setSelectedContracters] = useState(null);

  const handleContractersSelection = (e) => {
    setSelectedContracters(e.target.value);
  };
  return (
    <>
      <div className={`row parent-wrapper`}>
        <div className={`col-12 table-box-container`}>
          <div className={`table-card`} style={{backgroundColor:'white'}}>
            <div className={`row table-row`}>
              <div className={`col-2 first-section`}>
                <div className={`row`}>
                  <div className={`col-12`}>{timeFormatFull(props.data.startDate) +" - "+ timeFormatFull(props.data.endDate)}</div>
                  <div className={`col-12`}>{props.data.shiftTypeDes}</div>
                </div>
              </div>
              <div className={`col-6 second-section`}>
                <div className={`row`}>
                  <div className={`col-6`}>Type : {props.data.contractorTypeCode}</div>
                  <div className={`col-6`}>Client Confirmed : {parseInt(props.data.state) >= 2 ? "Yes" : "No"}</div>
                </div>
                <div className={`row`}>
                  <div className={`col-6`}>Status : {"Open"}</div>
                  <div className={`col-6`}>Contracter Confirmed : {parseInt(props.data.state) >= 2 ? "Yes" : "No"}</div>
                </div>
              </div>
              <div className={`col-4 third-section`}>
                  
                <select
                  value={props.data.assignedCtr ? props.data.assignedCtr : props.data.selectedSingle}
                  disabled={props.data.assignedCtr ? true : false}
                  onChange={(e) => props.onChangeClients(props.date, props.shift,props.position, e.target.value)}
                  className={`form-control`}
                >
                  <option value={0}>Select Contracters</option>
                  {props.data.responses && props.data.responses.map((c) => {
                    return <option value={c.ctr}>{c.contractorName}</option>;
                  })}
                </select>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BulkOrderComp;
