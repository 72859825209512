export const getBulkDetails = (bulkOrders) => {
    let components = {};
    if (bulkOrders) {
        let _dates = Object.keys(bulkOrders);
        _dates.map((b, i) => {
            let _date = bulkOrders[b];
            if (!components[b])
                components[b] = [];
            let _shifts = Object.keys(_date);
            _shifts.map((s, j) => {
                let _shift = _date[s];

                if (!components[b][s])
                    components[b][s] = [];

                _shift.map((o, k) => {
                    components[b][s].push({
                        ...o,
                        selectedSingle: 0,
                        selectedResponse: o.responses
                    })
                })
            })
        })
    }

    return components;
}

export const updateBulkDetails = (bulkOrders) => {
    // console.log("asdsad")
    let components = [];
    let _dates = Object.keys(bulkOrders);
    _dates.map((b, i) => {
        let _date = bulkOrders[b];
        let _shifts = Object.keys(_date);
        _shifts.map((s, j) => {
            let _shift = _date[s];
            _shift.map((o, k) => {
                if (parseInt(o.selectedSingle) > 0) {
                    // console.log("asdsasdsaad")
                    components.push({
                        "order": o.id,
                        "ctr": parseInt(o.selectedSingle)
                    })
                }

            })
        })
    })
    // console.log(components)

    return components;
}