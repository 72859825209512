import React, { Component } from 'react';
import Search from './search';
import NewList from './newList';
import { connect } from "react-redux";
import EmptyComponent from '../../../component/EmptyData';
import { setBreadcrumbItems } from "../../../store/actions";
import { GET_INVOICE_BASED_ON_FILTER, GENERATE_INVOICE, NEXT_INVOICE_PAYROLL } from "../../../APICall/urls/invoicePayroll";
import { postAdminAPI, getAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import { toFixed } from '../../../Utils/stringManupulation'
import { select } from 'redux-saga/effects';
import _ from 'lodash';

class NewInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [],
            apiData: [],
            isEmpty: false,
            filterData: null,
            selected: new Set(),
            nextInvoice:"",
            invNumber:"",
            columns: [
                {
                    label: <label className="container">
                        <input
                            type="checkbox"
                            value={"all"}
                            className="form-control"
                            onClick={(event) => this.handleCheckboxAll(event, "all")}
                            // checked={() => this.checkAllChech()}
                        />
                        <span className="checkmark"></span>
                    </label>,
                    field: 'sro',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Bill To',
                    field: 'bill',
                    sort: 'asc',
                    width: 270
                },
                {
                    label: 'Pay Terms',
                    field: 'terms',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Total Amount($)',
                    field: 'amount',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Total Hours',
                    field: 'hours',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Total Shifts',
                    field: 'shifts',
                    sort: 'asc',
                    width: 150
                }
            ],
            invNumber:""
        }
    }


    // Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Generate Invoice", this.state.breadcrumbItems);
        this.getNextInvoice()
        // let data = {
        //     "client": [
        //         3,
        //         1,
        //         2,
        //         30,
        //         31,
        //         36,
        //         35,
        //         32,
        //         29,
        //         34,
        //         38,
        //         33
        //     ],
        //     "startDate": "2024-01-01",
        //     "endDate": "2024-03-28",
        //     "invoice": "2024-03-21"
        // }
        // this.handleFilter(data)
    }
    getNextInvoice = async () =>{
        let  res =  await getAdminAPI(NEXT_INVOICE_PAYROLL)
        if(res?.invoiceNumber?.[0]?.max_invoice_number){
            this.setState({...this.state, nextInvoice:res.invoiceNumber[0].max_invoice_number +1, invNumber:res.invoiceNumber[0].max_invoice_number +1})
        }
        else{
            this.setState({...this.state, nextInvoice:1, invNumber:1})
        }
    }

    // Filter
    handleFilter = async (filterData) => {
        let _finalData = { ...filterData }
        delete _finalData.invoice;

        this.setState({ filterData: filterData })
        let result = await postAdminAPI(GET_INVOICE_BASED_ON_FILTER, _finalData)
        if (result.status) {
            if (result.data.length > 0) {
                this.fetchAllData(result.data, this.state.selected);
                this.setState({ apiData: result.data })
            }
            else {
                this.setState({ isEmpty: true })
            }
        }

    }

    // AllNewData
    fetchAllData = (apiData, selected) => {
        let _allData = []
        apiData && apiData.length > 0 && apiData.map(a => {
            _allData.push({
                sro: <label className="container">
                    <input
                        type="checkbox"
                        value={"all"}
                        className="form-control"
                        onClick={(event) => this.handleCheckbox(event, a.client)}
                        checked={ selected ? selected.has(a.client.toString()) ? true : false : false}
                    />
                    <span className="checkmark"></span>
                </label>,
                bill: a.clientName,
                terms: a.payTerms,
                amount: toFixed(a.total),
                hours: toFixed(a.totalHours),
                shifts: a.totalShifts,
            })
        })

        return _allData
    }

    handleGeneratePayroll = async (e) => {
        e.preventDefault();
        let sort = e.target[0].value;
        // console.log(e.target[0].value);
        notiflixLoaderStart();
        const { filterData, selected, invNumber, nextInvoice } = this.state;
        console.log( filterData)
        let client = [...this.state.selected]
        if (filterData, client.length) {
            let data = {
                "clients": client,
                "startDate": filterData.startDate,
                "endDate": filterData.endDate,
                "invoiceDate": filterData.invoice,
                sortType: sort ? sort : 1
            }
            if(selected.size == 1 && invNumber != nextInvoice){
                data.invNumber = invNumber
            }
            // console.log(data)
            let result = await postAdminAPI(GENERATE_INVOICE, data)
            // console.log(result)
            if (result) {
                notificationStatus(result)
                if (result.status) {
                    let invoicePath = result.data.invoicePath
                    // window.open(
                    //     'http://api.rnplus.thecodebucket.com/invoice-pdf/'+invoiceNumber+'.pdf',
                    //     // <- This is what makes it open in a new window.
                    // );

                    this.props.history.push("/view_all_invoice");
                    // this.props.history.push("/view_payroll/"+result.data.id);
                }
            }
        }
        else{
            notificationStatus({status:false, message:"Please select atleast one client to proceed."})
        }
    }

    handleCheckbox = (event, id) => {
        let { selected } = this.state
        if (event.target.checked) {
            selected.add(id.toString());
        }
        else {
            selected.delete(id.toString());
        }
        this.setState({...this.state, selected})
    }

    handleCheckboxAll = (event) => {
        let { apiData, selected } = this.state
        if (event.target.checked) {
            apiData.map((q, i) => {
                selected.add(q.client.toString());
            })
        }
        else {
            apiData.map((q, i) => {
                selected.delete(q.client.toString());
            })
        }
        this.setState({...this.state, selected})
    }

    changeCheckBox = () =>{
        let { columns } = this.state;
        columns[0].label = (
            <label  className="container">
                <input                                 
                    type="checkbox" 
                    value={"all"}
                    onClick={(event) => this.handleCheckboxAll(event, "all")}
                    checked={this.checkAllChech()}
                    />
                    <span className="checkmark"></span>
            </label>
        );
        this.setState({...this.state, columns: columns});
    }


    checkAllChech() {
        //console.log(selectedQuestion);
        let { allData, selected } = this.state
        //console.log(levels);
        if (allData.length > 0) {
            var ret = false;
            for (var i = 0; i < allData.length; i++) {
                let ret = selected.has(allData[i].client.toString());
                //console.log(questions[i].quest_id.toString()) ;
                if (ret === false) {
                    return false;
                }
            }
            return true;
        }
        else {
            return false;
        }
    }

    setInvNumber = (invNumber) =>{
        this.setState({...this.state, invNumber: invNumber})
    }

    render() {
        const { isEmpty, columns, selected, apiData, invNumber } = this.state;
        console.log(invNumber);
        return (
            <>
                <Search
                    allClients={this.props.basicDetails.allClients}
                    handleFilter={this.handleFilter}
                    invNumber={invNumber}
                    selected={selected}
                    setInvNumber={this.setInvNumber}
                />
                {
                    apiData && apiData.length > 0
                        ?
                        <NewList
                            allData={this.fetchAllData(apiData, selected)}
                            columns={columns}
                            handleGeneratePayroll={this.handleGeneratePayroll}

                        />
                        : null

                }

                {
                    isEmpty
                        ?
                        <EmptyComponent />
                        : null

                }

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        basicDetails: state.BasicDetails
    }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(NewInvoice);;