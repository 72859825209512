import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import CloseIcon from "@material-ui/icons/Close";
import ReactSelect from '../ReactSelect';

const size = [0, 1, 2, 3, 4, 5, 6];

const AddRateCard = (props) => {

    const [selected, setSelected] = useState({
        "shift": '',
        "ctrType": '',
        "d0": '',
        "d1": '',
        "d2": '',
        "d3": '',
        "d4": '',
        "d5": '',
        "d6": '',
    })

    useEffect(() => {
        console.log(props)
        if (props.singleShift) {
            setSelected({
                "shift": props.singleShift.shift_id,
                "ctrType": props.singleShift.client_id,
                "d0": props.singleShift.d0,
                "d1": props.singleShift.d1,
                "d2": props.singleShift.d2,
                "d3": props.singleShift.d3,
                "d4": props.singleShift.d4,
                "d5": props.singleShift.d5,
                "d6": props.singleShift.d6,
            })
        }
    }, [])

    useEffect(() => {
        if (selected.ctrType)
            props.getAllShifts(selected.ctrType)
    }, [selected.ctrType])

    const handleChange = (e, type) => {
        let _selected = { ...selected }
        _selected[type] = e.target.value;
        setSelected(_selected)
        console.log(type)
    }



    const handleCheckbox = () => {

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(selected)
        delete selected.ctrType
        if (props.singleShift) {
            selected.id = props.singleShift.id
        }
        props.handleSave(selected)
    }


    console.log(selected)

    return (
        <Modal size="xl" isOpen={props.isOpen}>
            <ModalHeader>
                Pay Rate Properties
                <CloseIcon
                    className={`header-close-icon`}
                    onClick={() => props.handleClose()}
                />
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div class="form-group col-md-12">
                            <label for="desc_id">Client</label>
                            <select className="form-control"
                                value={selected.ctrType}
                                required
                                onChange={(e) => handleChange(e, "ctrType")}
                            >
                                <option value={null}>---SELECT---</option>
                                {
                                    props.allClients && props.allClients.length > 0 && props.allClients.map((a, i) => (
                                        <option value={a.id} key={i}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div class="form-group col-md-12">
                            <label for="desc_id">Shift</label>
                            <select className="form-control"
                                value={selected.shift}
                                required
                                onChange={(e) => handleChange(e, "shift")}
                            >
                                <option value={null}>---SELECT---</option>
                                {
                                    props.allShifts && props.allShifts.length > 0 && props.allShifts.map((a, i) => (
                                        <option value={a.id} key={i}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div class="form-group col-md-12">
                            <label for="desc_id">Area</label>
                            <select className="form-control"
                                value={selected.ctrType}
                                required
                                onChange={(e) => handleChange(e, "ctrType")}
                            >
                                <option value={null}>---SELECT---</option>
                                <option value={2}>LPN</option>
                                <option value={3}>CNA</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="row">
                        <table className="table" >
                            <thead>
                                <tr>
                                    <th>Monday</th>
                                    <th>Tuesday</th>
                                    <th>Wednesday</th>
                                    <th>Thursday</th>
                                    <th>Friday</th>
                                    <th>Saturday</th>
                                    <th>Sunday</th>
                                    {/* <th>Unpaid Break</th> */}
                                </tr>
                            </thead>
                            <tbody id="rates_body">
                                <tr>
                                    {
                                        size.map((s, i) => (
                                            <td>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    step={0.01}
                                                    required
                                                    value={selected["d" + s]}
                                                    onChange={(e) => handleChange(e, "d" + s)}
                                                />
                                            </td>
                                        ))
                                    }
                                    {/* <td>
                                        <input
                                            type="checkbox"
                                            onChange={(e) => handleCheckbox(e)}
                                        />
                                    </td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-2">
                            <button
                                type="button"
                                className="btn btn-danger suspend"
                                onClick={() => props.handleClose()}
                            >Close</button>
                        </div>

                        <div className="col-md-10">
                            <button
                                type="submit"
                                className="btn btn-success float-right"
                            >Submit</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddRateCard;