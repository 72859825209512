export const updateLocalStorage = (state, data) =>{
    console.log(data);
    if(state === 3)
        localStorage.setItem("RNPlusFilterStartDate", data);
    else if(state === 4){
        localStorage.setItem("RNPlusFilterEndDate", data);
    }
    else if(state === 0){
        localStorage.setItem("RNPlusFilterClients", JSON.stringify(data));
    }
    else if(state === 1){
        localStorage.setItem("RNPlusFilterContractorType", JSON.stringify(data));
    }
    else if(state === 2){
        localStorage.setItem("RNPlusFilterShiftType", JSON.stringify(data));
    }
}

export const getLocalStorage = (state) =>{
    let data;
    if(state === "startDate"){
        data = localStorage.getItem("RNPlusFilterStartDate");
        data = data ? data == "undefined" ? '' : data  :'';
    }
    else if(state === "endDate"){
        data = localStorage.getItem("RNPlusFilterEndDate");
        // console.log(data);
        data = data ? data == "undefined" ? '' : data  :'';
        // console.log(data);
    }
    else if(state === "clients"){
        data = localStorage.getItem("RNPlusFilterClients");
        data = data ? data == "undefined" ? '' : JSON.parse(data)  :[];
    }
    else if(state === "contractorType"){
        data = localStorage.getItem("RNPlusFilterContractorType");
        data = data ? data == "undefined" ? '' : JSON.parse(data)  :[];
    }
    else if(state === "shiftType"){
        data = localStorage.getItem("RNPlusFilterShiftType");
        data = data ? data == "undefined" ? '' : JSON.parse(data)  :[];
    }
    return data;
}