import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    CardBody,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";

import CloseIcon from "@material-ui/icons/Close";
import classnames from "classnames";
import Comments from "../../../component/Comments";
import General from "../ModalComponents/General/General";
import Location from "../ModalComponents/Map"
import History from "../ModalComponents/History/History";
import Notifications from "../ModalComponents/Notifications/Notification";
import Response from "../ModalComponents/Responses/Response";
import InvoicePayroll from "../ModalComponents/InvoicePayroll";
import { getAdminAPI } from "../../../APICall/index";
import { ORDER_LABEL } from "../../../APICall/urls";
import { postAdminAPI } from "../../../APICall/index";
import * as APIs from "../../../APICall/urls";

import "./ModalWrapper.scss";

const navTagTabs = [
    "General",
    "Location",
    "Notifications",
    "Responses",
    "Comments",
    "History",
    "Invoice and Payroll",
];

const ModalWrapper = (props) => {
    const [activeTab, setActiveTab] = useState("General");
    const [allInvoiceData, setAllInvoiceData] = useState({})
    const toggle = (tab) => {
        return activeTab !== tab
            ? setActiveTab(tab)
            : "";
    };

    // const checkCount = () =>{
    //     let count = 0;
    //     if( props.selectedOrder && props.selectedOrder.responses && props.selectedOrder.responses.length > 0){
    //         count = props.selectedOrder.responses.length;
    //     }
    //     else if(props.selectedResponse && props.selectedResponse.length > 0){
    //         count = props.selectedResponse.length;
    //     }
    //     // setCount(count)
    //     return count;
    // }

    // (props, "amrit")

    const checkClose = () => {
        if (props.selectedOrder?.id && props.change) {
            var proceed = window.confirm("The changes you have made will be lost. Do you want to continue?");
            if (proceed) {
                props.handleClose()
            }
        }
        else {
            props.handleClose()
        }
    }

    useEffect(() => {
        if(props.selectedOrder?.id){
            getDataInvoice()
        }
    }, [props.selectedOrder?.id])

    const getDataInvoice = async () => {
        let result = await postAdminAPI(APIs.VIEW_SINGLE_ORDER, { id: props.selectedOrder?.id })
        if (result.status) {
            let _data = { ...result.data }
            if (result.data.payrollResult) {
                let _payroll = result.data.payrollResult
                _data.payrollTotal = _payroll.total;
            }
            if (_data.billResult) {
                let _bill = _data.billResult
                _data.invoiceTotal = _bill.total
            }

            setAllInvoiceData(_data)
        }
    }

    const checkIsDisabled = () => {
        if (allInvoiceData.billInvoice || allInvoiceData.payrollCheque) {
            return true
        }
        return false;
    }

    return (
        <div>
            <Modal size="xl" isOpen={props.isOpen}>
                <ModalHeader style={{padding:"5px 10px"}}>
                    Shift Details
                    <CloseIcon
                        className={`header-close-icon`}
                        onClick={() => checkClose()}
                    />
                </ModalHeader>
                <ModalBody style={{padding: '0.5rem 1rem'}}>
                    <div className={`modal-body-container`}>
                        <Nav tabs>
                            {props.selectedOrder
                                ? navTagTabs.map((tabTag, i) => {
                                    return (
                                        <NavItem key={i}>
                                            <NavLink
                                                onClick={() => toggle(tabTag)}
                                                className={classnames({
                                                    active: activeTab === tabTag,
                                                })}
                                                style={{fontSize:12, padding: '0.5rem 0.5rem'}}
                                            >
                                                {tabTag === "Responses"
                                                    ?
                                                    props.selectedOrder && props.selectedOrder.responses && props.selectedOrder.responses.length > 0
                                                        ?
                                                        tabTag + "(" + props.selectedOrder.responses.length + ")"
                                                        : tabTag

                                                    : tabTag}
                                            </NavLink>
                                        </NavItem>
                                    );
                                })
                                : <NavItem>
                                    <NavLink
                                        onClick={() => toggle("General")}
                                        className={classnames({
                                            active: activeTab === "General",
                                        })}
                                    >
                                        {"General"}
                                    </NavLink>
                                </NavItem>

                            }

                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId={activeTab}>
                                {activeTab === "General" ? (
                                    <General
                                        clients={props.clients}
                                        contractors={props.contractors}
                                        shifts={props.shifts}
                                        contractorType={props.contractorType}
                                        selectedClient={props.selectedClient}
                                        selectedDate={props.selectedDate}
                                        selectedShift={props.selectedShift}
                                        handleSubmitShift={props.handleSubmitShift}
                                        selectedOrder={props.selectedOrder}
                                        allContacts={props.allContacts}
                                        selectedContractor={props.selectedContractor}
                                        updateResponse={props.updateResponse}
                                        allState={props.allState}
                                        toggle={() => checkClose()}
                                        handleChange={props.handleChange}
                                        isDisabled={checkIsDisabled()}
                                    />
                                ) : activeTab === "Location" ? (
                                    <Location
                                        selectedOrder={props.selectedOrder}
                                        isOpen={props.isOpen}
                                    />
                                ) : activeTab === "Notifications" ? (
                                    <Notifications
                                        contractors={props.contractors}
                                        handleAddNotification={props.handleAddNotification}
                                    />
                                ) : activeTab === "Responses" ? (
                                    <Response
                                        handleResponse={props.handleResponse}
                                        selectedOrder={props.selectedOrder}
                                    />
                                ) : activeTab === "History" ? (
                                    <History
                                        selectedHistory={props.selectedHistory}
                                    />
                                ) : activeTab === "Invoice and Payroll" ? (
                                    <InvoicePayroll
                                        selectedOrder={props.selectedOrder}
                                        allData={allInvoiceData}
                                        getData={getDataInvoice}
                                    />
                                ) : activeTab === "Comments" ? (
                                    <Comments
                                        addComments={props.handleAddComment}
                                        comments={props.selectedComments}

                                    />
                                ) : (
                                    ""
                                )}
                            </TabPane>
                        </TabContent>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};
export default ModalWrapper;
