import React, { useState, useEffect } from 'react';
import TableComponent from '../../../component/TableComponent';
import { contactColumns } from "./columns/contacts"
import { notiflixStatus } from '../../../component/Notiflix/notiflix'
import { changeTzToNormal, singleDate } from '../../../Utils/dateFunctions'
import ConfirmModal from "../../../component/confirm/confirmForOrder";

const sampleObject = {
    "date_time": "",
    "contact_by": "",
    "reason": "",
    "result": "",
    "comment": "",
    "used": 1,
}

const Contacts = (props) => {

    const [contacts, setContacts] = useState([]);
    const [allContacts, setAllContacts] = useState([]);
    const [selected, setSelected] = useState(null);
    const [deleteContact, setDeleteContact] = useState(null)

    const [userData, setUserData] = useState([
        {
            date: <input type="datetime-local" className="form-control" />,
            contacted: <input type="text" className="form-control" />,
            reason: <input type="text" className="form-control" />,
            result: <input type="text" className="form-control" />,
            comments: <input type="time" className="form-control" />,
            mod_by: "Amrit Anand",
            mod_on: "2020-10-10",
        },
    ])

    useEffect(() => {
        console.log(props, props.contactInfo)
        if (props.contactInfo && props.contactInfo.length >= 0) {
            let _allContacts = modifyData(props.contactInfo)
            setAllContacts(_allContacts);
            updateTable(_allContacts, selected)
        }
    }, [props])

    const modifyData = (all) => {
        let _all = [];
        all.forEach(h => {
            let date = h.date_time.split(" ");
            // console.log(date[0] + "T" + date[1])
            _all.push({
                ...h,
                date_time: date[0] + "T" + date[1]
            })
        })
        return _all
    }

    const updateTable = (allContacts, selected) => {
        let _allContacts = [];
        console.log(allContacts)
        allContacts.map((p, i) => {
            if (p.used == 1) {
                _allContacts.push({
                    date: <input
                        type="datetime-local"
                        className="form-control"
                        value={p.date_time}
                        onChange={(e) => handleChange(e, i, allContacts, "date_time")}
                    />,
                    contacted: <input
                        type="text"
                        className="form-control"
                        value={p.contact_by}
                        onChange={(e) => handleChange(e, i, allContacts, "contact_by")}

                    />,
                    reason: <input
                        type="text"
                        className="form-control"
                        value={p.reason}
                        onChange={(e) => handleChange(e, i, allContacts, "reason")}

                    />,
                    result: <input
                        type="text"
                        className="form-control"
                        value={p.result}
                        onChange={(e) => handleChange(e, i, allContacts, "result")}

                    />,
                    comments: <input
                        type="text"
                        className="form-control"
                        value={p.comment}
                        onChange={(e) => handleChange(e, i, allContacts, "comment")}

                    />,
                    mod_by: p.mod_by,
                    mod_on: singleDate(changeTzToNormal(p.mod_on)),
                    select: <input
                        type="checkbox"
                        className="form-control"
                        checked={selected == i ? true : false}
                        onChange={(e) => handleSelectCheck(e, i, allContacts)}
                    />,


                })
            }

        })
        // console.log(_allContacts)
        setContacts(_allContacts)
    }

    const handleChange = (e, i, allContacts, state, type) => {
        // console.log(e.target.value)
        let _allContacts = [...allContacts];
        // console.log(_allContacts, _allContacts[i], i, state)

        _allContacts[i][state] = e.target.value;

        updateTable(_allContacts, selected)
        setAllContacts(_allContacts)
    }

    const handleSelectCheck = (e, id, all) => {
        if (e.target.checked) {
            setSelected(id)
            updateTable(all, id)
        }
        else {
            setSelected(null)
            updateTable(all, null)
        }
        console.log(id, all)
    }

    const onDeleteBtnClick = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a contacts to delete.")
        }
        else {
            setDeleteContact(true)
        }
    }

    const handleDeleteButton = () => {
        let _all = [...allContacts];
        console.log(_all, selected)
        if (_all[selected].id) {
            props.deleteContacts(_all[selected].id)
            setSelected(null)
        }
        else {
            _all.splice(selected, 1);
            console.log(_all)
            setSelected(null)
            setAllContacts(_all)
            updateTable(_all, null)
        }
        setDeleteContact(null)
    }

    const saveData = () => {
        props.updateContacts(allContacts)
    }

    const copyRow = () => {
        if (selected === null) {
            notiflixStatus(false, "Please select a contacts to copy.")
        }
        else {
            let _contact = { ...allContacts[selected] };
            let _allContact = [...allContacts];
            if (_contact.id) {
                delete _contact.id
            }
            _allContact.push(_contact)
            updateTable(_allContact, null)
            setSelected(null)
        }
    }

    const addRow = () => {
        let _allContacts = [...allContacts];
        _allContacts.push({ ...sampleObject })
        console.log(_allContacts)
        updateTable(_allContacts, selected)
        setAllContacts(_allContacts)
    }

    return (
        <>
            <TableComponent
                rows={contacts}
                columns={contactColumns}
                addRow={addRow}
                deleteRow={onDeleteBtnClick}
                handleDeleteButton={onDeleteBtnClick}
                saveTable={saveData}
                copyRow={copyRow}
            />
            {
                deleteContact
                    ?
                    <ConfirmModal
                        modal={
                            {
                                message: "Do you want to delete this contact?",
                                proceed: true
                            }

                        }
                        title="Delete contact"
                        toggle={() => setDeleteContact(null)}
                        click={handleDeleteButton}
                    />
                    : null
            }
        </>
    )
}

export default Contacts;