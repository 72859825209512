// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/app";



const firebaseConfig = {
    apiKey: "AIzaSyCfUr0TfKiCAnT4aJdZ6EMDQsejBqARmiw",
    authDomain: "rnplus-71d5d.firebaseapp.com",
    databaseURL: "https://rnplus-71d5d-default-rtdb.firebaseio.com",
    projectId: "rnplus-71d5d",
    storageBucket: "rnplus-71d5d.appspot.com",
    messagingSenderId: "100461259014",
    appId: "1:100461259014:web:b3f374a9dbfab8c0e99687",
    measurementId: "G-ZV0G39Y353"
};

// Initialize Firebase
let fbase = firebase.initializeApp(firebaseConfig);
export default fbase;
