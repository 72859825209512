import React, { useState, useEffect } from 'react';
import SingleInfo from "./singleInfo";

const SingleChange = (props) => {

    // console.log(props)

    const [name, setName] = useState("");

    useEffect(() => {
        let old = props.old ? JSON.parse(props.old) : {};
        setName(old ? old.contractorName  : "")
    },[])

    const generateData = () =>{
        let card = [];
        // console.log(props.old);
        let old = props.old ? JSON.parse(props.old) : {};
        let section = Object.keys(old);
        // setName(old ? old.contractorName  : "")
        if(props.tableName === "contract_detail"){
            
           
    
            
            section && section.forEach((s, i) =>{
                card.push(
                    <SingleInfo 
                        name={s}
                        new={old[s].new}
                        old={props.new[s]}
                    />
                )
            })
        }
        else if(props.tableName === "contract_profile"){
           
            section && section.forEach((s, i) =>{
                let arr = ["mod_from", "mod_on", "mod_by", "ctr", "cred_type"];
                if(!arr.includes(s)){
                    card.push(
                        <SingleInfo 
                            name={s}
                            new={old[s]}
                            old={props.new ? props.new[s] : ""}
                        />
                    )
                }
                
            })
        }
        

        return card;
    }

    return (
        <div className="row border-top">
            <div className="col-lg-3">
                <div className="p-3">
                    <h5 className="font-medium text-inverse">Contractor: {name}</h5>
                    <h6 className="card-subtitle mt-2">{props.tableName === "contract_detail" ?"General Details" : "Credentials"}</h6>
                </div>
            </div>
            <div className="col-lg-7 border-left">
                <div className="card-body">
                    { generateData()}
                   
                </div>
            </div>
            <div className="col-lg-2 border-left">
                <div className="card-body">
                    <form action="forms/request_change.php" method="post">
                        <input type="hidden" name="sr_no" value="1" />
                        <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                            <button
                                type="button"
                                className="btn btn-success mb-2"
                                onClick={() => props.approve()}
                            >
                                Approve
                            </button>
                            <button
                                type="button"
                                className="btn btn-danger" name="reject"
                                onClick={() => props.reject()}
                            >
                                Reject
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SingleChange;