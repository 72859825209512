import * as actionTypes from './actionTypes';
import { postAdminAPI, getAdminAPI } from "../../APICall/index";
import { GET_ALL_CONTRACTOR, GET_ALL_SHIFT, GET_ALL_CLIENT, GET_CONTRACTOR_TYPE } from '../../APICall/urls';
import { GET_ALL_LIST } from '../../APICall/urls/settings';


export const updateClients = (data) => {
    return {
        type: actionTypes.GET_ALL_CLIENTS,
        payload: data
    };
};

export const updateContractor = (data) => {
    return {
        type: actionTypes.GET_ALL_CONTRACTORS,
        payload: data
    };
};

export const updateShifts = (data) => {
    return {
        type: actionTypes.GET_ALL_SHIFTS,
        payload: data
    };
};


export const updateContractorTypes = (data) => {
    return {
        type: actionTypes.GET_ALL_CONTRACTOR_TYPES,
        payload: data,
    };
};

export const updateSystemList = (contractorTypes, shiftTypes, workCode, credentialsType, carriers) => {
    return {
        type: actionTypes.GET_ALL_SYSTEM_LIST,
        contractorTypes: contractorTypes,
        shiftTypes: shiftTypes,
        workCode: workCode,
        credentialsType: credentialsType,
        carriers: carriers,
    };
};

export const getClients = () => {
    return dispatch => {
        postAdminAPI(GET_ALL_CLIENT)
            .then((res) => {
                if (res.status) {
                    dispatch(updateClients(res.data));
                }
            });

    };
};

export const getContractors =  () => {
    return dispatch => {
        postAdminAPI(GET_ALL_CONTRACTOR)
            .then((res) => {
                if (res.status) {
                    dispatch(updateContractor(res.data));
                }
            });

    };
};


export const getShifts = (client) => {
    return dispatch => {
        postAdminAPI(GET_ALL_SHIFT, { "client": client })
            .then((res) => {
                if (res.status) {
                    dispatch(updateShifts(res.data));
                }
            });;

    };
};


export const getContractorTypes =  () => {
    return dispatch => {
        postAdminAPI(GET_CONTRACTOR_TYPE)
            .then((res) => {
                if (res.status) {
                    dispatch(updateContractorTypes(res.data));
                    let _data = res.data.filter((f) => f.code != "RN/LPN");
                    dispatch(updateUniqueContractorType(_data));    
                }
            });
    };
};

export const getSystemList =  () => {
    return dispatch => {
        getAdminAPI(GET_ALL_LIST)
            .then((res) => {
                
                if (res) {
                    // console.log(res)
                    

                    dispatch(updateSystemList(res.contractorTypes, res.shiftType, res.workCode, res.credentialType, res.carrier));
                }
            });
    };
};

export const getAllBasicDetails = () => {
    return dispatch => {
        dispatch(getClients());
        dispatch(getContractors());
        dispatch(getContractorTypes());
        dispatch(getSystemList());
    }
}

export const updateRealTime = (data) => {
    // console.log(data, "dsadsadsad");
    return {
        type: actionTypes.REALTIME_UPDATE,
        payload: data
    };
};

export const updateRealTimeCheck = (data) => {
    return {
        type: actionTypes.REALTIME_UPDATE_CHECK,
        payload: data
    };
};

export const updateUniqueContractorType = (data) => {
    return {
        type: actionTypes.GET_ALL_UNIQUE_CONTRACTOR_TYPES,
        payload: data
    };
};

export const updateBothTime = (time) =>{
    return dispatch => {
        dispatch(updateRealTime(time));
        dispatch(updateRealTimeCheck(time));
    }
}







