import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

import { setBreadcrumbItems } from "../../../store/Breadcrumb/actions";
import { postAdminAPI, postAdminFormadataAPI, getAdminAPI } from '../../../APICall/index';
import {
    GET_ONE_CONTRACTOR, ADD_UPDATE_ONE_CONTRACTOR,
    GET_CONTRACTOR_CRED, ADD_CONTRACTOR_CRED, DELETE_CONTRACTOR_CRED, GET_All_CRED_TYPE,
    ADD_CONTRACTOR_COMMENT, GET_CONTRACTOR_COMMENT,
    UPLOAD_CONTRACTOR_FILE, GET_CONTRACTOR_FILE, DELETE_CONTRACTOR_FILE,
    GET_CONTRACTOR_CONTACT, ADD_CONTRACTOR_CONTACT, DELETE_CONTRACTOR_CONTACT,
    ADD_UPDATE_CUSTOM, GET_CUSTOM, ADD_PAYSLIP, GET_PAYSLIP
} from '../../../APICall/urls/contractor';
import { notiflixLoaderStart, notificationStatus } from '../../../component/Notiflix/notiflix'
import { generateAllJson } from "../../../Utils/arrayFunction";
import { updateContractor } from "../../../helpers/authUtils"

import General from "./General/Basic";
import Credentials from "./Credentials";
import Comments from './Comments';
import Documents from "./Documents";
import Contacts from "./Contacts";
import FollowUp from "./FollowUp"
import CustomInfo from "./CustomInfo";
import ModalAddCredentials from "./AddCredentialsModal";
import Payroll from "./Payroll";


const navTagTabs = [
    "General",
    "Credentials",
    "Comments",
    "Documents",
    "Contacts",
    // "FollowUp",
    "CustomInfo",
    "Payroll"
];

class SetUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            breadCrumbItems: [],
            activeTab: "General",

            selectedContractor: null,
            contractorDetails: {},
            contractorCredentials: [],
            selectedCredential: null,
            credModal: false,
            allCredType: [],
            comments: [],
            contactInfo: [],
            customInfo: {},
            allFiles: [],
            selectedCredentialId: null,
            paySlip: {},
        }
    }

    componentDidMount() {
        this.props.setBreadcrumbItems("Contractor Setup", this.state.breadCrumbItems);
        let contractor = parseInt(localStorage.getItem("RNPlusContractor"))
        this.setState({ selectedContractor: contractor })
        // console.log(contractor)
        if (contractor) {
            this.getContractorType();
            this.getContractorDetails(contractor)
            this.getAllCredentials(contractor);
            // this.getContractorCredentials(contractor)
            this.getContractorComments(contractor)
            this.getContacts(contractor);
            this.getCustomInfo(contractor);
            this.getDocuments(contractor)
            this.getPaySlip(contractor)
        }

    }

    getContractorType = async () => {
        // let cType = await postAdminAPI()
    }

    clean = (obj) => {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        }
        return obj
    }

    getAllContractor = async (contId) => {
        let data = await postAdminAPI('/contractor/get-name', {})
        // console.log(data, contId)

        updateContractor(data.data, contId)
        window.location.reload()
    }

    //---Setup
    getContractorDetails = async (contactor) => {
        let result = await postAdminAPI(GET_ONE_CONTRACTOR, { id: contactor })
        // console.log(result)
        if (result.status) {
            this.setState({ contractorDetails: result.data })
        }
    }
    modifyContractorDetails = async (data) => {
        notiflixLoaderStart()
        data = this.clean(data)
        // console.log(data);
        let contId = 0;
        if (data && data.id) {
            contId = data.id
        }
        let result = await postAdminAPI(ADD_UPDATE_ONE_CONTRACTOR, data)
        console.log(result)
        if (result.status) {
            // console.log(result)
            notificationStatus(result)
            this.getAllContractor(contId ? contId : result.data)
        }
        else {
            notificationStatus(result)
        }
    }

    //--Credentials
    getAllCredentials = async (contactor) => {
        // console.log(contactor)
        let result = await getAdminAPI(GET_All_CRED_TYPE)
        // console.log(result)
        if (result) {
            let _allCred = []
            result?.map(r => {
                _allCred.push({
                    id: r.id,
                    code: r.code,
                    files: [],
                })
            })
            this.setState({ allCredType: _allCred })
            this.getContractorCredentials(contactor, _allCred)
        }
    }
    getContractorCredentials = async (contactor, allCred) => {
        // console.log(contactor, allCred)
        let result = await postAdminAPI(GET_CONTRACTOR_CRED, { ctr: contactor })
        // console.log(result, "sadsad")
        if (result.status) {
            let allData = generateAllJson(result.data, allCred, "code")
            // console.log(allData)
            this.setState({ contractorCredentials: allData })
        }
    }
    addContractorCredentials = async () => {
        this.setState({ selectedCredential: null })
        this.toggleCredModal();
    }

    handleSelectedCredentials = (data, selectedCredentialId) => {
        this.setState({ selectedCredential: data, selectedCredentialId: selectedCredentialId })
    }


    modifyContractorCredentials = async () => {
        // console.log("sadsa")
        this.toggleCredModal();
        // notiflixLoaderStart()
        // let result = await postAdminAPI(ADD_CONTRACTOR_CRED, { data })
        // if (result) {
        //     console.log(result)
        //     notificationStatus(result)
        //     this.getContractorCredentials(this.state.selectedContractor)
        // }
    }
    deleteContractorCredentials = async (id) => {
        notiflixLoaderStart()
        // console.log(id)
        let result = await postAdminAPI(DELETE_CONTRACTOR_CRED, { id: id })
        if (result) {
            console.log(result)
            notificationStatus(result)
            this.getContractorCredentials(this.state.selectedContractor, this.state.allCredType)
            this.setState({ selectedCredential: null })
        }
    }

    toggleCredModal = () => {
        this.setState({ credModal: !this.state.credModal })
    }

    addNewContractorCred = async (apiData) => {
        notiflixLoaderStart()
        let result = await postAdminFormadataAPI(ADD_CONTRACTOR_CRED, apiData)
        if (result) {
            // console.log(result)
            notificationStatus({
                status: true,
                message: "Successfully added new credentials"
            })
            this.getContractorCredentials(this.state.selectedContractor, this.state.allCredType)
            this.toggleCredModal();
            this.setState({ selectedCredential: null })
        }
        else {
            notificationStatus({
                status: false,
                message: "Something went wrong."
            })
        }
    }

    //----------------Comments---------------------
    getContractorComments = async (contactor) => {
        // console.log(contactor)
        let result = await postAdminAPI(GET_CONTRACTOR_COMMENT, { ctr: contactor })
        // console.log(result)
        if (result.status) {
            this.setState({ comments: result.data })
        }
    }
    addComments = async (data) => {
        notiflixLoaderStart()
        data = {
            ...data,
            "ctr": this.state.selectedContractor
        };
        // console.log(data);
        let result = await postAdminAPI(ADD_CONTRACTOR_COMMENT, data)
        // console.log(result);
        if (result) {
            notificationStatus(result)
            this.getContractorComments(this.state.selectedContractor)
        }
    }


    //-----------------CONTACTS---------------
    getContacts = async (contactor) => {
        let data = {
            "ctr": contactor
        };
        // console.log(data)
        let result = await postAdminAPI(GET_CONTRACTOR_CONTACT, data)
        // console.log(result);
        if (result.status) {
            // console.log(result.data)
            this.setState({ contactInfo: result.data })
        }
    }
    updateContacts = async (data) => {
        notiflixLoaderStart()
        let _data = [];
        data && data.forEach(d => {
            let single = {
                "dateAndTime": d.date_time,
                "contactedBy": d.contact_by,
                "reason": d.reason,
                "result": d.result,
                "comment": d.comment
            }
            if (d.id) {
                single = {
                    ...single,
                    id: d.id
                }
            }
            _data.push(single)
        })

        data = {
            contacts: _data,
            "ctr": this.state.selectedContractor
        };
        // console.log(data);
        let result = await postAdminAPI(ADD_CONTRACTOR_CONTACT, data)
        // console.log(result);
        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.getContacts(this.state.selectedContractor)
            }
        }
    }
    deleteContacts = async (id) => {
        notiflixLoaderStart()
        let data = {
            "id": id
        };
        // console.log(data);
        let result = await postAdminAPI(DELETE_CONTRACTOR_CONTACT, data)
        // console.log(result);
        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.getContacts(this.state.selectedContractor)
            }
        }
    }

    //-----------------CUSTOM---------------
    getCustomInfo = async (contactor) => {
        let data = {
            "ctr": contactor
        };
        let result = await postAdminAPI(GET_CUSTOM, data)
        // console.log(result);
        if (result.status) {
            // console.log(result.data)
            this.setState({ customInfo: result.data })
        }
    }

    updateInfo = async (data) => {
        notiflixLoaderStart()
        data = {
            ...data,
            "ctr": this.state.selectedContractor
        };
        // console.log(data);
        let result = await postAdminAPI(ADD_UPDATE_CUSTOM, data)
        // console.log(result);
        if (result) {
            notificationStatus(result)
            if (result.status) {
                this.getCustomInfo(this.state.selectedContractor)
            }
        }
    }

    //--Documents
    getDocuments = async (contractor) => {
        let data = {
            "ctr": contractor
        };
        let result = await postAdminAPI(GET_CONTRACTOR_FILE, data)
        // console.log(result);
        if (result.status) {
            // console.log(result.data)
            this.setState({ allFiles: result.data })
        }
    }

    deleteDocuments = async (data) => {
        // console.log(data)
        let result = await postAdminAPI(DELETE_CONTRACTOR_FILE, data)
        // console.log(result);
        if (result) {
            notificationStatus(result)
            if (result.status)
                this.getDocuments(this.state.selectedContractor)
        }
    }

    uploadDocuments = async (data) => {
        notiflixLoaderStart()
        let formData = new FormData();
        formData.append("ctr", this.state.selectedContractor)
        formData.append("file", data)
        let result = await postAdminFormadataAPI(UPLOAD_CONTRACTOR_FILE, formData)
        // console.log(result);
        if (result) {
            result = {
                message: "Success",
                status: true,
            }
            // console.log(result.data)
            notificationStatus(result)
            if (result.status)
                this.getDocuments(this.state.selectedContractor)
        }
    }

    //PAYSLIP
    getPaySlip = async (id) => {
        let result = await postAdminAPI(GET_PAYSLIP, { "contractorId": id })
        console.log(result);
        if (result) {
            this.setState({ paySlip: result.data })
        }
    }

    updatePaySlip = async (data) => {
        notiflixLoaderStart()
        data = {
            ...data,
            contractorId: this.state.selectedContractor
        }
        if (this.state.paySlip?.id) {
            data = {
                ...data,
                id: this.state.paySlip.id
            }
        }
        let result = await postAdminAPI(ADD_PAYSLIP, data)
        // console.log(result);
        if (result.status) {
            notificationStatus(result)
            this.getPaySlip(this.state.selectedContractor)
        }
        else {
            notificationStatus(result)
        }
    }


    componentDidUpdate(prevProps) {

        // if(parseInt(localStorage.getItem("RNPlusContractor")) !== this.state.selectedContractor){
        //     let contractor = parseInt(localStorage.getItem("RNPlusContractor"))
        //     this.setState({selectedContractor: contractor})
        //     console.log(contractor)
        // }
    }

    toggle = (tab) => {
        return this.state.activeTab !== tab ? this.setState({ activeTab: tab }) : "";
    };

    render() {
        const { activeTab, contractorDetails, contractorCredentials, comments, contactInfo, customInfo, selectedContractor, allFiles, selectedCredential, selectedCredentialId, credModal, paySlip } = this.state;
        return (
            <div className={`card`}>
                <div className={`col-12`}>SetUp</div>
                <hr className={`setup-header-divider`} />
                <div className={`modal-body-container`}>
                    <Nav tabs>
                        {selectedContractor > 0 && navTagTabs.map((tabTag) => {
                            return (
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggle(tabTag)}
                                        className={classnames({
                                            active: activeTab === tabTag,
                                        })}
                                    >
                                        {tabTag}
                                    </NavLink>
                                </NavItem>
                            );
                        })}
                        {
                            selectedContractor == 0 ?
                                <NavItem>
                                    <NavLink
                                        onClick={() => this.toggle("General")}
                                        className={classnames({
                                            active: activeTab === "General",
                                        })}
                                    >
                                        {"General"}
                                    </NavLink>
                                </NavItem>
                                : null
                        }
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={activeTab}>
                            {activeTab === "General" ? (
                                <General
                                    contractorDetails={contractorDetails}
                                    modifyContractorDetails={this.modifyContractorDetails}
                                />
                            ) : activeTab === "Credentials" ? (
                                <Credentials
                                    contractorCredentials={contractorCredentials}

                                    add={this.addContractorCredentials}
                                    modify={this.modifyContractorCredentials}
                                    delete={this.deleteContractorCredentials}
                                    selected={selectedCredential}
                                    handleSelected={this.handleSelectedCredentials}
                                />
                            ) : activeTab === "Comments" ? (
                                <Comments
                                    addComments={this.addComments}
                                    comments={comments}
                                />
                            ) : activeTab === "Documents" ? (
                                <Documents
                                    allFiles={allFiles}
                                    deleteDocuments={this.deleteDocuments}
                                    uploadDocuments={this.uploadDocuments}
                                />
                            ) : activeTab === "Contacts" ? (
                                <Contacts
                                    contactInfo={contactInfo}
                                    deleteContacts={this.deleteContacts}
                                    updateContacts={this.updateContacts}
                                />
                            ) : activeTab === "FollowUp" ? (
                                <FollowUp />
                            ) : activeTab === "CustomInfo" ? (
                                <CustomInfo
                                    customInfo={customInfo}
                                    updateInfo={this.updateInfo}
                                />
                            ) : activeTab === "Payroll" ? (
                                <Payroll
                                    paySlip={paySlip}
                                    updatePaySlip={this.updatePaySlip}
                                />

                            ) : (
                                ""
                            )}
                        </TabPane>
                    </TabContent>
                </div>
                {
                    credModal
                        ?
                        <ModalAddCredentials
                            isOpen={credModal}
                            handleClose={this.toggleCredModal}
                            contractorCredentials={contractorCredentials}
                            addContractorCredentials={this.addNewContractorCred}
                            contractor={selectedContractor}
                            selectedCredential={selectedCredential}
                            selectedCredentialId={selectedCredentialId}
                        />
                        : null
                }
            </div>
        );
    }

};



export default connect(null, { setBreadcrumbItems })(SetUp);
