import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import './styles.scss';
const MySelect = React.forwardRef((props) => {
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState([]);

	useEffect(() =>{
		if(props.options.length){
			setOptions([...props.options])
		}
	},[props.options])

	const customStyles = {
		control: base => ({
			...base,
			maxHeight: '65px',
			width: '100%',
			minHeight: '65px',
			overflow: 'auto'
		})
	};

	const customStylesNoMin = {
		control: base => ({
			...base,
			maxHeight: '65px',
			overflow: 'auto'
		})
	};

	// const sortedOptions = [
	// 	...props.value,
	// 	...props.options.filter(option => !props.value.includes(option))
	// ];

	// const getSortedOptions = () => {


	// 	return [...props.options];
	// }

	useEffect(() => {
		let filteredArray = []
		if (props.value && props.value.length > 0 && !isOpen) {
			filteredArray = props.options.filter(
				item1 => !props.value.some(item2 => item1.value === item2.value)
			);
			setOptions([...props.value, ...filteredArray])
		}
	}, [isOpen])

	const handleMenuToggle = () => {
		setIsOpen(!isOpen);
	};

	console.log(isOpen);
	

	return (
		<div className="react-multi-checkbox" onClick={handleMenuToggle}>
			<ReactMultiSelectCheckboxes
				{...props}
				// styles={props.noMin ? customStylesNoMin :  customStylesNoMin}
				options={props.options}
				onChange={props.onChange}
				className="react-multi"
				dropdownButton={{ display: "flex" }}
				style={{ display: "flex" }}
			/>
		</div>
	);
});

export default MySelect;
