import React, { Component } from 'react';
import { connect } from "react-redux";

import EmptyData from "../../../component/EmptyData";

import { setBreadcrumbItems } from "../../../store/actions";
import { GET_ALL_CHANGES, UPDATE_CHANGES } from "../../../APICall/urls/settings";
import { getAdminAPI, postAdminAPI } from "../../../APICall/index";
import { notiflixLoaderStart, notificationStatus } from "../../../component/Notiflix/notiflix"

import SingleChange from "./singleChange"

class RequestOfChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [],
            allData: [], 
            a:[]
        }
    }

    //----Basic
    componentDidMount() {
        this.props.setBreadcrumbItems("Request of Change", this.state.breadcrumbItems);
        this.getAPIData();
    }

    //-----APIS
    getAPIData = async () => {
        let result = await getAdminAPI(GET_ALL_CHANGES);
        console.log(result);
        if (result) {
            this.setState({ allData: result });
        }

    }

    update = async (id, response) => {
        let postData = {
            "id": id,
            "response": response
        }
        let result = await postAdminAPI(UPDATE_CHANGES, postData);
        console.log(result);
        if(result){
            notificationStatus({status:true, message:"Success"})
            this.getAPIData();
        }
        else{
            notificationStatus({status:false, message:"Something went wrong. Please try again later."})
        }

    }



    render() {

        const { allData } = this.state;

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 ">
                    {
                        allData && allData.length > 0
                        ?
                        <div className="card">
                            {
                                allData.map((a, i) => (
                                    
                                    <SingleChange
                                        approve={() => this.update(a.id, "1")}
                                        reject={() => this.update(a.id, "2")}
                                        new={a.new}
                                        tableName={a.table_name}
                                        old={a.old}
                                        id={a.id}
                                        key={a.id}
                                    />
                                    
                                ))
                            }

                        </div>
                        : <EmptyData />
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, { setBreadcrumbItems })(RequestOfChange);;
