import React, { useState } from 'react';
import { Collapse, CardBody, Card, CardHeader, Row, Col } from "reactstrap";
import { changeTzToNormal, singleDate } from '../../Utils/dateFunctions'

const DescriptionCard = (props) => {
    // console.log(props.selected)
    return (
        <Card>
            <CardHeader className="card-header">
                Description
            </CardHeader>
            <CardBody style={{ color: '#5b626b' }}>

                <h3 >Shift Type: <span id="rate_span">{props.selected.children?.length ? props.selected.children[0].name : ""}</span></h3>
                <h3 >Description : <span id="desc_span">{props.selected.description}</span></h3>
                {/* <h3 >Last Modified On : <span id="mod_on_span">{singleDate(changeTzToNormal(props.selected.modified_at))}</span></h3>
                <h3 >Last Modified By : <span id="mod_by_span">{props.selected.modified_by}</span></h3> */}
                <hr />
                <h3 >Contractor Type : <span id="cont_span">{props.selected.description}</span></h3>
            </CardBody>
        </Card>
    )
}

export default DescriptionCard;