import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { dateBasicFormant, dateStandard } from "../../Utils/dateFunctions"
import "./styles.css";
const DatePickerSection = (props) => {
    const handleDate = (name, date) => {
        let _date = dateStandard(date);
        props.handleDateChange(name, _date);
    }
    // console.log(props);
    return (
        <DatePicker
            style={{ width: '100%' }}
            popperPlacement={"bottom"}
            required={props.required}
            className={`date-width ${props.className}`}
            placeholderText="mm/dd/yyyy"
            dateFormat="MM/dd/yyyy"
            selected={props.date ? dateBasicFormant(props.date) : null}
            onChange={(date) => handleDate(props.name, date)}
        />
    )
}

export default DatePickerSection;