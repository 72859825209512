export const columnsInvoiced = [
    {
        label: 'Shift Date',
        field: 'shift_date',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Shift Name',
        field: 'shift_name',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Contractor',
        field: 'contractorName',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Facility',
        field: 'clientName',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Start Time',
        field: 'startDate',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'End Time',
        field: 'endDate',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Type',
        field: 'contractorType',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'T Hrs',
        field: 't_hrs',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Employee Id',
        field: 'employee_id',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Supervisor',
        field: 'supervisor',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Supr. Signature',
        field: 'signature',
        sort: 'disabled',
        width: 150
    },
    {
        label: 'Processed date',
        field: 'paidDate',
        sort: 'disabled',
        width: 150
    },
]