import { UPDATE_CLIENT, UPDATE_CONTRACTOR } from './actionTypes';

const initialState = {
    selectedClient:null,
    selectedContractor:null,
}

const selectedUser = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_CLIENT:
            state = {
                ...state,
                selectedClient:action.client
            }
            break;
        case UPDATE_CONTRACTOR:
            state = {
                ...state,
                selectedContractor:action.contractor
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default selectedUser;