

const getStatusObject = (clientVerify, contVerify, tsVerified) => {
    // console.log(clientVerify, contVerify, tsVerified);
    return {
        clientVerify: clientVerify,
        contVerify: contVerify,
        tsVerified: tsVerified,
    }
}
export const staffingState = (status) => {

    if (status <= 1) {
        return getStatusObject(0, 0, 0)
    }
    else if (status <= 2) {
        return getStatusObject(1, 0, 0)
    }
    else if (status <= 3) {
        return getStatusObject(1, 1, 0)
    }
    else if (status >= 4) {
        return getStatusObject(1, 1, 1)
    }
}

export const getStatus = (status, checkState) => {
    let allStatus = [
        {
            "id": 1,
            "shortName": "open",
            "name": "New Order"
        },
        {
            "id": 2,
            "shortName": "approved_by_client",
            "name": "Confirmed by Client"
        },
        {
            "id": 3,
            "shortName": "acknowledged_by_contractor",
            "name": "Acknowledged by Contractor"
        },
        {
            "id": 4,
            "shortName": "timesheet_verified",
            "name": "TS Verified"
        },
        {
            "id": 5,
            "shortName": "contractor_cancelled",
            "name": "Contractor Cancelled"
        },
        {
            "id": 6,
            "shortName": "client_sent_home",
            "name": "CLient Sent Home"
        },
        {
            "id": 7,
            "shortName": "client_cancelled",
            "name": "Client Cancelled"
        },
        {
            "id": 8,
            "shortName": "admin_cancelled",
            "name": "Admin cancelled"
        },
        {
            "id": 9,
            "shortName": "no_call_no_show",
            "name": "No Call No Show"
        },
        {
            "id": 10,
            "shortName": "scheduling_error_by_admin",
            "name": "Scheduling Error by admin"
        },
        {
            "id": 11,
            "shortName": "scheduling_error_by_client",
            "name": "Scheduling Error by Client"
        },
        {
            "id": 13,
            "shortName": "signed_in",
            "name": "Signed In"
        },
        {
            "id": 14,
            "shortName": "signed_out",
            "name": "Signed Out"
        },
        {
            "id": 15,
            "shortName": "client_cancelled",
            "name": "Call Out"
        },
        {
            "id": 0,
            "shortName": "",
            "name": ""
        },

    ];
    let data = allStatus.filter((s) => s.id == status)[0];
    if (checkState) {

    }
    console.log(data);
    return data;
}

export const getStatusTest = (status) => {
    let allStatus = [
        {
            "id": 4,
            "shortName": "timesheet_verified",
            "name": "TS Verified"
        },
        {
            "id": 13,
            "shortName": "signed_in",
            "name": "Signed In"
        },
        {
            "id": 14,
            "shortName": "signed_out",
            "name": "Signed Out"
        }
    ];
    let data = allStatus.filter((s) => s.id == status)[0];
    if (!data || data.length <= 0) {
        return {
            "id": 0,
            "shortName": "",
            "name": ""
        }
    }
    return data;
}

export const getLabel = (allData, label) => {
    let list = JSON.parse(label)
    let items = []
    list?.forEach((l) => {
        let data = allData.filter((s) => s.id == l);
        if (data.length > 0)
            items.push(data[0].code)


    })
    return items.join(',')
}